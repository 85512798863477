import React, { memo, useRef } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { Z_INDEX_LEVEL } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { useZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { CellNew, CellOld } from '@atlassian/jira-portfolio-3-treegrid/src/ui/cell/index.tsx';
import { useColspan } from '../colspan/index.tsx';
import { useColumnCollapsedOld, useColumnCollapsedNew } from '../column-collapse/utils/state.tsx';
import { DragIndicator } from '../drag-and-drop/drag-indicator/index.tsx';
import { DropIndicator } from '../drag-and-drop/drop-indicator/index.tsx';
import { ColumnDragHandle } from '../draggable-column/drag-handle/index.tsx';
import { ColumnDropIndicator } from '../draggable-column/drop-indicator/index.tsx';
import { DraggableColumn } from '../draggable-column/index.tsx';
import type { InnerProps, Props } from './types.tsx';
import { useFocusWithin } from './utils.tsx';

export const EnhancedCell = ({
	hasBorderTop,
	hasBorderBottom,
	hasDragIndicator,
	hasDropIndicator,
	columnText,
	resizeHandle,
	clipped,
	hasBackground,
	columnId,
	isDraggableColumn,
	hasColumnDropIndicator,
	children,
	rowType = 'body',
	hasRowSpan = false,
	...restProps
}: Props) => {
	const { column } = restProps;

	const ref = useRef<HTMLTableCellElement>(null);
	const isFocusWithin = useFocusWithin(ref);
	const zIndex = useZIndex({
		rowType,
		column,
		level: (() => {
			if (hasRowSpan && fg('plans_performance_improvements_2')) {
				return Z_INDEX_LEVEL.STICKY;
			}
			if (isFocusWithin && fg('fix-cell-focus-within-zindex')) {
				return Z_INDEX_LEVEL.FOCUSED;
			}

			if (rowType === 'body') {
				return Z_INDEX_LEVEL.DEFAULT;
			}

			if (isFocusWithin && !fg('fix-cell-focus-within-zindex')) {
				return Z_INDEX_LEVEL.FOCUSED;
			}

			return undefined;
		})(),
	});
	const [collapsed] = fg('convert-column-provider-to-context')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useColumnCollapsedNew({ isHeader: rowType === 'header', column })
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useColumnCollapsedOld({ isHeader: rowType === 'header', column });

	const [colSpan] = fg('plans_performance_improvements_2')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useColspan({ row: rowType ?? 'body', column })
		: [0];

	let rowSpan;
	if (fg('plans_performance_improvements_2')) {
		rowSpan = collapsed && hasRowSpan ? 0 : 1;

		if (
			colSpan === 0 ||
			(collapsed && !hasRowSpan && rowType !== 'header' && rowType !== 'subheader')
		) {
			// do not render cell if behind the collapsed fields subheader columns
			return null;
		}
	}
	const Cell = fg('plans_performance_improvements_2') ? CellNew : CellOld;

	return (
		<Cell
			{...restProps}
			{...(fg('plans_performance_improvements_2') ? { colSpan } : {})}
			{...(rowSpan !== 1 && fg('plans_performance_improvements_2') ? { rowSpan } : {})}
			xcss={cellStyles}
			style={{ zIndex }}
			ref={ref}
		>
			<MemoedCellInner
				hasBorderTop={hasBorderTop}
				hasBorderBottom={hasBorderBottom}
				hasDragIndicator={hasDragIndicator}
				hasDropIndicator={hasDropIndicator}
				columnText={columnText}
				resizeHandle={resizeHandle}
				clipped={clipped}
				hasBackground={hasBackground}
				columnId={columnId}
				isDraggableColumn={isDraggableColumn}
				hasColumnDropIndicator={hasColumnDropIndicator}
				collapsed={collapsed}
			>
				{children}
			</MemoedCellInner>
		</Cell>
	);
};

const CellInner = ({
	hasBorderTop = false,
	hasBorderBottom = false,
	hasDragIndicator = false,
	hasDropIndicator = false,
	hasBackground = true,
	columnText,
	resizeHandle,
	clipped = true,
	columnId,
	isDraggableColumn = false,
	hasColumnDropIndicator = false,
	collapsed,
	children,
}: InnerProps & { collapsed: boolean }) => {
	const contents = (
		<Box
			xcss={[
				fg('convert-column-provider-to-context') ? wrapperStylesNew : wrapperStylesOld,
				hasBorderTop && borderTopStyles,
				hasBorderBottom && borderBottomStyles,
				!fg('convert-column-provider-to-context') && collapsed && collapsedStyles,
				clipped && clippedStyles,
				!hasBackground && stripBackgroundStyles,
				focusStyles,
			]}
		>
			{!collapsed && children}
		</Box>
	);

	return (
		<>
			{isDraggableColumn && columnId && fg('plan_timeline_drag_and_drop_field_columns') ? (
				<DraggableColumn
					columnId={columnId}
					renderTrigger={({ ref, ...props }) => (
						<ColumnDragHandle ref={ref} columnId={columnId} {...props} />
					)}
					isDisabled={collapsed}
				>
					{contents}
				</DraggableColumn>
			) : (
				contents
			)}
			{collapsed && columnText}
			{resizeHandle}
			{hasColumnDropIndicator && fg('plan_timeline_drag_and_drop_field_columns') && (
				<ColumnDropIndicator columnId={columnId} />
			)}
			{hasDragIndicator && (fg('plans_performance_improvements_2') ? !collapsed : true) && (
				<DragIndicator />
			)}
			{hasDropIndicator && (fg('plans_performance_improvements_2') ? !collapsed : true) && (
				<DropIndicator />
			)}
		</>
	);
};

const MemoedCellInner = memo(CellInner);

const cellStyles = xcss({
	backgroundColor: 'elevation.surface',
	verticalAlign:
		'top' /* Some content (e.g. group header) is hard-coded 39px height and meant to be aligned at top. */,
});

const wrapperStylesOld = xcss({
	position: 'absolute',
	inset: 'space.0',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderRight: `1px solid ${token('color.background.accent.gray.subtler', colors.N50)}`,
	background: 'var(--plan-grid-row-background)',
	overflow: 'initial',
	transition: 'background-color 0.1s ease',
});

const wrapperStylesNew = xcss({
	position: 'absolute',
	inset: 'space.0',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderRight: `1px solid ${token('color.background.accent.gray.subtler', colors.N50)}`,
	background: 'var(--plan-grid-row-background)',
	overflow: 'initial',
});

const borderTopStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.background.accent.gray.subtler', colors.N50)}`,
});

const borderBottomStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.background.accent.gray.subtler', colors.N50)}`,
});

const collapsedStyles = xcss({
	background: 'unset',
});

const clippedStyles = xcss({
	overflow: 'hidden',
});

const focusStyles = xcss({
	/*
	 * The `overflow: hidden` need to be reset when focusing on cells, such as Status Fields,
	 * Otherwise, the menu which opened when editing the cells would be clipped.
	 */
	':focus-within': {
		overflow: 'initial',
	},
});

const stripBackgroundStyles = xcss({
	'--plan-grid-row-background': 'elevation.surface',
	':hover': {
		// @ts-expect-error - TS2353: Object literal may only specify known properties
		'--plan-grid-row-background': 'elevation.surface',
	},
});
