.marker {
	position: absolute;
	border: 1px solid;
	height: 100%;
	z-index: 4;
	pointer-events: none;
	/* remove the next class and merge with the parent one when cleaning up plan-timeline-non-transposed */
	&.transposed {
		top: 0;
		z-index: unset;
	}
	&.releasedSpa {
		border-color: var(--ds-border-success, var(--adg3-color-G400));
	}

	&.unreleasedSpa,
	&.onTrackSpa {
		border-color: var(--ds-border-information, var(--adg3-color-B400));
	}

	&.offTrackSpa {
		border-color: var(--ds-border-danger, var(--adg3-color-R400));
	}
}
