import React, {
	type ReactNode,
	type KeyboardEvent,
	useState,
	useRef,
	useCallback,
	useEffect,
	useMemo,
} from 'react';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import classNames from 'classnames';
import SearchIcon from '@atlaskit/icon/core/search';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import SearchIconOld from '@atlaskit/icon/glyph/search';
import { Box } from '@atlaskit/primitives';
import AkTextField, { type TextFieldProps } from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import { IconButton } from '@atlassian/jira-portfolio-3-common/src/icon/index.tsx';
import HijackBrowserSearch from '@atlassian/jira-portfolio-3-keyboard-shortcuts/src/index.tsx';
import { useOnClickOutside } from '@atlassian/jira-portfolio-3-portfolio/src/common/hooks/use-click-outside.tsx';
import { SearchField } from '@atlassian/jira-searchfield/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import IssueSearchWarningFlag from './issue-search-warning-flag/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, SearchSummaryProps } from './types.tsx';

const SearchSummary = ({
	searchQuery,
	totalResults,
	onDismiss,
	activeSearchResultIndex,
	navigateResults,
}: SearchSummaryProps) => {
	const { formatMessage } = useIntl();
	const hasSearchQuery = searchQuery.trim() !== '';
	const displayedActiveIndex = activeSearchResultIndex + 1;

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.searchSummaryContainer}>
			{hasSearchQuery && (
				<>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className={styles.summaryCount}>
						{Math.min(displayedActiveIndex, totalResults)}/{totalResults}
					</span>
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={classNames(
							styles.iconWrapper,
							isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2')
								? styles.justifyCentered
								: undefined,
							{
								[styles.disabled]: totalResults === 0 || displayedActiveIndex === 1,
							},
						)}
					>
						<IconButton
							onClick={() => navigateResults('up')}
							ariaLabel={formatMessage(messages.viewPreviousSearchDataArialabel)}
						>
							<ChevronUpIcon label="" primaryColor={token('color.text.subtlest', colors.N90)} />
						</IconButton>
					</div>
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={classNames(
							styles.iconWrapper,
							isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2')
								? styles.justifyCentered
								: undefined,
							{
								[styles.disabled]: totalResults === 0 || displayedActiveIndex === totalResults,
							},
						)}
					>
						<IconButton
							onClick={() => navigateResults('down')}
							ariaLabel={formatMessage(messages.viewNextSearchDataArialabel)}
						>
							<ChevronDownIcon label="" primaryColor={token('color.text.subtlest', colors.N90)} />
						</IconButton>
					</div>
				</>
			)}
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.iconWrapper}
				data-testid="portfolio-3-portfolio.app-simple-plans.top.page-header.search.input-close"
			>
				<IconButton
					onClick={onDismiss}
					ariaLabel={formatMessage(messages.clearSearchDataArialabel)}
				>
					<CrossCircleIcon
						label=""
						size="small"
						primaryColor={token('color.text.subtle', colors.N70)}
					/>
				</IconButton>
			</div>
		</div>
	);
};

const Search = ({
	searchQuery,
	setSearchQuery,
	issueSearchResults: { total },
	searchIconClick,
	startedSearchResultNavigation,
	setActiveSearchResultIndex,
	activeSearchResultIndex,
	expandHierarchy,
	IssueSearchWarningFlagComponent = IssueSearchWarningFlag,
	toggleIssueSearch,
	isConfluenceMacro,
	isEmbed,
}: Props) => {
	const [searchString, setSearchString] = useState(searchQuery);
	const { formatMessage } = useIntl();
	const isVisuallyRefreshed = isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2');

	const closeSearchIfEmpty = () => {
		if (searchQuery.trim() === '') {
			toggleIssueSearch(false);
		}
	};

	const [isTouched, setIsTouched] = useState(false);

	const searchInputRef = useRef<HTMLInputElement>(null);

	const isSearchBoxEmpty = searchString.trim() === '';

	const closeSearchbar = () => {
		setSearchString('');
		toggleIssueSearch(false);
		searchInputRef.current && searchInputRef.current.blur();
	};

	const focusSearchInput = () => {
		if (!isNil(searchInputRef) && !isNil(searchInputRef.current)) {
			// This is the same way the global search input prevents
			// the shortcut from being fed into the input
			// See: src/packages/navigation-apps/atlassian-navigation/src/ui/search/main.js
			setTimeout(() => searchInputRef.current && searchInputRef.current.focus(), 0);
		}
	};

	const navigateResults = (direction: 'up' | 'down') => {
		// the analytics are triggered only when the user starts to browse through the matches
		if (!isTouched) {
			setIsTouched(true);
			startedSearchResultNavigation();
		}

		const nextIndex =
			direction === 'up'
				? Math.max(0, activeSearchResultIndex - 1)
				: Math.min(activeSearchResultIndex + 1, total - 1);
		setActiveSearchResultIndex(nextIndex);
		expandHierarchy();
	};

	const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
		const { key, shiftKey } = e;
		if (key === 'Escape') {
			closeSearchbar();
		} else if (key === 'ArrowUp' || (key === 'Enter' && shiftKey)) {
			navigateResults('up');
		} else if (key === 'ArrowDown' || key === 'Enter') {
			navigateResults('down');
		}
	};

	const searchQueryChange = useCallback(
		(query: string) => {
			setSearchQuery(query);
			setActiveSearchResultIndex(0);
			expandHierarchy();
		},
		[expandHierarchy, setActiveSearchResultIndex, setSearchQuery],
	);

	const debouncedSearchQueryChange = useMemo(
		() => debounce(searchQueryChange, 300),
		[searchQueryChange],
	);

	useEffect(() => {
		debouncedSearchQueryChange(searchString);
	}, [debouncedSearchQueryChange, searchString]);

	useEffect(() => {
		setSearchString(searchQuery);
	}, [searchQuery]);

	const searchBox = useRef<HTMLDivElement>(null);

	useOnClickOutside(searchBox, () => closeSearchIfEmpty());

	const elemAfterInput = isSearchBoxEmpty ? (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.searchIconWrapper}>
			<SearchIconOld
				label={formatMessage(messages.searchIconLabel)}
				size="small"
				primaryColor={token('color.text.subtlest', colors.N90)}
			/>
		</div>
	) : (
		<SearchSummary
			searchQuery={searchString}
			onDismiss={() => closeSearchbar()}
			totalResults={total}
			activeSearchResultIndex={activeSearchResultIndex}
			navigateResults={navigateResults}
		/>
	);

	const elemBeforeInput = (
		<Box paddingInlineStart="space.100">
			<SearchIcon
				color="currentColor"
				label={formatMessage(messages.searchIconLabel)}
				spacing="none"
			/>
		</Box>
	);

	const renderCustomSearchField = ({
		textFieldProps,
	}: {
		textFieldProps: TextFieldProps;
		searchIcon: ReactNode;
	}) => {
		const textField = (
			<AkTextField
				{...textFieldProps}
				elemAfterInput={isVisuallyRefreshed ? !isSearchBoxEmpty && elemAfterInput : elemAfterInput}
				elemBeforeInput={isVisuallyRefreshed ? elemBeforeInput : undefined}
				isCompact
				width={isVisuallyRefreshed ? '200px' : undefined}
				onKeyDown={(e) => {
					handleKeyPress(e);
				}}
			/>
		);
		return textField;
	};

	return (
		<div
			data-testid="portfolio-3-portfolio.app-simple-plans.top.page-header.search"
			ref={searchBox}
		>
			<HijackBrowserSearch
				onFocusSearch={() => {
					toggleIssueSearch(true);
					searchIconClick();
					focusSearchInput();
				}}
			/>
			<IssueSearchWarningFlagComponent />
			<SearchField
				maxLength={255}
				onChange={(e) => {
					toggleIssueSearch(true);
					setSearchString(e);
					searchIconClick();
				}}
				value={searchString}
				ariaLabelText={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.searchFieldAriaLabelIssueTermRefresh
						: messages.searchFieldAriaLabel,
				)}
				placeholder={formatMessage(
					isVisuallyRefreshed ? messages.searchPlaceholder : messages.searchPlaceholderOld,
				)}
				inputRef={searchInputRef}
				renderCustomSearchField={renderCustomSearchField}
				isAutocompleteDisabled={isConfluenceMacro || isEmbed}
				placeholderAlwaysVisible={isVisuallyRefreshed}
				isAlwaysExpanded={isVisuallyRefreshed}
			/>
		</div>
	);
};

export default Search;
