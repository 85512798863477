import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	optimize: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.optimize',
		defaultMessage: 'Auto-schedule',
		description: 'Label for the button used to start auto-scheduling',
	},
	optimizeTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.optimize-tooltip',
		defaultMessage: 'Schedule work based on estimates',
		description: 'It is a tooltip for the button used to start auto-scheduling',
	},
	noTeamsAvailableTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.no-teams-available-tooltip',
		defaultMessage: 'Add a team to your plan to auto-schedule issues',
		description:
			'Label for the tooltip on the button used to start selective auto-scheduling when no teams are available in the plan',
	},
	noTeamsAvailableTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.no-teams-available-tooltip-issue-term-refresh',
		defaultMessage: 'Add a team to your plan to auto-schedule issues',
		description:
			'Label for the tooltip on the button used to start selective auto-scheduling when no teams are available in the plan',
	},
});
