import React, { useState, type ReactNode, type ReactElement } from 'react';
import classNames from 'classnames';
import * as R from 'ramda';
import Lozenge from '@atlaskit/lozenge';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { FlagGroup, AkAutoDismissFlag as AutoDismissFlag } from '@atlassian/jira-flags';
import { type IntlShape, type MessageDescriptor, useIntl } from '@atlassian/jira-intl';
import type { CSSObject } from '@atlassian/jira-portfolio-3-common/src/common/types/css-object.tsx';
import {
	DefaultDateFormat,
	IsoDateFormat,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import {
	formatDateUTC,
	useDateFormatter,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import type { FlagData } from '@atlassian/jira-portfolio-3-common/src/flag/types.tsx';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import ScrollLock from '@atlassian/jira-portfolio-3-common/src/scroll-lock/index.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import { getOptimizedValue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util.tsx';
import {
	DATEFIELD_TYPES,
	ISSUE_INFERRED_DATE_SELECTION,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	withSlots,
	slots,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import RollupIcon from '@atlassian/jira-portfolio-3-portfolio/src/common/icons/rollup.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import Cell from '../../column/cell/view.tsx';
import cellMessages from '../messages.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import { type DateFieldTypes, type Props, DateTypes } from './types.tsx';
import {
	issueField,
	labels,
	getDateDiff,
	getTheMethodOfDateInferrence,
	handleChange as handleChangeUtil,
} from './utils.tsx';

const DateCell = ({
	dateConfiguration,
	attribute: attributeProp,
	type,
	issue: { summary },
	isReadOnly,
	isScrolling,
	issue,
	showOptimizations = false,
	onChange,
	DatePicker,
	isExportMode,
}: Props) => {
	const intl = useIntl();
	const locale = useLocale();
	const { formatDate: formatDateIntl, formatTimestamp } = useDateFormatter();
	const [isActive, setIsActive] = useState(false);
	const [flags, setFlags] = useState<FlagData[]>([]);
	const [isHovered, setIsHovered] = useState(false);
	const [isValid, setIsValid] = useState(true);

	const mapDateToFormattedValue = (_: ScopeIssue, value?: number): string => {
		if (!value) {
			return '';
		}
		return formatDateUTC(value, DefaultDateFormat);
	};

	const attribute = issueField[type] ?? attributeProp;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const date = issue[attribute as keyof ScopeIssue];
	const label: MessageDescriptor = labels[type];

	let value = showOptimizations ? getOptimizedValue(issue, attribute) : date;

	if (isDefined(value)) {
		// for browser comparability(e.g Safari) we have to pass value to datepicker only ISO date format
		value = formatDateUTC(value, IsoDateFormat);
	} else {
		value = '';
	}

	/*
	 * Ex-class methods
	 */

	const isCellDirty = (): boolean => {
		const { baselineStartField, baselineEndField } = dateConfiguration;
		const startDatePath =
			baselineStartField.type === DATEFIELD_TYPES.CUSTOM
				? ['customFields', baselineStartField.key]
				: [baselineStartField.key];
		const endDatePath =
			baselineEndField.type === DATEFIELD_TYPES.CUSTOM
				? ['customFields', baselineEndField.key]
				: [baselineEndField.key];

		if (type === DateTypes.Start) {
			return !!issue.originals && R.hasPath(startDatePath, issue.originals);
		}
		if (type === DateTypes.End) {
			return !!issue.originals && R.hasPath(endDatePath, issue.originals);
		}
		if (type === DateTypes.Other) {
			return !!issue.originals && R.has(attribute, issue.originals);
		}
		return false;
	};

	const handleChange = (changedDate: string) => {
		handleChangeUtil(changedDate, {
			onChange,
			issue,
			type,
			attribute,
			flags,
			setFlags,
			intl,
			setIsValid,
		});
	};

	const getAlignedDate = (
		isDatePickerActive: boolean,
		dateType: DateFieldTypes,
		baseLineStart: Timestamp | null | undefined,
		baseLineEnd: Timestamp | null | undefined,
		rawDate: string,
	) => {
		// if the calendar Date Picker is triggered
		if (isDatePickerActive) {
			// if the calendar is End type and Start Date is set but Due Date is not defined
			// then we set start date as initial end date
			// else if the calendar is Start type and Due Date is set but Start Date is not defined,
			// we set due date as initial start date
			if (dateType === DateTypes.End && isDefined(baseLineStart) && !isDefined(baseLineEnd)) {
				return formatDateUTC(baseLineStart, IsoDateFormat);
			}

			if (dateType === DateTypes.Start && isDefined(baseLineEnd) && !isDefined(baseLineStart)) {
				return formatDateUTC(baseLineEnd, IsoDateFormat);
			}
		}
		return rawDate;
	};

	const renderDatePicker = ({
		// eslint-disable-next-line @typescript-eslint/no-shadow
		intl,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		isReadOnly,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		label,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		summary,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		value,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		type,
	}: {
		intl: IntlShape;
		isReadOnly: boolean | undefined;
		label: MessageDescriptor;
		summary: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value: any;
		type: DateFieldTypes;
	}) => {
		const adjustedDate = getAlignedDate(
			isActive,
			type,
			issue.baselineStart,
			issue.baselineEnd,
			value,
		);
		return (
			<div
				aria-label={intl.formatMessage(label, { issue: summary })}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={classNames({
					[styles['cell-valid']]: isValid,
					[styles['cell-invalid']]: !isValid,
					[styles.isReadOnly]: isReadOnly,
				})}
			>
				<ScrollLock enabled={isActive} />
				<HoverObserver onHoverChanged={setIsHovered}>
					<DatePicker
						autoFocus={isActive && (type === DateTypes.Start || type === DateTypes.End)}
						isDisabled={isReadOnly}
						isTransparentBackground
						onChange={handleChange}
						onToggle={setIsActive}
						placeholder={intl.formatMessage(messages.placeholder)}
						showClearIndicator
						styles={{
							// eslint-disable-next-line @typescript-eslint/no-shadow
							placeholder: (styles: CSSObject) => ({
								display: !isHovered && 'none',
								textOverflow: 'ellipsis',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								width: 'calc(100% - 9px)',
								...styles,
							}),
						}}
						value={adjustedDate}
						label={intl.formatMessage(messages.placeholder)}
					/>
				</HoverObserver>
			</div>
		);
	};

	// Used to format rollup dates
	const formattedDate = ((): string => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		let date = 0;
		if (type === DateTypes.Start) {
			if (!isDefined(issue.baselineStart)) {
				return '';
			}
			date = issue.baselineStart;
		} else if (type === DateTypes.End) {
			if (!isDefined(issue.baselineEnd)) {
				return '';
			}
			date = issue.baselineEnd;
		}

		return formatTimestamp(date);
	})();

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const renderInferredDate = (tooltip: ReactNode, icon: ReactElement, summary?: string) => (
		<div
			onClick={() => setIsActive(true)}
			onKeyDown={() => setIsActive(true)}
			role="button"
			tabIndex={0}
			{...(fg('portfolio-colour-sortby-a11y-fix') && {
				'aria-label': intl.formatMessage(label, { issue: summary }),
			})}
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.projectedDate}>
				{isExportMode ? (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.exportPNGLozenge}>{icon}</div>
				) : (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.rollUpIcon}>
						<Tooltip content={tooltip}>{icon}</Tooltip>
					</div>
				)}
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.projectedDateText}>{formattedDate}</div>
			</div>
		</div>
	);

	const getContent = ({
		// eslint-disable-next-line @typescript-eslint/no-shadow
		intl,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		isReadOnly,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		label,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		showOptimizations,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		summary,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		value,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		type,
	}: {
		intl: IntlShape;
		isReadOnly: boolean | undefined;
		label: MessageDescriptor;
		showOptimizations: boolean;
		summary: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value: any;
		type: DateFieldTypes;
	}) => {
		const methodOfDateInferrence = getTheMethodOfDateInferrence(issue, type);

		const tooltipForInferredDateUsingSprint = intl.formatMessage(
			fg('jira-issue-terminology-refresh-m3')
				? messages.projectedDatesUsingSprintTooltipIssueTermRefresh
				: messages.projectedDatesUsingSprintTooltip,
		);

		const iconForInferredDateUsingSprint = <Lozenge>S</Lozenge>;

		const tooltipForInferredDateUsingRelease = intl.formatMessage(
			fg('jira-issue-terminology-refresh-m3')
				? messages.projectedDatesUsingReleasesTooltipIssueTermRefresh
				: messages.projectedDatesUsingReleasesTooltip,
		);

		const iconForInferredDateUsingRelease = <Lozenge>R</Lozenge>;

		const tooltipForInferredDateUsingRollUp = intl.formatMessage(
			cellMessages.rolledUpEstimateToolip,
			{
				value: formattedDate || intl.formatMessage(messages.noDate),
			},
		);

		const iconForInferredDateUsingRollUp = <RollupIcon label="" />;

		if (!showOptimizations && (!isActive || isReadOnly)) {
			if (methodOfDateInferrence === ISSUE_INFERRED_DATE_SELECTION.SPRINT) {
				return renderInferredDate(
					tooltipForInferredDateUsingSprint,
					iconForInferredDateUsingSprint,
					summary,
				);
			}
			if (methodOfDateInferrence === ISSUE_INFERRED_DATE_SELECTION.RELEASE) {
				return renderInferredDate(
					tooltipForInferredDateUsingRelease,
					iconForInferredDateUsingRelease,
					summary,
				);
			}
			if (methodOfDateInferrence === ISSUE_INFERRED_DATE_SELECTION.ROLL_UP) {
				return renderInferredDate(
					tooltipForInferredDateUsingRollUp,
					iconForInferredDateUsingRollUp,
					summary,
				);
			}
		}

		return renderDatePicker({
			intl,
			isReadOnly,
			label,
			summary,
			value,
			type,
		});
	};

	const getWireFrame = ({
		// eslint-disable-next-line @typescript-eslint/no-shadow
		intl,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		isReadOnly,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		label,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		showOptimizations,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		summary,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		value,
	}: {
		intl: IntlShape;
		isReadOnly: boolean | undefined;
		label: MessageDescriptor;
		showOptimizations: boolean;
		summary: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value: any;
	}) => {
		const className = classNames(
			styles.wireframe,
			styles[isValid ? 'cell-valid' : 'cell-invalid'],
			styles[showOptimizations ? 'readOnly' : ''],
		);

		function getFormattedValue(): string {
			return formatDateIntl(value);
		}

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div aria-label={intl.formatMessage(label, { issue: summary })} className={className}>
				<input
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={value ? '' : styles.placeholder}
					value={value ? getFormattedValue() : ''}
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onChange={() => {}}
					readOnly={isReadOnly}
					disabled={isReadOnly}
				/>
			</div>
		);
	};

	const dismissFlag = () => {
		setFlags(flags.slice(1));
	};

	const warningFlags = () =>
		flags.length > 0 ? (
			<FlagGroup onDismissed={dismissFlag}>
				{flags.map(({ ...flag }, index: number) => (
					<AutoDismissFlag {...flag} key={index} />
				))}
			</FlagGroup>
		) : null;

	return (
		<Cell
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			attribute={attribute as string}
			isCellDirty={isCellDirty}
			issue={issue}
			valueMapper={mapDateToFormattedValue}
			delta={getDateDiff}
			isScrolling={isScrolling}
			showOptimizations={showOptimizations}
			locale={locale}
		>
			{!isScrolling
				? getContent({
						intl,
						isReadOnly,
						label,
						showOptimizations,
						summary,
						value,
						type,
					})
				: getWireFrame({
						intl,
						isReadOnly,
						label,
						showOptimizations,
						summary,
						value,
					})}
			{warningFlags()}
		</Cell>
	);
};

export default withSlots({ DatePicker: slots.FieldDatePicker })(DateCell);
