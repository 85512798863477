import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import StandardButton from '@atlaskit/button/standard-button';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const RemoveIssuesDialog = ({ toggleModalDialog, bulkActionSuccess, removeFromPlan }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<ShortcutScope>
			<ModalDialog shouldScrollInViewport onClose={toggleModalDialog} autoFocus={false}>
				<ModalHeader>
					<ModalTitle appearance="danger">
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.removeFromPlanTitleIssueTermRefresh
								: messages.removeFromPlanTitle,
						)}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Text>
						<FormattedMessage
							{...(fg('jira-issue-terminology-refresh-m3')
								? messages.removeIssueFromPlanMessageIssueTermRefresh
								: messages.removeIssueFromPlanMessage)}
							values={{
								b: (chunks) => <b>{chunks}</b>,
							}}
						/>
					</Text>
				</ModalBody>
				<ModalFooter>
					<StandardButton appearance="subtle" onClick={toggleModalDialog}>
						{formatMessage(commonMessages.cancel)}
					</StandardButton>
					<StandardButton
						appearance="primary"
						onClick={(_, analyticsEvent: UIAnalyticsEvent) => {
							removeFromPlan();
							bulkActionSuccess(analyticsEvent);
						}}
					>
						{formatMessage(messages.removeFromPlanActionButton)}
					</StandardButton>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
};

export default RemoveIssuesDialog;
