import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	titlePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.title-placeholder',
		defaultMessage: 'Issue summary ...',
		description: 'Placeholder message for empty inline create title input',
	},
	ariaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.aria-label',
		defaultMessage: 'Issue summary',
		description: 'Aria label for inline create input in Plans',
	},
	savingIssuePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.saving-issue-placeholder',
		defaultMessage: 'Creating issue ...',
		description: 'Placeholder message when saving an issue',
	},
	selectProject: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.select-project',
		defaultMessage: 'Choose project',
		description: 'Default option for select projects',
	},
	selectIssueType: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.select-issue-type',
		defaultMessage: 'Choose issue type',
		description: 'Description text for issue type selector',
	},
	blankInputErrorMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.blank-input-error-message',
		defaultMessage: 'You must specify a summary of the issue.',
		description: 'Error message displayed when the issue summary is blank',
	},
	notMatchFilterMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.not-match-filter-message',
		defaultMessage: "This issue will be created, but won't be visible due to the applied filters.",
		description:
			'Error message displayed when the issue summary is valid, but does not match the applied filters and will therefore be hidden once created',
	},
	tooLongInputErrorMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.too-long-input-error-message',
		defaultMessage: 'Summary must be less than 256 characters.',
		description:
			'Error message displayed when the user enters an issue summary longer than 255 characters',
	},
	titlePlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.title-placeholder-issue-term-refresh',
		defaultMessage: 'Issue summary ...',
		description: 'Placeholder message for empty inline create title input',
	},
	ariaLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.aria-label-issue-term-refresh',
		defaultMessage: 'Issue summary',
		description: 'Aria label for inline create input in Plans',
	},
	savingIssuePlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.saving-issue-placeholder-issue-term-refresh',
		defaultMessage: 'Creating issue ...',
		description: 'Placeholder message when saving an issue',
	},
	selectIssueTypeIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.select-issue-type-issue-term-refresh',
		defaultMessage: 'Choose issue type',
		description: 'Description text for issue type selector',
	},
	blankInputErrorMessageIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.blank-input-error-message-issue-term-refresh',
		defaultMessage: 'You must specify a summary of the issue.',
		description: 'Error message displayed when the issue summary is blank',
	},
	notMatchFilterMessageIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.not-match-filter-message-issue-term-refresh',
		defaultMessage: "This issue will be created, but won't be visible due to the applied filters.",
		description:
			'Error message displayed when the issue summary is valid, but does not match the applied filters and will therefore be hidden once created',
	},
});
