import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
// Remove with the cleanup of plan-timeline-non-transposed
// Also move contents of view.tsx back into index.tsx
import ScrollLockOld from './view-old.tsx';
import ScrollLockNew from './view.tsx';

const ScrollLock = componentWithCondition(
	() => !fg('plan-timeline-non-transposed'),
	ScrollLockNew,
	ScrollLockOld,
);

export default ScrollLock;
