import React, { type ReactNode, type RefObject, useRef } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useDragTarget,
	useDropTarget,
} from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/bindings/index.tsx';
import { useCellOld } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';

type Props = {
	children: (ref: RefObject<HTMLDivElement>, isDragging: boolean) => ReactNode;
};

const DraggableIssue = ({ children }: Props) => {
	const ref = useRef<HTMLDivElement>(null);
	let row;
	if (fg('plans_performance_improvements_2')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ row } = useRow());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[{ row }] = useCellOld();
	}

	const { isDragging } = useDragTarget(ref, row);
	useDropTarget(ref, row);

	return <>{children(ref, isDragging)}</>;
};

export default DraggableIssue;
