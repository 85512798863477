import React, { useCallback, type ReactNode } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { useRedirectToSpaPlanPage } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/redirect-to-spa-plan-page/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function NoIssuesEmptyState(_props: Record<any, any>) {
	const redirectToSpaPlanPage = useRedirectToSpaPlanPage();

	const onClick = useCallback(() => {
		redirectToSpaPlanPage('/settings/issue-sources');
	}, [redirectToSpaPlanPage]);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.emptyView}>
			{_props.isReadOnly ? (
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.emptyViewMessageReadOnlyIssueTermRefresh
						: messages.emptyViewMessageReadOnly)}
				/>
			) : (
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.emptyViewMessageIssueTermRefresh
						: messages.emptyViewMessage)}
					values={{
						button: (chunks: ReactNode[]) => (
							<Button target="_blank" appearance="link" onClick={onClick} spacing="none">
								{chunks}
							</Button>
						),
					}}
				/>
			)}
		</div>
	);
}
