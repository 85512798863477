import { type Effect, delay } from 'redux-saga';
import * as R from 'ramda';
import { call, fork, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { IssueValues } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import fetch, {
	aggressiveFetch,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { colourByOptions } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import {
	CustomFieldTypes,
	type CustomFieldType,
	CustomFields,
	ERROR_REPORTING_TEAM,
	GROUPING,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	getCustomFieldIdFromCustomFieldGrouping,
	isRoadmapGroupedByCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import { transformPersons } from '../../query/assignees/utils.tsx';
import { getCustomFields, getCustomFieldsByKey } from '../../query/custom-fields/index.tsx';
import { getOriginalIssues } from '../../query/issues/index.tsx';
import { getIssues } from '../../query/raw-issues/index.tsx';
import {
	getColorByValue,
	getVisualisationGrouping,
	getVisualisationSorting,
} from '../../query/visualisations/index.tsx';
import type { JiraUser } from '../../state/domain/assignees/types.tsx';
import { add, update, remove } from '../../state/domain/custom-fields/actions.tsx';
import type { CustomField } from '../../state/domain/custom-fields/types.tsx';
import { reset as resetCustomLabels } from '../../state/domain/custom-labels/actions.tsx';
import { reset as resetSelectOptions } from '../../state/domain/select-options/actions.tsx';
import { reset as resetUserPickerOptions } from '../../state/domain/user-picker-options/actions.tsx';
import { setColourByValue } from '../../state/domain/view-settings/colour-by/actions.tsx';
import { reset as resetCustomFieldFilter } from '../../state/domain/view-settings/filters/custom-field-filter/actions.tsx';
import {
	changeGroup,
	changeSort,
} from '../../state/domain/view-settings/visualisations/actions.tsx';
import { defaultSorting } from '../../state/domain/view-settings/visualisations/types.tsx';
import type { State } from '../../state/types.tsx';
import {
	fetchUserStart,
	fetchUserStop,
} from '../../state/ui/main/tabs/roadmap/fields/custom-fields/user-picker/actions.tsx';
import { POST } from '../api.tsx';
import { getUsers } from '../assignees/index.tsx';
import { getBacklog } from '../backlog/index.tsx';
import { jsonOrError } from '../http/index.tsx';
import { toErrorID } from '../util.tsx';
import {
	type SelectOptionPayload,
	urls,
	getUpdateCustomFieldsBody,
	getRemoveCustomFieldBody,
	getUserPickerCustomFieldOptionsBody,
	getLabelForFieldBody,
	getAddCustomFieldsBody,
	getCustomFieldVisibleOptionsBody,
} from './api.tsx';

export const ADD_CUSTOM_FIELDS = 'command.custom-fields.ADD_CUSTOM_FIELDS' as const;
export const REMOVE_CUSTOM_FIELD = 'command.custom-fields.REMOVE_CUSTOM_FIELD' as const;
export const FETCH_SELECT_OPTIONS = 'command.custom-fields.FETCH_SELECT_OPTIONS' as const;
export const FETCH_USER_PICKER_OPTIONS = 'command.custom-fields.FETCH_USER_PICKER_OPTIONS' as const;
export const FETCH_USER_PICKER_OPTIONS_BY_IDS =
	'command.custom-fields.FETCH_USER_PICKER_OPTIONS_BY_IDS' as const;
export const POPULATE_USER_PICKER_OPTIONS =
	'command.custom-fields.POPULATE_USER_PICKER_OPTIONS' as const;
export const TOGGLE_CUSTOM_FIELD_FILTERABILITY =
	'command.custom-fields.TOGGLE_CUSTOM_FIELD_FILTERABILITY' as const;

export type ToggleCustomFieldFilterabilityActionPayload = {
	filteringAllowed: boolean;
	fieldId: number;
	type: CustomFieldType;
};

type AddCustomFieldsPayload = {
	fields: CustomField[];
};

type RemoveCustomFieldPayload = {
	id: number;
};

type FetchUserPickOptionsByIdsPayload = {
	ids: string[];
};

export type FetchUserPickerOptionsPayload = {
	query: string;
	issueId: string;
};

type AddCustomFieldsAction = {
	type: typeof ADD_CUSTOM_FIELDS;
	payload: AddCustomFieldsPayload;
	options: {
		skipBackendRequest: boolean;
	};
};

type RemoveCustomFieldAction = {
	type: typeof REMOVE_CUSTOM_FIELD;
	payload: RemoveCustomFieldPayload;
};

type FetchSelectionOptionsAction = {
	type: typeof FETCH_SELECT_OPTIONS;
};

export type FetchUserPickerOptionsAction = {
	type: typeof FETCH_USER_PICKER_OPTIONS;
	payload: FetchUserPickerOptionsPayload;
};

type FetchUserPickerOptionsByIdsAction = {
	type: typeof FETCH_USER_PICKER_OPTIONS_BY_IDS;
	payload: FetchUserPickOptionsByIdsPayload;
};

type PopulateUserPickerAction = {
	type: typeof POPULATE_USER_PICKER_OPTIONS;
};

export const addCustomFields = (
	fields: CustomField[],
	options: AddCustomFieldsAction['options'] = { skipBackendRequest: false },
) => ({
	type: ADD_CUSTOM_FIELDS,
	options,
	payload: { fields },
});

export const removeCustomField = (id: number) => ({
	type: REMOVE_CUSTOM_FIELD,
	payload: {
		id,
	},
});

export const fetchSelectOptions = (): FetchSelectionOptionsAction => ({
	type: FETCH_SELECT_OPTIONS,
});

export const populateUserPickerOptions = (): PopulateUserPickerAction => ({
	type: POPULATE_USER_PICKER_OPTIONS,
});

export const fetchUserPickerOptions = (
	query: string,
	issueId: string,
): FetchUserPickerOptionsAction => ({
	type: FETCH_USER_PICKER_OPTIONS,
	payload: {
		query,
		issueId,
	},
});

type ToggleCustomFieldFilterabilityAction = {
	type: typeof TOGGLE_CUSTOM_FIELD_FILTERABILITY;
	payload: ToggleCustomFieldFilterabilityActionPayload;
};

export const toggleCustomFieldFilterability = (
	payload: ToggleCustomFieldFilterabilityActionPayload,
) => ({
	type: TOGGLE_CUSTOM_FIELD_FILTERABILITY,
	payload,
});

function getCustomFieldValuesFromIssues(fields: CustomField[], issues: Partial<IssueValues>[]) {
	return issues
		.filter(
			({ customFields = {} }: Partial<IssueValues>) =>
				R.keys(
					R.pick(
						fields.map((field) => field.id.toString()),
						customFields,
					),
				).length > 0,
		)
		.flatMap(({ customFields = {} }: Partial<IssueValues>) =>
			R.values(
				R.pick(
					fields.map((field) => field.id.toString()),
					customFields,
				),
			),
		);
}

const getValuesOfCustomField = (
	type: string,
	customFields: CustomField[],
	originalIssues: {
		[key: string]: IssueValues;
	},
	issues: Partial<IssueValues>[],
) => {
	const customFieldsWithType = getCustomFieldsByKey(type, customFields);
	const originalIssueOptionIds = getCustomFieldValuesFromIssues(
		customFieldsWithType,
		R.values(originalIssues),
	);
	const optionIds = getCustomFieldValuesFromIssues(customFieldsWithType, issues);
	return R.flatten([...originalIssueOptionIds, ...optionIds]);
};

export function* doUpdateUserPickerOptionsByIds({
	payload: { ids }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: FetchUserPickerOptionsByIdsAction): Generator<Effect, any, any> {
	const users: JiraUser[] = yield call(getUsers, {
		keys: ids || [],
	});

	yield put(
		resetUserPickerOptions(
			(users || []).map((user) => ({
				value: user.accountId,
				label: user.title,
				icon: user.avatarUrl,
				email: user.email,
			})),
		),
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doPopulateUserPickerOptions(): Generator<Effect, any, any> {
	// need fetch the user picker value of issues and originalIssues
	const customFields = yield select(getCustomFields);
	const originalIssues = yield select(getOriginalIssues);
	const issues = yield select(getIssues);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const userPickerOptions = getValuesOfCustomField(
		CustomFieldTypes.UserPicker,
		customFields,
		originalIssues,
		issues,
	) as string[]; // User picker options are always string

	if (userPickerOptions.length === 0) return;

	const users: JiraUser[] = yield call(getUsers, {
		keys: userPickerOptions || [],
	});

	yield put(
		resetUserPickerOptions(
			(users || []).map((user) => ({
				value: user.accountId,
				label: user.title,
				icon: user.avatarUrl,
				email: user.email,
			})),
		),
	);
}

export function* doAddCustomFields({
	payload: { fields },
	options: { skipBackendRequest }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: AddCustomFieldsAction): Generator<Effect, any, any> {
	try {
		const state = yield select(R.identity);
		const {
			domain: {
				plan: { id: planId },
			},
		}: State = state;

		if (!planId) return;

		const withFilteringAllowed = fields.map((field) => ({
			...field,
			filteringAllowed: CustomFields.FilterableFields.includes(
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				field.type
					?.key as keyof (typeof CustomFields)['FilterableFields'][keyof (typeof CustomFields)['FilterableFields']],
			),
		}));

		const wrapCustomFields = (field: CustomField) => ({
			customFieldId: field.id,
			filteringAllowed: field.filteringAllowed,
		});

		const body = getAddCustomFieldsBody(
			planId,
			withFilteringAllowed.map((field) => wrapCustomFields(field || {})),
		);

		if (!skipBackendRequest) {
			const response = yield call(fetch, urls.addCustomFields, {
				method: POST,
				body,
			});

			if (!response.ok) {
				const error = new Error(yield call(response.text.bind(response)));
				fireErrorAnalytics({
					error,
					meta: {
						id: toErrorID(error, 'add-custom-field-fetch-failed'),
						packageName: PACKAGE_NAME,
						teamName: ERROR_REPORTING_TEAM,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		}

		if (typeof withFilteringAllowed !== 'undefined') {
			yield put(add(withFilteringAllowed));
		}
		yield call(getBacklog);
		yield put(fetchSelectOptions());
		yield put(populateUserPickerOptions());
	} finally {
		yield put(resetCustomFieldFilter({ customFields: yield select(getCustomFields) }));
	}
}

export function* doRemoveCustomField({
	payload: { id }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: RemoveCustomFieldAction): Generator<Effect, any, any> {
	const state = yield select(R.identity);
	const {
		domain: {
			plan: { id: planId, currentScenarioId },
		},
	}: State = state;

	if (!planId || !currentScenarioId) return;

	const body = getRemoveCustomFieldBody(planId, currentScenarioId, id);
	const response = yield call(fetch, urls.removeCustomField, {
		method: POST,
		body,
	});

	if (response.ok) {
		yield put(remove(id));
		const colorByValue = yield select(getColorByValue);
		const sorting = yield select(getVisualisationSorting);
		if (colorByValue === id.toString()) {
			yield put(
				setColourByValue({
					id: colourByOptions.NONE,
				}),
			);
		}

		if (sorting.field === id.toString()) {
			yield put(changeSort({ sorting: defaultSorting }));
		}

		const grouping = yield select(getVisualisationGrouping);

		if (
			isRoadmapGroupedByCustomField(grouping) &&
			getCustomFieldIdFromCustomFieldGrouping(grouping) === id.toString()
		) {
			yield put(changeGroup({ grouping: GROUPING.NONE }));
		}
	} else {
		const error = new Error(yield call(response.text.bind(response)));
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'delete-custom-field-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
	yield put(resetCustomFieldFilter({ customFields: yield select(getCustomFields) }));
}

export function* doToggleCustomFieldFilterability(
	{
		payload: { fieldId, filteringAllowed },
	}: {
		payload: ToggleCustomFieldFilterabilityActionPayload;
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const state = yield select(R.identity);
	const customField: CustomField = (yield select(getCustomFields)).find(
		(field: CustomField) => field.id === fieldId,
	);
	const {
		domain: {
			plan: { id: planId },
		},
	}: State = state;

	if (planId) {
		const body = getUpdateCustomFieldsBody(planId, [{ customFieldId: fieldId, filteringAllowed }]);
		const response = yield call(fetch, urls.updateCustomField, {
			method: POST,
			body,
		});

		if (response.ok) {
			yield put(update(R.merge(customField, { filteringAllowed })));
			yield put(resetCustomFieldFilter({ customFields: yield select(getCustomFields) }));
		} else {
			const error = new Error(yield call(response.text.bind(response)));
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'update-custom-field-fetch-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doFetchSelectOptions(): Generator<Effect, any, any> {
	const state = yield select(R.identity);
	const {
		domain: {
			plan: { id: planId, currentScenarioId },
			projects,
		},
	}: State = state;

	const projectIds = projects.map((project) => project.id);

	if (!planId || !currentScenarioId) return;

	// note: "CustomFieldTypes.Labels" is not in the list of allowed types because
	// label options are fetched from a different endpoint - see doFetchLabelForField()
	const allowedTypes = new Set([
		...(fg('asset-custom-field-internal-support') ? [CustomFieldTypes.AssetObject] : []),
		CustomFieldTypes.SingleSelect,
		CustomFieldTypes.MultiSelect,
		CustomFieldTypes.MultiCheckboxes,
		CustomFieldTypes.RadioButtons,
	]);
	const allowedCustomFields =
		/* eslint-disable @typescript-eslint/consistent-type-assertions */
		(
			(yield select(getCustomFields)) as Array<{
				type: { key: typeof allowedTypes extends Set<infer V> ? V : string };
				id: number;
			}>
		)
			/* eslint-enable @typescript-eslint/consistent-type-assertions */
			.filter((field) => allowedTypes.has(field.type.key));
	// if there are no allowed custom fields in the plan, the "urls.getCustomFieldVisibleOptions" endpoint (see below) returns response.data = []
	// therefore we can reset select options to this empty array earlier in order to avoid calling the endpoint
	if (allowedCustomFields.length === 0) {
		yield put(resetSelectOptions([]));
		return;
	}

	const url = urls.getCustomFieldVisibleOptions;
	const response = yield* jsonOrError<SelectOptionPayload>({
		url,
		method: POST,
		body: getCustomFieldVisibleOptionsBody(R.map(R.prop('id'))(allowedCustomFields), projectIds),
		profile: url,
	});

	if (response.ok) {
		const selectOptions = response.data
			.map(({ customFieldId: fieldId, options }) =>
				options.map((option) => R.assoc('fieldId', fieldId, option)),
			)
			.reduce((a, b) => a.concat(b), []);
		yield put(resetSelectOptions(selectOptions));
	}
}

export function* doFetchLabelForField(
	{
		planId,
		customFieldId,
		scenarioId,
	}: {
		planId: number;
		customFieldId: number;
		scenarioId?: number;
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const body = getLabelForFieldBody(planId, customFieldId, scenarioId);
	const url = urls.labels;
	const { items } = yield call(
		aggressiveFetch,
		url,
		{
			method: POST,
			body,
			json: true,
			profile: url,
		},
		'Failed to fetch doFetchLabelForField',
	);

	yield put(resetCustomLabels({ customFieldId, labels: items }));
}

export function* doFetchUserPickerOptions(
	action: FetchUserPickerOptionsAction = {
		type: FETCH_USER_PICKER_OPTIONS,
		payload: { query: '', issueId: '' },
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const issueId = action && action.payload && action.payload.issueId;
	const query = action ? action.payload && action.payload.query : '';
	const url = urls.getUserPickerCustomFieldOptions;
	const method = 'POST';
	const body = getUserPickerCustomFieldOptionsBody({ query });

	yield put(fetchUserStart(issueId));

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const response: any = yield* jsonOrError({ url, method, body });

	if (response.ok) {
		yield put(
			resetUserPickerOptions(transformPersons((response.data && response.data.results) || [])),
		);
	}

	yield put(fetchUserStop(issueId));
}

export function* debounceFetchUserList(
	action: FetchUserPickerOptionsAction, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	yield call(delay, 500);
	yield call(doFetchUserPickerOptions, action);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchFetchUserOptionsList(): Generator<Effect, any, any> {
	yield takeLatest(FETCH_USER_PICKER_OPTIONS, debounceFetchUserList);
	yield takeLatest(FETCH_USER_PICKER_OPTIONS_BY_IDS, doUpdateUserPickerOptionsByIds);
	yield takeLatest(POPULATE_USER_PICKER_OPTIONS, doPopulateUserPickerOptions);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchAddCustomField(): Generator<Effect, any, any> {
	yield takeLatest(ADD_CUSTOM_FIELDS, doAddCustomFields);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchRemoveCustomField(): Generator<Effect, any, any> {
	yield takeLatest(REMOVE_CUSTOM_FIELD, doRemoveCustomField);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchToggleCustomFieldFilterability(): Generator<Effect, any, any> {
	yield takeEvery<ToggleCustomFieldFilterabilityAction>(
		TOGGLE_CUSTOM_FIELD_FILTERABILITY,
		doToggleCustomFieldFilterability,
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchFetchSelectOptions(): Generator<Effect, any, any> {
	yield takeLatest(FETCH_SELECT_OPTIONS, doFetchSelectOptions);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchAddCustomField);
	yield fork(watchRemoveCustomField);
	yield fork(watchFetchSelectOptions);
	yield fork(watchFetchUserOptionsList);
	yield fork(watchToggleCustomFieldFilterability);
}
