import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	exportCsvOptionLabel: {
		id: 'portfolio-3-common.share-tabs.export.export-csv-option-label',
		defaultMessage: 'Export data (.csv)',
		description: 'Label for the option to export data into a csv file.',
	},
	exportCsvDescription: {
		id: 'portfolio-3-common.share-tabs.export.export-csv-description',
		defaultMessage:
			'When you export your plan, only issues that are currently visible in your plan based on filters and other view settings will be included in the file.',
		description:
			'Description for the csv export explaining that only currently visible issues will be included in the csv file. Issue visibility is determined by filters and view settings.',
	},
	exportPngOptionLabel: {
		id: 'portfolio-3-common.share-tabs.export.export-png-option-label',
		defaultMessage: 'Export an image of your timeline view (.png)',
		description: 'Label for the option to export data as a png image.',
	},
	export: {
		id: 'portfolio-3-common.share-tabs.export.export',
		defaultMessage: 'Export',
		description:
			'Text for the export button in the export tab of the share button available in Jira plans.',
	},
	exporting: {
		id: 'portfolio-3-common.share-tabs.export.exporting',
		defaultMessage: 'Exporting...',
		description:
			'Text for the export button in the export tab of the share button available in Jira plans.',
	},
});

export default messages;
