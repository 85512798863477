import React, {
	Component,
	type ComponentType,
	type KeyboardEvent,
	type MouseEvent,
	type SyntheticEvent,
} from 'react';
import * as R from 'ramda';
import {
	withAnalyticsContext,
	withAnalyticsEvents,
	type UIAnalyticsEvent,
} from '@atlaskit/analytics-next';
import FilterIcon from '@atlaskit/icon/glyph/filter';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import Spinner from '@atlassian/jira-portfolio-3-common/src/spinner/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { DEPENDENCIES_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import {
	withSlots,
	slots,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import ChangeIndicator from '@atlassian/jira-portfolio-3-portfolio/src/common/view/change-indicator/index.tsx';
import {
	ISSUE_LINK_DIRECTION,
	LINES,
	DEFAULT,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { fireUIAnalytics, MountEvent } from '@atlassian/jira-product-analytics-bridge';
import AddDependency from './add-dependency/index.tsx';
import AssigneeCell from './cells/assignee/index.tsx';
import IssueCell from './cells/issue/index.tsx';
import LeadTimeCell from './cells/lead-time/index.tsx';
import RemoveCell from './cells/remove/index.tsx';
import StatusCell from './cells/status/index.tsx';
import TypeCell from './cells/type/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, Row } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class DependenciesFlyout extends Component<
	Props & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		QuickFilter: ComponentType<any>;
	} & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		FilterDependencyGraphFromRoadmap: ComponentType<any>;
	}
> {
	componentDidMount() {
		const { loadExternalIssues, missingExternalIssues } = this.props;

		if (missingExternalIssues.length > 0) {
			loadExternalIssues(missingExternalIssues);
		}
	}

	fireViewDependencyFlyOutAnalyticsEvent = (analyticsEvent: UIAnalyticsEvent) => {
		const { context, externalRows, internalRows } = this.props;
		const conflictCount = R.reduce(
			(acc, row) => (row.leadTime && row.leadTime < 0 ? acc + 1 : acc),
			0,
			internalRows.concat(externalRows),
		);

		let analyticsKey = '';
		if (context === 'dropdown') {
			analyticsKey = PRODUCT_ANALYTICS_EVENT_NAMES.OPENED_DEPENDENCY_FLYOUT_FROM_DROPDOWN;
		} else if (context === 'badge') {
			analyticsKey = PRODUCT_ANALYTICS_EVENT_NAMES.OPENED_DEPENDENCY_FLYOUT_FROM_BADGE;
		}

		if (analyticsKey) {
			const [actionSubject, eventAction] = analyticsKey.split(' ');
			fireUIAnalytics(analyticsEvent.update({ action: eventAction, actionSubject }), analyticsKey, {
				conflictCount,
			});
		}
	};

	onRemoveDependency =
		({ itemKey, issue: { id }, type }: Row) =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(e: SyntheticEvent<any>) => {
			// prevent InlineDialog from closing
			// it's required because at the moment InlineDialog processes event
			// current button is removed from DOM because row was removed
			// as dependency was removed by the removeDependency call
			// and InlineDialog thinks that it was click outside
			e.preventDefault();
			const { removeDependency, issue, direction } = this.props;
			removeDependency({
				itemKey,
				sourceItemKey:
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					direction === ISSUE_LINK_DIRECTION.OUTWARD ? issue.id : (id as string),
				targetItemKey:
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					direction === ISSUE_LINK_DIRECTION.OUTWARD ? (id as string) : issue.id,
				type: type.id,
			});
		};

	renderRow = (row: Row, isReadOnly: boolean | null = this.props.isReadOnly) => {
		const { direction } = this.props;
		const { isChanged, issue, type, projectKey } = row;
		return (
			<tr key={`${type.id}:${issue.id}`}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<td className={styles['change-indicator']}>
					{isChanged && <ChangeIndicator appearance="bar" />}
				</td>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<td className={styles.typeCell}>
					<TypeCell row={row} direction={direction} />
				</td>
				<td>
					<IssueCell row={row} />
				</td>
				<td>
					<StatusCell row={row} />
				</td>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<td className={styles.assigneeCell}>
					<AssigneeCell row={row} />
				</td>
				<td>
					<LeadTimeCell row={row} />
				</td>
				<td>
					{!isReadOnly ? (
						<RemoveCell
							issueKey={issue.issueKey}
							projectKey={projectKey}
							onClick={this.onRemoveDependency(row)}
							updatePopup={this.props.updatePopup}
						/>
					) : null}
				</td>
			</tr>
		);
	};

	renderHeader = () => (
		<thead>
			<tr>
				<th />
				{/* isChanged */}
				<th>
					<FormattedMessage {...messages.typeHeader} />
				</th>
				<th>
					<FormattedMessage
						{...(fg('jira-issue-terminology-refresh-m3')
							? messages.issueHeaderIssueTermRefresh
							: messages.issueHeader)}
					/>
				</th>
				<th>
					<FormattedMessage {...messages.statusHeader} />
				</th>
				<th>
					<FormattedMessage {...commonMessages.assignee} />
				</th>
				<th>
					<FormattedMessage {...messages.leadTimeHeader} />
				</th>
				<th />
				{/* removeDependency */}
			</tr>
		</thead>
	);

	renderExternalDependencies = () => {
		const { externalRows, missingExternalIssues } = this.props;
		if (
			missingExternalIssues.length === 0 &&
			externalRows.length === 0 &&
			fg('fix_external_issue_link_for_scenario_issue_removal')
		) {
			return null;
		}
		const header = (
			<tr>
				<td />
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<td colSpan={6} className={styles.externalDependenciesHeader}>
					<FormattedMessage {...messages.externalDependenciesHeader} />
				</td>
			</tr>
		);
		let content = null;
		if (missingExternalIssues.length > 0) {
			content = (
				<tr>
					<td />
					<td colSpan={6}>
						<Spinner size="small" delay={0} />
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
						<span style={{ paddingLeft: token('space.150', '12px') }}>
							<FormattedMessage {...commonMessages.loadingIssuesMessage} />
						</span>
					</td>
				</tr>
			);
		} else {
			content = externalRows.map((row) => this.renderRow(row, true));
		}
		return (
			<>
				{header}
				{content}
			</>
		);
	};

	getRowsCount = () => this.props.externalRows.length + this.props.internalRows.length;

	quickFilter = () => {
		const { issue, projectsById, QuickFilter } = this.props;

		const issueKey = issue.issueKey
			? `${projectsById[issue.project].key}-${issue.issueKey}`
			: 'ScenarioIssue';

		return (
			<QuickFilter
				value={{
					id: DEPENDENCIES_FILTER_ID,
					value: {
						type: 'specificIssue',
						issueId: issue.id,
						includeTransitive: false,
					},
				}}
				clearable
				clearText={
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles['remove-filter']}>
						<FilterIcon label="" />
						<FormattedMessage
							{...(fg('jira-issue-terminology-refresh-m3')
								? messages.removeQuickFilterTextIssueTermRefresh
								: messages.removeQuickFilterText)}
							values={{
								issueKey,
							}}
						/>
					</div>
				}
			>
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.addQuickFilterTextIssueTermRefresh
						: messages.addQuickFilterText)}
					values={{ issueKey }}
				/>
			</QuickFilter>
		);
	};

	handleToggleDependencyView = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		_: KeyboardEvent<any> | MouseEvent<any>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const { changeDependencySettingsDisplay, dependencyViewSettings } = this.props;
		const { display } = dependencyViewSettings;

		const nextSetting = display === LINES ? DEFAULT : LINES;

		changeDependencySettingsDisplay({ display: nextSetting });

		const [actionSubject, eventAction] =
			PRODUCT_ANALYTICS_EVENT_NAMES.UPDATED_DEPENDENCY_STYLE.split(' ');
		fireUIAnalytics(analyticsEvent.update({ action: eventAction, actionSubject }), {
			dependencyStyle: nextSetting,
		});
	};

	toggleDependencyViewButton = () => {
		const { dependencyViewSettings } = this.props;
		const { display } = dependencyViewSettings;

		return (
			<Button appearance="link" spacing="none" onClick={this.handleToggleDependencyView}>
				<FormattedMessage {...messages.toggleDependencyViewButton} values={{ value: display }} />
			</Button>
		);
	};

	renderFilterDependencyGraphFromRoadmapLink = () => {
		const { issue, projectsById, FilterDependencyGraphFromRoadmap } = this.props;
		const issueKey = issue.issueKey
			? `${projectsById[issue.project].key}-${issue.issueKey}`
			: 'ScenarioIssue';
		return (
			<FilterDependencyGraphFromRoadmap issueId={issue.id}>
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.visualizeDependenciesIssueTermRefresh
						: messages.visualizeDependencies)}
					values={{
						issueKey,
					}}
				/>
			</FilterDependencyGraphFromRoadmap>
		);
	};

	render() {
		const {
			addDependency,
			context,
			direction,
			externalIssueLinks,
			internalIssueLinks,
			internalRows,
			isReadOnly,
			issue,
			issueLinkTypes,
			issueCountByLinkType,
			issueTypes,
			issues,
			missingExternalIssues,
			projectsById,
			updatePopup,
		} = this.props;

		const addDependencyProps = {
			addDependency,
			context,
			issueCountByLinkType,
			direction,
			isInitiallyAddingNewDependency:
				this.getRowsCount() === 0 && missingExternalIssues.length === 0,
			issue,
			issueLinkTypes,
			internalIssueLinks,
			issueTypes,
			issues,
			projectsById,
			updatePopup,
		};

		return (
			<div data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout">
				<MountEvent onMount={this.fireViewDependencyFlyOutAnalyticsEvent} />
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<table className={styles.table}>
					{this.getRowsCount() > 0 || missingExternalIssues.length > 0 ? this.renderHeader() : null}
					<tbody>
						{internalRows.map((row) => this.renderRow(row))}
						{/* eslint-disable-next-line no-nested-ternary */}
						{fg('fix_external_issue_link_for_scenario_issue_removal')
							? this.renderExternalDependencies()
							: externalIssueLinks.length > 0
								? this.renderExternalDependencies()
								: null}
					</tbody>
				</table>

				{!isReadOnly ? <AddDependency {...addDependencyProps} /> : null}

				{!addDependencyProps.isInitiallyAddingNewDependency && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.footerActions}>
						<div>
							{this.quickFilter()}
							{!isReadOnly && this.renderFilterDependencyGraphFromRoadmapLink()}
						</div>
						{this.toggleDependencyViewButton()}
					</div>
				)}
			</div>
		);
	}
}

export default withAnalyticsContext({
	source: 'dependenciesFlyoutDialog',
})(
	withAnalyticsEvents()(
		withSlots({
			FilterDependencyGraphFromRoadmap: slots.FilterDependencyGraphFromRoadmap,
			QuickFilter: slots.QuickFilter,
		})(DependenciesFlyout),
	),
);
