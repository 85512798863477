import React, { createContext, useContext, useMemo, type PropsWithChildren } from 'react';
import { createStore, createHook, createContainer } from '@atlassian/react-sweet-state';
import type { StateOld, State } from './types.tsx';

const storeOld = createStore<StateOld, {}>({
	initialState: {
		row: -1,
		column: -1,
		colSpan: 1,
	},
	actions: {},
});

export const useCellOld = createHook(storeOld, {
	selector: ({ row, column, colSpan }) => ({ row, column, colSpan }),
});

export const CellProviderOld = createContainer(storeOld, {
	onInit:
		() =>
		({ setState }, state: StateOld) =>
			setState(state),
	onUpdate:
		() =>
		({ setState }, state: StateOld) =>
			setState(state),
});

const context = createContext<State>({
	column: -1,
	colSpan: 1,
});

export const CellProviderNew: React.FC<PropsWithChildren<State>> = ({
	column,
	colSpan,
	children,
}) => {
	const value = useMemo(() => ({ column, colSpan }), [column, colSpan]);
	return <context.Provider value={value}>{children}</context.Provider>;
};

export const useCellNew = () => {
	const { column, colSpan } = useContext(context);
	return [{ column, colSpan }];
};
