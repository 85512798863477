import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	descriptionWithLimit: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-limit-warning-flag.description.description-with-limit',
		defaultMessage:
			'The maximum issue count for a plan is {issueLimit} {issueLimit, plural, one {issue} other {issues}}. Try choosing an issue source with fewer issues, or edit your exclusion rules to reduce issue count.',
		description: 'The description for issue-limit warning flag',
	},
	descriptionWithLimitIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-limit-warning-flag.description.description-with-limit-issue-term-refresh',
		defaultMessage:
			'The maximum issue count for a plan is {issueLimit} {issueLimit, plural, one {issue} other {issues}}. Try choosing an issue source with fewer issues, or edit your exclusion rules to reduce issue count.',
		description: 'The description for issue-limit warning flag',
	},
});
export default messages;
