import { type FC, useEffect } from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { COMMAND_QUERY_PARAM, COMMAND_STORAGE_KEY } from '../../services/storage/constants.tsx';
import { storage } from '../../services/storage/index.tsx';
import type { DispatchProps } from '../../types.tsx';

type Command = {
	type: string;
	values: Array<string | number | boolean | object>;
};

const LocalStorageCommandSubscriberOld: FC<Partial<DispatchProps>> = (props) => {
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const commands = (storage.get(COMMAND_STORAGE_KEY) as Command[]) || null;

		if (Array.isArray(commands) && commands.length) {
			commands.forEach(({ type: commandType, values }) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const command = props[commandType as keyof DispatchProps];
				if (typeof command === 'function') {
					type argsType = Parameters<DispatchProps[keyof DispatchProps]>;
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(command as (...args: argsType) => void)(...(values as argsType));
				}
			});

			storage.clearAll();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};

const UrlParamCommandSubscriber: FC<Partial<DispatchProps>> = (props) => {
	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const url = new URL(window.location.href);
		const commandQuery = url.searchParams.get(COMMAND_QUERY_PARAM);

		if (!commandQuery) {
			return;
		}

		const commands = JSON.parse(commandQuery);

		if (Array.isArray(commands) && commands.length) {
			commands.forEach(({ type: commandType, values }) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const command = props[commandType as keyof DispatchProps];
				if (typeof command === 'function') {
					type argsType = Parameters<DispatchProps[keyof DispatchProps]>;
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(command as (...args: argsType) => void)(...(values as argsType));
				}
			});
		}

		url.searchParams.delete(COMMAND_QUERY_PARAM);
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.history.replaceState(null, '', url);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};

export const LocalStorageCommandSubscriber = componentWithFG(
	'smart_links_for_plans',
	UrlParamCommandSubscriber,
	LocalStorageCommandSubscriberOld,
);
