import React, { type ReactNode } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import AkTabs, { Tab, TabList, TabPanel, useTabPanel } from '@atlaskit/tabs';
import { Box } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const CustomTabPanel = ({ children }: { children: ReactNode }) => {
	const { role, id, hidden, 'aria-labelledby': ariaLabelledBy } = useTabPanel();
	return (
		<Box role={role} id={id} hidden={hidden} aria-labelledby={ariaLabelledBy}>
			{children}
		</Box>
	);
};

export default function Tabs({ setInitialFocusRef, ...props }: Props) {
	const selectedTabIndex = props.tabs.findIndex((tab) => tab && tab.id === props.selected?.id);
	const curatedSelectedTabIndex = selectedTabIndex === -1 ? 0 : selectedTabIndex;
	const defaultSelectedTabIndex = props.tabs.findIndex(
		(tab) => tab && tab.id === props.defaultSelected?.id,
	);
	const curatedDefaultSelectedTabIndex =
		defaultSelectedTabIndex === -1 ? 0 : defaultSelectedTabIndex;

	const handleChange = (index: number, analyticsEvent: UIAnalyticsEvent) => {
		const tab = props.tabs[index];
		props.onSelect && props.onSelect(tab, index, analyticsEvent);
	};

	const tabs = () =>
		fg('smart_links_for_plans') ? (
			<AkTabs
				{...props}
				selected={props.selected ? curatedSelectedTabIndex : undefined}
				defaultSelected={props.defaultSelected ? curatedDefaultSelectedTabIndex : undefined}
				onChange={handleChange}
				id="portfolio-common-tabs"
				shouldUnmountTabPanelOnChange
			>
				<TabList>
					{props.tabs.map((tab, index) => (
						<Tab
							testId={tab?.testId}
							key={index}
							ref={index === selectedTabIndex ? setInitialFocusRef : undefined}
						>
							{tab?.label}
						</Tab>
					))}
				</TabList>
				{props.tabs.map((tab, index) =>
					fg('migrate_summary_page_to_avp_charts') ? (
						<CustomTabPanel key={index}>{tab?.content}</CustomTabPanel>
					) : (
						<TabPanel key={index}>{tab?.content}</TabPanel>
					),
				)}
			</AkTabs>
		) : (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles['tabs-container']}>
				<AkTabs
					{...props}
					selected={props.selected ? curatedSelectedTabIndex : undefined}
					defaultSelected={props.defaultSelected ? curatedDefaultSelectedTabIndex : undefined}
					onChange={handleChange}
					id="portfolio-common-tabs"
					shouldUnmountTabPanelOnChange
				>
					<TabList>
						{props.tabs.map((tab, index) => (
							<Tab testId={tab?.testId} key={index}>
								{tab?.label}
							</Tab>
						))}
					</TabList>
					{props.tabs.map((tab, index) =>
						fg('migrate_summary_page_to_avp_charts') ? (
							<CustomTabPanel key={index}>{tab?.content}</CustomTabPanel>
						) : (
							<TabPanel key={index}>{tab?.content}</TabPanel>
						),
					)}
				</AkTabs>
			</div>
		);

	return props.direction === 'column' ? (
		tabs()
	) : (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles['tabs-row-wrapper']}>{tabs()}</div>
	);
}

Tabs.defaultProps = {
	direction: 'column',
};
