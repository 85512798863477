import React, { Component } from 'react';
import Tooltip from '@atlaskit/tooltip';
import { injectIntl, type IntlShape } from '@atlassian/jira-intl';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { statusBreakdownColourMap } from '../colours/index.tsx';
import { ISSUE_STATUS_CATEGORIES, BREAKDOWN_SPRINT_CAPACITY_BY } from '../constant.tsx';
import EstimateFlyout from './components/estimate-flyout.tsx';
import StatusFlyout from './components/status-flyout.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types.tsx';

const { TODO, INPROGRESS, DONE } = ISSUE_STATUS_CATEGORIES;
// eslint-disable-next-line jira/react/no-class-components
class StatusBreakdown extends Component<
	Props & {
		intl: IntlShape;
	},
	State
> {
	static defaultProps: {
		onlyRenderStatusBar: boolean;
		breakdownBy: BREAKDOWN_SPRINT_CAPACITY_BY;
	} = {
		onlyRenderStatusBar: false,
		breakdownBy: BREAKDOWN_SPRINT_CAPACITY_BY.STATUS,
	};

	state = {
		showFlyout: false,
	};

	showFlyout = () => this.setState({ showFlyout: true });

	hideFlyout = () => this.setState({ showFlyout: false });

	getBreakdown() {
		const { byCategoryId } = this.props;
		return {
			todo: byCategoryId[String(TODO)],
			inprogress: byCategoryId[String(INPROGRESS)],
			done: byCategoryId[String(DONE)],
		};
	}

	renderStatusBar() {
		const { total, breakdownBy, label, showToolTip, planningUnit, intl } = this.props;
		const { todo, inprogress, done } = this.getBreakdown();
		const remaining = todo + inprogress;
		const todoWidth = (todo / total) * 100;
		const inProgressWidth = (inprogress / total) * 100;
		const doneWidth = total === 0 ? 0 : (done / total) * 100;
		const remainingWidth = total === 0 ? 100 : (remaining / total) * 100;
		const overBooked = done > total;

		const todoBar = (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			<div style={{ width: `${todoWidth}%`, backgroundColor: statusBreakdownColourMap.TODO }} />
		);

		const inprogressBar = (
			<div
				style={{
					width: `${inProgressWidth}%`,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					backgroundColor: statusBreakdownColourMap.INPROGRESS,
				}}
			/>
		);

		const doneBar = (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			<div style={{ width: `${doneWidth}%`, backgroundColor: statusBreakdownColourMap.DONE }} />
		);

		let bar =
			breakdownBy === 'status' ? (
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.bar}
					data-testid="portfolio-3-portfolio.common.status-breakdown.status-breakdown-bar"
				>
					{doneWidth > 0 && doneBar}
					{inProgressWidth > 0 && inprogressBar}
					{todoWidth > 0 && todoBar}
				</div>
			) : (
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.bar}
					data-testid="portfolio-3-portfolio.common.status-breakdown.status-breakdown-bar"
				>
					{doneWidth > 0 && (
						<div
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={overBooked ? `${styles.completed} ${styles.overbooked}` : styles.completed}
							style={{ width: `${doneWidth}%` }}
						/>
					)}
					{remainingWidth > 0 && (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.remaining} style={{ width: `${remainingWidth}%` }} />
					)}
				</div>
			);

		if (showToolTip === true && breakdownBy === 'estimate') {
			bar = (
				<Tooltip
					content={intl.formatMessage(messages.breakdownByEstimateToolTip, {
						done,
						donePercent: total ? Math.round((done * 100) / total) : '0',
						total,
						planningUnit,
					})}
					position="mouse"
				>
					{bar}
				</Tooltip>
			);
		}

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.breakdown}>
				{label}
				{bar}
			</div>
		);
	}

	render() {
		const { showFlyout } = this.state;
		const { intl, ...flyoutProps } = this.props;
		const { total, onlyRenderStatusBar, issueCount, breakdownBy } = this.props;
		if (total === 0 && breakdownBy === 'status') {
			return null;
		}

		if (onlyRenderStatusBar === true) {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			return <div className={styles.container}>{this.renderStatusBar()}</div>;
		}
		const flyout =
			breakdownBy === 'status' ? (
				<StatusFlyout {...flyoutProps} />
			) : (
				<EstimateFlyout {...flyoutProps} />
			);
		return (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.container}
				onMouseEnter={() => this.showFlyout()}
				onMouseLeave={() => this.hideFlyout()}
			>
				<InlineDialog content={flyout} isOpen={showFlyout} issueCount={issueCount}>
					{this.renderStatusBar()}
				</InlineDialog>
			</div>
		);
	}
}

export default injectIntl(StatusBreakdown);
