import React from 'react';
import reject from 'lodash/reject';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
	DialogMenuGroup,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import type { AggregatedIssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/types.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import { FILTER_WIDTH, FILTER_MAX_WIDTH } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TitleWithAvatar from '../common/title-with-avatar/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import '../common/styles.module.css';
import messages from './messages.tsx';
import Trigger from './trigger/view.tsx';
import type { Props } from './types.tsx';

const IssueTypeKeyFilter = ({
	value,
	aggregatedIssueTypeHierarchy,
	searchQuery,
	isOpen,
	onOpenChange,
	onQueryChange,
	changeIssueTypeKeyFilter,
	clearIssueTypeKeyFilter,
}: Props) => {
	const { formatMessage } = useIntl();

	const getValueMatcher =
		(level: number, name: string) => (currentValue: { level: number; name: string }) =>
			currentValue.level === level && currentValue.name === name;

	const onIssueTypeKeyClick = (level: number, name: string, isChecked: boolean): void => {
		if (isChecked) {
			changeIssueTypeKeyFilter(reject(value, getValueMatcher(level, name)));
		} else {
			changeIssueTypeKeyFilter([...value, { level, name }]);
		}
	};

	const renderIssueTypeKeyOptions = (level: number, aggregatedIssueTypes: AggregatedIssueType[]) =>
		aggregatedIssueTypes.map(({ name, iconUrl }) => {
			const isChecked = value.some(getValueMatcher(level, name));
			return (
				<DialogMenuItem key={name}>
					<Checkbox
						isChecked={isChecked}
						onChange={() => onIssueTypeKeyClick(level, name, isChecked)}
						label={
							<TitleWithAvatar avatarUrl={iconUrl} name={name} appearance="square" size="xsmall" />
						}
					/>
				</DialogMenuItem>
			);
		});

	const renderIssueTypeKeyGroups = () => {
		const groupItems = aggregatedIssueTypeHierarchy
			.map(({ level, title, issueTypes }) => {
				const filtered = issueTypes.filter(({ name }) =>
					name.toLowerCase().includes(searchQuery.toLowerCase()),
				);

				if (filtered.length === 0) {
					return null;
				}

				return (
					<DialogMenuGroup heading={title} key={level}>
						{renderIssueTypeKeyOptions(level, filtered)}
					</DialogMenuGroup>
				);
			})
			.filter((groupItem) => groupItem !== null);

		return groupItems.length > 0 ? groupItems : <NoMatchFound />;
	};

	return (
		<InlineDialog
			noPaddings
			maxWidth={FILTER_MAX_WIDTH}
			minWidth={FILTER_WIDTH}
			onClose={onOpenChange}
			isOpen={isOpen}
			content={
				<DialogMenuContainer>
					<ClearFilterButton isVisible={value.length > 0} onClearClick={clearIssueTypeKeyFilter} />
					<SearchField
						placeholder={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.searchIssueTypePlaceholderIssueTermRefresh
								: messages.searchIssueTypePlaceholder,
						)}
						searchQuery={searchQuery}
						onQueryChange={onQueryChange}
						ariaLabel={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.searchIssueTypeLabelIssueTermRefresh
								: messages.searchIssueTypeLabel,
						)}
					/>
					{renderIssueTypeKeyGroups()}
				</DialogMenuContainer>
			}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-types-key-filter"
		>
			<Trigger
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				value={value}
				aggregatedIssueTypeHierarchy={aggregatedIssueTypeHierarchy}
			/>
		</InlineDialog>
	);
};

export default IssueTypeKeyFilter;
