import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { MapStateToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { StateProps } from './types.tsx';

export const isLoading = (state: State) => state.ui.Top.TitleBar.ShareReport.isLoading;

export const getShareReportUrl = (state: State) => state.ui.Top.TitleBar.ShareReport.url;

const mapStateToProps: MapStateToPropsSimple<State, StateProps> = (state): StateProps => ({
	isLoading: isLoading(state),
	url: getShareReportUrl(state),
});

export default mapStateToProps;
