import type { Effect } from 'redux-saga';
import { put, call, fork, takeEvery } from 'redux-saga/effects';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import {
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	SET_INTRO_OPEN,
	SET_ONBOARDING_SHOWN,
	type SetOnBoardingShownAction,
} from '../../state/ui/onboarding/actions.tsx';
import { POST, parseError } from '../api.tsx';
import { genericError as deprecatedGenericError } from '../errors/index.tsx';
import { toErrorID } from '../util.tsx';
import { urls, setUserPropertyBody } from './api.tsx';

export const setIntroOpen = (payload: boolean) => ({
	type: SET_INTRO_OPEN,
	payload,
});

export function* doSetOnboardingShown({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: SetOnBoardingShownAction): Generator<Effect, any, any> {
	const url = urls.set;
	const body = setUserPropertyBody('onboadingShown', payload);
	const response = yield call(fetch, url, {
		method: POST,
		body,
	});
	if (!response.ok) {
		if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
			yield put(
				deprecatedGenericError({
					...parseError(response, yield call(response.text.bind(response))),
					requestInfo: {
						url,
						type: POST,
						status: response.status,
						body,
					},
				}),
			);
		} else {
			const error = new Error(yield call(response.text.bind(response)));
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'set-onboarding-shown-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchSetOnboarding(): Generator<Effect, any, any> {
	yield takeEvery(SET_ONBOARDING_SHOWN, doSetOnboardingShown);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchSetOnboarding);
}
