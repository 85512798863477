import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.idea-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchIdeasPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.idea-filter.search-ideas-placeholder',
		defaultMessage: 'Show issues from ideas',
		description: 'This is a placeholder for search',
	},
	searchIdeasPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.idea-filter.search-ideas-placeholder-issue-term-refresh',
		defaultMessage: 'Show issues from ideas',
		description: 'This is a placeholder for search',
	},
});
