import { useEffect, useState, type RefObject } from 'react';

/**
 * Hook to determine if the focus is within the element.
 * Used to lift the z-index of the element when it is focused.
 */
export const useFocusWithin = <E extends HTMLElement>(ref: RefObject<E>) => {
	const [isFocusWithin, setIsFocusWithin] = useState(false);

	useEffect(() => {
		const element = ref.current;
		if (!element) return;

		const update = () => setIsFocusWithin(element.matches(':focus-within'));

		element.addEventListener('focusin', update);
		element.addEventListener('focusout', update);

		return () => {
			element.removeEventListener('focusin', update);
			element.removeEventListener('focusout', update);
		};
	});

	return isFocusWithin;
};
