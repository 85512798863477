import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import IssueLink from '@atlassian/jira-portfolio-3-common/src/issue-link/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { PlanningUnits, ISSUE_STATUS_CATEGORIES } from '../../constant.tsx';
import EllipsedWithTooltip from '../../ellipsed-with-tooltip/index.tsx';
import type { IssueTitleData, Props } from '../types.tsx';
import messages from './messages.tsx';
import Percentage from './percentage.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import { roundToTwoDigit } from './util.tsx';

const { TODO, INPROGRESS, DONE } = ISSUE_STATUS_CATEGORIES;

const EstimateFlyout = (props: Props) => {
	const { planningUnit, byCategoryId, unestimated = 0, issueCount = 0, issueTitleData } = props;
	const { formatMessage } = useIntl();

	const getBreakdown = () => ({
		completed: byCategoryId[String(DONE)],
		remaining: byCategoryId[String(TODO)] + byCategoryId[String(INPROGRESS)],
	});

	const getLabel = (count: number) => {
		const countToTwoDigit = roundToTwoDigit(count);

		let label = formatMessage(
			fg('jira-issue-terminology-refresh-m3')
				? messages.issueCountLabelIssueTermRefresh
				: messages.issueCountLabel,
			{
				numberOfIssues: countToTwoDigit,
			},
		);

		if (planningUnit === PlanningUnits.storyPoints) {
			label = formatMessage(messages.storyPointsCountLabel, {
				storyPoints: countToTwoDigit,
			});
		} else if (planningUnit === PlanningUnits.days) {
			label = formatMessage(messages.daysCountLabel, {
				days: countToTwoDigit,
			});
		} else if (planningUnit === PlanningUnits.hours) {
			label = formatMessage(messages.hoursCountLabel, {
				hours: countToTwoDigit,
			});
		}

		return label;
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getIssueTitle = (issueTitleData: IssueTitleData) => {
		const { projectKey, issueType, issueKey, id, summary } = issueTitleData;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.issueTitle}>
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.issueIcon}
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						backgroundSize: 'contain',
						backgroundImage: issueType && `url(${issueType.iconUrl})`,
					}}
				/>
				<IssueLink projectKey={projectKey} issueKey={issueKey} xcssStyles={issueLinkStyles} />
				<EllipsedWithTooltip content={summary} id={id}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.issueSummary}>{summary}</div>
				</EllipsedWithTooltip>
			</div>
		);
	};

	const { completed, remaining } = getBreakdown();
	const total = completed + remaining;
	const hasUnestimatedIssues = unestimated > 0;
	const hasChildIssues = unestimated + issueCount > 1;
	const percent = total > 0 ? roundToTwoDigit((completed / total) * 100) : 0;

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={!fg('plan-timeline-non-transposed') ? styles.flyout : styles.flyoutOld}
			data-testid="portfolio-3-portfolio.common.status-breakdown.components.estimate-flyout"
		>
			<table>
				<tbody>
					{isDefined(issueTitleData) && (
						<tr>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<td colSpan={2} className={styles.issueTitleCell}>
								{getIssueTitle(issueTitleData)}
							</td>
						</tr>
					)}

					{!hasChildIssues && (
						<tr>
							<td colSpan={2}>
								{hasUnestimatedIssues
									? formatMessage(messages.individualIssueUnestimatedLabel)
									: formatMessage(messages.individualIssueBreakdownLabel, {
											percent,
											remaining: roundToTwoDigit(remaining),
											total: getLabel(total),
										})}
							</td>
						</tr>
					)}

					{hasChildIssues && (
						<>
							<tr>
								<td>
									<Lozenge appearance="success">
										<FormattedMessage {...messages.completed} />
									</Lozenge>
								</td>
								<td>
									<Percentage count={completed} total={total} label={getLabel(completed)} />
								</td>
							</tr>
							<tr>
								<td>
									<Lozenge appearance="default">
										<FormattedMessage {...messages.remaining} />
									</Lozenge>
								</td>
								<td>
									<Percentage count={remaining} total={total} label={getLabel(remaining)} />
								</td>
							</tr>
							<tr>
								<td>
									<FormattedMessage {...messages.total} />
								</td>
								<td>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<span className={styles.countLabel}>
										{getLabel(total)}
										{hasUnestimatedIssues ? '*' : null}
									</span>
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<hr className={styles.separator} />
								</td>
							</tr>
							<tr>
								<td>
									<FormattedMessage
										{...(fg('jira-issue-terminology-refresh-m3')
											? messages.estimatedIssuesLabelIssueTermRefresh
											: messages.estimatedIssuesLabel)}
									/>
								</td>
								<td>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<span className={styles.countLabel}>{issueCount}</span>
								</td>
							</tr>
							<tr>
								<td>
									<FormattedMessage
										{...(fg('jira-issue-terminology-refresh-m3')
											? messages.unestimatedIssuesLabelIssueTermRefresh
											: messages.unestimatedIssuesLabel)}
									/>
								</td>
								<td>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<span className={styles.countLabel}>{unestimated}</span>
								</td>
							</tr>
							{hasUnestimatedIssues && (
								<tr>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<td colSpan={2} className={styles.accuracyWarning}>
										<FormattedMessage
											{...(fg('jira-issue-terminology-refresh-m3')
												? messages.notAccurateProgressIssueTermRefresh
												: messages.notAccurateProgress)}
										/>
									</td>
								</tr>
							)}
						</>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default EstimateFlyout;

const issueLinkStyles = xcss({
	whiteSpace: 'nowrap',
});
