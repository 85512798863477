import {
	getExportCsvScopeItems,
	planExportedAnalytics,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/index.tsx';
import type {
	AdditionalData,
	CsvIntlMessages,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	getExportCsvScopeItems: (csvIntlMessages: CsvIntlMessages, additionalData?: AdditionalData) => {
		dispatch(getExportCsvScopeItems(csvIntlMessages, additionalData));
	},
	planExportedAnalytics,
});

export default mapDispatchToProps;
