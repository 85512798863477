/* eslint-disable jira/react/no-style-attribute */
import React, { type Ref, forwardRef } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { CellProviderNew, CellProviderOld } from '../../controllers/cell/index.tsx';
import { useColSpan, useColumnStickyOffset } from '../../controllers/grid/index.tsx';
import { useRow } from '../../controllers/row/index.tsx';
import type { Props } from './types.tsx';

export const CellOld = forwardRef(
	(
		{ column, colSpan, style, top, xcss: xcssStyles, ...restProps }: Props,
		ref: Ref<HTMLTableCellElement>,
	) => {
		const { row, height } = useRow();
		const [left] = useColumnStickyOffset({ column, preview: true });
		const [colSpanForZeroWidthColumns] = useColSpan({ row, column, preview: true });

		const isSticky = top !== undefined || left !== undefined;

		if (colSpanForZeroWidthColumns === 0) {
			return null;
		}

		const finalColSpan = colSpan ?? colSpanForZeroWidthColumns;

		return (
			<CellProviderOld row={row} column={column} colSpan={finalColSpan}>
				<Box
					as="td"
					ref={ref}
					colSpan={finalColSpan}
					xcss={[
						cellStyles,
						isSticky && stickyStyles,
						...(Array.isArray(xcssStyles) ? xcssStyles : [xcssStyles]),
					]}
					style={{
						top,
						left,
						height,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						...style,
					}}
					// This spreading is to allow the consumer to customize the tr attributes
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...restProps}
				/>
			</CellProviderOld>
		);
	},
);

export const CellNew = forwardRef(
	(
		{ column, colSpan, style, top, xcss: xcssStyles, ...restProps }: Props,
		ref: Ref<HTMLTableCellElement>,
	) => {
		const [left] = useColumnStickyOffset({ column, preview: true });

		const isSticky = top !== undefined || left !== undefined;

		return (
			<CellProviderNew column={column} colSpan={colSpan ?? 1}>
				<Box
					as="td"
					ref={ref}
					colSpan={colSpan}
					xcss={[
						cellStyles,
						isSticky && stickyStyles,
						...(Array.isArray(xcssStyles) ? xcssStyles : [xcssStyles]),
					]}
					style={{
						top,
						left,
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						...style,
					}}
					// This spreading is to allow the consumer to customize the tr attributes
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...restProps}
				/>
			</CellProviderNew>
		);
	},
);

const cellStyles = xcss({
	margin: 'space.0',
	padding: 'space.0',
	position: 'relative',
});

const stickyStyles = xcss({
	position: 'sticky',
});
