import React, { useCallback } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useDependencyCreateDragSourceData } from '@atlassian/jira-portfolio-3-dependency-line-drag-create/src/ui/index.tsx';
import { getContainerWidth } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/utils/index.tsx';
import { useZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import Row from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/schedule/row/index.tsx';
import {
	useCellOld,
	useCellNew,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';
import { useColumnWidth } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';
import type { Props } from './types.tsx';

export const TimelineIssueRow = ({
	issue,
	warnings,
	onlyEnabledIssueId,
	enableOnlyIssueId,
}: Props) => {
	let row;
	if (fg('plans_performance_improvements_2')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ row } = useRow());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[{ row }] = useCellOld();
	}
	const [{ column }] =
		// eslint-disable-next-line react-hooks/rules-of-hooks
		fg('plans_performance_improvements_2') ? useCellNew() : useCellOld();
	const dragSourceData = useDependencyCreateDragSourceData();
	const [zoomLevel] = useZoomLevel();
	const [columnWidth = 0] = useColumnWidth({ column, preview: true });
	const width = zoomLevel ? getContainerWidth(zoomLevel) : columnWidth;

	const issueWarnings = warnings.byIssue[issue.id];

	const isReadOnly = onlyEnabledIssueId != null && issue.id !== onlyEnabledIssueId;

	const barStartDragging = useCallback(() => {
		enableOnlyIssueId(issue.id);
		// TODO: Disable dependency lines interaction
	}, [enableOnlyIssueId, issue.id]);

	const barStopDragging = useCallback(() => {
		enableOnlyIssueId();
		// TODO: Re-enable dependency lines interaction
	}, [enableOnlyIssueId]);

	return (
		<Row
			backgroundColor={'transparent' /* TODO: verify if we need this. */}
			hasDependencyLineDragPreview={dragSourceData?.row === row}
			disableOtherRows={barStartDragging}
			isReadOnly={isReadOnly}
			issue={issue}
			issueWarnings={issueWarnings}
			key={issue.id}
			reEnableOtherRows={barStopDragging}
			topOffset={0 /* TODO: This is related to DnD dependency creation. */}
			width={width}
		/>
	);
};
