import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	summaryTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.summary-title',
		defaultMessage: 'Summary',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans summary page',
	},
	timelineTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.timeline-title',
		defaultMessage: 'Timeline',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans timeline page',
	},
	calendarTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.calendar-title',
		defaultMessage: 'Calendar',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans calendar page',
	},
	programTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.program-title',
		defaultMessage: 'Program',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans program page',
	},
	dependenciesTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.dependencies-title',
		defaultMessage: 'Dependencies',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans dependencies page',
	},
	summaryFormTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.summary-form-title',
		defaultMessage: 'Share summary',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans summary page',
	},
	timelineFormTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.timeline-form-title',
		defaultMessage: 'Share timeline',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans timeline page',
	},
	calendarFormTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.calendar-form-title',
		defaultMessage: 'Share calendar',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans calendar page',
	},
	programFormTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.program-form-title',
		defaultMessage: 'Share program',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans program page',
	},
	dependenciesFormTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.dependencies-form-title',
		defaultMessage: 'Share dependencies',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans dependencies page',
	},
	export: {
		id: 'portfolio-3-common.share-tabs.export.export',
		defaultMessage: 'Export',
		description:
			'Text for the export button in the export tab of the share button available in Jira plans.',
	},
	copyTooltipText: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.copy-tooltip-text',
		defaultMessage: 'Embed this link in other Atlassian products such as Confluence',
		description: 'Message shown in copy link tooltip describing how the share link can be used',
	},
	iframeTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.iframe-title',
		defaultMessage: 'iFrame',
		description:
			'Text for the export button in the export tab of the share button available in Jira plans.',
	},
});
