import React, { cloneElement } from 'react';
import StandardButton from '@atlaskit/button/standard-button';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { fg } from '@atlassian/jira-feature-gating';
import DropMenu from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { DraggableIconButton } from '../draggable-button/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Children, Props } from './types.tsx';

function Divider() {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	return <div className={styles.divider} />;
}

function withDivider(children: Children): Children {
	if (!Array.isArray(children)) {
		return children;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
	const result = children.reduce<Array<any>>((result, elem, idx) => {
		const child = cloneElement(elem, { ...elem.props, key: idx });
		const divider = <Divider key={`divider-${idx}`} />;
		if (idx === 0) {
			return [child];
		}

		return result.concat([divider, child]);
	}, []);

	return result;
}

export default function IssueDropMenu({
	ariaLabel = '',
	handleBlurEvent,
	onOpenChange,
	children,
	hierarchyLevel,
}: Props) {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnOpenChange = ({ isOpen }: { isOpen: boolean }) => {
		if (isOpen) {
			const [actionSubject, eventAction] =
				PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_ISSUE_MORE_ACTIONS_MENU.split(' ');
			const analyticsEvent = createAnalyticsEvent({
				action: eventAction,
				actionSubject,
			});
			fireUIAnalytics(analyticsEvent, 'plansScopeIssueMoreActionsButton', {
				hierarchyLevel,
			});
		}
		onOpenChange({ isOpen });
	};
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.root}>
			<DropMenu
				trigger={({ triggerRef, ...props }) =>
					!fg('plan-timeline-non-transposed') ? (
						<DraggableIconButton
							{...props}
							ref={triggerRef}
							appearance="subtle"
							icon={MoreIcon}
							label={ariaLabel}
							onBlur={handleBlurEvent}
						/>
					) : (
						<StandardButton
							{...props}
							ref={triggerRef}
							appearance="subtle"
							iconAfter={<span />}
							spacing="none"
							onBlur={handleBlurEvent}
						>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.moreIconWrapper}>
								<MoreIcon label={ariaLabel} color="currentColor" />
							</div>
						</StandardButton>
					)
				}
				shouldRenderToParent={!fg('plan-timeline-non-transposed') ? false : undefined}
				onOpenChange={handleOnOpenChange}
				placement="bottom-end"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.more-meatball"
			>
				{withDivider(children)}
			</DropMenu>
		</div>
	);
}
