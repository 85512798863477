import React, { memo, useRef } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useDropTarget } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/bindings/index.tsx';
import { useCellOld } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';

export const OrphanDropTarget = memo(() => {
	const ref = useRef<HTMLDivElement>(null);
	let row;
	if (fg('plans_performance_improvements_2')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ row } = useRow());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[{ row }] = useCellOld();
	}

	useDropTarget(ref, row);

	return (
		<Box
			ref={ref}
			xcss={containerStyles}
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.body.orphan-drop-target"
		/>
	);
});

const containerStyles = xcss({
	width: '100%',
	height: '100%',
});
