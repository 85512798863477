import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	assigneeCellLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.assignee.assignee-cell-label',
		defaultMessage: 'Choose an assignee for issue {issue}',
		description: 'Label for assignee cell',
	},
	currentlyAssignedGroupTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.assignee.currently-assigned-group-title',
		defaultMessage: 'Currently assigned',
		description: 'Label for select for currently assigned users group title',
	},
	allAssigneesGroupTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.assignee.all-assignees-group-title',
		defaultMessage: 'All users',
		description: 'Label for group title for all assignees without issues',
	},
	unassignedTooltipMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.assignee.unassigned-tooltip-message',
		defaultMessage: 'This issue is not assigned to anyone',
		description: 'message for tooltip when value is not assigned',
	},
	assigneeCellLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.assignee.assignee-cell-label-issue-term-refresh',
		defaultMessage: 'Choose an assignee for issue {issue}',
		description: 'Label for assignee cell',
	},
	unassignedTooltipMessageIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.assignee.unassigned-tooltip-message-issue-term-refresh',
		defaultMessage: 'This issue is not assigned to anyone',
		description: 'message for tooltip when value is not assigned',
	},
});
