import React from 'react';
import Avatar from '@atlaskit/avatar';
import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-common/src/api/index.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import NotInPlan from '../not-in-plan/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function Team(props: Props) {
	const { prioritiesByIdMap, priority, priorityIdsForProject } = props;
	if (!priority) {
		return <div>-</div>;
	}
	const targetPriority = prioritiesByIdMap[priority];

	if (targetPriority && priorityIdsForProject.includes(priority)) {
		const { iconUrl, name } = targetPriority;
		return (
			<EllipsedWithTooltip content={name} id={priority}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.avatar}>
					<Avatar size="xsmall" src={proxyContextSafeUrl(iconUrl)} />
				</span>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.title}>{name}</span>
			</EllipsedWithTooltip>
		);
	}
	return (
		<div>
			<NotInPlan />
		</div>
	);
}
