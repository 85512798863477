import React, { useCallback, type ComponentType } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { toErrorID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/util.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonLabelMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/label/messages.tsx';
import type { Props, Messages, InjectProps, InjectedComponentProps } from './types.tsx';

const messageDescriptors: Messages = {
	placeholder: commonLabelMessages.chooseLabel,
	noLabels: commonLabelMessages.noLabels,
	noLabelsLeft: commonLabelMessages.noLabelsLeft,
	noMatch: commonLabelMessages.noMatch,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (ComposedComponent: ComponentType<Omit<Props, keyof InjectedComponentProps>>) =>
	function (props: Omit<Props, keyof InjectProps | keyof InjectedComponentProps>) {
		const fetchOptions = useCallback(
			(
				customFieldId: string,
				_: number,
				_2: string,
				query = '',
			): Promise<{ value: string; label: string }[]> => {
				const { plan } = props;

				if (!isDefined(plan.id)) {
					const error = new Error('Plan id is not available in the options provider label');
					fireErrorAnalytics({
						error,
						meta: {
							id: toErrorID(error, 'Plan id is not available in the options provider label'),
							packageName: PACKAGE_NAME,
							teamName: ERROR_REPORTING_TEAM,
						},
						sendToPrivacyUnsafeSplunk: true,
					});
					return Promise.reject('Plan id is not available');
				}
				if (!isDefined(plan.currentScenarioId)) {
					const error = new Error('Scenario id is not available in the options provider label');
					fireErrorAnalytics({
						error,
						meta: {
							id: toErrorID(error, 'Scenario id is not available in the options provider label'),
							packageName: PACKAGE_NAME,
							teamName: ERROR_REPORTING_TEAM,
						},
						sendToPrivacyUnsafeSplunk: true,
					});
					return Promise.reject('Scenario id is not available');
				}

				const body: {
					planId?: number;
					customFieldId: string;
					query: string;
					scenarioId?: string | number;
				} = {
					planId: plan.id,
					customFieldId,
					query,
					scenarioId: plan.currentScenarioId,
				};

				return fetch('/rest/jpo/1.0/labels', {
					method: 'POST',
					body,
				})
					.then((response) => {
						if (response.ok) {
							return response.json();
						}
						throw new Error(`Response not ok. Statuscode: ${response.status}`);
					})
					.then((data) => data.items.map((value: string) => ({ label: value, value })))
					.catch((err) => {
						fireErrorAnalytics({
							error: err,
							meta: {
								id: toErrorID(err, 'labels-fetch-failed'),
								packageName: PACKAGE_NAME,
								teamName: ERROR_REPORTING_TEAM,
							},
							sendToPrivacyUnsafeSplunk: true,
						});
					});
			},
			[props],
		);
		return (
			<ComposedComponent {...props} fetchOptions={fetchOptions} messages={messageDescriptors} />
		);
	};
