import { type Effect, delay } from 'redux-saga';
import * as R from 'ramda';
import { fork, takeLatest, takeEvery, call, put, select } from 'redux-saga/effects';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import type { Person as ApiPerson } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getAssigneeList } from '../../query/assignees/index.tsx';
import { getTeamsAssigneeAccountIds, getTeamsPersons } from '../../query/persons/index.tsx';
import {
	updateIssueAssigneeMap,
	updateAssigneeList,
} from '../../state/domain/assignees/actions.tsx';
import type {
	Person,
	PersonsRequestBody,
	PersonsResponseBody,
	UsersRequestBody,
	JiraUser,
} from '../../state/domain/assignees/types.tsx';
import type { State } from '../../state/types.tsx';
import {
	startUpdatingAssigneeList,
	stopUpdatingAssigneeList,
} from '../../state/ui/main/tabs/roadmap/fields/assignee/actions.tsx';
import { POST } from '../api.tsx';
import { toErrorID } from '../util.tsx';
import { urls, personsRequestBody, usersRequestBody } from './api.tsx';

export const FETCH_ASSIGNEE_LIST_FOR_QUERY =
	'command.assignees.FETCH_ASSIGNEE_LIST_FOR_QUERY' as const;
export const FETCH_ASSIGNEE_LIST_FOR_KEYS =
	'command.assignees.FETCH_ASSIGNEE_LIST_FOR_KEYS' as const;
export const FETCH_TEAMS_ASSIGNEE_LIST = 'command.assignees.FETCH_TEAMS_ASSIGNEE_LIST' as const;

const unassignedUser: JiraUser = {
	title: 'Unassigned',
	accountId: 'unassigned',
	email: '',
	avatarUrl: '',
};

export type FetchAssigneeListForQueryPayload = {
	query: string;
	issueId?: string;
};
export type FetchAssigneeListForKeysPayload = {
	keys?: string[];
	fetchAllAssignees?: boolean;
};

export type FetchAssigneeListForQueryAction = {
	type: typeof FETCH_ASSIGNEE_LIST_FOR_QUERY;
	payload: FetchAssigneeListForQueryPayload;
};

export type FetchAssigneeListForKeysAction = {
	type: typeof FETCH_ASSIGNEE_LIST_FOR_KEYS;
	payload: FetchAssigneeListForKeysPayload;
};

export const fetchAssigneeList = (payload: FetchAssigneeListForQueryPayload) => ({
	type: FETCH_ASSIGNEE_LIST_FOR_QUERY,
	payload,
});
export const fetchAssigneeListForKeys = (payload: FetchAssigneeListForKeysPayload) => ({
	type: FETCH_ASSIGNEE_LIST_FOR_KEYS,
	payload,
});
export const fetchTeamsAssignees = () => ({
	type: FETCH_TEAMS_ASSIGNEE_LIST,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* getUsers({ keys }: UsersRequestBody = { keys: [] }): Generator<Effect, any, any> {
	let users: JiraUser[] = [];
	const url = urls.users;
	const body = usersRequestBody({ keys: keys.filter(isDefined) });

	const response = yield call(fetch, url, {
		method: POST,
		body,
		profile: url,
	});

	if (response.ok) {
		const results = yield call(response.json.bind(response));
		users = [...results.users];
	} else {
		const error = new Error(yield call(response.text.bind(response)));
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'get-users-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
	return users;
}

export function* getPersons(
	config: PersonsRequestBody = { query: '' }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	let results: Array<Person> = [];
	const url = urls.assignees;
	const body = personsRequestBody(config);
	const response = yield call(fetch, url, {
		method: POST,
		body,
	});
	if (response.ok) {
		const persons: PersonsResponseBody = yield call(response.json.bind(response));
		results = [...persons.results];
	} else {
		const error = new Error(yield call(response.text.bind(response)));
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'find-persons-fetch-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
	return results;
}

export function* populateAssignees(
	{ payload }: FetchAssigneeListForKeysAction = {
		payload: {},
		type: 'command.assignees.FETCH_ASSIGNEE_LIST_FOR_KEYS',
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const keys = payload && payload.keys;
	const {
		domain: { issues, originalIssues },
	}: State = yield select(R.identity);
	// should fetch assignees of all the issues, originalIssues and teams
	const originalIssueAssigneeIds =
		(R.values(originalIssues) || []).map((issue) => issue.assignee) || [];
	const issueAssigneeIds = issues.map((issue) => issue.assignee) || [];
	const fetchAllAssignees = payload && payload.fetchAllAssignees;

	const requestedKeys = keys || [];

	let requiredKeys;
	if (requestedKeys.length === 0 || fetchAllAssignees) {
		// if assignee keys are not specified we fetch all assignees
		const teamsAssigneeKeys = yield select(getTeamsAssigneeAccountIds);
		requiredKeys = [
			...requestedKeys,
			...issueAssigneeIds,
			...originalIssueAssigneeIds,
			...teamsAssigneeKeys,
		];
	} else {
		requiredKeys = requestedKeys;
	}

	const assigneeList: Person[] = yield select(getAssigneeList);
	const availableUserKeys = assigneeList.map((assignee) => assignee.personId);

	const userIds = R.difference(requiredKeys, availableUserKeys).filter(isDefined);

	if (R.length(userIds) === 0) {
		return;
	}

	const users: JiraUser[] = yield call(getUsers, {
		keys: userIds,
	});

	users.unshift(unassignedUser);

	const transformedUsersList = users.map((user) => ({
		personId: user.accountId,
		jiraUser: user,
	}));

	yield put(
		updateAssigneeList({
			people: [...transformedUsersList],
		}),
	);

	const issueAssigneeMap = issues.reduce<{ [id: string]: Person }>((acc, issue) => {
		const assignee = users.find(({ accountId }) => accountId === issue.assignee);

		if (assignee) {
			acc[issue.id] = {
				personId: assignee.accountId,
				jiraUser: assignee,
			};
		}
		return acc;
	}, {});
	yield put(updateIssueAssigneeMap(issueAssigneeMap));
}

export function* populateAssigneesFromProfiles(
	assigneeProfiles: Record<string, JiraUser>, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const {
		domain: { issues },
	}: State = yield select(R.identity);
	// if assignee keys are not specified we fetch all assignees
	const teamAssignees: ApiPerson[] = yield select(getTeamsPersons);

	// List of account id's for the list order
	const allPersonsOrder: string[] = [unassignedUser.accountId];
	// Map of account id to person
	const allPersonsMap: Record<string, Person> = {
		[unassignedUser.accountId]: {
			personId: unassignedUser.accountId,
			jiraUser: unassignedUser,
		},
	};

	for (const assigneePerson of Object.values(assigneeProfiles)) {
		allPersonsOrder.push(assigneePerson.accountId);
		allPersonsMap[assigneePerson.accountId] = {
			personId: assigneePerson.accountId,
			jiraUser: assigneePerson,
		};
	}

	for (const apiPerson of teamAssignees) {
		// Using full expansion in the if statement to make typescripts inference happy
		if (apiPerson?.jiraUser?.accountId && !allPersonsMap[apiPerson?.jiraUser?.accountId]) {
			const accountId = apiPerson.jiraUser.accountId;

			allPersonsOrder.push(accountId);
			allPersonsMap[accountId] = {
				personId: accountId,
				jiraUser: {
					accountId,
					title: apiPerson.jiraUser.title,
					avatarUrl: apiPerson.jiraUser.avatarUrl,
					email: apiPerson.jiraUser?.email,
				},
			};
		}
	}

	yield put(
		updateAssigneeList({
			people: allPersonsOrder.map((accountId) => allPersonsMap[accountId]),
		}),
	);

	const issueAssigneeMap: { [id: string]: Person } = {};
	issues.forEach((issue) => {
		const assigneePerson = allPersonsMap[issue.assignee];

		if (assigneePerson) {
			issueAssigneeMap[issue.id] = assigneePerson;
		}
	});
	yield put(updateIssueAssigneeMap(issueAssigneeMap));
}

export function* doFetchAssigneeList({
	payload: { query, issueId }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: FetchAssigneeListForQueryAction): Generator<Effect, any, any> {
	yield put(startUpdatingAssigneeList(issueId || ''));
	const assigneeList: Person[] = yield call(getPersons, {
		query,
	});
	assigneeList.unshift({
		personId: unassignedUser.accountId,
		jiraUser: { ...unassignedUser },
	});

	yield put(
		updateAssigneeList({
			people: assigneeList
				.filter((assignee) => assignee.jiraUser)
				.map(({ jiraUser }) => ({ personId: jiraUser.accountId, jiraUser })),
		}),
	);
	yield put(stopUpdatingAssigneeList(issueId || ''));
}

export function* debounceFetchAssigneeList(
	action: FetchAssigneeListForQueryAction, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	yield call(delay, 500);
	yield call(doFetchAssigneeList, action);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doFetchTeamsAssigneeList(): Generator<Effect, any, any> {
	const keys = yield select(getTeamsAssigneeAccountIds);

	yield put(fetchAssigneeListForKeys({ keys }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchFetchAssigneeList(): Generator<Effect, any, any> {
	yield takeLatest(FETCH_ASSIGNEE_LIST_FOR_QUERY, debounceFetchAssigneeList);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchFetchAssigneeListForKeys(): Generator<Effect, any, any> {
	yield takeEvery<FetchAssigneeListForKeysAction>(FETCH_ASSIGNEE_LIST_FOR_KEYS, populateAssignees);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchFetchTeamsAssigneeList(): Generator<Effect, any, any> {
	yield takeLatest(FETCH_TEAMS_ASSIGNEE_LIST, doFetchTeamsAssigneeList);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchFetchAssigneeList);
	yield fork(watchFetchAssigneeListForKeys);
	yield fork(watchFetchTeamsAssigneeList);
}
