import React, { Component } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl } from '@atlassian/jira-intl';
import {
	UNDEFINED_GROUP,
	groupSortComparator,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { GROUPING } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import DialogContent from '../dialog-content/view.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class TeamOptions extends Component<Props> {
	isTeamDisabled = (teamId: string) => isDefined(this.props.additionalTeamsById[teamId]);

	createGroupOptionFromTeam = (tooltipMessage: string, team: Team): GroupOption => ({
		group: `team-${team.id}`,
		groupCombination: { team: team.id },
		groupName: team.title,
		groupUrl: team.avatarUrl || '',
		grouping: GROUPING.TEAM,
		startInlineCreateInEmptyGroup: true,
		groupTooltip: tooltipMessage,
		isDisabled: this.isTeamDisabled(team.id),
	});

	getGroupOptionsFromTeams = (): GroupOption[] => {
		const { groups, teams, intl } = this.props;

		const tooltipMessage = intl.formatMessage(
			fg('jira-issue-terminology-refresh-m3')
				? messages.teamTooltipIssueTermRefresh
				: messages.teamTooltip,
		);

		const unassigned: GroupOption = {
			groupName: intl.formatMessage(commonMessages.unassigned),
			grouping: 'TEAM',
			isExpanded: true,
			group: UNDEFINED_GROUP,
			groupCombination: { team: null },
			startInlineCreateInEmptyGroup: !groups.some(({ group }) => group === UNDEFINED_GROUP),
			groupUrl: '',
			groupTooltip: tooltipMessage,
		};

		const groupOptions = new Map();

		for (const group of groups) {
			const { scope, ...groupOption } = group;
			if (groupOption.groupCombination) {
				groupOptions.set(groupOption.groupCombination.team, {
					...groupOption,
					groupTooltip: tooltipMessage,
					isDisabled: !!(
						groupOption.groupCombination &&
						groupOption.groupCombination.team &&
						this.isTeamDisabled(groupOption.groupCombination.team)
					),
				});
			}
		}

		for (const team of teams) {
			if (!groupOptions.has(team.id)) {
				const option = this.createGroupOptionFromTeam(tooltipMessage, team);
				groupOptions.set(team.id, option);
			}
		}

		groupOptions.set(null, unassigned);

		return [...groupOptions.values()].sort(groupSortComparator);
	};

	render() {
		const { intl, onGroupClick, hierarchyItem } = this.props;

		return (
			<DialogContent
				groupOptions={this.getGroupOptionsFromTeams()}
				hierarchyItem={hierarchyItem}
				searchPlaceholder={intl.formatMessage(messages.teamSearchPlaceholder)}
				onGroupClick={onGroupClick}
			/>
		);
	}
}

export default injectIntl(TeamOptions);
