import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AssociatedIssue } from '../../common/types.tsx';
import type { JiraSearchApiResponse, EndpointParam } from '../types.tsx';

const ISSUE_LOADING_PARALLEL_REQUESTS = 20;
const DEFAULT_BATCH_SIZE = 100;
const SEARCH_JIRA_URL = '/rest/api/3/search';

const makeSearchBody = (
	endpointParam: EndpointParam,
	fields: string[] | null,
	expand: string[],
	batch: number,
): string => {
	return 'projectId' in endpointParam
		? JSON.stringify({
				projectId: endpointParam.projectId,
				fields,
				startAt: batch * DEFAULT_BATCH_SIZE,
				maxResults: DEFAULT_BATCH_SIZE,
			})
		: JSON.stringify({
				jql: endpointParam.jql,
				fields,
				expand,
				startAt: batch * DEFAULT_BATCH_SIZE,
				maxResults: DEFAULT_BATCH_SIZE,
				validateQuery: 'strict',
			});
};

const createInitialSearchBody = (endpointParam: EndpointParam): string =>
	'projectId' in endpointParam
		? JSON.stringify({
				projectId: endpointParam.projectId,
				fields: null,
				startAt: 0,
				maxResults: 0,
			})
		: JSON.stringify({
				jql: endpointParam.jql,
				fields: [],
				expand: [],
				startAt: 0,
				maxResults: 0,
				validateQuery: 'strict',
			});

const fetchIssuesCountAndFields = (endpointParam: EndpointParam) =>
	fetchJson(SEARCH_JIRA_URL, {
		method: 'POST',
		body: createInitialSearchBody(endpointParam),
	}).then((response) => ({
		count: response.total,
	}));

const fetchBatch = (
	endpointParam: EndpointParam,
	fields: string[] | null,
	expand: string[],
	batch: number,
) =>
	fetchJson(SEARCH_JIRA_URL, {
		method: 'POST',
		body: makeSearchBody(endpointParam, fields, expand, batch),
	});

export const fetchIssueIssueBatches = (
	endpointParam: EndpointParam,
	fieldsToLoad: string[],
	issueLimit: number,
): Promise<JiraSearchApiResponse> => {
	const expand: string[] = [];
	const firstBatch = fetchBatch(endpointParam, fieldsToLoad, expand, 0);

	return fetchIssuesCountAndFields(endpointParam).then(({ count: numberOfIssues }) => {
		const requestCount = Math.min(
			Math.ceil(numberOfIssues / DEFAULT_BATCH_SIZE),
			Math.ceil(issueLimit / DEFAULT_BATCH_SIZE),
		);

		let count = 1;
		let aggregatedResponse: JiraSearchApiResponse = {
			issues: [],
			total: numberOfIssues,
		};
		const processLoadedBatch = () => (result: JiraSearchApiResponse) => {
			aggregatedResponse = {
				issues: [...aggregatedResponse.issues, ...result.issues],
				total: aggregatedResponse.total,
			};
		};
		const createRecursiveFetch = (): Promise<void> => {
			if (count >= requestCount) {
				return Promise.resolve();
			}
			const batchIndex = count;
			count += 1;
			return fetchBatch(endpointParam, fieldsToLoad, expand ?? [], batchIndex)
				.then(processLoadedBatch())
				.then(createRecursiveFetch);
		};

		return Promise.all([
			firstBatch.then(processLoadedBatch()),
			...[...Array(ISSUE_LOADING_PARALLEL_REQUESTS).keys()].map(createRecursiveFetch),
		]).then(() => aggregatedResponse);
	});
};

export const fetchAssociatedIssuesRest = (uniqueIds: string[], issueFetchLimit: number) => {
	const jql = `id in (${uniqueIds.join(',')}) AND projectType = 'product_discovery'`;
	const fieldsToLoad = ['id', 'key', 'summary', 'issuetype'];

	return fetchIssueIssueBatches({ jql }, fieldsToLoad, issueFetchLimit).then((result) => {
		const associatedIssues: Record<IssueId, AssociatedIssue> = {};
		const rawAssociatedIssues = result.issues.map((issue) => ({
			[issue.id]: {
				id: issue.id,
				key: issue.key,
				summary: `${issue.fields.summary}`,
				iconUrl: issue.fields.issuetype.iconUrl,
			},
		}));
		rawAssociatedIssues.forEach((data) => {
			Object.assign(associatedIssues, data);
		});

		return associatedIssues;
	});
};
