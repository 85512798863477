import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { mapStateToProps } from './query.tsx';
import { ZIndexProvider as View } from './view.tsx';

/** https://hello.atlassian.net/wiki/spaces/AG/pages/3580431937 */
export const ZIndexProvider = connect(mapStateToProps, {})(View);
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { Z_INDEX_LAYER, Z_INDEX_LEVEL } from './types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useZIndex, ZIndex } from './view';
