import React, { type KeyboardEvent, type MouseEvent, useState } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ButtonGroup from '@atlaskit/button/button-group';
import { HelperMessage, Field } from '@atlaskit/form';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import {
	AkSelect,
	selectIssueOption,
} from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { getIssueOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/utils.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	type Relation,
	RANK_AFTER,
	RANK_BEFORE,
	RANK_LAST,
	RANK_TOP,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import InfoMessage from '../info-message/view.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { DataPayload, Props } from './types.tsx';
import { findSelectedIssuesWithLowerPosition } from './utils.tsx';

export default function SetRankForm({
	allSortedIssues,
	getAncestors,
	issueTypesById,
	onCancel,
	onSubmit,
	projectsById,
	selectedIssues = [],
}: Props) {
	const { formatMessage } = useIntl();

	const [operationTypeFieldValue, setOperationTypeFieldValue] = useState<'TOP' | Relation>(
		RANK_TOP,
	);
	const [anchorFieldValue, setAnchorFieldValue] = useState<string | undefined>();

	const handleSubmit = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		_: KeyboardEvent<any> | MouseEvent<any>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const formData: DataPayload = {
			operationType: operationTypeFieldValue,
		};

		if (anchorFieldValue) {
			formData.anchor = anchorFieldValue;
		}
		onSubmit(formData, analyticsEvent);
	};

	const getCurrentValue = (): string => {
		if (selectedIssues.length === 1) {
			const index = allSortedIssues.findIndex(({ id }) => id === selectedIssues[0].id);
			return formatMessage(messages.currentValue, { currentValue: index + 1 });
		}
		return formatMessage(messages.variousValues);
	};

	const getUniqueSelectedIssuesIds = () => new Set(selectedIssues.map(({ id }) => id));

	const getPossibleIssues = (): Issue[] => {
		const selectedIssuesIds = getUniqueSelectedIssuesIds();
		return allSortedIssues.filter(({ id }) => !selectedIssuesIds.has(id));
	};

	const getSelectIssueOptions = () =>
		getPossibleIssues().map((issue) => getIssueOption(issue, issueTypesById, projectsById));

	const renderOptionRank = () => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const rankMapOptions = allSortedIssues.reduce<Record<string, any>>((acc, { id }, i) => {
			acc[id] = i + 1;
			return acc;
		}, {});

		if (anchorFieldValue && rankMapOptions[anchorFieldValue]) {
			return formatMessage(messages.optionRankLabel, {
				currentIndex: rankMapOptions[anchorFieldValue],
			});
		}
		return null;
	};

	const renderInfoMessage = () => {
		const { issuesWithLowerPosition, issuesWithHigherPosition } =
			findSelectedIssuesWithLowerPosition({
				allSortedIssues,
				anchorFieldValue,
				getAncestors,
				issues: getPossibleIssues(),
				operationTypeFieldValue,
				selectedIdsSet: getUniqueSelectedIssuesIds(),
				selectedIssues,
			});

		return issuesWithLowerPosition.length || issuesWithHigherPosition.length ? (
			<InfoMessage
				issueTypesById={issueTypesById}
				issuesWithHigherPosition={issuesWithHigherPosition}
				issuesWithLowerPosition={issuesWithLowerPosition}
				projectsById={projectsById}
				isDefaultFlyoutOpen={false}
			/>
		) : null;
	};

	const rankPositions = [
		{ label: formatMessage(messages.rankTop), value: RANK_TOP },
		{ label: formatMessage(messages.rankLast), value: RANK_LAST },
		{
			label: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.rankBeforeIssueTermRefresh
					: messages.rankBefore,
			),
			value: RANK_BEFORE,
		},
		{
			label: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.rankAfterIssueTermRefresh
					: messages.rankAfter,
			),
			value: RANK_AFTER,
		},
	];

	const issueOptions = getSelectIssueOptions();

	// the new rank of selected issue(s) cannot be applied if the user selected "Above issue" or "Below issue" and no issue has been selected
	const isFormInvalid = () => {
		if (operationTypeFieldValue === RANK_TOP || operationTypeFieldValue === RANK_LAST) {
			return false;
		}

		if (
			(operationTypeFieldValue === RANK_BEFORE || operationTypeFieldValue === RANK_AFTER) &&
			!isDefined(anchorFieldValue)
		) {
			return true;
		}

		return false;
	};

	return (
		<>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<section className={styles['form-group']}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles['current-value']}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles['value-label']}>{formatMessage(commonMessages.current)}</div>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles['value-placeholder']}>{getCurrentValue()}</div>
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.icon}>
					<ArrowRightIcon label="" />
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles['operation-type']}>
					<Field
						isRequired
						label={formatMessage(commonMessages.new)}
						name="operationTypeFieldValue"
					>
						{({ fieldProps }) => (
							<AkSelect
								{...fieldProps}
								defaultValue={rankPositions[0]}
								onChange={(option: OptionType | null) => {
									option && setOperationTypeFieldValue(option.value);
								}}
								options={rankPositions}
								value={rankPositions.find((v) => v.value === operationTypeFieldValue)}
								testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.set-rank-form.ak-select"
							/>
						)}
					</Field>
				</div>
				{(operationTypeFieldValue === RANK_BEFORE || operationTypeFieldValue === RANK_AFTER) && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.anchor}>
						<Field isRequired name="anchorFieldValue">
							{({ fieldProps }) => (
								<AkSelect
									{...fieldProps}
									defaultValue={undefined}
									formatOptionLabel={selectIssueOption}
									onChange={(option: OptionType | null) => {
										option && setAnchorFieldValue(option.value);
									}}
									options={issueOptions}
									placeholder={formatMessage(
										fg('jira-issue-terminology-refresh-m3')
											? messages.chooseValuePlaceholderIssueTermRefresh
											: messages.chooseValuePlaceholder,
									)}
									value={issueOptions.find((v) => v.value === anchorFieldValue)}
								/>
							)}
						</Field>
						<HelperMessage>{renderOptionRank()}</HelperMessage>
					</div>
				)}
			</section>
			{renderInfoMessage()}
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<section className={styles['form-footer']}>
				<ButtonGroup>
					<Button appearance="subtle" onClick={onCancel}>
						{formatMessage(commonMessages.cancel)}
					</Button>
					<Button appearance="primary" isDisabled={isFormInvalid()} onClick={handleSubmit}>
						{formatMessage(commonMessages.apply)}
					</Button>
				</ButtonGroup>
			</section>
		</>
	);
}
