import type { Dispatch } from 'redux';
import { change } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	HIERARCHY_FILTER_ID,
	HIERARCHY_RANGE_FILTER_ID,
	type HierarchyFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { clearInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/actions.tsx';

const mapDispatchToProps = (dispatch: Dispatch) => ({
	onHierarchyChange: (payload: HierarchyFilterValue) => {
		dispatch(clearInlineCreate());
		if (!fg('stop-dual-writing-hierarchy-filters-in-plans')) {
			dispatch(
				change({
					id: HIERARCHY_FILTER_ID,
					value: { start: payload.start + 1, end: payload.end + 1 },
				}),
			);
		}
		dispatch(
			change({
				id: HIERARCHY_RANGE_FILTER_ID,
				value: payload,
			}),
		);
	},
});

export default mapDispatchToProps;
