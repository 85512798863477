import { createContext, useState, useRef, useCallback } from 'react';
import equals from 'lodash/fp/equals';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService } from '@atlassian/jira-flags';
import type { IssueLinksByIssueId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/index.tsx';
import type { DependencySettingsInfo } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/system/types.tsx';
import { ISSUE_LINK_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import messages from './messages.tsx';
import type {
	AddIssueLink,
	ContextType,
	DragSource,
	Rect,
	DnDItemId,
	IssueRect,
} from './types.tsx';

const DEPENDENCY_CREATION_ERROR_FLAG_ID = 'dragPreviewDependencyCreationErrorFlag';

export const context = createContext<ContextType>({
	width: 0,
	dragSource: null,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setDragSource: () => {},
	dragHovered: null,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	updateIssueRect: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	removeIssueRect: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	handleDragMove: () => {},
	handleDrop: () => false,
});

/**
 * Provides ability to detect which issue rect is being hoverred.
 * @returns {DnDItemId} `DnDItemId` The id and scope index of row in which the issue rect is hovered.
 * @returns {Function} `updateIssueRect` the method for the issue bar to register its rect.
 * @returns {Function} `removeIssueRect` the method for the issue bar to deregister its rect.
 * @returns {Function} `handleDragMove` the method for the drag behavior to notify the mouse position.
 * @returns {Function} `handleDragEnd` the method to handle when the drag behavior is ended.
 */
export const useHoverableIssueRect = () => {
	const [hovered, setHovered] = useState<DnDItemId | null | undefined>(null);
	const issueRectsRef = useRef<Array<IssueRect>>([]);

	const removeIssueRect = useCallback((rowId: DnDItemId) => {
		issueRectsRef.current = issueRectsRef.current.filter(
			(issueRect: IssueRect) => !equals(issueRect.rowId, rowId),
		);
	}, []);

	const updateIssueRect = useCallback((rowId: DnDItemId, rect: Rect) => {
		issueRectsRef.current = issueRectsRef.current
			.filter((issueRect: IssueRect) => !equals(issueRect.rowId, rowId))
			.concat([{ rowId, rect }]);
	}, []);

	const handleDragMove = useCallback(
		(offsetX: number, offsetY: number) => {
			const issueRects = issueRectsRef.current;
			for (let i = 0; i < issueRects.length; i++) {
				const { rowId, rect } = issueRects[i];
				const { top, left, width, height } = rect;
				if (
					offsetX >= left &&
					offsetX <= left + width &&
					offsetY >= top &&
					offsetY <= top + height
				) {
					if (!equals(hovered, rowId)) {
						setHovered(rowId);
					}
					return;
				}
			}
			if (offsetX >= 0 && offsetY >= 0) {
				setHovered(null);
			}
		},
		[hovered],
	);

	const handleDragEnd = useCallback(() => setHovered(null), []);

	return { hovered, removeIssueRect, updateIssueRect, handleDragMove, handleDragEnd };
};

export const useDropHandling = (
	hovered: DnDItemId | null | undefined,
	dragSource: DragSource | null | undefined,
	addIssueLink: AddIssueLink,
	outgoingLinks: IssueLinksByIssueId,
	dependencySettings: DependencySettingsInfo,
) => {
	const { showFlag } = useFlagsService();
	const dragSourceRef = useRef(dragSource);
	const hoveredRef = useRef(hovered?.issueId);

	dragSourceRef.current = dragSource;
	hoveredRef.current = hovered?.issueId;

	return useCallback(
		({ issueLinkType }: { issueLinkType: number }) => {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const hovered = hoveredRef.current;
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const dragSource = dragSourceRef.current;

			if (hovered == null || dragSource == null) {
				return false;
			}

			const [source, target]: string[] =
				dragSource.direction === ISSUE_LINK_DIRECTION.OUTWARD
					? [dragSource.rowId.issueId, hovered]
					: [hovered, dragSource.rowId.issueId];

			// We don't create a dependency with itself
			if (source === target) {
				return false;
			}

			const outgoingLinksForItem = outgoingLinks[source] || [];

			const existingLinkArray = outgoingLinksForItem.filter(
				(link) => link.targetItemKey === target && link.type === issueLinkType,
			);

			if (existingLinkArray.length > 0) {
				return false;
			}

			const dependencyIssueLinkTypes = dependencySettings.dependencyIssueLinkTypes ?? [];
			const hasIssueLinkTypeConfigured = dependencyIssueLinkTypes.find(
				(linkType) => linkType.issueLinkTypeId === issueLinkType,
			);

			if (!hasIssueLinkTypeConfigured) {
				showFlag({
					id: DEPENDENCY_CREATION_ERROR_FLAG_ID,
					title: messages.noLinkTypeFlagTitle,
					type: 'error',
					description: fg('jira-issue-terminology-refresh-m3')
						? messages.noLinkTypeFlagDescriptionIssueTermRefresh
						: messages.noLinkTypeFlagDescription,
					isAutoDismiss: true,
					actions: [
						{
							content: messages.noLinkTypeFlagLink,
							href: 'https://support.atlassian.com/jira-software-cloud/docs/configure-dependencies-in-jira-for-advanced-roadmaps/',
							target: '_blank',
						},
					],
				});

				return false;
			}

			addIssueLink({
				sourceItemKey: source,
				targetItemKey: target,
				type: issueLinkType,
			});

			return true;
		},
		[addIssueLink, outgoingLinks, showFlag, dependencySettings?.dependencyIssueLinkTypes],
	);
};
