import mergeWith from 'lodash/mergeWith';
import pick from 'lodash/pick';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import {
	combineReducersOpen,
	composeReducers,
	dynamicKeyReducer,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import { RESET, type ResetAction } from './actions.tsx';
import activeFilterView from './active-filter-view/reducer.tsx';
import colourBy from './colour-by/reducer.tsx';
import componentGroups from './component-groups/reducer.tsx';
import customFieldValuesGroups from './custom-field-values-groups/reducer.tsx';
import dateConstraints from './date-constraints-setting/reducer.tsx';
import dependencySettings from './dependency-settings/reducer.tsx';
import fieldColumns from './field-columns/reducer.tsx';
import filterOptions from './filter-options/reducer.tsx';
import filters from './filters/reducer.tsx';
import highlightedVersions from './highlighted-versions/reducer.tsx';
import issueExpansions from './issue-expansions/reducer.tsx';
import labelGroups from './label-groups/reducer.tsx';
import rollupSettings from './rollup-settings/reducer.tsx';
import timeScale from './time-scale/reducer.tsx';
import type { ViewSettingsState } from './types.tsx';
import viewMode from './view-mode/reducer.tsx';
import visualisations from './visualisations/reducer.tsx';
import warningSettings from './warning-settings/reducer.tsx';

const fieldColumnsReducer = dynamicKeyReducer(
	fieldColumns,
	(state?: ViewSettingsState) =>
		state?.viewModeV0?.mode === VIEW_MODES.LIST ? 'listFieldColumnsV0' : 'fieldColumnsV0',
	['fieldColumnsV0', 'listFieldColumnsV0'],
);

const filtersReducer = dynamicKeyReducer(
	filters,
	(state?: ViewSettingsState) =>
		state?.activeFilterViewV0 === 'calendar' ? 'calendarFiltersV1' : 'filtersV1',
	['filtersV1', 'calendarFiltersV1'],
);

const staticKeyReducers = combineReducersOpen({
	colourByV2: colourBy,
	timeScaleV1: timeScale,
	issueExpansionsV0: issueExpansions,
	filterOptionsV1: filterOptions,
	rollupSettingsV0: rollupSettings,
	highlightedVersionsV0: highlightedVersions,
	componentGroupsV0: componentGroups,
	labelGroupsV0: labelGroups,
	customFieldValuesGroupsV0: customFieldValuesGroups,
	warningSettingsV1: warningSettings,
	dateConstraintsV0: dateConstraints,
	visualisationsV0: visualisations,
	dependencySettingsV0: dependencySettings,
	viewModeV0: viewMode,
	activeFilterViewV0: activeFilterView,
});

const combined = composeReducers(staticKeyReducers, fieldColumnsReducer, filtersReducer);

const resetState = combined(undefined, { type: '@@INIT' });
// activeFilterViewV0 is in the view-settings state for the convenience of accessing it from filtersReducer.
// However, it's set based on the route and must not be reset by saved views.
const resetKeys = Object.keys(resetState).filter((key) => key !== 'activeFilterViewV0');

const mergeDeepLeft = <T, U>(left: T, right: U) =>
	mergeWith({}, right, left, (_, leftValue) => {
		if (Array.isArray(leftValue)) return leftValue;
		return undefined;
	});

const finalReducer = (
	state: ViewSettingsState = resetState,
	action: ResetAction,
): ViewSettingsState => {
	switch (action.type) {
		case RESET: {
			return mergeDeepLeft(pick(action.payload, resetKeys), resetState);
		}
		default: {
			return combined(state, action);
		}
	}
};

export default finalReducer;
