.spaBar {
	display: flex;
	min-width: 3px;
	height: 24px;
	position: absolute;
	border-radius: 4px;
	user-select: none;
	overflow: hidden;
	z-index: var(
		--jpo-z-index-dependency-bar
	); /* remove this when cleaning up plan-timeline-non-transposed */
}

.content {
	flex: 1 1 auto;
}

.leftHandleOld,
.rightHandleOld {
	flex: 0 0 10px;
	height: 100%;
	background-color: var(--ds-background-inverse-subtle-pressed, rgba(255, 255, 255, 0.3));
	display: inline-block;
	cursor: ew-resize;
	user-select: none;
	z-index: var(--jpo-z-index-dependency-handle);
}

.leftHandle,
.rightHandle {
	flex: 0 0 4px;
	height: 16px;
	margin: 4px;
	border-radius: 3px;
	background-color: var(--ds-background-inverse-subtle-pressed, rgba(255, 255, 255, 0.3));
	display: inline-flex;
	cursor: ew-resize;
	user-select: none;
	z-index: var(--jpo-z-index-dependency-handle);
}

.stripes {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.flyoutPositioner {
	position: absolute;
}

.flyoutContent {
	height: 20px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	cursor: pointer;
}
