import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapStateToProps, { mapStateToPropsOld } from './query.tsx';
import type { OwnProps, StateProps } from './types.tsx';
import View from './view.tsx';

const IssueTypeOptionsProviderOld = connect<StateProps, {}, OwnProps, State>(
	mapStateToPropsOld,
	{},
)(View);

const IssueTypeOptionsProvider = connect<StateProps, {}, OwnProps, State>(
	mapStateToProps,
	{},
)(View);

export default componentWithFG(
	'replace_gethierarchyfilter_gethierarchyrangefilter',
	IssueTypeOptionsProvider,
	IssueTypeOptionsProviderOld,
);

// delete the three lines above and uncomment this line when removing the "replace_gethierarchyfilter_gethierarchyrangefilter" feature gate
// export default connect<StateProps, {}, OwnProps, State>(mapStateToProps, {})(View);
