import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { planExportedAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapStateToProps from './query.tsx';
import type { StateProps, DispatchProps, OwnProps } from './types.tsx';
import PngExport from './view.tsx';

const componentOld = connect<StateProps, DispatchProps, OwnProps, State>(mapStateToProps, {
	planExportedAnalytics,
})(PngExport);

const componentNew = connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToProps,
	{
		planExportedAnalytics,
	},
	null,
	{ forwardRef: true },
)(PngExport);

export default componentWithFG('smart_links_for_plans', componentNew, componentOld);
