import isNil from 'lodash/isNil';
import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import { SUB_TASK_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import {
	isConfluenceMacro,
	isEmbed,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import {
	indexBy,
	isDefined,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { CustomField } from '../../state/domain/custom-fields/types.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import {
	type ResolvedFilters,
	HIERARCHY_FILTER_ID,
	HIERARCHY_RANGE_FILTER_ID,
	PROJECT_FILTER_ID,
	CROSS_PROJECT_RELEASE_FILTER_ID,
	RELEASE_FILTER_ID,
	SUMMARY_FILTER_ID,
	TEAM_FILTER_ID,
	ASSIGNEE_FILTER_ID,
	REPORTER_FILTER_ID,
	DEPENDENCIES_FILTER_ID,
	LABEL_FILTER_ID,
	STATUS_FILTER_ID,
	STATUS_KEY_FILTER_ID,
	COMPONENT_FILTER_ID,
	SPRINT_FILTER_ID,
	ISSUE_TYPES_FILTER_ID,
	ISSUE_TYPE_KEY_FILTER_ID,
	ISSUE_PRIORITIES_FILTER_ID,
	CUSTOM_FIELD_FILTER_ID,
	DATE_RANGE_FILTER_ID,
	ISSUE_WARNING_FILTER_ID,
	type FilterId,
	type CustomFieldFilterValueV0,
	type Filter,
	type HierarchyRangeFilterValue,
	GOAL_FILTER_ID,
	IDEA_FILTER_ID,
} from '../../state/domain/view-settings/filters/types.tsx';
import type { State } from '../../state/types.tsx';
import { getCustomFields } from '../custom-fields/index.tsx';
import type { UnMatchedFilters } from '../scope/types.tsx';
import { isAtlasConnectInstalled } from '../system/index.tsx';
import { getFiltersViewSettings } from '../view-settings/index.tsx';
import { getAssigneeFilter, applyFilter as applyAssigneeFilter } from './assignee-filter/index.tsx';
import {
	getComponentFilter,
	applyFilter as applyComponentFilter,
} from './component-filter/index.tsx';
import { getCrossProjectReleaseFilter } from './cross-project-release-filter/index.tsx';
import {
	getCustomFieldFilterCount,
	getCustomFieldFilter,
	applyFilter as applyCustomFieldFilter,
} from './custom-field-filter/index.tsx';
import {
	getDateRangeFilter,
	applyFilter as applyDateRangeFilter,
} from './date-range-filter/index.tsx';
import {
	getDependenciesFilter,
	applyFilter as applyDependenciesFilter,
} from './dependencies-filter/index.tsx';
import { getGoalFilter, applyFilter as applyGoalFilter } from './goal-filter/index.tsx';
import {
	getHierarchyFilter,
	applyFilter as applyHierarchyFilter,
} from './hierarchy-filter/index.tsx';
import {
	getHierarchyRangeFilter,
	applyHierarchyRangeFilter,
} from './hierarchy-range-filter/index.tsx';
import { getIdeaFilter, applyFilter as applyIdeaFilter } from './idea-filter/index.tsx';
import {
	getIssuePrioritiesFilter,
	applyFilter as applyIssuePrioritiesFilter,
} from './issue-priorities-filter/index.tsx';
import {
	getIssueTypeKeyFilter,
	applyFilter as applyIssueTypeKeyFilter,
} from './issue-type-key-filter/index.tsx';
import {
	getIssueWarningFilter,
	applyFilter as applyIssueWarningFilter,
} from './issue-warning-filter/index.tsx';
import { getLabelFilter, applyFilter as applyLabelFilter } from './label-filter/index.tsx';
import { getProjectFilter, applyFilter as applyProjectFilter } from './project-filter/index.tsx';
import { getReleaseFilter, applyFilter as applyReleaseFilter } from './release-filter/index.tsx';
import { getReporterFilter, applyFilter as applyReporterFilter } from './reporter-filter/index.tsx';
import { getSprintFilter, applyFilter as applySprintFilter } from './sprint-filter/index.tsx';
import {
	getStatusKeyFilter,
	applyFilter as applyStatusKeyFilter,
} from './status-key-filter/index.tsx';
import { getSummaryFilter, applyFilter as applySummaryFilter } from './summary-filter/index.tsx';
import { getTeamFilter, applyFilter as applyTeamFilter } from './team-filter/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	HIERARCHY_FILTER_ID,
	PROJECT_FILTER_ID,
	CROSS_PROJECT_RELEASE_FILTER_ID,
	RELEASE_FILTER_ID,
	SUMMARY_FILTER_ID,
	TEAM_FILTER_ID,
	ASSIGNEE_FILTER_ID,
	REPORTER_FILTER_ID,
	DEPENDENCIES_FILTER_ID,
	LABEL_FILTER_ID,
	STATUS_FILTER_ID,
	STATUS_KEY_FILTER_ID,
	COMPONENT_FILTER_ID,
	SPRINT_FILTER_ID,
	ISSUE_TYPES_FILTER_ID,
	ISSUE_TYPE_KEY_FILTER_ID,
	ISSUE_PRIORITIES_FILTER_ID,
	CUSTOM_FIELD_FILTER_ID,
	DATE_RANGE_FILTER_ID,
} from '../../state/domain/view-settings/filters/types';

// @ts-expect-error - 2322 - This error will fade away when removing the dual-write of hierarchy filters in https://hello.jira.atlassian.cloud/browse/JPO-27959
export const getFilters: (state: State) => ResolvedFilters = createStructuredSelector({
	[ASSIGNEE_FILTER_ID]: getAssigneeFilter,
	[COMPONENT_FILTER_ID]: getComponentFilter,
	[CROSS_PROJECT_RELEASE_FILTER_ID]: getCrossProjectReleaseFilter,
	[CUSTOM_FIELD_FILTER_ID]: getCustomFieldFilter,
	[DEPENDENCIES_FILTER_ID]: getDependenciesFilter,
	[GOAL_FILTER_ID]: getGoalFilter,
	[IDEA_FILTER_ID]: getIdeaFilter,
	[HIERARCHY_FILTER_ID]: getHierarchyFilter,
	[HIERARCHY_RANGE_FILTER_ID]: getHierarchyRangeFilter,
	[ISSUE_PRIORITIES_FILTER_ID]: getIssuePrioritiesFilter,
	[ISSUE_TYPE_KEY_FILTER_ID]: getIssueTypeKeyFilter,
	[LABEL_FILTER_ID]: getLabelFilter,
	[PROJECT_FILTER_ID]: getProjectFilter,
	[RELEASE_FILTER_ID]: getReleaseFilter,
	[REPORTER_FILTER_ID]: getReporterFilter,
	[SPRINT_FILTER_ID]: getSprintFilter,
	[STATUS_KEY_FILTER_ID]: getStatusKeyFilter,
	[SUMMARY_FILTER_ID]: getSummaryFilter,
	[TEAM_FILTER_ID]: getTeamFilter,
	[ISSUE_WARNING_FILTER_ID]: getIssueWarningFilter,
	[DATE_RANGE_FILTER_ID]: getDateRangeFilter,
});

export const getFiltersAndHierarchyLevelsForAnalytics = (state: State) => {
	const filters = getFilters(state);

	const customFieldsById = indexBy(R.prop('id'), getCustomFields(state));

	// highest and lowest hierarchy levels
	const hierarchyLevels = {
		start: SUB_TASK_LEVEL,
		end: SUB_TASK_LEVEL,
	};

	const ignoredFilters: FilterId[] = [
		ISSUE_TYPE_KEY_FILTER_ID, // existing behaviour
		ISSUE_PRIORITIES_FILTER_ID, // existing behaviour
		CUSTOM_FIELD_FILTER_ID, // custom handler
		HIERARCHY_FILTER_ID, // custom handler
		HIERARCHY_RANGE_FILTER_ID, // custom handler
		DEPENDENCIES_FILTER_ID, // custom handler
		SUMMARY_FILTER_ID, // custom handler
		DATE_RANGE_FILTER_ID, // custom handler
	];

	const selectedFilters: Array<string> = [];

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	for (const [filterKey, filterValue] of Object.entries(filters) as [FilterId, Filter][]) {
		if (filterKey === CUSTOM_FIELD_FILTER_ID) {
			/**
			 * A bit of context before starting to engineer the special case of custom fields
			 * When there are custom fields in the plan, the "filters" object includes the following key / value pair
			 * {
			 *   state.domain.view-settings.filters.CUSTOM_FIELD_FILTER_ID: {
			 *      id: "state.domain.view-settings.filters.CUSTOM_FIELD_FILTER_ID",
			 *      value: {
			 *          10201: ["10101"],
			 *          10202: [],
			 *          10203: ["10105", "10107"],
			 *      }
			 *  }
			 * in this example, the user:
			 * - selected one option in the custom field 10201 filter
			 * - did NOT select any options in the custom field 10202 filter (empty array)
			 * - selected two options in the custom field 10203 filter
			 *
			 * For each key / value pair of the filterValue object i.e.
			 * { 10201: ["10101"], 10202: [], 10203: ["10105", "10107"] }
			 * we get the custom field type key e.g. com.atlassian.jira.plugin.system.customfieldtypes:multiselect
			 *
			 *  Since there might be several custom fields with the same type (e.g. 2 MultiSelect custom fields), we either
			 * - increment the current count value if the selectedFilters object has already that custom field type
			 * - set the count value to "1" if the selectedFilters object does not yet have that custom field type
			 */
			for (const [customFieldId, selectedOptionsIds] of Object.entries(
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				R.path<(typeof filterValue)['value']>(['value'], filterValue)!,
			)) {
				const customFieldTypeKey = R.path<CustomField['type']['key']>(
					['type', 'key'],
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					customFieldsById[customFieldId as unknown as number],
				);

				if (isDefined(customFieldTypeKey) && selectedOptionsIds && selectedOptionsIds.length) {
					selectedFilters.push(customFieldTypeKey);
				}
			}
		} else if (
			filterKey === DEPENDENCIES_FILTER_ID &&
			R.path(['value', 'type'], filterValue) !== 'off'
		) {
			// when the user is filtering on dependencies, a value will be defined and its type will be different than "off"
			selectedFilters.push(filterKey);
		} else if (filterKey === HIERARCHY_RANGE_FILTER_ID) {
			// for the hierarchy, we are looking at the "start" and "end" values and assign it to their respective keys
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const { start, end } = (filterValue?.value as HierarchyRangeFilterValue) || {};
			if (!isNil(start)) {
				hierarchyLevels.start = start;
			}
			if (!isNil(end)) {
				hierarchyLevels.end = end;
			}
		} else if (filterKey === SUMMARY_FILTER_ID && R.path(['value'], filterValue)) {
			// for "Summary" and "Warnings" filters, they DO remain in the filters state even if the user does not filter
			// on them anymore, hence why we check whether the "value" is actually defined
			selectedFilters.push(filterKey);
		} else if (
			filterValue.id === DATE_RANGE_FILTER_ID &&
			(filterValue.value?.startDate || filterValue.value?.endDate)
		) {
			selectedFilters.push(filterKey);
		} else if (!ignoredFilters.includes(filterKey)) {
			// for all other filters: checking if their key is included in the selectedFilters object (this check is there to
			// prevent unwanted keys - such as state.domain.filters.ISSUE_TYPES_FILTER_ID - to be added to the selectedFilters object)

			// this line looks overkill, but is necessary to keep Flow happy
			const selectedFilterValue = R.path<(typeof filterValue)['value']>(['value'], filterValue);

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (selectedFilterValue && (selectedFilterValue as unknown[]).length) {
				selectedFilters.push(filterKey);
			}
		}
	}
	return { selectedFilters, hierarchyLevels };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEmptyFilterValue = ({ value }: { value: any }): boolean =>
	R.isNil(value) || R.isEmpty(value) || value.type === 'off' || value === false;

export const getFilterCountPure = (
	customFieldCount: number,
	isAtlasConnectInstalledCheck: boolean,
	{
		[HIERARCHY_FILTER_ID]: _,
		[HIERARCHY_RANGE_FILTER_ID]: __,
		[CUSTOM_FIELD_FILTER_ID]: ___,
		...remainingFilters
	}: ResolvedFilters,
	isEmbedMode: boolean,
	isConfluenceMacroMode: boolean,
): number => {
	const isEmbedOrMacro = isEmbedMode || isConfluenceMacroMode;
	const nonEmptyFilters = Object.entries(remainingFilters)
		// eslint-disable-next-line @typescript-eslint/no-shadow
		.filter(([_, filter]) => !isEmptyFilterValue(filter))
		// eslint-disable-next-line @typescript-eslint/no-shadow
		.map(([filterId, _]) => filterId);
	// avoid double counting issue type and status filters
	let adjustment = 0;
	if (
		nonEmptyFilters.indexOf(STATUS_KEY_FILTER_ID) > -1 &&
		nonEmptyFilters.indexOf(STATUS_FILTER_ID) > -1
	) {
		adjustment += 1;
	}
	if (
		nonEmptyFilters.indexOf(ISSUE_TYPE_KEY_FILTER_ID) > -1 &&
		nonEmptyFilters.indexOf(ISSUE_TYPES_FILTER_ID) > -1
	) {
		adjustment += 1;
	}
	if (!isAtlasConnectInstalledCheck && nonEmptyFilters.includes(GOAL_FILTER_ID)) {
		adjustment += 1;
	}
	if (
		(!fg('polaris-arj-eap-override') ||
			(isEmbedOrMacro &&
				!fg('polaris-use-rest-in-embed-macro-mode') &&
				fg('polaris-arj-eap-override'))) &&
		nonEmptyFilters.includes(IDEA_FILTER_ID)
	) {
		adjustment += 1;
	}
	return customFieldCount + nonEmptyFilters.length - adjustment;
};

export const getFilterCount = createSelector(
	[getCustomFieldFilterCount, isAtlasConnectInstalled, getFilters, isEmbed, isConfluenceMacro],
	getFilterCountPure,
);

export const getRawFilters = (state: State) => getFiltersViewSettings(state);

export const filterById: Partial<
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Record<keyof ResolvedFilters, (issue: Issue, ...args: any[]) => boolean>
> = {
	[PROJECT_FILTER_ID]: applyProjectFilter,
	[HIERARCHY_FILTER_ID]: applyHierarchyFilter,
	[HIERARCHY_RANGE_FILTER_ID]: applyHierarchyRangeFilter,
	[RELEASE_FILTER_ID]: applyReleaseFilter,
	[TEAM_FILTER_ID]: applyTeamFilter,
	[ASSIGNEE_FILTER_ID]: applyAssigneeFilter,
	[REPORTER_FILTER_ID]: applyReporterFilter,
	[SUMMARY_FILTER_ID]: applySummaryFilter,
	[DEPENDENCIES_FILTER_ID]: applyDependenciesFilter,
	[LABEL_FILTER_ID]: applyLabelFilter,
	[STATUS_KEY_FILTER_ID]: applyStatusKeyFilter,
	[COMPONENT_FILTER_ID]: applyComponentFilter,
	[ISSUE_WARNING_FILTER_ID]: applyIssueWarningFilter,
	[SPRINT_FILTER_ID]: applySprintFilter,
	[ISSUE_TYPE_KEY_FILTER_ID]: applyIssueTypeKeyFilter,
	[ISSUE_PRIORITIES_FILTER_ID]: applyIssuePrioritiesFilter,
	[CUSTOM_FIELD_FILTER_ID]: applyCustomFieldFilter,
	[GOAL_FILTER_ID]: applyGoalFilter,
	[IDEA_FILTER_ID]: applyIdeaFilter,
	[DATE_RANGE_FILTER_ID]: applyDateRangeFilter,
};

export type MatchFilterOptions = {
	exclude?: FilterId[];
};

export function matchFilter(
	filters: ResolvedFilters,
	optimizedMode: boolean,
	customFields: CustomField[],
	issue: Issue,
	options: MatchFilterOptions = {},
): boolean {
	const exclude = options.exclude || [];
	for (const filterId of R.keys(filters)) {
		const filterFn = filterById[filterId];

		if (typeof filterFn === 'function' && !exclude.includes(filterId)) {
			if (filterId === CUSTOM_FIELD_FILTER_ID) {
				if (!filterFn(issue, filters[filterId], customFields)) {
					return false;
				}
			} else if (!filterFn(issue, filters[filterId], optimizedMode)) {
				return false;
			}
		}
	}

	return true;
}

export function getUnmatchedFiltersList(
	filters: ResolvedFilters,
	optimizedMode: boolean,
	customFields: CustomField[],
	issue: Issue,
): UnMatchedFilters[] {
	const customFieldsById = indexBy(R.prop('id'), customFields);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const appliedCustomFilters = (filters: CustomFieldFilterValueV0) => {
		const customFilters: Array<CustomField> = [];
		for (const [key, value] of Object.entries(filters)) {
			// if the value is not an empty array, the plan is filtered on that custom field
			if (value && value.length) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				customFilters.push(customFieldsById[key as unknown as number]);
			}
		}
		return customFilters;
	};

	const unmatchedList: Array<UnMatchedFilters> = [];

	for (const filterId of R.keys(filters)) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const filterFn = filterById[filterId as keyof typeof filters];

		if (typeof filterFn === 'function') {
			if (filterId === CUSTOM_FIELD_FILTER_ID) {
				if (!filterFn(issue, filters[filterId], customFields)) {
					unmatchedList.push({
						id: filterId,
						type: 'custom',
						filters: appliedCustomFilters(filters[filterId].value),
					});
				}
			} else if (!filterFn(issue, filters[filterId], optimizedMode)) {
				unmatchedList.push({ id: filterId, type: 'standard', filters: [] });
			}
		}
	}

	return unmatchedList;
}
