import React, { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import Select from '@atlassian/jira-portfolio-3-portfolio/src/common/view/select/view.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const MAX_FIELD_LENGTH = 255;

type OptionType = { label: string; value: string };

export const fetchLabels = (planId: number, scenarioId: number, query?: string) =>
	fetch('/rest/jpo/1.0/scenarios/labels', {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({
			limit: 50,
			planId,
			scenarioId,
			query: fg('refetch_bulk_labels') ? query || null : null,
		}),
	});

const useLabelsDebounced = (planId?: number, scenarioId?: number, query?: string): OptionType[] => {
	const [labels, setLabels] = useState<OptionType[]>([]);

	const debouncedGetLabels = useMemo(() => {
		const getLabels = async (searchText?: string) => {
			if (planId == null || scenarioId == null) {
				throw Error('No planId or scenarioId from useLabels');
			}
			try {
				const raw = await fetchLabels(planId, scenarioId, searchText);
				if (raw.ok) {
					const response = await raw.json();
					const formattedResponse =
						response?.items?.map((item: string) => ({ value: item, label: item })) || [];
					setLabels(formattedResponse);
				} else {
					throw Error('Failed to retrieve labels');
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				throw Error('Failed to retrieve labels');
			}
		};
		return debounce(getLabels, 500);
	}, [planId, scenarioId]);

	useEffect(() => {
		debouncedGetLabels(query);
	}, [debouncedGetLabels, query]);

	return labels;
};

const useLabels = (planId?: number, scenarioId?: number): OptionType[] => {
	const [labels, setLabels] = useState<OptionType[]>([]);

	useEffect(() => {
		const getLabels = async () => {
			if (planId == null || scenarioId == null) {
				throw Error('No planId or scenarioId from useLabels');
			}
			try {
				const raw = await fetchLabels(planId, scenarioId);
				if (raw.ok) {
					const response = await raw.json();
					const formattedResponse =
						response?.items?.map((item: string) => ({ value: item, label: item })) || [];
					setLabels(formattedResponse);
				} else {
					throw Error('Failed to retrieve labels');
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				throw Error('Failed to retrieve labels');
			}
		};

		getLabels();
	}, [planId, scenarioId]);

	return labels;
};

const LabelSelect = ({ plan, editedLabels, onLabelChange }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [currentTypedInput, setCurrentTypedInput] = useState('');
	const [currentValue, setCurrentValue] = useState(editedLabels);

	const planLabels = fg('refetch_bulk_labels')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useLabelsDebounced(plan.id, plan.currentScenarioId, currentTypedInput)
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useLabels(plan.id, plan.currentScenarioId);
	const [options, setOptions] = useState<OptionType[]>(planLabels);

	useEffect(() => {
		setOptions(planLabels.sort((a, b) => a.label.localeCompare(b.label)));
	}, [planLabels]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleChange = (newOption: any) => {
		setCurrentValue(newOption);
		// Callback
		onLabelChange(newOption);
	};

	const handleCreate = (inputValue: string) => {
		const newOption = {
			label: inputValue,
			value: inputValue,
		};

		setOptions([...options, newOption].sort((a, b) => a.label.localeCompare(b.label)));
		setCurrentValue([...currentValue, newOption]);
		// Callback
		onLabelChange([...currentValue, newOption]);

		// Analytics
		const [actionSubject, action] = PRODUCT_ANALYTICS_EVENT_NAMES.ADD_LABEL.split(' ');
		const analyticsEvent = createAnalyticsEvent({ action, actionSubject });
		fireTrackAnalytics(analyticsEvent, PRODUCT_ANALYTICS_EVENT_NAMES.ADD_LABEL);
	};

	const noOptionsMessage = (obj: { inputValue: string }) => {
		if (obj.inputValue.includes(' ')) {
			return formatMessage(messages.invalidInputWithSpace);
		}
		if (obj.inputValue.trim().length > 255) {
			return formatMessage(messages.invalidInputWithMaxLength);
		}
		return null;
	};

	const handleInputChange = (input: string) => {
		setCurrentTypedInput(input);
	};

	return (
		<Select
			id="label-select"
			placeholder={formatMessage(messages.labelPlaceholder)}
			isMulti
			isSearchable
			isClearable
			isCreatable
			closeMenuOnSelect={false}
			noOptionsMessage={noOptionsMessage}
			isValidNewOption={(newValue: string | undefined) =>
				newValue !== undefined &&
				newValue.length > 0 &&
				newValue.length <= MAX_FIELD_LENGTH &&
				!newValue.includes(' ')
			}
			value={currentValue}
			onChange={handleChange}
			onCreateOption={(x: string) => handleCreate(x)}
			onInputChange={handleInputChange}
			options={options}
			formatCreateLabel={(newValue: string): string =>
				`${newValue} (${formatMessage(messages.newLabel)})`
			}
			isTransparentBackground={false}
			styles={{
				input: (styles) => ({
					...styles,
					width: currentTypedInput ? '100px' : '1px',
				}),
			}}
			aria-label={formatMessage(messages.labelPlaceholder)}
		/>
	);
};

export default LabelSelect;
