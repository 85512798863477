import { useCallback, useMemo } from 'react';
import dropRight from 'lodash/fp/dropRight';
import sum from 'lodash/fp/sum';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useCellOld,
	useCellNew,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';
import { useViewport } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import { useColumnWidths } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';
import { useActions, useContextActions } from './state.tsx';

/**
 * Returns the column widths that fits in the viewport.
 *
 * This handles the scrollable timeline view where the timeline column is set to be 4e6-pixel width.
 */
export const useCollapsibleWidths = ({ preview }: { preview: boolean }) => {
	const [widths] = useColumnWidths({ preview });
	const [viewport] = useViewport();

	return useMemo(() => {
		const withLastDropped = dropRight(1)(widths);
		const refinedTimelineWidth = viewport.width - sum(withLastDropped);

		return withLastDropped.concat([refinedTimelineWidth]);
	}, [viewport.width, widths]);
};

/** Exposes a callback to toggle the column from a cell. */
export const useColumnToggle = () => {
	let row;
	if (fg('plans_performance_improvements_2')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ row } = useRow());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[{ row }] = useCellOld();
	}
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [{ column }] = fg('plans_performance_improvements_2') ? useCellNew() : useCellOld();

	const { expand, collapse, expandFields, collapseFields } = fg(
		'convert-column-provider-to-context',
	) // eslint-disable-next-line react-hooks/rules-of-hooks
		? useContextActions()
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useActions();

	const isFieldsSection = row === 0 && column === 1;

	return useCallback(
		(expanding: boolean) => {
			if (isFieldsSection) {
				expanding ? expandFields() : collapseFields();
			} else {
				expanding ? expand(column) : collapse(column);
			}
		},
		[isFieldsSection, column, expand, collapse, expandFields, collapseFields],
	);
};
