import type { MiddlewareAPI } from 'redux';
import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { isPollinatorTenant } from '@atlassian/jira-portfolio-3-common/src/feature-flags/index.tsx';
import Timer from '@atlassian/jira-portfolio-3-common/src/timer/index.tsx';
import analytics, {
	context,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import {
	ADD_RELEASE as ADD_CROSS_PROJECT_RELEASE,
	type AddReleaseCommand as AddCrossProjectReleaseCommand,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/cross-project-versions/index.tsx';
import {
	CLICK_EXPORT_TO_CSV_ANALYTICS,
	PLAN_EXPORTED_ANALYTICS,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/index.tsx';
import {
	BULK_INLINE_CREATE_ISSUES,
	type BulkInlineCreateIssuesAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/inline-create/index.tsx';
import {
	ADD_ISSUE,
	BULK_ISSUES_UPDATE,
	UPDATE_ISSUE,
	RANK_ISSUE,
	EXPAND_ALL_ANALYTICS_ACTION,
	COLLAPSE_ALL_ANALYTICS_ACTION,
	type ExpandAllAnalyticsAction,
	type CollapseAllAnalyticsAction,
	type BulkUpdateIssueAction,
	type UpdateIssueAction,
	type RankIssueAction,
	type AddIssueAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/types.tsx';
import {
	BEFORE_APPLY_CHANGE,
	AFTER_APPLY_CHANGE,
	BEFORE_OPTIMIZE_CALCULATION,
	AFTER_OPTIMIZE_CALCULATION,
	type AfterOptimizeCalculationAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/optimize/index.tsx';
import {
	UPDATE_PLAN,
	type UpdatePlan,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/plan/index.tsx';
import {
	VIEW_ROADMAP,
	type ViewRoadmapAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/roadmap/index.tsx';
import {
	CREATE_TEAM,
	SHARE_TEAM,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/teams/index.tsx';
import {
	COMMIT_CHANGES,
	type CommitChangesAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/update-jira/index.tsx';
import {
	getAnalyticsEvent,
	getMode,
	getPlanUserRole,
	isExportMode,
	getPlanModeForAnalytics,
	isConfluenceMacroProxy as getIsConfluenceMacroProxy,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import {
	getCrossProjectVersionChanges,
	getCrossProjectVersionChangeCount,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/index.tsx';
import {
	getCustomFields,
	getCustomFieldsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import {
	getRawFilters,
	getFiltersAndHierarchyLevelsForAnalytics,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/index.tsx';
import { getUniqueLinks } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/index.tsx';
import {
	getFilteredIssues,
	getSelectedIssues,
	getIssueAttributeChangeCounts,
	getCreatedIssueCount,
	getIssueChangeCount,
	getIssueChanges,
	getIssueChangesWithChangedLinksCount,
	getIssueChangesWithChangedLinks,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import {
	getIssueSources,
	getPlan,
	getPlanningUnit,
	getCreatedTimestamp,
	getPlanScenarios,
	isMultiScenarioEnabled,
	getAutoSchedulerEnabled,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import {
	getProjects,
	getProjectsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import {
	getAllIssues,
	getIssueMapById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import { getFieldKey } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/sort-utils.tsx';
import { getSprintIdsWithDatesIsInconsistent } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/index.tsx';
import {
	getInstanceUserRole,
	getFiscalMonth,
	isAtlasConnectInstalledSystem as getIsAtlasConnectInstalled,
	getHasAtlasPermissions,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import {
	getTeamAndResourceChanges,
	getTeamOrResourceChangeCount,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/changes.tsx';
import {
	getAllTeams,
	getAdditionalTeams,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/index.tsx';
import { getZoomLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline/index.tsx';
import { getToolBarVisibilityState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/tool-bar/index.tsx';
import {
	getSelectedChanges,
	getReviewChangesCount,
	getReviewChanges,
	getCountForStatusCategory,
	getCountForGoals,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/index.tsx';
import { getUrlPersistenceEstimate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/url-persistence/index.tsx';
import {
	getVersionChanges,
	getVersionChangeCount,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/index.tsx';
import {
	getViewMode,
	getColorByViewSettings,
	getDependencySettings,
	getTimeScaleViewSettings,
	getRollupViewSettings,
	getWarningViewSettings,
	getVisualisationViewSettings,
	getFieldColumnsViewSettingsByViewMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import { getViews } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/views/index.tsx';
import {
	isColorByCustomFieldOption,
	getColorByValue,
	getVisualisationGrouping,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import { getTagTableItems } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/query.tsx';
import { DATEFIELD_TYPES } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	isDefined,
	valuesKeysLength,
	indexBy,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ENTITY,
	SCENARIO_TYPE,
	type ScenarioType,
	PROJECT_TYPE_KEY,
	TIMELINE_MODES,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getGroupForAnalyticEvent } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/grouping/index.tsx';
import {
	type Attributes,
	fireTrackAnalytics,
	fireUIAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { AFTER_RESET_BACKLOG } from '../../domain/actions.tsx';
import { UPDATE_PARENT, type UpdateParentAction } from '../../domain/history-issues/actions.tsx';
import type { PlanInfo } from '../../domain/plan/types.tsx';
import type { Project } from '../../domain/projects/types.tsx';
import { AFTER_SUCCESS as AFTER_GET_CHANGES_SUCCESS } from '../../domain/update-jira/changes/actions.tsx';
import {
	HIDE_COLUMN,
	SHOW_COLUMN,
	MOVE_COLUMN,
	type MoveColumnAction,
	type ShowColumnAction,
	type HideColumnAction,
} from '../../domain/view-settings/field-columns/actions.tsx';
import {
	CHANGE as CHANGE_FILTER,
	CLEAR as CLEAR_FILTER,
	CLEAR_ALL as CLEAR_ALL_FILTERS,
	type ChangeAction,
	type ClearAction,
} from '../../domain/view-settings/filters/actions.tsx';
import {
	UPDATE as CHANGE_CUSTOM_FILTER,
	CLEAR as CLEAR_CUSTOM_FILTER,
	type UpdateAction as UpdateCustomFieldFilterAction,
} from '../../domain/view-settings/filters/custom-field-filter/actions.tsx';
import {
	type FilterValue,
	ROADMAP_FILTER_SOURCE,
	RELEASE_FILTER_SOURCE,
	ISSUE_PRIORITIES_FILTER_ID,
	CUSTOM_FIELD_FILTER_ID,
	ISSUE_TYPE_KEY_FILTER_ID,
	DEPENDENCIES_FILTER_ID,
	SUMMARY_FILTER_ID,
} from '../../domain/view-settings/filters/types.tsx';
import {
	SET_IS_EXPANDED,
	type SetIsExpandedAction,
} from '../../domain/view-settings/issue-expansions/actions.tsx';
import {
	CHANGE_OPTION,
	CHANGE_MODE,
	CHANGE_CUSTOM_DATES,
	type ChangeModeAction,
	type ChangeCustomDatesAction,
} from '../../domain/view-settings/time-scale/actions.tsx';
import {
	TOGGLE_EXPAND_GROUP,
	type ToggleExpandGroupAction,
} from '../../domain/view-settings/visualisations/actions.tsx';
import {
	RESET as RESET_VIEWS,
	SET_ACTIVE_VIEW,
	SAVE_VIEW,
	ADD_VIEW,
	MARK_AS_DEFAULT_VIEW,
	type ResetAction,
	type SetActiveViewAction,
	type SaveViewAction,
	type AddViewAction,
	type MarkAsDefaultViewAction,
} from '../../domain/views/actions.tsx';
import type { View } from '../../domain/views/types.tsx';
import type { State } from '../../types.tsx';
import {
	SEARCH_ICON_CLICK,
	STARTED_SEARCH_RESULT_NAVIGATION,
} from '../../ui/main/tabs/roadmap/scope/header/search/actions.tsx';
import { TOGGLE_TOOLBAR } from '../../ui/top/title-bar/tool-bar/actions.tsx';
import { CustomFieldTypeNameMap } from './constants.tsx';
import type { GenericAction, IssueAnalyticRequiredAttributes } from './types.tsx';
import {
	countFutureSprintDates,
	getColoursUsedInPlan,
	countIssueInferredDates,
	getTimelineAttributes,
	logAnalyticsError,
	getPresetName,
	getIssueAnalyticAttributes,
} from './utils.tsx';

const changesOfType = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	changes: any[],
	selectedChanges: string[],
	scenarioType: ScenarioType,
): number => {
	const changesById = indexBy(R.prop('id'), changes);
	return selectedChanges.filter(
		(id: string) => changesById[id].metaData.scenarioType === scenarioType,
	).length;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const typeOfCustomField = (field: any) => {
	const fieldKey = field && R.path(['type', 'key'], field);
	const fieldType = (fieldKey && fieldKey.split(':')[1]) || '';
	return `customfield_${fieldType}`;
};

const filterSourceMap: Record<string, string> = {
	'#plan/backlog': ROADMAP_FILTER_SOURCE,
	'#plan/releases': RELEASE_FILTER_SOURCE,
};

const isPredefined = (view: View): boolean =>
	!isDefined(view.createdBy) && !isDefined(view.updatedBy);

const handleAnalyticsError =
	(analyticsArgs: (arg1: GenericAction) => undefined | null | undefined) =>
	(action: GenericAction): undefined | null | undefined => {
		try {
			return analyticsArgs(action);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			logAnalyticsError(error, `analytics failed in ${action.type}`);
		}
	};

class AnalyticsMiddleware {
	applyChangeTimer: Timer | undefined;

	optimizeCalculationTimer: Timer | undefined;

	ignoredFilterIds: string[];

	constructor() {
		this.ignoredFilterIds = [
			ISSUE_TYPE_KEY_FILTER_ID,
			ISSUE_PRIORITIES_FILTER_ID,
			CUSTOM_FIELD_FILTER_ID,
		];
	}

	apply =
		(store: MiddlewareAPI<State>) =>
		(next: (action: GenericAction) => undefined | null | undefined) =>
			handleAnalyticsError((action: GenericAction): undefined | null | undefined => {
				switch (action.type) {
					case AFTER_RESET_BACKLOG: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const dependencyCount = valuesKeysLength(state.domain.issueLinks.values);
						if (isDefined(planId) && isDefined(scenarioId)) {
							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.RESET_BACKLOG.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });
							fireOperationalAnalytics(
								analyticsEvent,
								PRODUCT_ANALYTICS_EVENT_NAMES.RESET_BACKLOG,
								{ dependencyCount },
							);
						}
						break;
					}
					case BEFORE_APPLY_CHANGE:
						this.applyChangeTimer = new Timer();
						this.applyChangeTimer.start();
						break;

					// Triggered when a suggested schedule has finished being accepted into a plan
					case AFTER_APPLY_CHANGE:
						if (
							isDefined(this.applyChangeTimer) &&
							this.applyChangeTimer.isStarted() &&
							!this.applyChangeTimer.isStopped()
						) {
							this.applyChangeTimer.stop();
							const state = store.getState();
							const { id: planId } = getPlan(state);
							if (isDefined(planId)) {
								const analyticsEvent = getAnalyticsEvent(state);
								const [actionSubject, eventAction] =
									PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_ACCEPT_OPTIMIZATION.split(' ');
								analyticsEvent.update({ action: eventAction, actionSubject });

								fireUIAnalytics(analyticsEvent, {
									// The time (in milli-seconds) that it took to accept the suggested schedule
									totalTimeInMillis: this.applyChangeTimer.duration(),
								});
							}
						}
						break;
					case BEFORE_OPTIMIZE_CALCULATION:
						this.optimizeCalculationTimer = new Timer();
						this.optimizeCalculationTimer.start();
						break;
					case AFTER_OPTIMIZE_CALCULATION: {
						const afterOptimizeCalculationAction: AfterOptimizeCalculationAction =
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
							action as any;
						if (
							isDefined(this.optimizeCalculationTimer) &&
							this.optimizeCalculationTimer.isStarted() &&
							!this.optimizeCalculationTimer.isStopped()
						) {
							this.optimizeCalculationTimer.stop();
							const state = store.getState();
							const { id: planId } = getPlan(state);
							if (isDefined(planId)) {
								const selectedIssues = getSelectedIssues(state);
								analytics.triggerCalculationDurationAnalytics(
									afterOptimizeCalculationAction.meta.analyticsEvent,
									{
										totalTimeInMillis: this.optimizeCalculationTimer.duration(),
										isSelectiveScheduling: selectedIssues.length > 0,
									},
								);
							}
						}
						break;
					}

					case COMMIT_CHANGES: {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const commitChangesAction: CommitChangesAction = action as any;
						const state = store.getState();

						const countForGoals = getCountForGoals(state);

						if (countForGoals.numGoalsRemoved || countForGoals.numGoalsAdded) {
							const analyticsEvent = getAnalyticsEvent(state);
							fireTrackAnalytics(analyticsEvent, 'goals updated', {
								source: 'Plans',
								...countForGoals,
							});
						}

						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const teamsData = state.domain.teams;
						const versionChanges = getVersionChanges(state);
						const crossProjectVersionChanges = getCrossProjectVersionChanges(state);
						const selectedChanges = getSelectedChanges(state);
						const selectedVersionChanges = selectedChanges[ENTITY.RELEASE];
						const selectedCrossProjectVersionChanges =
							selectedChanges[ENTITY.CROSS_PROJECT_RELEASE];
						const changedAttributeCounts = getIssueAttributeChangeCounts(state);
						const createdIssueCount = getCreatedIssueCount(state);
						const countForStatusCategory = getCountForStatusCategory(state);
						if (isDefined(planId) && isDefined(scenarioId)) {
							analytics.triggerUpdateJiraEventAnalytics(commitChangesAction.meta.analyticsEvent, {
								teamCreateCount: teamsData.filter(
									(team) => team.scenarioType === SCENARIO_TYPE.ADDED,
								).length,
								teamUpdateCount: teamsData.filter(
									(team) => team.scenarioType === SCENARIO_TYPE.UPDATED,
								).length,
								versionCreateCount: changesOfType(
									versionChanges,
									selectedVersionChanges,
									SCENARIO_TYPE.ADDED,
								),
								versionUpdateCount: changesOfType(
									versionChanges,
									selectedVersionChanges,
									SCENARIO_TYPE.UPDATED,
								),
								versionRemoveCount: changesOfType(
									versionChanges,
									selectedVersionChanges,
									SCENARIO_TYPE.DELETED,
								),
								versionCPRCreateCount: changesOfType(
									crossProjectVersionChanges,
									selectedCrossProjectVersionChanges,
									SCENARIO_TYPE.ADDED,
								),
								versionCPRUpdateCount: changesOfType(
									crossProjectVersionChanges,
									selectedCrossProjectVersionChanges,
									SCENARIO_TYPE.UPDATED,
								),
								versionCPRRemoveCount: changesOfType(
									crossProjectVersionChanges,
									selectedCrossProjectVersionChanges,
									SCENARIO_TYPE.DELETED,
								),
								issueCreateCount: createdIssueCount,
								assigneeChangeCount: changedAttributeCounts.assignee || 0,
								baselineStartChangeCount: changedAttributeCounts.baselineStart || 0,
								baselineEndChangeCount: changedAttributeCounts.baselineEnd || 0,
								customFieldChangeCount: changedAttributeCounts.customField || 0,
								dueDateChangeCount: changedAttributeCounts.dueDate || 0,
								fixVersionsChangeCount: changedAttributeCounts.fixVersions || 0,
								labelsChangeCount: changedAttributeCounts.labels || 0,
								lexoRankChangeCount: changedAttributeCounts.lexoRank || 0,
								parentChangeCount: changedAttributeCounts.parent || 0,
								priorityChangeCount: changedAttributeCounts.priority || 0,
								sprintChangeCount: changedAttributeCounts.sprint || 0,
								storyPointsChangeCount: changedAttributeCounts.storyPoints || 0,
								summaryChangeCount: changedAttributeCounts.summary || 0,
								descriptionChangeCount: changedAttributeCounts.description || 0,
								teamChangeCount: changedAttributeCounts.team || 0,
								componentsChangeCount: changedAttributeCounts.components || 0,
								countForStatusCategory,
							});
						}
						break;
					}

					case ADD_ISSUE: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						if (isDefined(planId) && isDefined(scenarioId)) {
							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.ADD_ISSUE.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });

							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							const addAction = action as AddIssueAction;

							const attributes: Attributes = getIssueAnalyticAttributes(
								addAction.payload.issue,
								getProjectsById(state),
								{},
							);

							fireTrackAnalytics(
								analyticsEvent,
								PRODUCT_ANALYTICS_EVENT_NAMES.ADD_ISSUE,
								attributes,
							);
						}
						break;
					}

					/**
					 * This action is triggered when bulk creating child issues from a parent issue.
					 * A "TRACK" analytics event is fired for each child issue created.
					 * At the time of adding this comment, it is only used in the ai-work-breakdown-popup feature.
					 */
					case BULK_INLINE_CREATE_ISSUES: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);

						if (isDefined(planId) && isDefined(scenarioId)) {
							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.ADD_ISSUE.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });

							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							const bulkInlineCreateIssuesAction = action as BulkInlineCreateIssuesAction;
							const {
								payload: {
									parentIssue: { hierarchyLevel, projectId },
									suggestedIssues,
								},
							} = bulkInlineCreateIssuesAction;

							suggestedIssues.forEach(({ id, issueTypeId }) => {
								/** note: the lines of code in this loop are inspired from the "ADD_ISSUE" action in the previous "case" */
								const issue: IssueAnalyticRequiredAttributes = {
									id,
									project: projectId,
									level: hierarchyLevel - 1,
									type: issueTypeId,
								};

								const attributes: Attributes = getIssueAnalyticAttributes(
									issue,
									getProjectsById(state),
									{},
								);

								fireTrackAnalytics(
									analyticsEvent,
									PRODUCT_ANALYTICS_EVENT_NAMES.ADD_ISSUE,
									attributes,
								);
							});
						}
						break;
					}

					case BULK_ISSUES_UPDATE: {
						const state = store.getState();
						const analyticsEvent = getAnalyticsEvent(state);
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const selectedIssues = getSelectedIssues(state);

						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.UPDATE_ISSUES.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const bulkUpdateAction: BulkUpdateIssueAction = action as any;
						if (isDefined(planId) && isDefined(scenarioId)) {
							// We can't rely on the MOVE_ISSUE action to determine issues because it is also
							// called when ranking issues. However, we know that a move event has occurred when
							// an issue is updated with a new parent.
							if (bulkUpdateAction.payload && bulkUpdateAction.payload.parent) {
								// eslint-disable-next-line @typescript-eslint/no-shadow
								const [actionSubject, eventAction] =
									PRODUCT_ANALYTICS_EVENT_NAMES.REPARENT_ISSUES.split(' ');
								analyticsEvent.update({ action: eventAction, actionSubject });
							}

							fireUIAnalytics(analyticsEvent, {
								issueCount: selectedIssues.length,
								attributes: Object.keys(bulkUpdateAction.payload).filter((key) => key !== 'id'),
							});
						}
						break;
					}

					case UPDATE_ISSUE: {
						const state = store.getState();
						const analyticsEvent = getAnalyticsEvent(state);
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);

						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.UPDATE_ISSUE.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const updateAction: UpdateIssueAction = action as any;

						const issue = getIssueMapById(state)[updateAction.payload.id];
						const updatedFields = Object.keys(updateAction.payload).filter((key) => key !== 'id');

						const attributes: Attributes = getIssueAnalyticAttributes(
							issue,
							getProjectsById(state),
							{
								updatedFields,
							},
						);

						if (isDefined(planId) && isDefined(scenarioId)) {
							fireUIAnalytics(analyticsEvent, attributes);
						}
						break;
					}

					case RANK_ISSUE: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);

						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const rankAction: RankIssueAction = action as any;

						if (isDefined(planId) && isDefined(scenarioId)) {
							const issueId = rankAction.payload.itemKeys[0];
							const issue = getIssueMapById(state)[issueId];

							const attributes: Attributes = getIssueAnalyticAttributes(
								issue,
								getProjectsById(state),
								{
									method: 'dragged',
								},
							);

							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.RANK_ISSUE.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });

							fireTrackAnalytics(
								analyticsEvent,
								PRODUCT_ANALYTICS_EVENT_NAMES.RANK_ISSUE,
								attributes,
							);
						}
						break;
					}

					case VIEW_ROADMAP: {
						const state = store.getState();
						// Don't fire the view roadmapTabScreen event in export mode.
						if (isExportMode(state)) {
							break;
						}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const viewRoadmapAction: ViewRoadmapAction = action as any;
						const autoSchedulerEnabled: boolean = getAutoSchedulerEnabled(state);

						const { id: planId, currentScenarioId: scenarioId, isSamplePlan } = getPlan(state);
						const planningUnit = getPlanningUnit(state);
						const customFields = getCustomFields(state);
						const group = getGroupForAnalyticEvent(
							getVisualisationViewSettings(state).grouping,
							customFields,
						);
						const sorting = getVisualisationViewSettings(state).sorting;
						const sortingField = getFieldKey(sorting.field, planningUnit);
						const sortByField = sorting.isCustomField
							? `customfield_${sorting.type}`
							: sortingField;
						const showSprints = getVisualisationViewSettings(state).showSprints;
						const showWarnings = getWarningViewSettings(state).showWarning;
						const rollupSettings = getRollupViewSettings(state);
						let colourBy = getColorByValue(state);
						if (isColorByCustomFieldOption(colourBy)) {
							const customFieldsById = getCustomFieldsById(state);
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							const CFTypeKey = customFieldsById[colourBy as unknown as number]?.type?.key;
							const CFTypeName = CustomFieldTypeNameMap[CFTypeKey];
							colourBy = CFTypeName || CustomFieldTypeNameMap.default;
						}

						const colours = getColoursUsedInPlan(getColorByViewSettings(state));
						const existingTeams = state.domain.teams.filter(
							(team) => team.scenarioType !== 'DELETED',
						);
						const teamsScrumCount = existingTeams.filter(
							(team) => team.schedulingMode === 'Scrum',
						).length;

						const teamsKanbanCount = existingTeams.filter(
							(team) => team.schedulingMode === 'Kanban',
						).length;

						const teamsSharedCount = existingTeams.filter((team) => team.shareable).length;
						const projectsShownInPlan: number[] = getIssueSources(state)
							.filter((source) => source.type === 'Project')
							.map((project) => Number(project.value));
						const boardsShownInPlan: number[] = getIssueSources(state)
							.filter((source) => source.type === 'Board')
							.map((board) => Number(board.value));
						const filtersShownInPlan: number[] = getIssueSources(state)
							.filter((source) => source.type === 'Filter')
							.map((filter) => Number(filter.value));
						const sourcesTotalCount = getIssueSources(state).length;
						const sourcesBoardCount = boardsShownInPlan.length;
						const sourcesProjectCount = projectsShownInPlan.length;
						const sourcesFilterCount = filtersShownInPlan.length;
						const planPermission = getPlanUserRole(state);
						const instancePermission = getInstanceUserRole(state);
						const {
							active: sprintActiveCount,
							future: sprintFutureCount,
							sprintFutureSetDates: sprintFutureSetDatesCount,
							sprintFutureInferredDates: sprintFutureInferredDatesCount,
						} = countFutureSprintDates(state.domain.sprints);

						const {
							issuesWithFixedStartDateCount,
							issuesWithFixedEndDateCount,
							issuesWithSprintStartDateCount,
							issuesWithSprintEndDateCount,
							issuesWithReleaseStartDateCount,
							issuesWithReleaseEndDateCount,
						} = countIssueInferredDates(
							state.domain.plan,
							state.domain.issues,
							state.domain.versions,
						);

						const fieldColumns = getFieldColumnsViewSettingsByViewMode(state)
							.columns.filter((field) => field.isVisible)
							.map((field) => field.id);
						const issuesCount = getAllIssues(state).length;
						const dependenciesCount = getUniqueLinks(state).length;
						const dependencySettings = getDependencySettings(state);
						const dependencySettingsDisplay = dependencySettings ? dependencySettings.display : '';
						const planCreatedTimestamp = getCreatedTimestamp(state) || 0;
						const planCreatedDate = formatDateUTC(
							planCreatedTimestamp * 1000,
							'YYYY-MM-DD HH:mm:ss',
						);

						const allTeams = getAllTeams(state);
						const teamsShownInPlan = allTeams.map(({ id }) => Number(id));

						const externalTeams = getAdditionalTeams(state);

						const multiScenarioEnabled = !!isMultiScenarioEnabled(state);
						const scenarios = getPlanScenarios(state);
						const scenariosCount = scenarios?.length || 1;

						const { selectedFilters, hierarchyLevels } =
							getFiltersAndHierarchyLevelsForAnalytics(state);

						if (isDefined(planId) && isDefined(scenarioId)) {
							const customDateRange = getTimeScaleViewSettings(state).customDateRange || {};
							const zoomLevel = getZoomLevel(state);
							const { timelineType, customTimelineType } = getTimelineAttributes(
								customDateRange,
								zoomLevel,
							);

							const views = getViews(state);
							const viewsInPlanCount = views.length;
							const viewsWithDependencyBadgesCount = views.filter(
								(view) => view.preferences.dependencySettingsV0.display === 'default',
							).length;
							const viewsWithDependencyLinesCount = views.filter(
								(view) => view.preferences.dependencySettingsV0.display === 'lines',
							).length;
							const viewsWithDependencyCountCount = views.filter(
								(view) => view.preferences.dependencySettingsV0.display === 'count',
							).length;

							const projects: Project[] = getProjects(state);
							const projectsByType: {
								[key: string]: Project[];
							} = R.groupBy(
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
								R.props(['projectTypeKey', 'isSimplified']) as any,
								projects,
							);

							// jira software project types count
							const sourcesProjectCountJswCmpCount: number =
								projectsByType[`${PROJECT_TYPE_KEY.SOFTWARE},false`]?.length ?? 0;
							const sourcesProjectCountJswTmpCount: number =
								projectsByType[`${PROJECT_TYPE_KEY.SOFTWARE},true`]?.length ?? 0;

							// jira work management project types count
							const sourcesProjectCountJwmCmpCount: number =
								projectsByType[`${PROJECT_TYPE_KEY.WORK_MANAGEMENT},false`]?.length ?? 0;
							const sourcesProjectCountJwmTmpCount: number =
								projectsByType[`${PROJECT_TYPE_KEY.WORK_MANAGEMENT},true`]?.length ?? 0;

							// jira service management project type count (cmp only)
							const sourcesProjectCountJsmCmpCount: number =
								projectsByType[`${PROJECT_TYPE_KEY.SERVICE_MANAGEMENT},false`]?.length ?? 0;

							// all project types count
							const sourcesProjectCountCmpCount: number =
								sourcesProjectCountJswCmpCount +
								sourcesProjectCountJwmCmpCount +
								sourcesProjectCountJsmCmpCount;
							const sourcesProjectCountTmpCount: number =
								sourcesProjectCountJswTmpCount + sourcesProjectCountJwmTmpCount;

							const fiscalMonth = getFiscalMonth(state);

							const hasSprintsWithInconsistentDates =
								getSprintIdsWithDatesIsInconsistent(state).length > 0;

							const hasAtlasPermissions = getHasAtlasPermissions(state);
							const isAtlasConnectInstalled = getIsAtlasConnectInstalled(state);

							const tagTableItems = getTagTableItems(state);
							const rowHeights = tagTableItems.map((tableItem) => tableItem.height || 1);
							const maxOverlappingSprints = Math.max(...rowHeights, 0);

							const viewMode = getViewMode(state);

							const urlPersistenceEstimate = fg('plans_-_url_persistence_estimates')
								? getUrlPersistenceEstimate(state)
								: undefined;

							const isMacroProxy = getIsConfluenceMacroProxy(state);
							analytics.triggerViewRoadmapEventAnalytics(
								viewRoadmapAction.meta.analyticsEvent,
								group,
								colourBy,
								colours,
								sortByField,
								existingTeams.length,
								teamsScrumCount,
								teamsKanbanCount,
								teamsSharedCount,
								externalTeams.length,
								sourcesTotalCount,
								sourcesBoardCount,
								sourcesProjectCount,
								sourcesFilterCount,
								rollupSettings,
								showSprints,
								showWarnings,
								planPermission,
								instancePermission,
								planningUnit,
								sprintActiveCount,
								sprintFutureCount,
								sprintFutureSetDatesCount,
								sprintFutureInferredDatesCount,
								hasSprintsWithInconsistentDates,
								hasAtlasPermissions,
								isAtlasConnectInstalled,
								timelineType,
								customTimelineType,
								fieldColumns,
								issuesCount,
								dependenciesCount,
								dependencySettingsDisplay,
								issuesWithFixedStartDateCount,
								issuesWithFixedEndDateCount,
								issuesWithSprintStartDateCount,
								issuesWithSprintEndDateCount,
								issuesWithReleaseStartDateCount,
								issuesWithReleaseEndDateCount,
								viewsInPlanCount,
								viewsWithDependencyBadgesCount,
								viewsWithDependencyLinesCount,
								viewsWithDependencyCountCount,
								planCreatedDate,
								teamsShownInPlan,
								projectsShownInPlan,
								boardsShownInPlan,
								filtersShownInPlan,
								sourcesProjectCountJswCmpCount,
								sourcesProjectCountJswTmpCount,
								sourcesProjectCountJwmCmpCount,
								sourcesProjectCountJwmTmpCount,
								sourcesProjectCountJsmCmpCount,
								sourcesProjectCountCmpCount,
								sourcesProjectCountTmpCount,
								multiScenarioEnabled,
								scenariosCount,
								hierarchyLevels,
								selectedFilters,
								isSamplePlan,
								maxOverlappingSprints,
								autoSchedulerEnabled,
								fiscalMonth,
								viewMode,
								urlPersistenceEstimate,
								isMacroProxy,
							);
						}
						break;
					}

					// Triggered when the expand all button is pressed
					case EXPAND_ALL_ANALYTICS_ACTION: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const expandAllAnalyticsAction: ExpandAllAnalyticsAction = action as any;
						if (isDefined(planId) && isDefined(scenarioId)) {
							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.EXPANDED_ALL_ISSUES.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });

							// hierarchyLevels is the number of issue hierarchy levels that were expanded
							fireUIAnalytics(analyticsEvent, {
								hierarchyLevels: expandAllAnalyticsAction.payload.hierarchyLevels,
							});
						}
						break;
					}

					// Triggered when the collapse all button is pressed
					case COLLAPSE_ALL_ANALYTICS_ACTION: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const collapseAllAnalyticsAction: CollapseAllAnalyticsAction =
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
							action as any;

						if (isDefined(planId) && isDefined(scenarioId)) {
							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.COLLAPSED_ALL_ISSUES.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });

							// hierarchyLevels is the number of issue hierarchy levels that were expanded
							fireUIAnalytics(analyticsEvent, {
								hierarchyLevels: collapseAllAnalyticsAction.payload.hierarchyLevels,
							});
						}
						break;
					}

					case CHANGE_CUSTOM_FILTER: {
						const state = store.getState();
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const filterChangeAction: UpdateCustomFieldFilterAction = action as any;
						const customFieldId = filterChangeAction.payload.customField.id;
						const nextFilterValue = filterChangeAction.payload.value;
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.UPDATE_FILTER.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });
						let source = '';

						if (typeof window !== 'undefined') {
							const { hash } = window.location;
							source = filterSourceMap[hash];
						}
						const filterValue = R.path(
							[CUSTOM_FIELD_FILTER_ID, `${customFieldId}`],
							getRawFilters(state),
						);
						const addition = R.difference(
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
							(nextFilterValue as any) || [],
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
							(filterValue as any) || [],
						);

						const addedOrRemoved = addition.length > 0 ? 1 : 0;
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const itemCount = (nextFilterValue as any).length;
						if (isDefined(planId) && isDefined(scenarioId)) {
							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								filterID: CUSTOM_FIELD_FILTER_ID,
								plan: context.PLAN,
								source,
								addedOrRemoved,
								itemCount,
								customFieldType: filterChangeAction.payload.customField.type.key,
							});
						}
						break;
					}

					case CLEAR_CUSTOM_FILTER: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						if (isDefined(planId) && isDefined(scenarioId)) {
							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.UPDATE_FILTER.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });

							let source = '';

							if (typeof window !== 'undefined') {
								const { hash } = window.location;
								source = filterSourceMap[hash];
							}

							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								filterID: CUSTOM_FIELD_FILTER_ID,
								plan: context.PLAN,
								source,
								addedOrRemoved: 0,
								itemCount: 0,
							});
						}
						break;
					}

					case CHANGE_FILTER: {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						if ((action as any).bypassAnalytics) {
							break;
						}

						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);

						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const filterChangeAction: ChangeAction = action as any;
						const filterID = filterChangeAction.payload.id;
						const currentRawFilters = getRawFilters(state);
						const currentRawFilter = currentRawFilters[filterID];
						const filterValue: FilterValue | null | undefined =
							currentRawFilter && currentRawFilter.value;
						const nextFilterValue: FilterValue = filterChangeAction.payload.value;

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.UPDATE_FILTER.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						if (!this.ignoredFilterIds.includes(filterID)) {
							let addedOrRemoved = 0;
							let itemCount = 0;
							let source = '';

							if (typeof window !== 'undefined') {
								const { hash } = window.location;
								source = filterSourceMap[hash];
							}

							if (filterID === SUMMARY_FILTER_ID) {
								if (nextFilterValue === '') {
									addedOrRemoved = 0;
									itemCount = 0;
								} else {
									addedOrRemoved = 1;
									itemCount = 1;
								}
							} else if (
								filterID === DEPENDENCIES_FILTER_ID &&
								typeof nextFilterValue === 'object' &&
								'type' in nextFilterValue
							) {
								addedOrRemoved = filterValue && nextFilterValue.type === 'off' ? 0 : 1;
							} else {
								const addition = R.difference(
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
									(nextFilterValue as any) || [],
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
									(filterValue as any) || [],
								);

								addedOrRemoved = addition.length > 0 ? 1 : 0;
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
								itemCount = (nextFilterValue as any).length;
							}

							if (isDefined(planId) && isDefined(scenarioId)) {
								fireUIAnalytics(analyticsEvent, {
									planId,
									scenarioId,
									filterID,
									plan: context.PLAN,
									source,
									addedOrRemoved,
									itemCount,
								});
							}
						}
						break;
					}

					case CLEAR_ALL_FILTERS: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);

						if (isDefined(planId) && isDefined(scenarioId)) {
							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.CLEAR_FILTERS.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });
							let source = '';

							if (typeof window !== 'undefined') {
								const { hash } = window.location;
								source = filterSourceMap[hash];
							}
							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								plan: context.PLAN,
								source,
							});
						}
						break;
					}

					case CLEAR_FILTER: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const filterChangeAction: ClearAction = action as any;
						const filterID = filterChangeAction.payload;

						if (
							!this.ignoredFilterIds.includes(filterID) &&
							isDefined(planId) &&
							isDefined(scenarioId)
						) {
							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.UPDATE_FILTER.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });

							let source = '';

							if (typeof window !== 'undefined') {
								const { hash } = window.location;
								source = filterSourceMap[hash];
							}

							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								filterID,
								plan: context.PLAN,
								source,
								addedOrRemoved: 0,
								itemCount: 0,
							});
						}
						break;
					}
					case ADD_CROSS_PROJECT_RELEASE: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const addCrossProjectReleaseCommand: AddCrossProjectReleaseCommand =
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
							action as any;
						const crossProjectRelease = addCrossProjectReleaseCommand.payload;
						const projectReleaseCount = crossProjectRelease.versions.length;

						if (isDefined(planId) && isDefined(scenarioId)) {
							analytics.triggerCreateCrossProjectReleaseAnalytics(
								addCrossProjectReleaseCommand.meta.analyticsEvent,
								{ projectReleaseCount },
							);
						}
						break;
					}

					case CREATE_TEAM: {
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.PRIVATE_TEAM_CREATED.split(' ');

						const analyticsEvent = getAnalyticsEvent(store.getState());
						analyticsEvent.update({ action: eventAction, actionSubject });

						fireUIAnalytics(analyticsEvent);
						break;
					}

					case SHARE_TEAM: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						if (isDefined(planId) && isDefined(scenarioId)) {
							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.TEAM_IS_SHARED.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });

							fireUIAnalytics(analyticsEvent);
						}
						break;
					}

					case UPDATE_PLAN: {
						const state = store.getState();
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const updatePlanAction: UpdatePlan = action as any;

						const configuredDates =
							updatePlanAction.payload.baselineStartField ||
							updatePlanAction.payload.baselineEndField;

						if (configuredDates) {
							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.CONFIGURABLE_DATE_CHANGED.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });
							fireUIAnalytics(analyticsEvent);

							const updatedPlan: PlanInfo = {
								...getPlan(state),
								...updatePlanAction.payload,
							};

							const isPlanUsingAnyCustomDates = (plan: PlanInfo) =>
								plan.baselineStartField.type === DATEFIELD_TYPES.CUSTOM ||
								plan.baselineEndField.type === DATEFIELD_TYPES.CUSTOM;
							const isPrevPlanUsingCustomDates = isPlanUsingAnyCustomDates(getPlan(state));
							const isNextPlanUsingCustomDates = isPlanUsingAnyCustomDates(updatedPlan);

							if (!isPrevPlanUsingCustomDates && isNextPlanUsingCustomDates) {
								// eslint-disable-next-line @typescript-eslint/no-shadow
								const [actionSubject, eventAction] =
									PRODUCT_ANALYTICS_EVENT_NAMES.CUSTOM_CONFIGURABLE_DATE_USED.split(' ');
								analyticsEvent.update({ action: eventAction, actionSubject });
								fireUIAnalytics(analyticsEvent);
							} else if (isPrevPlanUsingCustomDates && !isNextPlanUsingCustomDates) {
								// eslint-disable-next-line @typescript-eslint/no-shadow
								const [actionSubject, eventAction] =
									PRODUCT_ANALYTICS_EVENT_NAMES.NO_CUSTOM_CONFIGURABLE_DATES_USED.split(' ');
								analyticsEvent.update({ action: eventAction, actionSubject });
								fireUIAnalytics(analyticsEvent);
							}
						}

						break;
					}

					case UPDATE_PARENT: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const updateParentAction: UpdateParentAction = action as any;

						if (isDefined(planId) && isDefined(scenarioId)) {
							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.REPARENT_ISSUE.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });
							const method = updateParentAction.payload.isDragAndDrop ? { method: 'dragged' } : {};
							fireTrackAnalytics(
								analyticsEvent,
								PRODUCT_ANALYTICS_EVENT_NAMES.REPARENT_ISSUE,
								method,
							);
						}
						break;
					}
					case SET_ACTIVE_VIEW: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const {
							payload: { id }, // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						}: SetActiveViewAction = action as any;
						const activeView = getViews(state).find((view) => view.id === id);

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.SET_ACTIVE_VIEW.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						if (isDefined(planId) && isDefined(scenarioId) && isDefined(activeView)) {
							const isPredefinedView = isPredefined(activeView);
							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								presetName: getPresetName(activeView),
								isDefault: activeView.isDefault ? 1 : 0,
								isPredefined: isPredefinedView ? 1 : 0,
								isCustom: isPredefinedView ? 0 : 1,
								isEdited: activeView.modified ? 1 : 0,
							});
						}
						break;
					}

					case SAVE_VIEW: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const {
							payload: { id }, // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						}: SaveViewAction = action as any;
						const view: View | undefined = R.find<View>(R.propEq('id', id))(getViews(state));

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] = PRODUCT_ANALYTICS_EVENT_NAMES.SAVE_VIEW.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						if (isDefined(planId) && isDefined(scenarioId) && isDefined(view)) {
							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								isDefault: view.isDefault ? 1 : 0,
								isCustom: view.isUnchangedPredefinedView ? 0 : 1,
							});
						}
						break;
					}

					case ADD_VIEW: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const { payload }: AddViewAction = action as any;

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] = PRODUCT_ANALYTICS_EVENT_NAMES.ADD_VIEW.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						if (isDefined(planId) && isDefined(scenarioId) && isDefined(payload)) {
							const isPredefinedView = isPredefined(payload);
							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								isDefault: payload.isDefault ? 1 : 0,
								isCustom: isPredefinedView ? 0 : 1,
							});
						}

						break;
					}

					case RESET_VIEWS: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const { payload }: ResetAction = action as any;
						const activeView = payload.find((view) => view.active === true);

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.RESET_VIEWS.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						if (isDefined(planId) && isDefined(scenarioId) && isDefined(activeView)) {
							const isPredefinedView = isPredefined(activeView);
							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								presetName: getPresetName(activeView),
								isDefault: activeView.isDefault ? 1 : 0,
								isPredefined: isPredefinedView ? 1 : 0,
								isCustom: isPredefinedView ? 0 : 1,
								isEdited: activeView.modified ? 1 : 0,
							});
						}

						break;
					}

					case HIDE_COLUMN: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const viewMode = getViewMode(state);

						const {
							id,
							meta: { source },
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						}: HideColumnAction = action as any;
						const isCustomField = !!parseInt(id, 10);
						let fieldName = id;

						if (isCustomField) {
							const field = R.find(R.propEq('id', parseInt(id, 10)))(state.domain.customFields);
							fieldName = typeOfCustomField(field);
						}

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.HIDE_COLUMN.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						if (isDefined(planId) && isDefined(scenarioId)) {
							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								fieldName,
								viewMode,
								source,
							});
						}

						break;
					}

					case SHOW_COLUMN: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const viewMode = getViewMode(state);

						const {
							id,
							meta: { source },
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						}: ShowColumnAction = action as any;
						const isCustomField = !!parseInt(id, 10);
						let fieldName = id;

						if (isCustomField) {
							const field = R.find(R.propEq('id', parseInt(id, 10)))(state.domain.customFields);
							fieldName = typeOfCustomField(field);
						}

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.SHOW_COLUMN.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						if (isDefined(planId) && isDefined(scenarioId)) {
							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								fieldName,
								viewMode,
								source,
							});
						}

						break;
					}

					case MOVE_COLUMN: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const viewMode = getViewMode(state);

						const {
							payload: { columnToMove },
							meta: { source },
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						}: MoveColumnAction = action as any;
						const isCustomField = !!parseInt(columnToMove, 10);
						let fieldName = columnToMove;

						if (isCustomField) {
							const field = R.find(R.propEq('id', parseInt(columnToMove, 10)))(
								state.domain.customFields,
							);
							fieldName = typeOfCustomField(field);
						}

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.MOVE_COLUMN.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						if (isDefined(planId) && isDefined(scenarioId)) {
							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								fieldName,
								viewMode,
								source,
							});
						}

						break;
					}

					// An analytics event is fired when the default view is changed from the view management page
					case MARK_AS_DEFAULT_VIEW: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const {
							payload: { id }, // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						}: MarkAsDefaultViewAction = action as any;
						const viewMarkedAsDefault: View | undefined = R.find<View>(R.propEq('id', id))(
							getViews(state),
						);

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.MARK_AS_DEFAULT_VIEW.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						if (isDefined(planId) && isDefined(scenarioId) && isDefined(viewMarkedAsDefault)) {
							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								// the following values are defined in JPOS-4198
								isDefault: 1,
								isPredefined: viewMarkedAsDefault.isUnchangedPredefinedView ? 1 : 0,
								isCustom: viewMarkedAsDefault.isUnchangedPredefinedView ? 0 : 1,
								isEdited: viewMarkedAsDefault.modified ? 1 : 0,
							});
						}
						break;
					}

					case CHANGE_OPTION: {
						const state = store.getState();
						const { id: planId } = getPlan(state);

						const customDateRange = getTimeScaleViewSettings(state).customDateRange || {};
						const zoomLevel = getZoomLevel(state);
						const { timelineType, customTimelineType } = getTimelineAttributes(
							customDateRange,
							zoomLevel,
						);

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.SELECT_TIMELINE_RANGE.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						if (isDefined(planId)) {
							fireUIAnalytics(analyticsEvent, {
								planId,
								plan: context.PLAN,
								timelineType,
								customTimelineType,
							});
						}
						break;
					}

					case CHANGE_MODE: {
						const state = store.getState();
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const { payload }: ChangeModeAction = action as any;
						const { id: planId } = getPlan(state);

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.SELECT_TIMELINE_RANGE.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						if (payload !== TIMELINE_MODES.CUSTOM) {
							fireUIAnalytics(analyticsEvent, {
								planId,
								plan: context.PLAN,
								timelineType: payload,
							});
						}
						break;
					}

					case CHANGE_CUSTOM_DATES: {
						const state = store.getState();
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const { payload }: ChangeCustomDatesAction = action as any;
						const { id: planId } = getPlan(state);

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.SELECT_TIMELINE_RANGE.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });

						const { timelineType, customTimelineType } = getTimelineAttributes(payload);

						fireUIAnalytics(analyticsEvent, {
							planId,
							plan: context.PLAN,
							timelineType,
							customTimelineType,
						});
						break;
					}

					case SEARCH_ICON_CLICK: {
						const state = store.getState();
						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.ISSUE_SEARCH_ICON_CLICK.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });
						fireUIAnalytics(analyticsEvent);
						break;
					}

					case STARTED_SEARCH_RESULT_NAVIGATION: {
						const state = store.getState();
						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.ISSUE_SEARCH_STARTED_SEARCH_RESULT_NAVIGATION.split(
								' ',
							);
						analyticsEvent.update({ action: eventAction, actionSubject });
						fireUIAnalytics(analyticsEvent);
						break;
					}

					case CLICK_EXPORT_TO_CSV_ANALYTICS: {
						const state = store.getState();
						const { id: planId, currentScenarioId: scenarioId } = getPlan(state);
						const { selectedFilters, hierarchyLevels } =
							getFiltersAndHierarchyLevelsForAnalytics(state);

						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const clickExportToCsvAction = action as any;
						const issueExportCount = clickExportToCsvAction.payload.issueExportCount;

						if (isDefined(planId) && isDefined(scenarioId) && isDefined(issueExportCount)) {
							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_EXPORT_TO_CSV.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });

							// Flow can't make up its mind wrt filters - this will be fixed when we remove the feature flag of View Switching
							fireUIAnalytics(analyticsEvent, {
								planId,
								scenarioId,
								plan: context.PLAN,
								issueExportCount,
								hierarchyLevels,
								planMode: getPlanModeForAnalytics(state),
								selectedFilters,
							});
						}

						break;
					}

					case PLAN_EXPORTED_ANALYTICS: {
						const state = store.getState();

						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const planExportedAction = action as any;
						const attributes = planExportedAction.payload;

						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] =
							PRODUCT_ANALYTICS_EVENT_NAMES.PLAN_EXPORTED.split(' ');
						analyticsEvent.update({ action: eventAction, actionSubject });
						const allAttributes: Attributes = {
							planMode: getPlanModeForAnalytics(state),
							...attributes,
						};
						fireTrackAnalytics(
							analyticsEvent,
							PRODUCT_ANALYTICS_EVENT_NAMES.PLAN_EXPORTED,
							allAttributes,
						);

						break;
					}

					case AFTER_GET_CHANGES_SUCCESS: {
						const state = store.getState();
						const feStateChangeCount = getReviewChangesCount(state);
						const backendChangeCount = getReviewChanges(state).length;
						const isDialogOpen = state.ui.Top.TitleBar.UpdateJira.isDialogOpen;

						if (isDialogOpen) {
							const { id: planId, currentScenarioId: scenarioId } = getPlan(state);

							const changeCounts = {
								fromFrontendState: {
									total: feStateChangeCount,
									issuesIncludingLinks: getIssueChangeCount(state),
									issueLinks: getIssueChangesWithChangedLinksCount(state),
									versions: getVersionChangeCount(state),
									crossProjectVersions: getCrossProjectVersionChangeCount(state),
									teamsAndResources: getTeamOrResourceChangeCount(state),
								},
								fromBackend: {
									total: backendChangeCount,
									issuesIncludingLinks: getIssueChanges(state).length,
									issueLinks: getIssueChangesWithChangedLinks(state).length,
									versions: getVersionChanges(state).length,
									crossProjectVersions: getCrossProjectVersionChanges(state).length,
									teamsAndResources: getTeamAndResourceChanges(state).length,
								},
							};
							const hasMismatch = !R.equals(
								changeCounts.fromFrontendState,
								changeCounts.fromBackend,
							);
							const synthetic = isPollinatorTenant();

							const analyticsEvent = getAnalyticsEvent(state);
							const [actionSubject, eventAction] =
								PRODUCT_ANALYTICS_EVENT_NAMES.COMMIT_MODAL_LOADED_CHANGES.split(' ');
							analyticsEvent.update({ action: eventAction, actionSubject });

							if (isDefined(planId) && isDefined(scenarioId)) {
								fireOperationalAnalytics(
									analyticsEvent,
									PRODUCT_ANALYTICS_EVENT_NAMES.COMMIT_MODAL_LOADED_CHANGES,
									{
										planId,
										scenarioId,
										changeCounts,
										hasMismatch,
										synthetic,
									},
								);
							}
						}
						break;
					}

					case TOGGLE_TOOLBAR: {
						const state = store.getState();

						const grouping = getVisualisationGrouping(state);
						const mode = getMode(state);
						const showToolBar = getToolBarVisibilityState(state);
						const filteredIssues = getFilteredIssues(state).length;

						const analyticsEvent = getAnalyticsEvent(state);
						// eslint-disable-next-line @typescript-eslint/no-shadow
						const [actionSubject, action] = (
							showToolBar
								? PRODUCT_ANALYTICS_EVENT_NAMES.COLLAPSE_TOOL_BAR
								: PRODUCT_ANALYTICS_EVENT_NAMES.EXPAND_TOOL_BAR
						).split(' ');

						fireUIAnalytics(analyticsEvent.update({ action, actionSubject }), {
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							browserInnerWidth: window.innerWidth,

							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							browserInnerHeight: window.innerHeight,

							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							browserOuterWidth: window.outerWidth,

							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							browserOuterHeight: window.outerHeight,

							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							browserZoomPercentage: (window.outerWidth / window.innerWidth) * 100,
							filteredIssues,
							grouping,
							isFullScreen:
								// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
								window.screen.availWidth === window.screen.width &&
								// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
								window.screen.availHeight === window.screen.height,
							mode,

							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							screenWidth: window.screen.width,

							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							screenHeight: window.screen.height,
						});

						break;
					}

					case SET_IS_EXPANDED: {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const { payload }: SetIsExpandedAction = action as any;
						const keys = Object.keys(payload);

						/**
						 * We are checking the length === 1 because we are using the same action
						 * to expand and collpase ALL. The expand/collapse ALL ananlytics is captured
						 * by EXPAND[COLLPASE]_ALL_ANALYTICS_ACTION above.
						 *
						 * Here we are just taking care of single item expand and collapse.
						 */
						if (keys.length === 1) {
							const state = store.getState();
							const analyticsEvent = getAnalyticsEvent(state);
							const [item] = keys[0].split(':');
							const expand = payload[keys[0]];

							const [actionSubject, eventAction] = (
								expand
									? PRODUCT_ANALYTICS_EVENT_NAMES.EXPAND_SCOPE_ITEM
									: PRODUCT_ANALYTICS_EVENT_NAMES.COLLAPSE_SCOPE_ITEM
							).split(' ');

							fireUIAnalytics(analyticsEvent.update({ action: eventAction, actionSubject }), {
								item,
							});
						}

						break;
					}

					case TOGGLE_EXPAND_GROUP: {
						const state = store.getState();
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
						const { payload }: ToggleExpandGroupAction = action as any;
						const { grouping, expand } = payload;
						const analyticsEvent = getAnalyticsEvent(state);
						const [actionSubject, eventAction] = (
							expand
								? PRODUCT_ANALYTICS_EVENT_NAMES.EXPAND_SCOPE_GROUP
								: PRODUCT_ANALYTICS_EVENT_NAMES.COLLAPSE_SCOPE_GROUP
						).split(' ');

						fireUIAnalytics(analyticsEvent.update({ action: eventAction, actionSubject }), {
							grouping,
						});

						break;
					}

					default:
						break;
				}

				return next(action);
			});
}

const middleware = new AnalyticsMiddleware();

export default middleware.apply;
