import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchReleasesPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter.search-releases-placeholder',
		defaultMessage: 'Show issues from releases',
		description: 'This is a placeholder for search',
	},
	noReleasesFilterText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter.no-releases-filter-text',
		defaultMessage: 'No release',
		description: 'Text for selecting issues with no assigned releases',
	},
	searchReleasesLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter.search-releases-label',
		defaultMessage: 'Choose from releases',
		description: 'Aria label for releases search filed that appears on activating Filters button',
	},
	searchResultsCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter.search-results-count',
		defaultMessage: '{totalCount, plural, one {# suggestion} other {# suggestions}} available',
		description: 'Text for announcing the count of search results',
	},
	searchReleasesPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter.search-releases-placeholder-issue-term-refresh',
		defaultMessage: 'Show issues from releases',
		description: 'This is a placeholder for search',
	},
});
