.tooltip-wrapper {
	padding: var(--jpo-common-base-spacing);
	text-align: center;
	color: var(--ds-text-inverse, var(--adg3-color-N0));
}
.tooltip-title {
	font-size: 14px;
}

.external-lozenge-wrapper {
	margin: 0 var(--jpo-common-base-spacing);
}

/* Remove when cleaning up plan-timeline-non-transposed */
.group-details {
	height: 39px;
	background-color: var(--ds-surface, var(--jpo-bg-default-color));
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	box-shadow: 0px -2px var(--ds-border, var(--adg3-color-N40)); /* This box-shadow here works as border */
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	outline: none;
}
.group-details-transposed {
	height: 39px;
	background-color: var(--ds-surface, var(--jpo-bg-default-color));
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	outline: none;
}

.group-details:focus {
	box-shadow: inset 0 0 0 2px var(--ds-border-focused, #4c9aff);
	outline-offset: 0;
}

.group-chevron {
	padding-left: 8px;
	&.hasParentGroup {
		margin-left: 40px;
	}
}

.groupingAvatar {
	display: inline-block;
	min-width: 24px;
	padding-left: 8px;
}

.defaultTeamAvatar {
	background-color: var(--ds-background-neutral, var(--adg3-color-N40));
	border-radius: 50%;
	margin: 0px 2px;
	padding: 4px 4px;

	& > span {
		display: flex;
	}
}

.group-title {
	flex-grow: 1;
	padding-left: 9px;
	font-size: 16px;
	font-weight: 500;
	color: var(--ds-text, var(--adg3-color-N800));
	overflow: hidden;
}

.tooltip {
	padding: calc(var(--jpo-common-base-spacing) * 0.5) 0;
	text-align: center;
}

.tooltip > h6 {
	font-weight: bold;
	color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.groupName {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.groupRow {
	background-color: var(--ds-surface, var(--jpo-bg-default-color));
}

.exportPNGLozenge {
	display: flex;
	align-items: center;
	margin: 0 var(--jpo-common-base-spacing);
}
