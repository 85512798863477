import React, { Component } from 'react';
import * as R from 'ramda';
import Avatar from '@atlaskit/avatar';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { NO_TEAM_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/team-filter/index.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import { TEAM_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import FilterText from '../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '../common/styles.module.css';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class TeamFilter extends Component<Props> {
	constructFilterText = () => {
		const { teams, value, intl } = this.props;

		const noTeamText =
			value.length > 1
				? `${intl.formatMessage(messages.noTeamFilterText)}, `
				: intl.formatMessage(messages.noTeamFilterText);
		return (
			(R.contains(NO_TEAM_ID, value) ? noTeamText : '') +
			teams
				.filter(({ id }) => R.contains(id, value))
				.map(({ title }) => title)
				.join(', ')
		);
	};

	renderFilterText = () => {
		const { value } = this.props;
		const filterText = this.constructFilterText();
		return value.length > 0 ? (
			<FilterText text={filterText} />
		) : (
			<FormattedMessage {...messages.emptyPlaceholder} />
		);
	};

	ariaText = () => {
		const { value, intl } = this.props;
		const filterText = this.constructFilterText();
		// Teams, All selected
		return `${intl.formatMessage(filterMessages[TEAM_FILTER_ID])}, ${
			value.length > 0 ? filterText : intl.formatMessage(messages.emptyPlaceholder)
		} ${intl.formatMessage(filterMessages.selected)}`;
	};

	isTeamSelected = (teamTitle: string) => R.contains(teamTitle, this.props.value);

	filterTeamsWithSearchQuery = (team: Team) =>
		team.title.toLowerCase().includes(this.props.searchQuery.toLowerCase());

	onTeamClick = (id: string): void => {
		const { value, changeTeamFilter } = this.props;
		if (this.isTeamSelected(id)) {
			changeTeamFilter(R.filter((team) => team !== id, value));
		} else {
			changeTeamFilter([...value, id]);
		}
	};

	renderProjectGroups = () => {
		const { teams } = this.props;
		const searchResultTeams = teams.filter(this.filterTeamsWithSearchQuery);
		return (
			<>
				{searchResultTeams.map(({ avatarUrl = '', id, title }) => (
					<DialogMenuItem key={id}>
						<Checkbox
							id={id}
							key={id}
							isChecked={this.isTeamSelected(id)}
							onChange={() => this.onTeamClick(id)}
							label={
								<EllipsedWithTooltip id={id} content={title}>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<div className={styles.teamAvatarItem}>
										{!avatarUrl ? (
											// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
											<div className={styles.defaultTeamAvatar}>
												<PeopleIcon label="" size="small" />
											</div>
										) : (
											<Avatar size="small" src={avatarUrl} />
										)}
										{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
										<span className={styles.checkboxLabel}>{title}</span>
									</div>
								</EllipsedWithTooltip>
							}
						/>
					</DialogMenuItem>
				))}
				{searchResultTeams.length === 0 && <NoMatchFound />}
			</>
		);
	};

	renderNoTeams = () => {
		const { intl } = this.props;
		const title = intl.formatMessage({ ...messages.noTeamFilterText });
		return (
			<DialogMenuItem>
				<Checkbox
					id={NO_TEAM_ID}
					key={NO_TEAM_ID}
					isChecked={this.isTeamSelected(NO_TEAM_ID)}
					onChange={() => this.onTeamClick(NO_TEAM_ID)}
					label={
						<EllipsedWithTooltip id={NO_TEAM_ID} content={title}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<span className={styles.checkboxLabel}>{title}</span>
						</EllipsedWithTooltip>
					}
				/>
			</DialogMenuItem>
		);
	};

	render() {
		const { intl, onOpenChange, isOpen, value, clearTeamFilter, searchQuery, onQueryChange } =
			this.props;

		return (
			<InlineDialog
				noPaddings
				maxWidth={FILTER_MAX_WIDTH}
				minWidth={FILTER_WIDTH}
				onClose={onOpenChange}
				isOpen={isOpen}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.team-filter"
				content={
					<DialogMenuContainer>
						<ClearFilterButton isVisible={!!value.length} onClearClick={clearTeamFilter} />
						<SearchField
							placeholder={intl.formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.searchTeamPlaceholderIssueTermRefresh
									: messages.searchTeamPlaceholder,
							)}
							searchQuery={searchQuery}
							onQueryChange={onQueryChange}
							ariaLabel={intl.formatMessage(messages.searchTeamLabel)}
						/>
						{this.props.teams.length > 0 && this.renderNoTeams()}
						{this.renderProjectGroups()}
					</DialogMenuContainer>
				}
			>
				<TriggerButton
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.team-filter.trigger-btn"
					triggerButtonText={this.renderFilterText()}
					ariaLabel={this.ariaText()}
				/>
			</InlineDialog>
		);
	}
}

export default injectIntl(TeamFilter);
