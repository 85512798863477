import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { put, call, select, fork, takeEvery } from 'redux-saga/effects';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import type { Sequence } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ENTITY,
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
	SCENARIO_ISSUE_ID_PREFIX,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { AnalyticsEventMeta } from '../../analytics/types.tsx';
import {
	getCrossProjectVersionsById,
	getRemovedCrossProjectVersionsById,
	getOriginalCrossProjectVersions,
} from '../../query/cross-project-versions/index.tsx';
import type { CrossProjectVersionsById } from '../../query/cross-project-versions/types.tsx';
import { getPlan } from '../../query/plan/index.tsx';
import * as crossProjectVersionsActions from '../../state/domain/cross-project-versions/actions.tsx';
import type { CrossProjectVersion } from '../../state/domain/cross-project-versions/types.tsx';
import * as originalCrossProjectVersionsActions from '../../state/domain/original-cross-project-versions/actions.tsx';
import * as removedCrossProjectVersionsActions from '../../state/domain/removed-cross-project-versions/actions.tsx';
import { update as updateSequence } from '../../state/domain/sequence/actions.tsx';
import { add as addCommitWarning } from '../../state/domain/update-jira/warnings/actions.tsx';
import type { VersionPatch, NewVersion } from '../../state/domain/versions/types.tsx';
import {
	CROSS_PROJECT_RELEASE_FILTER_ID,
	RELEASE_FILTER_ID,
} from '../../state/domain/view-settings/filters/types.tsx';
import type { State } from '../../state/types.tsx';
import {
	closeDialog as closeAlignDatesDialog,
	initiateRequest as initiateAlignDatesRequest,
	resetRequest as resetAlignDatesRequest,
} from '../../state/ui/main/tabs/releases/cross-project-releases/align-dates-dialog/actions.tsx';
import {
	closeDialog as closeDeleteDialog,
	initiateRequest as initiateDeleteRequest,
	resetRequest as resetDeleteRequest,
} from '../../state/ui/main/tabs/releases/cross-project-releases/delete-release-dialog/actions.tsx';
import {
	closeDialog,
	initiateRequest,
	resetRequest,
} from '../../state/ui/main/tabs/releases/cross-project-releases/release-dialog/actions.tsx';
import { POST } from '../api.tsx';
import batch from '../batch/index.tsx';
import type { BulkCommitResponseEntity } from '../commit-bulk/types.tsx';
import { revertBody } from '../commit/api.tsx';
import { inspectForCommitWarnings, defaultWarning } from '../commit/warnings.tsx';
import { change as changeFilter, clear as clearFilter } from '../filters/index.tsx';
import * as http from '../http/index.tsx';
import { toErrorID } from '../util.tsx';
import {
	urls,
	addVersionBody,
	updateVersionBody,
	deleteVersionBody,
	type AddReleaseCommandPayload,
	type UpdateReleaseCommandPayload,
	type DeleteReleaseCommandPayload,
} from './api.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	openFlyout as openProjectReleasesFlyout,
	closeFlyout as closeProjectReleasesFlyout,
} from '../../state/ui/main/tabs/releases/cross-project-releases/releases-table/project-releases/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	setMode as setModeForCreateUpdateDialog,
	openDialog as openCreateUpdateDialog,
	closeDialog as closeCreateUpdateDialog,
} from '../../state/ui/main/tabs/releases/cross-project-releases/release-dialog/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	openDialog as openAlignDatesDialog,
	closeDialog as closeAlignDatesDialog,
} from '../../state/ui/main/tabs/releases/cross-project-releases/align-dates-dialog/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	openDialog as openDeleteDialog,
	closeDialog as closeDeleteDialog,
} from '../../state/ui/main/tabs/releases/cross-project-releases/delete-release-dialog/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	CREATE_RELEASE,
	EDIT_RELEASE,
} from '../../state/ui/main/tabs/releases/cross-project-releases/release-dialog/types';

// TODO This is a horrendous hack to temporarily workaround mutual dependency between command/versions and command/cross-project-versions via injection at common parent. Proper solution pending.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let versionsCommands: Record<string, any> = {};

export const ADD_RELEASE = 'command.cross-project-versions.ADD_RELEASE' as const;
export const UPDATE_RELEASE = 'command.cross-project-versions.UPDATE_RELEASE' as const;
export const DELETE_RELEASE = 'command.cross-project-versions.DELETE_RELEASE' as const;
export const ALIGN_DATES_OF_RELEASES =
	'command.cross-project-versions.ALIGN_DATES_OF_RELEASES' as const;
export const VIEW_RELEASE_IN_ROADMAP =
	'command.cross-project-versions.VIEW_RELEASE_IN_ROADMAP' as const;

export type AddReleaseCommand = {
	type: typeof ADD_RELEASE;
	payload: AddReleaseCommandPayload;
	meta: AnalyticsEventMeta;
};

export type UpdateReleaseCommand = {
	type: typeof UPDATE_RELEASE;
	payload: UpdateReleaseCommandPayload;
};

export type DeleteReleaseCommand = {
	type: typeof DELETE_RELEASE;
	payload: DeleteReleaseCommandPayload;
};

type AlignDatesOfReleasesCommandPayload = {
	versions: string[];
	patch: Partial<NewVersion>;
};

export type AlignDatesOfReleasesCommand = {
	type: typeof ALIGN_DATES_OF_RELEASES;
	payload: AlignDatesOfReleasesCommandPayload;
};

type ViewReleaseInRoadmapCommand = {
	type: typeof VIEW_RELEASE_IN_ROADMAP;
	payload: string[];
};

export const addRelease = (
	version: AddReleaseCommandPayload,
	analyticsEvent: UIAnalyticsEvent,
): AddReleaseCommand => ({
	type: ADD_RELEASE,
	payload: version,
	meta: { analyticsEvent },
});

export const updateRelease = (payload: UpdateReleaseCommandPayload): UpdateReleaseCommand => ({
	type: UPDATE_RELEASE,
	payload,
});

export const deleteRelease = (payload: DeleteReleaseCommandPayload): DeleteReleaseCommand => ({
	type: DELETE_RELEASE,
	payload,
});

export const alignDatesOfReleases = (
	payload: AlignDatesOfReleasesCommandPayload,
): AlignDatesOfReleasesCommand => ({
	type: ALIGN_DATES_OF_RELEASES,
	payload,
});

export const viewReleaseInRoadmap = (filterValue: string[]): ViewReleaseInRoadmapCommand => ({
	type: VIEW_RELEASE_IN_ROADMAP,
	payload: filterValue,
});

export function* addRemovedCrossProjectVersionToMainCrossProjectVersionsList(
	removedVersion: CrossProjectVersion, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const { id } = removedVersion;

	// Remove cross-project version from removed cross-project versions' list
	yield put(removedCrossProjectVersionsActions.remove(id));

	// Add cross-project version to state.domain.cross-project-versions.
	yield put(crossProjectVersionsActions.add(removedVersion));
}

export function* handleCrossProjectVersionWarnings(
	entityResponse: BulkCommitResponseEntity, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	if (entityResponse.success) {
		const internalEntity = entityResponse.entity?.entity;
		const warnings = yield call(inspectForCommitWarnings, internalEntity, entityResponse.error);
		if (warnings.length) {
			yield put(
				addCommitWarning({
					category: ENTITY.CROSS_PROJECT_RELEASE,
					itemId: entityResponse.generatedId ?? entityResponse.itemKey,
					warnings,
				}),
			);
		}
	} else {
		yield put(
			addCommitWarning({
				category: ENTITY.CROSS_PROJECT_RELEASE,
				itemId: entityResponse.generatedId ?? entityResponse.itemKey,
				warnings: [defaultWarning],
			}),
		);
	}
}

export function* handleCrossProjectVersionCommitResponse(
	entityResponse: BulkCommitResponseEntity, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	yield call(handleCrossProjectVersionWarnings, entityResponse);

	// generatedId is present for an add operation from a scenario object
	const id = entityResponse.itemKey;
	const newId = entityResponse.generatedId ?? id;

	const removedCrossProjectVersionsById: CrossProjectVersionsById = yield select(
		getRemovedCrossProjectVersionsById,
	);
	const removedCrossProjectVersion = removedCrossProjectVersionsById[id];

	if (newId !== id) {
		yield put(crossProjectVersionsActions.bulkUpdate({ [id]: { id: newId } }));
		yield call(versionsCommands.updateReferences, {
			crossProjectVersion: [[id, newId]],
		});
	}
	if (isDefined(removedCrossProjectVersion)) {
		yield put(removedCrossProjectVersionsActions.remove(id));
		yield call(versionsCommands.updateReferences, {
			crossProjectVersion: [[id, null]],
		});
	}
	const originals: ReturnType<typeof getOriginalCrossProjectVersions> = yield select(
		getOriginalCrossProjectVersions,
	);
	yield put(originalCrossProjectVersionsActions.reset(R.dissoc(id, originals)));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* revertChange(id: string): Generator<Effect, http.JsonResponse<any>, any> {
	const {
		domain: {
			plan: { id: planId, currentScenarioId },
			sequence,
		},
	}: State = yield select(R.identity);
	const body = revertBody({ id: planId, currentScenarioId }, sequence, id);

	const response = yield* http.json({ url: urls.revertChanges, method: 'POST', body });

	if (response.ok) {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			change: { sequence },
		} = response.data;
		yield put(updateSequence(sequence));
	}

	return response;
}

export function* addVersionInCrossProjectVersion(
	versionId: string,
	crossProjectVersionId: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const removedCrossProjectVersionsById = yield select(getRemovedCrossProjectVersionsById);
	const removedCrossProjectVersion = removedCrossProjectVersionsById[crossProjectVersionId];
	/** We allow versions, that are part of deleted cross-project version (but not yet committed),
	 *  to be assigned to other cross-project version. So when that change is reverted we have to
	 *  add a version in deleted cross-project version back.
	 */
	if (isDefined(removedCrossProjectVersion)) {
		// Add a release in crossProjectVersion that is deleted, but not yet committed
		yield put(
			removedCrossProjectVersionsActions.addVersionInCrossProjectVersion({
				crossProjectVersionId,
				versionId,
			}),
		);
	} else {
		// Add a new release in crossProjectVersion that it belongs to.
		yield put(
			crossProjectVersionsActions.addVersionInCrossProjectVersion({
				crossProjectVersionId,
				versionId,
			}),
		);
	}
}

export function* removeVersionFromCrossProjectVersion(
	versionId: string,
	crossProjectVersionId: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const removedCrossProjectVersionsById = yield select(getRemovedCrossProjectVersionsById);
	const removedCrossProjectVersion = removedCrossProjectVersionsById[crossProjectVersionId];
	/** We allow versions, that are part of deleted cross-project version (but not yet committed),
	 *  to be assigned to other cross-project version. So when that change is reverted we have to
	 *  add a version in deleted cross-project version back.
	 */
	if (isDefined(removedCrossProjectVersion)) {
		// Remove a release from crossProjectVersion that is deleted, but not yet committed
		yield put(
			removedCrossProjectVersionsActions.removeVersionFromCrossProjectVersion({
				crossProjectVersionId,
				versionId,
			}),
		);
	} else {
		// Remove a release from crossProjectVersion
		yield put(
			crossProjectVersionsActions.removeVersionFromCrossProjectVersion({
				crossProjectVersionId,
				versionId,
			}),
		);
	}
}

export function* addCrossProjectVersionInStore({
	id,
	name,
	versions, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: CrossProjectVersion): Generator<Effect, any, any> {
	// Add a new release to state.domain.versions.
	yield put(
		crossProjectVersionsActions.add({
			id,
			name,
			versions: [],
		}),
	);

	// Update crossProjectVersion in each version.
	for (const versionId of versions) {
		const patch: VersionPatch = {
			versionId,
			patch: {
				crossProjectVersion: id,
			},
		};
		yield call(versionsCommands.updateVersion, patch);
	}
}

export function* removeCrossProjectVersionFromStore(
	versionId: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	// Remove a cross-project release from state.domain.cross-project-versions
	yield put(crossProjectVersionsActions.remove(versionId));

	// Revert crossProjectVersion in each version.
	yield call(versionsCommands.revertReferences, {
		crossProjectVersion: [versionId],
	});

	// Remove cross-project version from original cross-project versions as we don't commit such changes
	const originals = yield select(getOriginalCrossProjectVersions);
	yield put(originalCrossProjectVersionsActions.reset(R.dissoc(versionId, originals)));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doUpdateVersion({ payload }: UpdateReleaseCommand): Generator<Effect, any, any> {
	yield put(initiateRequest());
	try {
		const url = urls.update;
		const body = updateVersionBody(yield select(getPlan), payload);
		const response = yield call(fetch, url, {
			method: POST,
			body,
		});

		if (response.ok) {
			const { versionId, patch } = payload;
			const crossProjectVersionsById: CrossProjectVersionsById = yield select(
				getCrossProjectVersionsById,
			);
			const { name: originalName }: CrossProjectVersion = crossProjectVersionsById[versionId];

			const {
				change: { sequence },
			} = yield call(response.json.bind(response));

			yield* batch(function* () {
				// Update a release in client-side model
				yield put(
					crossProjectVersionsActions.bulkUpdate({
						[versionId]: patch,
					}),
				);

				/** Update the original cross-project versions model so that it shows updated release in
				 *  the update-jira dialog.
				 */
				yield put(
					originalCrossProjectVersionsActions.update({
						id: versionId,
						values: { name: originalName },
					}),
				);

				// Update the sequence
				yield put(updateSequence(sequence));

				// Close the update release dialog
				yield put(closeDialog());
			});
		} else {
			const error = new Error(yield call(response.text.bind(response)));
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'update-cross-project-versions-fetch-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
		yield put(resetRequest());
	} catch {
		yield put(resetRequest());
	}
}

export function* updateStateAfterAddingVersion(
	{ name, versions }: AddReleaseCommandPayload,
	newVersionId: string,
	sequence: Sequence, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	yield* batch(function* () {
		// Add a new release to client-side model
		yield call(addCrossProjectVersionInStore, {
			id: newVersionId,
			name,
			versions,
		});

		// Update the sequence
		yield put(updateSequence(sequence));

		/** Update the original cross-project versions model so that it shows newly added release in
		 *  the update-jira dialog.
		 */
		yield put(originalCrossProjectVersionsActions.update({ id: newVersionId, values: {} }));

		// Close the create release dialog
		yield put(closeDialog());
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doAddVersion({ payload }: AddReleaseCommand): Generator<Effect, any, any> {
	yield put(initiateRequest());
	try {
		const url = urls.add;
		const body = addVersionBody(yield select(getPlan), payload);
		const response = yield call(fetch, url, {
			method: POST,
			body,
		});
		if (response.ok) {
			const {
				itemKey,
				change: { sequence },
			} = yield call(response.json.bind(response));
			yield call(updateStateAfterAddingVersion, payload, itemKey, sequence);
		} else {
			const error = new Error(yield call(response.text.bind(response)));
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'add-cross-project-versions-fetch-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
		yield put(resetRequest());
	} catch {
		yield put(resetRequest());
	}
}

export function* updateStateAfterDeletingCrossProjectVersion(
	crossProjectVersion: CrossProjectVersion,
	sequence: Sequence, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	yield* batch(function* () {
		if (crossProjectVersion.id.startsWith(SCENARIO_ISSUE_ID_PREFIX)) {
			// Remove cross-project version from store
			yield call(removeCrossProjectVersionFromStore, crossProjectVersion.id);
		} else {
			// Remove a cross-project release from state.domain.cross-project-versions
			yield put(crossProjectVersionsActions.remove(crossProjectVersion.id));

			// Add a cross-project release in state.domain.removed-cross-project-versions
			yield put(removedCrossProjectVersionsActions.add(crossProjectVersion));

			/** Update the original cross-project versions model so that it shows deleted release in
			 *  the update-jira dialog.
			 */
			yield put(
				originalCrossProjectVersionsActions.update({
					id: crossProjectVersion.id,
					values: {},
				}),
			);
		}

		// Update the sequence
		yield put(updateSequence(sequence));

		// Close the delete release dialog
		yield put(closeDeleteDialog());
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doDeleteVersion({ payload }: DeleteReleaseCommand): Generator<Effect, any, any> {
	const { crossProjectVersion, doesRemoveVersions } = payload;
	yield put(initiateDeleteRequest());
	try {
		const url = urls.delete;
		const body = deleteVersionBody(yield select(getPlan), payload);
		const response = yield call(fetch, url, {
			method: POST,
			body,
		});
		if (response.ok) {
			const {
				change: { sequence },
			} = yield call(response.json.bind(response));
			yield call(updateStateAfterDeletingCrossProjectVersion, crossProjectVersion, sequence);
			if (doesRemoveVersions) {
				const { versions } = crossProjectVersion;
				for (const versionId of versions) {
					if (versionId.startsWith(SCENARIO_ISSUE_ID_PREFIX)) {
						yield call(versionsCommands.updateStateAfterDeletingVersion, versionId, sequence);
					} else {
						yield call(versionsCommands.updateStateAfterRemovingVersion, versionId, sequence);
					}
				}
			}
		} else {
			const error = new Error(yield call(response.text.bind(response)));
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'delete-cross-project-versions-fetch-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		}
		yield put(resetDeleteRequest());
	} catch {
		yield put(resetDeleteRequest());
	}
}

export function* doAlignDatesOfReleases({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: AlignDatesOfReleasesCommand): Generator<Effect, any, any> {
	const { versions, patch } = payload;

	yield put(initiateAlignDatesRequest());
	for (const versionId of versions) {
		const patchConfig: VersionPatch = {
			versionId,
			patch,
		};
		yield put(versionsCommands.updateRelease(patchConfig));
	}

	yield put(resetAlignDatesRequest());
	yield put(closeAlignDatesDialog());
}

export function* doViewReleaseInRoadmap({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: ViewReleaseInRoadmapCommand): Generator<Effect, any, any> {
	yield put(clearFilter(RELEASE_FILTER_ID));
	yield put(changeFilter({ id: CROSS_PROJECT_RELEASE_FILTER_ID, value: payload }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchAddVersion(): Generator<Effect, any, any> {
	yield takeEvery(ADD_RELEASE, doAddVersion);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateVersion(): Generator<Effect, any, any> {
	yield takeEvery(UPDATE_RELEASE, doUpdateVersion);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchDeleteVersion(): Generator<Effect, any, any> {
	yield takeEvery(DELETE_RELEASE, doDeleteVersion);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchAlignDatesOfReleases(): Generator<Effect, any, any> {
	yield takeEvery(ALIGN_DATES_OF_RELEASES, doAlignDatesOfReleases);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchViewReleaseInRoadmap(): Generator<Effect, any, any> {
	yield takeEvery(VIEW_RELEASE_IN_ROADMAP, doViewReleaseInRoadmap);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function (versionCommandsModule: any) {
	versionsCommands = versionCommandsModule;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return function* (): Generator<Effect, any, any> {
		yield fork(watchAddVersion);
		yield fork(watchUpdateVersion);
		yield fork(watchDeleteVersion);
		yield fork(watchAlignDatesOfReleases);
		yield fork(watchViewReleaseInRoadmap);
	};
}
