import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-priorities-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchPriorityPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-priorities-filter.search-priority-placeholder',
		defaultMessage: 'Show issues from issue priority',
		description: 'This is a placeholder for search',
	},
	searchPriorityLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-priorities-filter.search-priority-label',
		defaultMessage: 'Choose from priorities',
		description: 'Aria label for priorities search filed that appears on activating Filters button',
	},
	searchPriorityPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-priorities-filter.search-priority-placeholder-issue-term-refresh',
		defaultMessage: 'Show issues from issue priority',
		description: 'This is a placeholder for search',
	},
});
