import React, { useRef, useEffect, useCallback, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Stack, Inline, Text } from '@atlaskit/primitives';
import AkTextField from '@atlaskit/textfield';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export default function TimelineIframeTab({ createShareURL, isLoading, url }: Props) {
	const { formatMessage } = useIntl();

	useEffect(() => {
		if (!isLoading && url === '') {
			createShareURL();
		}
	}, [isLoading, url, createShareURL]);

	const textFieldRef = useRef<HTMLInputElement>(null);
	const iframeValue = `<iframe src='${url}' width='1024' height='640' style='border:1px solid #ccc;'></iframe>`;

	const onClickCopy = useCallback((_: SyntheticEvent, analyticEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticEvent, 'copyTimelineIframe');
		if (textFieldRef.current) {
			textFieldRef.current.select();

			globalThis.document.execCommand('copy');
		}
	}, []);

	return (
		<Stack space="space.100">
			<Text>{formatMessage(messages.iframeBody)}</Text>
			<Inline space="space.100" alignBlock="center">
				<AkTextField
					aria-label={formatMessage(messages.iframeLabel)}
					isReadOnly
					ref={textFieldRef}
					value={iframeValue}
					testId="portfolio-3-portfolio.app-simple-plans.share-tabs.iframe.text-field"
				/>
				<Button appearance="primary" onClick={onClickCopy}>
					{formatMessage(messages.copyButton)}
				</Button>
			</Inline>
		</Stack>
	);
}
