import React, { memo, useEffect, useMemo, useState } from 'react';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { Box } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { STORY_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import SelectWireframe from '@atlassian/jira-portfolio-3-common/src/select-wireframe/index.tsx';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { CellSkeleton } from '@atlassian/jira-portfolio-3-common/src/skeleton/cell.tsx';
import type { LazyGoal } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-goals/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import { DEFAULT_FIELD_WIDTH } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import GoalCellSelect from '@atlassian/jira-portfolio-3-portfolio/src/common/view/goal/goal-cell-select/index.tsx';
import type { Props } from './types.tsx';

const calculateFlexShrink = (lazyGoals: LazyGoal[]): number[] => {
	const goalNameLengths = lazyGoals.map((lazyGoal) =>
		lazyGoal.goal === undefined ? 0 : lazyGoal.goal.name.length,
	);
	const shrinkCoefficient = 0.2; // A smaller number will make the goal components shrink closer to a similar rate.
	return goalNameLengths.map((length) => Math.pow(length, shrinkCoefficient));
};

const AllAtlasGoals = memo<Props>(
	({
		hasAtlasPermissions,
		isScrolling = false,
		issue,
		onChange: onChangeApiUpdate,
		isExportMode,
		lazyGoalsByARI,
		resetGoals,
		...props
	}: Props) => {
		const goals = useMemo(() => issue.goals ?? [], [issue]);
		const lazyGoals = useMemo(
			() =>
				goals
					.map((goalARI) => lazyGoalsByARI[goalARI])
					.filter((lazyGoal) => lazyGoal !== undefined),
			[goals, lazyGoalsByARI],
		);

		const currentGoals = issue.goals;
		const originalGoals = issue.originals?.goals;

		const [wasChanged, setWasChanged] = useState(false);

		const hasBeenChanged =
			(originalGoals && !isEmpty(difference(currentGoals, originalGoals))) ||
			(currentGoals && !isEmpty(difference(originalGoals, currentGoals)));

		useEffect(() => {
			if (!fg('plans_goals_revert_changes_on_reset')) {
				return;
			}
			if (!hasBeenChanged && wasChanged) {
				// Only reset goals when hasBeenChanged turns false after being true
				resetGoals({
					issueId: issue.id,
					goals: currentGoals || [],
				});
			}
			// Update the wasChanged state to reflect the current hasBeenChanged status
			setWasChanged(hasBeenChanged);
		}, [hasBeenChanged, resetGoals, issue.id, currentGoals, wasChanged]);

		if (lazyGoals.some(({ isLoading }) => isLoading)) {
			return <CellSkeleton width={DEFAULT_FIELD_WIDTH.MEDIUM} />;
		}
		const filteredLazyGoals = lazyGoals.filter((lazyGoal) => lazyGoal.goal !== undefined);

		const canEditGoals =
			hasAtlasPermissions &&
			(expVal('native_goals_on_issue_view', 'isGoalsFieldEnabled', false) ||
				issue.level > STORY_LEVEL);

		if (isScrolling) {
			return <SelectWireframe />;
		}

		const flexShrinkValues = calculateFlexShrink(lazyGoals);

		const onChange = (scopedIssue: ScopeIssue, scopedGoals: OptionType[]) => {
			if (!isEqual(filteredLazyGoals, scopedGoals)) {
				onChangeApiUpdate(scopedIssue, scopedGoals);
			}
		};

		if (canEditGoals) {
			if (fg('plans_performance_improvements_3')) {
				return (
					<GoalCellSelect
						{...props}
						isCellDirty={() => hasBeenChanged}
						issue={issue}
						onChange={onChange}
						flexShrinkValues={flexShrinkValues}
						lazyGoals={filteredLazyGoals}
						isExportMode={isExportMode}
						goals={lazyGoals}
					/>
				);
			}

			return (
				<Box>
					<GoalCellSelect
						{...props}
						isCellDirty={() => hasBeenChanged}
						issue={issue}
						onChange={onChange}
						flexShrinkValues={flexShrinkValues}
						lazyGoals={filteredLazyGoals}
						isExportMode={isExportMode}
						goals={lazyGoals}
					/>
				</Box>
			);
		}
		return null;
	},
);

export default AllAtlasGoals;
