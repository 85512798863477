export const PRODUCT_ANALYTICS_EVENT_NAMES = {
	OPENED_ISSUE_SUMMARY: 'issueSummary opened',
	UPDATED_ISSUE_SUMMARY: 'issueSummary updated',
	CANCELLED_ISSUE_SUMMARY: 'issueSummary cancelled',
	START_EDIT_DESCRIPTION: 'descriptionEditor activated',
	CANCEL_EDIT_DESCRIPTION: 'descriptionEditor deactivated',
	SAVE_DESCRIPTION: 'descriptionEditor saved',
} as const;

// Possible static description values
export const DESCRIPTION_FETCH_ERROR = 'DESCRIPTION_FETCH_ERROR' as const;
