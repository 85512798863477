import isNil from 'lodash/isNil';
import { log } from '@atlassian/jira-common-util-logging/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { UNASSIGNED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import type { GroupCombination } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { SPRINT_STATES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { type Option, GROUPED, type OptionGroup } from '../types.tsx';
import { optionGroupSortComparator, optionSortComparator } from '../utils.tsx';
import messages from './messages.tsx';
import { EXTERNAL, type Props } from './types.tsx';

const SprintOptionsProvider = ({
	groups,
	sprints,
	sprintsByIdMap,
	externalSprintsById,
	issueSourcesById,
	children,
}: Props) => {
	const { formatMessage } = useIntl();

	const isSprintClosed = (sprintId: string) =>
		sprintsByIdMap[sprintId].state === SPRINT_STATES.CLOSED;

	const isSprintExternal = (sprintId: string) => !isNil(externalSprintsById[sprintId]);

	const createOptionFromGroup = (group: GroupOption): Option<GroupCombination> => ({
		id: group.group,
		name: group.groupName,
		value: { sprint: group.groupCombination?.sprint },
		isDisabled:
			!isNil(group.groupCombination?.sprint) && isSprintExternal(group.groupCombination.sprint),
		disabledTooltip: formatMessage(
			fg('jira-issue-terminology-refresh-m3')
				? messages.sprintTooltipIssueTermRefresh
				: messages.sprintTooltip,
		),
	});

	const createOptionFromSprint = (sprint: Sprint): Option<GroupCombination> => ({
		id: `sprint-${sprint.id}`,
		name: sprint.title,
		value: { sprint: sprint.id },
		isDisabled: isSprintExternal(sprint.id),
		disabledTooltip: formatMessage(
			fg('jira-issue-terminology-refresh-m3')
				? messages.sprintTooltipIssueTermRefresh
				: messages.sprintTooltip,
		),
	});

	const getOptionsFromSprints = (): OptionGroup<GroupCombination>[] => {
		const sourceIdToOptionsMap: Record<string, OptionGroup<GroupCombination>> = {
			[EXTERNAL]: {
				id: EXTERNAL,
				name: formatMessage(messages.externalSource),
				sortValue: -1,
				options: [],
			},
			[UNASSIGNED_GROUP]: {
				id: UNASSIGNED_GROUP,
				name: formatMessage(commonMessages.unassigned),
				sortValue: -2,
				options: [
					{
						id: UNASSIGNED_GROUP,
						name: formatMessage(commonMessages.unassigned),
						value: { sprint: null },
					},
				],
			},
		};
		const processedSprintIds: Set<string> = new Set();

		groups.forEach((group) => {
			if (
				isNil(group.groupCombination) ||
				isNil(group.groupCombination.sprint) ||
				isSprintClosed(group.groupCombination.sprint)
			)
				return;

			const option = createOptionFromGroup(group);

			const sprint = sprintsByIdMap[group.groupCombination.sprint];
			processedSprintIds.add(group.groupCombination.sprint);

			if (sprint === undefined) return;

			const issueSourceId = sprint.issueSources?.[0] ?? EXTERNAL;

			if (!sprint.issueSources) {
				log.safeErrorWithoutCustomerData(
					'app-simple-plans.view.main.tabs.roadmap.scope.header.create-issue.options-providers.sprint.view.getOptionsFromSprints',
					'Error reading data from sprint.issueSources in groups.forEach',
				);
			}

			const issueSource = issueSourcesById[issueSourceId];
			const issueSourceTitle = issueSource?.title ?? formatMessage(messages.externalSource);

			const existingGroup = sourceIdToOptionsMap[issueSourceId] ?? {
				id: `source-${issueSourceId}`,
				name: issueSourceTitle,
				options: [],
			};

			existingGroup.options.push(option);
			sourceIdToOptionsMap[issueSourceId] = existingGroup;
		});

		sprints.forEach((sprint) => {
			if (processedSprintIds.has(sprint.id) || isSprintClosed(sprint.id)) return;

			const option = createOptionFromSprint(sprint);

			const issueSourceId = sprint?.issueSources?.[0] ?? EXTERNAL;

			if (!sprint?.issueSources) {
				log.safeErrorWithoutCustomerData(
					'app-simple-plans.view.main.tabs.roadmap.scope.header.create-issue.options-providers.sprint.view.getOptionsFromSprints',
					'Error reading data from sprint.issueSources in sprints.forEach',
				);
			}

			const issueSource = issueSourcesById[issueSourceId];
			const issueSourceTitle = issueSource?.title ?? formatMessage(messages.externalSource);

			const existingGroup = sourceIdToOptionsMap[issueSourceId] ?? {
				id: `source-${issueSourceId}`,
				name: issueSourceTitle,
				options: [],
			};

			existingGroup.options.push(option);
			sourceIdToOptionsMap[issueSourceId] = existingGroup;
		});

		const optionGroups = Object.values(sourceIdToOptionsMap);

		optionGroups.forEach((group) => group.options.sort(optionSortComparator));
		optionGroups.sort(optionGroupSortComparator);

		return optionGroups;
	};

	return children({
		options: {
			type: GROUPED,
			groups: getOptionsFromSprints(),
		},
		menuTitle: formatMessage(messages.sprintMenuTitle),
		searchPlaceholder: formatMessage(messages.sprintSearchPlaceholder),
	});
};

export default SprintOptionsProvider;
