import React, { Component, type SyntheticEvent } from 'react';
import classNames from 'classnames';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { Radio } from '@atlaskit/radio';
import { injectIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
	DialogMenuGroup,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { selectIssueOption } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	type DependecyTypeSpecificIssue,
	type DependenciesFilterValue,
	DEPENDENCIES_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';

import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { AsyncSelect } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/select/view.tsx';
import { fireUIAnalytics, AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { PropsOld } from './types.tsx';

export const RadioButtonWithAnalytics = AnalyticsEventToProps('radioButton', {
	onChange: 'changed',
})(Radio);

const AsyncSelectWithAnalytics = AnalyticsEventToProps('asyncSelect', {
	onChange: 'changed',
})(AsyncSelect);

function isDepdendencyFilterValueHasTransitive(
	val: DependenciesFilterValue,
): val is DependecyTypeSpecificIssue {
	return val.type === 'specificIssue';
}

// eslint-disable-next-line jira/react/no-class-components
export class DependenciesFilter extends Component<PropsOld, State> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onIssueChange = (issue: Issue, _: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
		const { props, onDependencyFilterChange } = this;
		const { value } = props;

		onDependencyFilterChange(
			{
				type: 'specificIssue',
				issueId: issue && issue.id,
				includeTransitive:
					(isDepdendencyFilterValueHasTransitive(value) && value.includeTransitive) || false,
			},
			analyticsEvent,
		);
	};

	onOpenChange = (
		{
			isOpen,
			ignoreMenuOpen,
		}: {
			isOpen: boolean;
			ignoreMenuOpen?: boolean;
		},
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const { props, onDependencyFilterChange } = this;
		const { value, onOpenChange } = props;

		if (!ignoreMenuOpen && props.isMenuOpen) return;

		if (!isOpen && value.type === 'specificIssue' && !value.issueId) {
			onDependencyFilterChange({ type: 'off' }, analyticsEvent);
		}
		onOpenChange({ isOpen });
	};

	onDependencyFilterChange = (
		payload: DependenciesFilterValue,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		this.props.changeDependenciesFilter(payload);

		// triggering analytics when a user adds changes the "Dependencies" filter
		const [actionSubject, action] =
			PRODUCT_ANALYTICS_EVENT_NAMES.UPDATED_DEPENDENCIES_FILTER.split(' ');
		fireUIAnalytics(analyticsEvent.update({ action, actionSubject }), {
			type: payload.type,
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	toggleOpen = (_: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
		const { isOpen } = this.props;
		this.onOpenChange({ isOpen: !isOpen, ignoreMenuOpen: true }, analyticsEvent);
	};

	getIssueKey = ({ issueKey, project: projectId }: Issue) => {
		const { projectsById } = this.props;
		const project = projectsById[projectId || NaN];
		const key = issueKey ? `${project.key}-${issueKey}` : project.key;
		return key;
	};

	getDependencyIssueValue = (issue: Issue) => `${this.getIssueKey(issue)} ${issue.summary}`;

	loadOptions = (query: string) => {
		const q = query.toLowerCase();
		const issues = this.props.issues
			.filter((issue) => this.getDependencyIssueValue(issue).toLowerCase().includes(q))
			.slice(0, 50);
		return Promise.resolve(issues);
	};

	renderDependencyIssue = (issue: Issue) => {
		const { issueTypes } = this.props;
		const issueType = issueTypes.find(({ id }) => id === issue.type);

		return selectIssueOption({
			icon: issueType && issueType.iconUrl,
			label: this.getDependencyIssueValue(issue),
		});
	};

	renderTriggerButton = () => {
		const { intl, issueMapById, value, isOpen } = this.props;
		let triggerButtonText = '';
		if (value.type === 'specificIssue') {
			const { issueId } = value;
			if (issueId && issueMapById[issueId]) {
				triggerButtonText = intl.formatMessage(messages.specificIssueTrigger, {
					issueKey: this.getIssueKey(issueMapById[issueId]),
				});
			} else {
				triggerButtonText = intl.formatMessage(messages.offTrigger);
			}
		} else {
			triggerButtonText = intl.formatMessage(messages[`${value.type}Trigger`]);
		}

		const ariaText = () =>
			// Dependencies, All issues selected
			`${intl.formatMessage(
				filterMessages[DEPENDENCIES_FILTER_ID],
			)}, ${triggerButtonText} ${intl.formatMessage(filterMessages.selected)}`;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.filterButtonWrapper}>
				<Button
					iconAfter={<ChevronDownIcon label="" />}
					isSelected={isOpen}
					onClick={this.toggleOpen}
					ariaLabel={ariaText()}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.trigger-btn"
				>
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={classNames(styles.filterButton, {
							[styles.filterButtonApplied]: value.type !== 'off',
						})}
					>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.buttonText}>{triggerButtonText}</div>
					</div>
				</Button>
			</div>
		);
	};

	render() {
		const { props, onDependencyFilterChange } = this;
		const { intl, issueMapById, value, isOpen } = props;

		return (
			<InlineDialog
				noPaddings
				maxWidth={FILTER_MAX_WIDTH}
				minWidth={FILTER_WIDTH}
				overflow="visible"
				onClose={this.onOpenChange}
				isOpen={isOpen}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter"
				content={
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					<DialogMenuContainer style={{ overflow: 'visible' }}>
						<DialogMenuGroup heading={intl.formatMessage(messages.showIssues)}>
							<DialogMenuItem>
								<RadioButtonWithAnalytics
									isChecked={value.type === 'off'}
									label={intl.formatMessage(messages.allIssues)}
									name="showIssues"
									onChange={(
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										_: SyntheticEvent<any>,
										analyticsEvent: UIAnalyticsEvent,
									) => onDependencyFilterChange({ type: 'off' }, analyticsEvent)}
									value="off"
								/>
							</DialogMenuItem>
							<DialogMenuItem>
								<RadioButtonWithAnalytics
									isChecked={value.type === 'on'}
									label={intl.formatMessage(messages.hasDependencies)}
									name="showIssues"
									onChange={(
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										_: SyntheticEvent<any>,
										analyticsEvent: UIAnalyticsEvent,
									) => onDependencyFilterChange({ type: 'on' }, analyticsEvent)}
									value="on"
								/>
							</DialogMenuItem>
							<DialogMenuItem>
								<RadioButtonWithAnalytics
									isChecked={value.type === 'offtrack'}
									label={intl.formatMessage(messages.offtrackDependencies)}
									name="showIssues"
									onChange={(
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										_: SyntheticEvent<any>,
										analyticsEvent: UIAnalyticsEvent,
									) => onDependencyFilterChange({ type: 'offtrack' }, analyticsEvent)}
									value="offtrack"
								/>
							</DialogMenuItem>
							<DialogMenuItem>
								<RadioButtonWithAnalytics
									isChecked={value.type === 'specificIssue'}
									label={intl.formatMessage(messages.specificIssue)}
									name="showIssues"
									onChange={(
										// eslint-disable-next-line @typescript-eslint/no-explicit-any
										_: SyntheticEvent<any>,
										analyticsEvent: UIAnalyticsEvent,
									) => onDependencyFilterChange({ type: 'specificIssue' }, analyticsEvent)}
									value="specificIssue"
								/>
							</DialogMenuItem>
						</DialogMenuGroup>
						{value.type === 'specificIssue' ? (
							<>
								<AsyncSelectWithAnalytics
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
									className={styles.issueSelector}
									defaultOptions
									defaultValue={value.issueId && issueMapById[value.issueId]}
									formatOptionLabel={this.renderDependencyIssue}
									getOptionValue={this.getDependencyIssueValue}
									getOptionLabel={this.getDependencyIssueValue}
									isClearable
									isTransparentBackground={false}
									loadOptions={this.loadOptions}
									menuPlacement="auto"
									onChange={this.onIssueChange}
									placeholder={intl.formatMessage(messages.issueSelectorPlaceholder)}
									autoFocus
								/>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<div className={styles.includeTransitive}>
									<Checkbox
										isChecked={value.includeTransitive}
										label={intl.formatMessage(messages.includeTransitive)}
										onChange={(
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											_: SyntheticEvent<any>,
											analyticsEvent: UIAnalyticsEvent,
										) =>
											onDependencyFilterChange(
												{
													type: 'specificIssue',
													issueId: value.issueId,
													includeTransitive: !value.includeTransitive,
												},
												analyticsEvent,
											)
										}
									/>
								</div>
							</>
						) : null}
					</DialogMenuContainer>
				}
			>
				{this.renderTriggerButton()}
			</InlineDialog>
		);
	}
}

export default injectIntl(DependenciesFilter);
