import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.release.placeholder',
		defaultMessage: 'Choose a release',
		description: 'The placeholder for the release selector',
	},

	releaseSelectorLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.release.release-selector-label',
		defaultMessage: 'Choose a release for issue {issue}',
		description: 'The label for the release selector',
	},

	unknownVersion: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.release.unknown-version',
		defaultMessage: 'Unknown version {id}',
		description: 'Unknown version name',
	},

	released: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.release.released',
		defaultMessage: 'Released',
		description: 'A label for versions which are already released',
	},

	unreleased: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.release.unreleased',
		defaultMessage: 'Unreleased',
		description: 'A label for versions which are not yet released',
	},

	notAvailable: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.release.not-available',
		defaultMessage: 'Not enabled',
		description: 'A label to indicate Release feature is disabled',
	},

	notAvailableTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.release.not-available-tooltip',
		defaultMessage:
			'To enable releases for <strong>{projectName}</strong>, go to Project settings > Features',
		description: 'A tooltip displayed on a field when it is disabled.',
	},

	disabledReleaseField: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.release.disabled-release-field',
		defaultMessage: 'N/A',
		description:
			'This message indicates a non-editable field - N/A means the user can not edit it.',
	},

	releaseSelectorLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.release.release-selector-label-issue-term-refresh',
		defaultMessage: 'Choose a release for issue {issue}',
		description: 'The label for the release selector',
	},
});
