import fireErrorAnalyticsRaw from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ERROR_REPORTING_PACKAGE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { isPageUnloading } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';

const ignoredErrorRegexes = [
	// Ignore localStorage.setItem() errors
	// ==================================
	// If localStorage is full, Portfolio behaves okay, but some non-JPO code can throw exceptions (e.g. atlassian-analytics).
	// So we ignore any localStorage write exceptions.

	// Chrome
	/Uncaught QuotaExceededError: Failed to execute 'setItem' on 'Storage': Setting the value of '.*' exceeded the quota/,

	// Firefox
	/NS_ERROR_DOM_QUOTA_REACHED: Persistent storage maximum size reached/,

	// Safari
	/QuotaExceededError: DOM Exception 22: An attempt was made to add something to storage that exceeded the quota/,

	// IE 10, Edge
	/^QuotaExceededError$/,

	// If XHR response status is 401, we reload the page to be redirected to the login page.
	// Hence, no need to show the error.
	/^common\.fetch\.XHR_UNAUTHORIZED_ERROR/,
];

export const shouldIgnoreError = (errorMessage?: string) => {
	if (isPageUnloading()) return true;
	if (!errorMessage) return true;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const matchesError = (regex: any) => regex.test(errorMessage);
	return ignoredErrorRegexes.some(matchesError);
};

const environmentErrorMessagesRegexes = [
	'Failed to fetch$',
	'Failed to fetch page data for plan',
	"Cannot read property 'claim' of undefined$",
	'NetworkError when attempting to fetch resource.$',
	'cancelled$',
	'^Not enough storage is available to complete this operation.$',
	'The operation was aborted.$',
	"^undefined is not an object \\(evaluating 'window.WRM.data.claim'\\)$",
	'The request timed out.$',
	'The network connection was lost.$',
];
const environmentErrorMessagesRegex = new RegExp(environmentErrorMessagesRegexes.join('|'));
export const isEnvironmentError = (error: { message: string }) =>
	error.message.match(environmentErrorMessagesRegex);

export const RESPONSE_ERROR_CODE = {
	REQUIRE_STATUS_TRANSITION_SCREEN: 'REQUIRE_STATUS_TRANSITION_SCREEN',
};

/**
 * Monkey patched to do some handling before calling the original function
 */
export const fireErrorAnalytics: typeof fireErrorAnalyticsRaw = (_payload, ...args) => {
	// make a copy to avoid modifying the original object
	const payload = { ..._payload };

	if (shouldIgnoreError(payload.error?.message)) return Promise.resolve();

	// Environment errors are filtered out in our dashboards
	if (payload.meta.id && payload.error) {
		if (isEnvironmentError(payload.error)) {
			payload.meta.id = ERROR_REPORTING_PACKAGE.ENVIRONMENT;
		}
	}

	return fireErrorAnalyticsRaw(payload, ...args);
};
