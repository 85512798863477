import { fg } from '@atlassian/jira-feature-gating';
import type { ResetAction } from './actions.tsx';
import newReducer from './reducer-new.tsx';
import oldReducer from './reducer-old.tsx';
import type { ViewSettingsState } from './types.tsx';

type Action = ResetAction;

const reducer = (state: ViewSettingsState | undefined, action: Action) =>
	fg('extract_dynamic_key_reducer') ? newReducer(state, action) : oldReducer(state, action);

export default reducer;
