import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	bulkActions: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.bulk-actions',
		defaultMessage: 'Bulk actions',
		description: 'Bulk actions dropdown button',
	},
	bulkChange: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.bulk-change',
		defaultMessage: 'Bulk change in Jira',
		description: 'Label for bulk changes',
	},
	bulkChangeInJiraTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.bulk-change-in-jira-tooltip',
		defaultMessage: 'All selected issues must already be saved in Jira',
		description: 'Tooltip for disable state of Bulk Change in Jira menu item',
	},
	issueLimitExceededTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.issue-limit-exceeded-tooltip',
		defaultMessage: 'Maximum 100 issues',
		description:
			'Tooltip for disable state of Bulk Change in Jira menu item for selection exceeding limit',
	},
	setFieldGroupTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-field-group-title',
		defaultMessage: 'Update',
		description: 'Title for update group',
	},
	setParent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-parent',
		defaultMessage: 'Parent',
		description: 'set parent for issues button',
	},
	setRank: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-rank',
		defaultMessage: 'Rank',
		description: 'Label for rank action',
	},
	setTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-team',
		defaultMessage: 'Team',
		description: 'Label for team action',
	},
	setSprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-sprint',
		defaultMessage: 'Sprint',
		description: 'Label for sprint action',
	},
	labels: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.labels',
		defaultMessage: 'Labels',
		description: 'Label for the label action',
	},
	moreActionsGroupTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.more-actions-group-title',
		defaultMessage: 'More actions',
		description: 'Title for more actions group',
	},
	removeFromPlanTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.remove-from-plan-title',
		defaultMessage: 'Remove from plan',
		description: 'Bulk actions title',
	},
	releaseMenuItemTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.release-menu-item-tooltip',
		defaultMessage:
			'To set the release, the selected issues must belong to the same project, or there must be a cross-project release that has releases from all associated projects.',
		description: 'Tooltip for disable state of release menu item',
	},
	releaseDisabledMenuItemTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.release-disabled-menu-item-tooltip',
		defaultMessage:
			'To set the release, the selected issues must belong to project that has releases enabled.',
		description: 'Tooltip for disable state of release menu item',
	},
	issuesSameLevelTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.issues-same-level-tooltip',
		defaultMessage:
			'To move multiple issues to another parent issue, the issues must be of the same hierarchy level',
		description: 'Tooltip for disable state of Move menu item',
	},
	issuesTopLevelTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.issues-top-level-tooltip',
		defaultMessage:
			"One of the selected issues is already of the topmost hierarchy level, so it's not possible to move this any higher",
		description: 'Tooltip for disable state of Move menu item',
	},
	teamMenuItemTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.team-menu-item-tooltip',
		defaultMessage:
			'A sub-task is assigned to the same team as its parent story. To change this team, reassign the parent story instead.',
		description: 'Tooltip for disable state of Team menu item',
	},
	sprintMenuItemTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.sprint-menu-item-tooltip',
		defaultMessage:
			'A sub-task is assigned to the same sprint as its parent story. To change its sprint, change the sprint of the parent story instead',
		description: 'Tooltip for disable state of Sprint menu item',
	},
	bulkChangeInJiraTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.bulk-change-in-jira-tooltip-issue-term-refresh',
		defaultMessage: 'All selected issues must already be saved in Jira',
		description: 'Tooltip for disable state of Bulk Change in Jira menu item',
	},
	issueLimitExceededTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.issue-limit-exceeded-tooltip-issue-term-refresh',
		defaultMessage: 'Maximum 100 issues',
		description:
			'Tooltip for disable state of Bulk Change in Jira menu item for selection exceeding limit',
	},
	releaseMenuItemTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.release-menu-item-tooltip-issue-term-refresh',
		defaultMessage:
			'To set the release, the selected issues must belong to the same project, or there must be a cross-project release that has releases from all associated projects.',
		description: 'Tooltip for disable state of release menu item',
	},
	releaseDisabledMenuItemTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.release-disabled-menu-item-tooltip-issue-term-refresh',
		defaultMessage:
			'To set the release, the selected issues must belong to project that has releases enabled.',
		description: 'Tooltip for disable state of release menu item',
	},
	issuesSameLevelTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.issues-same-level-tooltip-issue-term-refresh',
		defaultMessage:
			'To move multiple issues to another parent issue, the issues must be of the same hierarchy level',
		description: 'Tooltip for disable state of Move menu item',
	},
	issuesTopLevelTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.issues-top-level-tooltip-issue-term-refresh',
		defaultMessage:
			"One of the selected issues is already of the topmost hierarchy level, so it's not possible to move this any higher",
		description: 'Tooltip for disable state of Move menu item',
	},
});
