import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.project-options.project-tooltip',
		defaultMessage:
			'This issue type doesn’t exist in this project. Ask your project admin to create this issue type for the project.',
		description: 'Tooltip for disabled option for CMP project',
	},
	projectTooltipTmp: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.project-options.project-tooltip-tmp',
		defaultMessage: 'This hierarchy level can’t be created in a team-managed project.',
		description: 'Tooltip for disabled option for TMP project',
	},
	projectSearchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.project-options.project-search-placeholder',
		defaultMessage: 'Find projects...',
		description: 'This is a placeholder for search',
	},
	projectTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.project-options.project-tooltip-issue-term-refresh',
		defaultMessage:
			'This issue type doesn’t exist in this project. Ask your project admin to create this issue type for the project.',
		description: 'Tooltip for disabled option for CMP project',
	},
});
