import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const Description = ({ loadingLimitsInfo }: Props) => (
	<FormattedMessage
		{...(fg('jira-issue-terminology-refresh-m3')
			? messages.descriptionWithLimitIssueTermRefresh
			: messages.descriptionWithLimit)}
		values={{ issueLimit: loadingLimitsInfo.absoluteIssueLimit }}
	/>
);

export default Description;
