.container {
	display: flex;
}

.rollUp {
	display: flex;
	flex-shrink: 0;
	padding: 9px;
	font-style: italic;
	color: var(--ds-text-subtlest, var(--adg3-color-N300));
	max-width: 50%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	align-items: center;
	cursor: default;
}

.rollupValue {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.estimate {
	flex-grow: 1;
}

.readOnly input {
	color: var(--ds-text-disabled, var(--adg3-color-N70));
	cursor: not-allowed;
	pointer-events: auto;
}

.readOnlyNew input {
	cursor: not-allowed;
	pointer-events: auto;
}
