import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	noChangesDialogHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.empty-dialog.no-changes-dialog-header',
		defaultMessage: 'Your plan is up to date!',
		description: 'The title text of the update jira modal when there are no changes in the plan.',
	},
	noChangesDialogDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.empty-dialog.no-changes-dialog-description',
		defaultMessage:
			"Any changes you make to the issues in your plan, including creating new ones, will appear here for your approval. These changes aren't applied until you decide your plan is ready to go.",
		description:
			'The description text of the update jira modal when there are no changes in the plan.',
	},
	noChangesDialogActionButtonText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.empty-dialog.no-changes-dialog-action-button-text',
		defaultMessage: 'Read how to save changes in your plan.',
		description:
			'The text action button visible in the empty state dialog when there are no changes in the plan used to redirect to documentation.',
	},
	closeIconLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.empty-dialog.close-icon-label',
		defaultMessage: 'Close dialog',
		description: 'Label for the close icon in the update jira empty state modal.',
	},
	noChangesDialogDescriptionIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.empty-dialog.no-changes-dialog-description-issue-term-refresh',
		defaultMessage:
			"Any changes you make to the issues in your plan, including creating new ones, will appear here for your approval. These changes aren't applied until you decide your plan is ready to go.",
		description:
			'The description text of the update jira modal when there are no changes in the plan.',
	},
});
export default messages;
