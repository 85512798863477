import React, { type KeyboardEvent, type MouseEvent } from 'react';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { Box } from '@atlaskit/primitives';
import { addUFOCustomData } from '@atlaskit/react-ufo/custom-data';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import CellButton from '../cell-button/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

function IssuesWithoutParent({
	id,
	isExpanded = false,
	level: { title },
	onToggle,
	rankDigits,
	rootIssuesCount,
	outerRef,
}: Props) {
	const handleOnToggle = (event: KeyboardEvent | MouseEvent) => {
		if (isExpanded !== null) {
			if (isExpanded) {
				traceUFOPress(KEYS.COLLAPSE_ISSUE_WITHOUT_PARENT, event.timeStamp);
			} else {
				traceUFOPress(KEYS.EXPAND_ISSUE_WITHOUT_PARENT, event.timeStamp);
			}
			addUFOCustomData({
				scalingFactors: {
					rootIssuesCount,
				},
			});
		}
		onToggle({ [id]: !isExpanded });
	};

	const getContentIcon = () => {
		if (rootIssuesCount > 0) {
			const chevronIcon =
				isExpanded === true ? <ChevronDownIcon label="" /> : <ChevronRightIcon label="" />;

			return isVisualRefreshEnabled() && fg('visual-refresh_drop_1') ? (
				<Box paddingInlineEnd="space.150">{chevronIcon}</Box>
			) : (
				chevronIcon
			);
		}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		return <div className={styles.icon} />;
	};

	const content = (
		<div
			ref={outerRef}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={!fg('plan-timeline-non-transposed') ? styles.container : styles.containerOld}
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.rank} style={{ width: 10 * rankDigits, minWidth: 10 * rankDigits }} />
			{getContentIcon()}
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.summary}>
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.levelIssueTermRefresh
						: messages.level)}
					values={{
						levelTitle: title,
						issuesCount: rootIssuesCount,
					}}
				/>
			</div>
		</div>
	);

	return (
		<CellButton
			onClick={handleOnToggle}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.toggleButton}
			data-name="toggleIssueWithoutParent"
			ariaExpanded={isExpanded}
		>
			{content}
		</CellButton>
	);
}

export default IssuesWithoutParent;
