import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	add: {
		id: 'portfolio-3-portfolio.common.add',
		defaultMessage: 'Add',
		description: 'It is a label for a add button.',
	},
	after: {
		id: 'portfolio-3-portfolio.common.after',
		defaultMessage: 'after',
		description:
			'It describes that the start date of a release is after the previous release ends.',
	},
	afterAllIssuesAreCompleted: {
		id: 'portfolio-3-portfolio.common.after-all-issues-are-completed',
		defaultMessage: 'After all issues are completed',
		description:
			'It describes that the release is set to be released after all issues are completed',
	},
	afterCapitalized: {
		id: 'portfolio-3-portfolio.common.after-capitalized',
		defaultMessage: 'After',
		description:
			'It describes that the start date of a release is after the previous release ends.',
	},
	allOtherIssues: {
		id: 'portfolio-3-portfolio.common.all-other-issues',
		defaultMessage: 'All other issues',
		description:
			'This label is used in color by and group by to represent all other issues that excluded from coloring and grouping',
	},
	apply: {
		id: 'portfolio-3-portfolio.common.apply',
		defaultMessage: 'Apply',
		description: 'It is a label for a apply button.',
	},
	asEarlyAsPossible: {
		id: 'portfolio-3-portfolio.common.as-early-as-possible',
		defaultMessage: 'As early as possible',
		description: 'It describes that the release is set to be started as early as possible.',
	},
	assignee: {
		id: 'portfolio-3-portfolio.common.assignee',
		defaultMessage: 'Assignee',
		description: 'The label is used to indicate assignee.',
	},
	reporter: {
		id: 'portfolio-3-portfolio.common.reporter',
		defaultMessage: 'Reporter',
		description: 'The label is used to indicate reporter.',
	},
	before: {
		id: 'portfolio-3-portfolio.common.before',
		defaultMessage: 'before',
		description:
			'It describes that the start date of a release is before the previous release ends.',
	},
	beforeCapitalized: {
		id: 'portfolio-3-portfolio.common.before-capitalized',
		defaultMessage: 'Before',
		description:
			'It describes that the start date of a release is before the previous release ends.',
	},
	cancel: {
		id: 'portfolio-3-portfolio.common.cancel',
		defaultMessage: 'Cancel',
		description: 'It is a label for a cancel button.',
	},
	confirm: {
		id: 'portfolio-3-portfolio.common.confirm',
		defaultMessage: 'Confirm',
		description: 'It is a label for a confirm button.',
	},
	changeIndicatorTooltip: {
		id: 'portfolio-3-portfolio.common.change-indicator-tooltip',
		defaultMessage: 'To apply issue changes beyond your plan, review and save changes.',
		description:
			'It is a text shown in the tooltip of change indicator which has orange triangle shape in each cell of the scope table.',
	},
	clearFilterText: {
		id: 'portfolio-3-portfolio.common.clear-filter-text',
		defaultMessage: 'Clear selected items',
		description: 'This is a text for a clear filter button',
	},
	close: {
		id: 'portfolio-3-portfolio.common.close',
		defaultMessage: 'Close',
		description: 'It is a label for a close button.',
	},
	component: {
		id: 'portfolio-3-portfolio.common.component',
		defaultMessage: 'Component',
		description: 'The label is used to indicate jira component.',
	},
	chooseConfigurableDatesUnavailableHeading: {
		id: 'portfolio-3-portfolio.common.choose-configurable-dates-unavailable-heading',
		defaultMessage: `Custom {count, plural,
            one {date}
            other {dates}
          } no longer available`,
		description:
			'Lets the user know the value has been defaulted because the original values is no longer available or does not exist anymore',
	},
	chooseConfigurableDatesUnavailableContentStartDate: {
		id: 'portfolio-3-portfolio.common.choose-configurable-dates-unavailable-content-start-date',
		defaultMessage:
			'<strong>{startDateTitle}</strong> is no longer available for scheduling issues. Use other custom start dates, or keep default start dates.',
		description:
			'Informs the user that the field used for the start date is no longer available, and that the start date has been defaulted to another field',
	},
	chooseConfigurableDatesUnavailableContentEndDate: {
		id: 'portfolio-3-portfolio.common.choose-configurable-dates-unavailable-content-end-date',
		defaultMessage:
			'<strong>{endDateTitle}</strong> is no longer available for scheduling issues. Use other custom end dates, or keep default end dates.',
		description:
			'Informs the user that the field used for the end date is no longer available, and that the end date has been defaulted to another field',
	},
	chooseConfigurableDatesUnavailableContentStartAndEndDate: {
		id: 'portfolio-3-portfolio.common.choose-configurable-dates-unavailable-content-start-and-end-date',
		defaultMessage:
			'<strong>{startDateTitle}</strong> and <strong>{endDateTitle}</strong> are no longer available for scheduling issues. Use other custom dates, or keep default dates.',
		description:
			'Informs the user that the fields used for the start and end dates are both no longer available, and that the start and end dates have been defaulted to other fields',
	},
	chooseConfigurableDatesUnavailableContentStartDateTooltip: {
		id: 'portfolio-3-portfolio.common.choose-configurable-dates-unavailable-content-start-date-tooltip',
		defaultMessage:
			'{startDateTitle} is no longer available for scheduling issues. Use other custom start dates, or keep default start dates.',
		description:
			'Tooltip that informs the user that the field used for the start date is no longer available, and that the start date has been defaulted to another field',
	},
	chooseConfigurableDatesUnavailableContentEndDateTooltip: {
		id: 'portfolio-3-portfolio.common.choose-configurable-dates-unavailable-content-end-date-tooltip',
		defaultMessage:
			'{endDateTitle} is no longer available for scheduling issues. Use other custom end dates, or keep default end dates.',
		description:
			'Tooltip that informs the user that the field used for the end date is no longer available, and that the end date has been defaulted to another field',
	},
	chooseConfigurableDateNotExistContent: {
		id: 'portfolio-3-portfolio.common.choose-configurable-date-not-exist-content',
		defaultMessage: `The previously used custom {count, plural,
                one {date is}
                other {dates are}
              } no longer available for scheduling issues. Use other custom dates, or keep using default dates.`,
		description:
			'Lets the user know the value has been defaulted because the original values no longer exists',
	},
	create: {
		id: 'portfolio-3-portfolio.common.create',
		defaultMessage: 'Create',
		description: 'It is a label for a create button.',
	},
	crossProjectRelease: {
		id: 'portfolio-3-portfolio.common.cross-project-release',
		defaultMessage: 'Cross-project release',
		description: 'This label is a header for crossProjectRelease column.',
	},
	crossProjectReleases: {
		id: 'portfolio-3-portfolio.common.cross-project-releases',
		defaultMessage: 'Cross-project releases',
		description: 'This label is a label for crossProject releases.',
	},
	current: {
		id: 'portfolio-3-portfolio.common.current',
		defaultMessage: 'Current',
		description: 'It is a label to represent the current value of any field.',
	},
	dates: {
		id: 'portfolio-3-portfolio.common.dates',
		defaultMessage: 'Dates',
		description: 'It is a label for dates option.',
	},
	dateRange: {
		id: 'portfolio-3-portfolio.common.date-range',
		defaultMessage: 'Date range',
		description: 'The label for the date range field',
	},
	days: {
		id: 'portfolio-3-portfolio.common.days',
		defaultMessage: 'Days',
		description:
			'It describes that the start date of a release is before the previous release ends.',
	},
	delete: {
		id: 'portfolio-3-portfolio.common.delete',
		defaultMessage: 'Delete',
		description: 'It is a label for a delete button.',
	},
	dueDate: {
		id: 'portfolio-3-portfolio.common.due-date',
		defaultMessage: 'Due date',
		description: 'This label is a header for due date column.',
	},
	edit: {
		id: 'portfolio-3-portfolio.common.edit',
		defaultMessage: 'Edit',
		description: 'It is a label for an edit button.',
	},
	epic: {
		id: 'portfolio-3-portfolio.common.epic',
		defaultMessage: 'Epic',
		description: 'It is a label for the epic issue hierarchy level',
	},
	estimate: {
		id: 'portfolio-3-portfolio.common.estimate',
		defaultMessage: 'Estimate',
		description: 'The label is used to indicate estimate.',
	},
	fixedDate: {
		id: 'portfolio-3-portfolio.common.fixed-date',
		defaultMessage: 'Fixed date',
		description: 'It describes that the release is set to be started or ended on the fixed date',
	},
	goals: {
		id: 'portfolio-3-portfolio.common.goals',
		defaultMessage: 'Goals',
		description: 'The label is used to indicate goals.',
	},
	ideas: {
		id: 'portfolio-3-portfolio.common.ideas',
		defaultMessage: 'Idea',
		description: 'The label is used to indicate JPD ideas.',
	},
	groupBy: {
		id: 'portfolio-3-portfolio.common.group-by',
		defaultMessage: 'Group by',
		description: 'A label that prefixes a control to select how issues are grouped',
	},
	issueType: {
		id: 'portfolio-3-portfolio.common.issue-type',
		defaultMessage: 'Issue type',
		description: 'Used throughout the app to label "Issue type" fields',
	},
	issueLinkType: {
		id: 'portfolio-3-portfolio.common.issue-link-type',
		defaultMessage: 'Issue link type',
		description: 'Used throughout the app to label "Issue link type" fields',
	},
	jira: {
		id: 'portfolio-3-portfolio.common.jira',
		defaultMessage: 'Jira',
		description: 'It is a label for text Jira',
	},
	label: {
		id: 'portfolio-3-portfolio.common.label',
		defaultMessage: 'Label',
		description: 'The label is used to indicate label.',
	},
	learnMore: {
		id: 'portfolio-3-portfolio.common.learn-more',
		defaultMessage: 'Learn more',
		description: 'This text is displayed when learn more link is shown.',
	},
	loadingIssuesMessage: {
		id: 'portfolio-3-portfolio.common.loading-issues-message',
		defaultMessage: 'Loading issues...',
		description: 'Message to be shown when issues are being loaded',
	},
	loading: {
		id: 'portfolio-3-portfolio.common.loading',
		defaultMessage: 'Loading',
		description: 'Label for assistive technologies to describe a loading state',
	},
	name: {
		id: 'portfolio-3-portfolio.common.name',
		defaultMessage: 'Name',
		description: 'It is a label used to specify name.',
	},
	new: {
		id: 'portfolio-3-portfolio.common.new',
		defaultMessage: 'New',
		description: 'It is a label to represent the new value of any field.',
	},
	next: {
		id: 'portfolio-3-portfolio.common.next',
		defaultMessage: 'Next',
		description: 'It is a label to use on a button to go on a next step or screen',
	},
	noMatchFoundText: {
		id: 'portfolio-3-portfolio.common.no-match-found-text',
		defaultMessage: 'No matches',
		description: 'This is a text is displayed when none of the option matches the search query.',
	},
	none: {
		id: 'portfolio-3-portfolio.common.none',
		defaultMessage: 'None',
		description: 'It is a text saying no value available.',
	},
	notInPlan: {
		id: 'portfolio-3-portfolio.common.not-in-plan',
		defaultMessage: 'Not in plan',
		description:
			'The label indicating that the entity is not a part of a plan or part of a another plan. It is either excluded or filtered out by the issue source.',
	},
	plan: {
		id: 'portfolio-3-portfolio.common.plan',
		defaultMessage: 'Plan',
		description: 'Plan',
	},
	planNotFound: {
		id: 'portfolio-3-portfolio.common.plan-not-found',
		defaultMessage:
			'We couldn’t find that plan. It might have been deleted by its owner or the URL might be wrong.',
		description: 'The label indicating that the plan is not found',
	},
	priority: {
		id: 'portfolio-3-portfolio.common.priority',
		defaultMessage: 'Priority',
		description: 'This label is a header for priority column.',
	},
	project: {
		id: 'portfolio-3-portfolio.common.project',
		defaultMessage: 'Project',
		description: 'This label is a header for project column.',
	},
	relativeToPreviousRelease: {
		id: 'portfolio-3-portfolio.common.relative-to-previous-release',
		defaultMessage: '{factor} previous release date',
		description:
			'It describes that the release is set to be started relative to the previous release',
	},
	relativeToPreviousReleaseMethod: {
		id: 'portfolio-3-portfolio.common.relative-to-previous-release-method',
		defaultMessage: 'Relative to previous release date',
		description:
			'It describes that the release is set to be started relative to the previous release',
	},
	requiredField: {
		id: 'portfolio-3-portfolio.common.required-field',
		defaultMessage: 'A value must be provided',
		description: 'This message is used when the form field does not have a value',
	},
	release: {
		id: 'portfolio-3-portfolio.common.release',
		defaultMessage: 'Release',
		description: 'This label is a header for release column.',
	},
	releases: {
		id: 'portfolio-3-portfolio.common.releases',
		defaultMessage: 'Releases',
		description: 'This label is used to indicate multiple releases.',
	},
	remove: {
		id: 'portfolio-3-portfolio.common.remove',
		defaultMessage: 'Remove',
		description: 'It is a label for a remove button.',
	},
	save: {
		id: 'portfolio-3-portfolio.common.save',
		defaultMessage: 'Save',
		description: 'It is a label for a save button.',
	},
	done: {
		id: 'portfolio-3-portfolio.common.done',
		defaultMessage: 'Done',
		description: 'It is a label for a done button',
	},
	sprint: {
		id: 'portfolio-3-portfolio.common.sprint',
		defaultMessage: 'Sprint',
		description: 'The label is used to indicate sprint.',
	},
	sprints: {
		id: 'portfolio-3-portfolio.common.sprints',
		defaultMessage: 'Sprints',
		description: 'The label is used to indicate multiple sprints.',
	},
	status: {
		id: 'portfolio-3-portfolio.common.status',
		defaultMessage: 'Status',
		description: 'The label indicating a status of any entity.',
	},
	statusBreakdown: {
		id: 'portfolio-3-portfolio.common.status-breakdown',
		defaultMessage: 'Progress (issue count)',
		description: 'The label indicating a status breakdown of any entity.',
	},
	team: {
		id: 'portfolio-3-portfolio.common.team',
		defaultMessage: 'Team',
		description: 'The label is used to indicate team.',
	},
	teams: {
		id: 'portfolio-3-portfolio.common.teams',
		defaultMessage: 'Teams',
		description: 'The label is used to indicate multiple teams.',
	},
	unassigned: {
		id: 'portfolio-3-portfolio.common.unassigned',
		defaultMessage: 'Unassigned',
		description: 'This label indicates issues are not assigned specific attribute value.',
	},
	viewIn: {
		id: 'portfolio-3-portfolio.common.view-in',
		defaultMessage: 'View in',
		description: 'This label indicates things to view in.',
	},
	weeks: {
		id: 'portfolio-3-portfolio.common.weeks',
		defaultMessage: 'Weeks',
		description: 'It describes that the unit where the start date of a release falls.',
	},
	whenPreviousReleaseEnds: {
		id: 'portfolio-3-portfolio.common.when-previous-release-ends',
		defaultMessage: 'When previous release ends',
		description:
			'It describes that the release is set to be started immediately when previous release ends',
	},
	targetStart: {
		id: 'portfolio-3-portfolio.common.target-start',
		defaultMessage: 'Target start',
		description: 'The label for the targetStart field',
	},
	targetEnd: {
		id: 'portfolio-3-portfolio.common.target-end',
		defaultMessage: 'Target end',
		description: 'The label for the targetEnd field',
	},
	invalidDateStartBeforeEnd: {
		id: 'portfolio-3-portfolio.common.invalid-date-start-before-end',
		defaultMessage: 'The start date of the issue must be before its end date.',
		description:
			'Warning flag message when user selects an invalid date (target start after target end)',
	},
	invalidDateEndAfterStart: {
		id: 'portfolio-3-portfolio.common.invalid-date-end-after-start',
		defaultMessage: 'The end date of the issue must be after its start date.',
		description:
			'Warning flag message when user selects an invalid date (target end after target start)',
	},
	invalidDateTitle: {
		id: 'portfolio-3-portfolio.common.invalid-date-title',
		defaultMessage: "That didn't work",
		description: 'Title for warning flag that appears when use selects an invalid date',
	},
	disabledSortByPriorityTooltip: {
		id: 'portfolio-3-portfolio.common.disabled-sort-by-priority-tooltip',
		defaultMessage:
			'To sort issues by priority, the projects in the plan must be using the same priority scheme.',
		description: 'Tooltip message for sort by priority buttons when sort is not available',
	},
	onTrackRelease: {
		id: 'portfolio-3-portfolio.common.on-track-release',
		defaultMessage: 'On-Track',
		description:
			'The label used when a release is still on track with regards to its release date. It should be translated as a feminine singular label.',
	},
	offTrackRelease: {
		id: 'portfolio-3-portfolio.common.off-track-release',
		defaultMessage: 'Off-Track',
		description:
			'The label used when a release is overdue with regards to its release date. It should be translated as a feminine singular label.',
	},
	releasedRelease: {
		id: 'portfolio-3-portfolio.common.released-release',
		defaultMessage: 'Released',
		description:
			'The label used when a release has been released. It should be translated as a feminine singular label.',
	},
	unReleasedRelease: {
		id: 'portfolio-3-portfolio.common.un-released-release',
		defaultMessage: 'Unreleased',
		description:
			'The label used when a release has not been released yet. It should be translated as a feminine singular label.',
	},
	dependency: {
		id: 'portfolio-3-portfolio.common.dependency',
		defaultMessage: 'Dependency',
		description: 'The label for dependency legends',
	},
	dependencyWarning: {
		id: 'portfolio-3-portfolio.common.dependency-warning',
		defaultMessage: 'Dependency warning',
		description: 'The label for dependency warning legends',
	},
	dependencyOffTrack: {
		id: 'portfolio-3-portfolio.common.dependency-off-track',
		defaultMessage: 'Off-track dependencies',
		description: 'The label for dependency off-track legends',
	},
	unsavedChanges: {
		id: 'portfolio-3-portfolio.common.unsaved-changes',
		defaultMessage: 'Unsaved changes',
		description: 'Unsaved changes indicator label',
	},
	externalLozenge: {
		id: 'portfolio-3-portfolio.common.external-lozenge',
		defaultMessage: 'EXT',
		description: 'Lozenge text for the external team or sprint',
	},
	datesOutsideRange: {
		id: 'portfolio-3-portfolio.common.dates-outside-range',
		defaultMessage: 'Dates outside current view range',
		description: 'The description for dates outside range icons',
	},
	teamSettings: {
		id: 'portfolio-3-portfolio.common.team-settings',
		defaultMessage: 'Team settings',
		description:
			'Label used on buttons / dialog headers where the user can change the settings of a team',
	},
	scope: {
		id: 'portfolio-3-portfolio.common.scope',
		defaultMessage: 'Scope',
		description: 'Heading of the "Scope" section of the roadmap',
	},
	fields: {
		id: 'portfolio-3-portfolio.common.fields',
		defaultMessage: 'Fields',
		description: 'The label for fields',
	},
	timeline: {
		id: 'portfolio-3-portfolio.common.timeline',
		defaultMessage: 'Timeline',
		description: 'Heading of the "Timeline" section of the roadmap',
	},
	issueSource: {
		id: 'portfolio-3-portfolio.common.issue-source',
		defaultMessage: 'Issue source',
		description: 'Label for issue source field',
	},
	customFields: {
		id: 'portfolio-3-portfolio.common.custom-fields',
		defaultMessage: 'Custom fields',
		description:
			'Label used as a heading in a dropdown menu to render custom fields in its own group. This label is always written in plural.',
	},
	more: {
		id: 'portfolio-3-portfolio.common.more',
		defaultMessage: '+ {count} more',
		description:
			'Affix for selected releases to show how many more releases are selected but not visible',
	},
	noAssociatedTeams: {
		id: 'portfolio-3-portfolio.common.no-associated-teams',
		defaultMessage: 'No associated teams',
		description: 'Label for sprints group title without teams',
	},
	otherSprints: {
		id: 'portfolio-3-portfolio.common.other-sprints',
		defaultMessage: 'Other sprints',
		description: 'Header text for other sprints group',
	},
	futureProjectedSprint: {
		id: 'portfolio-3-portfolio.common.future-projected-sprint',
		defaultMessage: 'Projected sprint',
		description: 'Label for projected sprints',
	},
	futureSprint: {
		id: 'portfolio-3-portfolio.common.future-sprint',
		defaultMessage: 'Future sprint',
		description: 'Label for future sprints',
	},
	completedSprint: {
		id: 'portfolio-3-portfolio.common.completed-sprint',
		defaultMessage: 'Completed sprint',
		description: 'Label for completed sprints',
	},
	activeSprint: {
		id: 'portfolio-3-portfolio.common.active-sprint',
		defaultMessage: 'Active sprint',
		description: 'Label for active sprints',
	},
	futureSprintState: {
		id: 'portfolio-3-portfolio.common.future-sprint-state',
		defaultMessage: 'Future',
		description: 'Label for future sprints',
	},
	completedSprintState: {
		id: 'portfolio-3-portfolio.common.completed-sprint-state',
		defaultMessage: 'Completed',
		description: 'Label for completed sprints',
	},
	activeSprintState: {
		id: 'portfolio-3-portfolio.common.active-sprint-state',
		defaultMessage: 'Active',
		description: 'Label for active sprints',
	},
	externalSprintState: {
		id: 'portfolio-3-portfolio.common.external-sprint-state',
		defaultMessage: 'external',
		description: 'external sprint lozenge text',
	},
	completedSprintsLabel: {
		id: 'portfolio-3-portfolio.common.completed-sprints-label',
		defaultMessage: 'All completed sprints',
		description: 'This is a label for completed sprints quick selection in sprint filter',
	},
	activeSprintsLabel: {
		id: 'portfolio-3-portfolio.common.active-sprints-label',
		defaultMessage: 'All active sprints',
		description: 'This is a label for active sprints quick selection in sprint filter',
	},
	futureSprintsLabel: {
		id: 'portfolio-3-portfolio.common.future-sprints-label',
		defaultMessage: 'All future sprints',
		description: 'This is a label for future sprints quick selection in sprint filter',
	},
	externalSprintsLabel: {
		id: 'portfolio-3-portfolio.common.external-sprints-label',
		defaultMessage: 'All external sprints',
		description: 'This is a label for external sprints quick selection in sprint filter',
	},
	noSprintLabel: {
		id: 'portfolio-3-portfolio.common.no-sprint-label',
		defaultMessage: 'No sprint assigned',
		description: 'The label for the "No sprint assigned" option in the sprint filter',
	},
	moreInformation: {
		id: 'portfolio-3-portfolio.common.more-information',
		defaultMessage: 'More information',
		description: 'The label for a "More information" icon / icon button',
	},
	afterAllIssuesAreCompletedIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.after-all-issues-are-completed-issue-term-refresh',
		defaultMessage: 'After all issues are completed',
		description:
			'It describes that the release is set to be released after all issues are completed',
	},
	allOtherIssuesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.all-other-issues-issue-term-refresh',
		defaultMessage: 'All other issues',
		description:
			'This label is used in color by and group by to represent all other issues that excluded from coloring and grouping',
	},
	changeIndicatorTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.change-indicator-tooltip-issue-term-refresh',
		defaultMessage: 'To apply issue changes beyond your plan, review and save changes.',
		description:
			'It is a text shown in the tooltip of change indicator which has orange triangle shape in each cell of the scope table.',
	},
	chooseConfigurableDatesUnavailableContentStartDateIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.choose-configurable-dates-unavailable-content-start-date-issue-term-refresh',
		defaultMessage:
			'<strong>{startDateTitle}</strong> is no longer available for scheduling issues. Use other custom start dates, or keep default start dates.',
		description:
			'Informs the user that the field used for the start date is no longer available, and that the start date has been defaulted to another field',
	},
	chooseConfigurableDatesUnavailableContentEndDateIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.choose-configurable-dates-unavailable-content-end-date-issue-term-refresh',
		defaultMessage:
			'<strong>{endDateTitle}</strong> is no longer available for scheduling issues. Use other custom end dates, or keep default end dates.',
		description:
			'Informs the user that the field used for the end date is no longer available, and that the end date has been defaulted to another field',
	},
	chooseConfigurableDatesUnavailableContentStartAndEndDateIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.choose-configurable-dates-unavailable-content-start-and-end-date-issue-term-refresh',
		defaultMessage:
			'<strong>{startDateTitle}</strong> and <strong>{endDateTitle}</strong> are no longer available for scheduling issues. Use other custom dates, or keep default dates.',
		description:
			'Informs the user that the fields used for the start and end dates are both no longer available, and that the start and end dates have been defaulted to other fields',
	},
	chooseConfigurableDatesUnavailableContentStartDateTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.choose-configurable-dates-unavailable-content-start-date-tooltip-issue-term-refresh',
		defaultMessage:
			'{startDateTitle} is no longer available for scheduling issues. Use other custom start dates, or keep default start dates.',
		description:
			'Tooltip that informs the user that the field used for the start date is no longer available, and that the start date has been defaulted to another field',
	},
	chooseConfigurableDatesUnavailableContentEndDateTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.choose-configurable-dates-unavailable-content-end-date-tooltip-issue-term-refresh',
		defaultMessage:
			'{endDateTitle} is no longer available for scheduling issues. Use other custom end dates, or keep default end dates.',
		description:
			'Tooltip that informs the user that the field used for the end date is no longer available, and that the end date has been defaulted to another field',
	},
	chooseConfigurableDateNotExistContentIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.choose-configurable-date-not-exist-content-issue-term-refresh',
		defaultMessage: `The previously used custom {count, plural,
                one {date is}
                other {dates are}
              } no longer available for scheduling issues. Use other custom dates, or keep using default dates.`,
		description:
			'Lets the user know the value has been defaulted because the original values no longer exists',
	},
	issueTypeIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.issue-type-issue-term-refresh',
		defaultMessage: 'Issue type',
		description: 'Used throughout the app to label "Issue type" fields',
	},
	issueLinkTypeIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.issue-link-type-issue-term-refresh',
		defaultMessage: 'Issue link type',
		description: 'Used throughout the app to label "Issue link type" fields',
	},
	loadingIssuesMessageIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.loading-issues-message-issue-term-refresh',
		defaultMessage: 'Loading issues...',
		description: 'Message to be shown when issues are being loaded',
	},
	statusBreakdownIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.status-breakdown-issue-term-refresh',
		defaultMessage: 'Progress (issue count)',
		description: 'The label indicating a status breakdown of any entity.',
	},
	invalidDateStartBeforeEndIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.invalid-date-start-before-end-issue-term-refresh',
		defaultMessage: 'The start date of the issue must be before its end date.',
		description:
			'Warning flag message when user selects an invalid date (target start after target end)',
	},
	invalidDateEndAfterStartIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.invalid-date-end-after-start-issue-term-refresh',
		defaultMessage: 'The end date of the issue must be after its start date.',
		description:
			'Warning flag message when user selects an invalid date (target end after target start)',
	},
	disabledSortByPriorityTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.disabled-sort-by-priority-tooltip-issue-term-refresh',
		defaultMessage:
			'To sort issues by priority, the projects in the plan must be using the same priority scheme.',
		description: 'Tooltip message for sort by priority buttons when sort is not available',
	},
	issueSourceIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.issue-source-issue-term-refresh',
		defaultMessage: 'Issue source',
		description: 'Label for issue source field',
	},
});
