import React, { type ReactNode } from 'react';
import { Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	MODAL,
	fireUIAnalytics,
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useCttOnboardingTour } from '../../../controllers/ctt-onboarding/index.tsx';
import type { CttOnboardingVariantType } from '../../../controllers/ctt-onboarding/types.tsx';
import { CttSpotlights } from '../../../controllers/ctt-spotlights/index.tsx';
import { messages } from './messages.tsx';

interface Props {
	variant: CttOnboardingVariantType;
}

export const ReviewChangesSpotlight = ({ variant }: Props) => {
	const { formatMessage } = useIntl();
	const [, { nextCttStage }] = useCttOnboardingTour();

	const renderReviewChangesSpotlightBody = () => (
		<Text as="p">
			{formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.spotlightUnsavedChangesBodyIssueTermRefresh
					: messages.spotlightUnsavedChangesBody,
				{
					b: (chunks: ReactNode[]) => (
						<Text color="inherit" weight="bold">
							{chunks}
						</Text>
					),
				},
			)}
		</Text>
	);

	return (
		<ContextualAnalyticsData sourceName="topLevelPlanningReviewChangesSpotlight" sourceType={MODAL}>
			<JiraSpotlight
				actions={[
					{
						text: formatMessage(messages.spotlightGotIt),
						onClick: (_, analyticsEvent) => {
							fireUIAnalytics(analyticsEvent, 'topLevelPlanningReviewChangesGotIt', {
								isReparentingSkipped:
									variant === 'TMP_EPIC' || variant === 'NO_EPIC' || variant === 'NO_INITIATIVE',
							});
							nextCttStage();
						},
					},
				]}
				heading={formatMessage(messages.spotlightUnsavedChangesTitle)}
				target={CttSpotlights.ReviewChanges}
				key={CttSpotlights.ReviewChanges}
				targetBgColor={token('elevation.surface', colors.N0)}
				targetRadius={3}
				messageId="portfolio-3-onboarding.ui.ctt-onboarding.spotlight-review-changes.jira-spotlight"
				messageType="engagement"
			>
				{renderReviewChangesSpotlightBody()}
			</JiraSpotlight>
			<FireScreenAnalytics
				attributes={{
					variant,
				}}
			/>
		</ContextualAnalyticsData>
	);
};
