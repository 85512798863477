import React, { Component, createRef } from 'react';
import {
	DatePicker as AkDatePicker,
	type DateTimePickerSelectProps,
} from '@atlaskit/datetime-picker';
import { layers } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';
import { standardizeLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { getLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { formatDateWithIntl } from '../date-manipulation/format.tsx';
import { injectMouseOverStyles } from '../select/index.tsx';
import type { Props, State } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class DatePickerComponent extends Component<Props, State> {
	static defaultProps = {
		isTransparentBackground: true,
		isDisabled: false,
		isActiveOnFirstRender: false,
		showClearIndicator: false,
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any, react/sort-comp
	selectRef = createRef<any>();

	// AtlasKit DatePicker fails to blur when the user clicks on another dropdown.
	// We need a reference to the AtlasKit DatePicker component to force `onSelectBlur`
	// when the user clicks outside of the datepicker.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	datePickerRef = createRef<any>();

	onWindowClick = (e: MouseEvent) => {
		const datePicker = this.datePickerRef.current;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		const node: Node = e.target as any;
		if (
			datePicker &&
			datePicker.state.isOpen &&
			!datePicker.containerRef.contains(node) &&
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.body &&
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.body.contains(node)
		) {
			datePicker.onSelectBlur(e);
		}
	};

	componentDidMount() {
		if (this.datePickerRef && fg('plan-timeline-non-transposed')) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('click', this.onWindowClick);
		}
	}

	componentWillUnmount() {
		if (fg('plan-timeline-non-transposed')) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('click', this.onWindowClick);
		}
	}

	render() {
		const {
			isTransparentBackground,
			onToggle,
			onChange,
			isDisabled,
			isActiveOnFirstRender,
			showClearIndicator,
			styles,
			placeholder,
			weekStartDay,
			formatOption,
			locale,
			intl,
			shouldRenderToParent = false,
			...props
		} = this.props;
		const selectProps: DateTimePickerSelectProps = {
			ref: this.selectRef,
			// This helps to avoid expensive built-in AutosizeInput on the first render as we anyway
			// re-render after Select is mounted and its components get available for extension.
			isSearchable: isActiveOnFirstRender,
			styles: {
				...styles,
				// eslint-disable-next-line @typescript-eslint/no-shadow
				menuPortal: (styles: object) => ({
					...styles,
					...(!fg('plan-timeline-non-transposed')
						? {
								zIndex: layers.modal(),
								marginTop: `calc(${token('space.negative.100', '-8px')} - ${token('space.400', '32px')})`,
							}
						: {}),
				}),
				// eslint-disable-next-line @typescript-eslint/no-shadow
				control: (styles: object, state: { isFocused: boolean }) =>
					injectMouseOverStyles(styles, true, state.isFocused, !!isTransparentBackground),
			},

			...(fg('plan-timeline-non-transposed') || shouldRenderToParent
				? {} // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				: { menuPortalTarget: document.body }),
		};

		const appearance = isTransparentBackground ? 'subtle' : 'default';
		// Empty string evaluates as false inside AtlasKit, and makes the datepicker show 2/18/1983 as the placeholder string.
		// In order to avoid this, we turn any empty strings into ' '.
		const placeholderString = isDisabled || placeholder === '' ? ' ' : placeholder;

		return (
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore Temporary incompatible selectProps type ('as any' workaround) until @atlaskit/select is upgraded
			<AkDatePicker
				{...props}
				hideIcon={props.hideIcon || !showClearIndicator}
				isDisabled={isDisabled}
				onFocus={() => {
					onToggle && onToggle(true);
				}}
				onBlur={() => {
					onToggle && onToggle(false);
				}}
				appearance={appearance}
				onChange={onChange}
				selectProps={selectProps}
				ref={this.datePickerRef}
				placeholder={placeholderString}
				weekStartDay={weekStartDay ?? 0}
				locale={locale && standardizeLocale(locale)}
				formatDisplayLabel={
					intl ? (date: string) => formatDateWithIntl(intl, date, formatOption) : undefined
				}
			/>
		);
	}
}

export default function DatePicker(props: Props) {
	const intl = useIntl();
	const locale = useLocale();
	const weekStartDay = getLocale(locale)?.options?.weekStartsOn || 0;

	return <DatePickerComponent {...props} weekStartDay={weekStartDay} intl={intl} locale={locale} />;
}
