import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noMatchingIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.no-issues-in-filter-empty-view.no-matching-issues',
		defaultMessage: 'No matching issues found.',
		description: 'Message to be shown in scope table when there are no issues in the plan',
	},
	adjustFilter: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.no-issues-in-filter-empty-view.adjust-filter',
		defaultMessage: 'Adjust your filters and try again.',
		description: 'Message to be shown in scope table when there are no issues in the plan',
	},
	noMatchingIssuesIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.no-issues-in-filter-empty-view.no-matching-issues-issue-term-refresh',
		defaultMessage: 'No matching issues found.',
		description: 'Message to be shown in scope table when there are no issues in the plan',
	},
});
