import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noMatchesText: {
		id: 'portfolio-3-portfolio.common.select.no-matches-text',
		defaultMessage: 'No matches',
		description: 'It is a message when none of the options match the search term.',
	},
	rolledUpValueLabel: {
		id: 'portfolio-3-portfolio.common.select.rolled-up-value-label',
		defaultMessage: 'Rolled-up values',
		description: 'The label for the rolled up values',
	},
	releaseActualValueLabel: {
		id: 'portfolio-3-portfolio.common.select.release-actual-value-label',
		defaultMessage: 'Release of this issue',
		description: 'Tooltip text for the release value of the issue',
	},
	sprintActualValueLabel: {
		id: 'portfolio-3-portfolio.common.select.sprint-actual-value-label',
		defaultMessage: 'Sprint of this issue',
		description: 'Tooltip text for the sprint value of the issue',
	},
	teamActualValueLabel: {
		id: 'portfolio-3-portfolio.common.select.team-actual-value-label',
		defaultMessage: 'Team of this issue',
		description: 'Tooltip text for the team value of the issue',
	},
	releaseActualValueLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.select.release-actual-value-label-issue-term-refresh',
		defaultMessage: 'Release of this issue',
		description: 'Tooltip text for the release value of the issue',
	},
	sprintActualValueLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.select.sprint-actual-value-label-issue-term-refresh',
		defaultMessage: 'Sprint of this issue',
		description: 'Tooltip text for the sprint value of the issue',
	},
	teamActualValueLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.select.team-actual-value-label-issue-term-refresh',
		defaultMessage: 'Team of this issue',
		description: 'Tooltip text for the team value of the issue',
	},
});
