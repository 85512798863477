import { createShareReportUrl } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/share-report/index.tsx';
import { UPDATE_SHARE_AS } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/share-report/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import { SHARE_AS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	createShareURL: () => {
		dispatch({
			type: UPDATE_SHARE_AS,
			payload: {
				shareAs: SHARE_AS.EMBED,
			},
		});
		dispatch(createShareReportUrl());
	},
});

export default mapDispatchToProps;
