import React, { useState, type KeyboardEvent, type MouseEvent, useCallback, memo } from 'react';
import map from 'lodash/map';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import StandardButton from '@atlaskit/button/standard-button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import DropMenu, { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-common/src/api/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { openInNewTab } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import BulkActionsMenu from './bulk-actions-menu/index.tsx';
import UpdateAttributeForm from './dialog/common/update-attribute-form/view.tsx';
import messages from './messages.tsx';
import {
	type Props,
	type DropOption,
	type GetDropOption,
	REMOVE_FROM_PLAN,
	SET_DATES,
	BULK_CHANGE,
} from './types.tsx';
import { type MapKey, DROPMENU_MAP } from './utils.tsx';

const DROPMENU_ITEMS = [
	'setAssignee',
	'dates',
	'setEstimate',
	'setLabel',
	'setParent',
	'setRank',
	'setReleases',
	'setSprint',
	'setTeam',
] // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	.map((key) => DROPMENU_MAP[key as MapKey]);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const MORE_DROPMENU_ITEMS = ['bulkChange', 'removeFromPlanTitle'].map(
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(key) => DROPMENU_MAP[key as MapKey],
) as GetDropOption[];

export const BulkActions = memo(({ selectedIssues, projectsById, ...props }: Props) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [optionSelected, setOptionSelected] = useState<DropOption | null>(null);

	const { formatMessage } = useIntl();

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const toggleModalDialog = useCallback((isDialogOpen: boolean) => {
		setIsDialogOpen(isDialogOpen);
	}, []);

	const buildUrl = useCallback((): string => {
		const issueKeys = map(selectedIssues, ({ project: projectId, issueKey }) => {
			const project = projectsById[projectId];
			if (!issueKey) {
				return undefined;
			}
			return `${project.key}-${issueKey}`;
		});

		const encodedIds = encodeURIComponent(issueKeys.filter((key) => key).toString());

		return proxyContextSafeUrl(`/secure/GHBulkChange.jspa?issueKeys=${encodedIds}`);
	}, [projectsById, selectedIssues]);

	const sendIDtoJira = useCallback(() => {
		openInNewTab(buildUrl());
		// eslint-disable-next-line @typescript-eslint/no-shadow
		setIsMenuOpen((isMenuOpen) => !isMenuOpen);
	}, [buildUrl]);

	const selectOption = useCallback(
		(dropOption: DropOption, analyticsEvent: UIAnalyticsEvent) => {
			if (analyticsEvent) {
				const [actionSubject, eventAction] =
					PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_OPTION_SELECTED.split(' ');
				fireUIAnalytics(
					analyticsEvent.update({ action: eventAction, actionSubject }),
					PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_OPTION_SELECTED,
					{
						selectedOption: dropOption.analyticsId,
					},
				);
			}

			if (dropOption.type === BULK_CHANGE) {
				sendIDtoJira();
				return;
			}

			setIsMenuOpen(false);
			setIsDialogOpen(true);
			setOptionSelected(dropOption);
		},
		[sendIDtoJira],
	);

	const renderOneItem = useCallback(
		(getDropOption: GetDropOption) => {
			const option = getDropOption({
				selectedIssues,
				projectsById,
				...props,
			});
			const { label, disabled, analyticsId, disabledTooltip } = option;
			const title = formatMessage(label);
			const tooltip =
				isDefined(disabled) && disabled && disabledTooltip ? formatMessage(disabledTooltip) : '';

			return (
				<Tooltip content={tooltip} key={title} position="bottom">
					<DropMenuItem
						testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.drop-menu-item.${analyticsId}`}
						onClick={(_: MouseEvent | KeyboardEvent, analyticsEvent: UIAnalyticsEvent) =>
							selectOption(option, analyticsEvent)
						}
						isDisabled={disabled}
					>
						{title}
					</DropMenuItem>
				</Tooltip>
			);
		},
		[formatMessage, projectsById, selectedIssues, selectOption, props],
	);

	const toggleMenu = useCallback(
		(_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
			if (analyticsEvent) {
				const [actionSubject, eventAction] =
					PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_CLICKED.split(' ');
				fireUIAnalytics(
					analyticsEvent.update({ action: eventAction, actionSubject }),
					PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_CLICKED,
					{
						selectedIssues: selectedIssues.length,
						isMenuOpen: !isMenuOpen,
					},
				);
			}
			// eslint-disable-next-line @typescript-eslint/no-shadow
			setIsMenuOpen((isMenuOpen) => !isMenuOpen);
		},
		[selectedIssues, isMenuOpen],
	);

	const fireBulkActionSuccessAnalytics = useCallback(
		(analyticsEvent: UIAnalyticsEvent, selectedOption: string) => {
			if (analyticsEvent) {
				const [actionSubject, eventAction] =
					PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_ACTION_SUCCEEDED.split(' ');
				fireUIAnalytics(
					analyticsEvent.update({ action: eventAction, actionSubject }),
					PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_ACTION_SUCCEEDED,
					{
						selectedOption,
						selectedIssues: selectedIssues.length,
					},
				);
			}
		},
		[selectedIssues.length],
	);

	const renderDialog = useCallback(() => {
		const Dialog = optionSelected && optionSelected.dialog;
		if (!isDialogOpen || !optionSelected || !Dialog) {
			return null;
		}

		switch (optionSelected.type) {
			case SET_DATES:
			case REMOVE_FROM_PLAN: {
				return (
					<Dialog
						toggleModalDialog={() => toggleModalDialog(!isDialogOpen)}
						bulkActionSuccess={(analyticsEvent: UIAnalyticsEvent) =>
							fireBulkActionSuccessAnalytics(analyticsEvent, optionSelected.analyticsId)
						}
					/>
				);
			}
			default: {
				return (
					<Dialog
						toggleModalDialog={() => toggleModalDialog(!isDialogOpen)}
						UpdateAttributeForm={UpdateAttributeForm}
						bulkActionSuccess={(analyticsEvent: UIAnalyticsEvent) =>
							fireBulkActionSuccessAnalytics(analyticsEvent, optionSelected.analyticsId)
						}
					/>
				);
			}
		}
	}, [fireBulkActionSuccessAnalytics, isDialogOpen, optionSelected, toggleModalDialog]);

	const onOpenChange = ({ isOpen }: { isOpen: boolean }) => {
		if (!isOpen) {
			setIsMenuOpen(false);
		}
	};

	return (
		<>
			<DropMenu
				isOpen={isMenuOpen}
				onOpenChange={onOpenChange}
				placement="bottom-end"
				shouldRenderToParent={!fg('plan-timeline-non-transposed') ? false : undefined}
				// eslint-disable-next-line @typescript-eslint/no-shadow
				trigger={({ triggerRef, ...props }) => (
					<StandardButton
						{...props}
						iconAfter={<ChevronDownIcon label="" />}
						onClick={toggleMenu}
						ref={triggerRef}
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions"
					>
						{formatMessage(messages.bulkActions)}
					</StandardButton>
				)}
			>
				<BulkActionsMenu
					dropMenuItems={DROPMENU_ITEMS.map(renderOneItem)}
					moreDropMenuItems={MORE_DROPMENU_ITEMS.map(renderOneItem)}
				/>
			</DropMenu>
			{renderDialog()}
		</>
	);
});

export default BulkActions;
