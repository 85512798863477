import React, { useCallback, type ReactNode } from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import { useZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import type { EnrichedVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import type { EnrichedCrossProjectVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/cross-project-versions/types.tsx';
import type { Mode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/timeline/release-bar/types.tsx';
import TimelineRangeSubscriber from '../../horizontal-scrolling/timeline-range-subscriber/index.tsx';
import ReleaseMarker from './marker/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type {
	TimelineRange,
	RenderMarkerProps,
	Props,
	VersionIdsMapByDate,
	VersionsGroupByDateObject,
} from './types.tsx';
import { calculateEndVersionsDate } from './utils.tsx';

export default function ReleaseBar({
	openFlyout,
	closeFlyout,
	releaseBarState,
	crossProjectVersions,
	mode,
	solutionVersionsById,
	versions,
	renderMarker,
	width,
}: Props) {
	const [zoomLevel, { scrollToView }] = useZoomLevel();
	const { isFlyoutOpen, versionIds } = releaseBarState;

	const toggleFlyout = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		(versionIds: string[], type: Mode | undefined) => {
			if (isFlyoutOpen) {
				closeFlyout();
			} else if (type !== undefined) {
				openFlyout(versionIds, type);
			}
		},
		[openFlyout, closeFlyout, isFlyoutOpen],
	);

	const handleScrollToViewRelease = useCallback(
		({ timestamp }: { timestamp: number }) => {
			if (zoomLevel === undefined) {
				return;
			}
			scrollToView({ timestamp }, 'releaseNavButton');
		},
		[zoomLevel, scrollToView],
	);

	const finalRenderMarker: (arg1: RenderMarkerProps, arg2: number | undefined) => ReactNode =
		renderMarker ??
		// eslint-disable-next-line @typescript-eslint/no-shadow
		(({ mode, date, versionIdsMapByDate }, index = 0) => {
			const showFlyout =
				isFlyoutOpen && versionIdsMapByDate[date].some((id) => versionIds.includes(id));

			return (
				<ReleaseMarker
					key={date}
					mode={mode}
					endDate={date}
					scrollToViewRelease={handleScrollToViewRelease}
					totalDays={Object.keys(versionIdsMapByDate).length}
					currentDayIndex={index + 1}
					versionIdsMapByDate={versionIdsMapByDate}
					showFlyout={showFlyout}
					toggleFlyout={toggleFlyout}
					width={width}
				/>
			);
		});

	// Returns an object that maps release dates to ids of releases that fall on that day.
	// Loop through all versions/CPRs, returning the end date if it is defined and falls within
	// the current timeline range. We want unique end dates so we group by the end date value.
	// Undefined end dates or those outside the date range are omitted from the list
	// For each end date, we loop through each version and return only the id of that version.
	const getVersionIdsMapByDate = (timelineRange: TimelineRange) =>
		R.pipe(
			R.groupBy((version: EnrichedVersion | EnrichedCrossProjectVersion) => {
				const end = calculateEndVersionsDate(version, solutionVersionsById, mode);
				if (end && end >= timelineRange.start && end <= timelineRange.end) {
					return end.toString(); // Cast to string as object keys cannot be numbers
				}
				// We want to use reduceBy, but our current Ramda version does not support this.
				// groupBy must return a string type and cannot return undefined
				return 'undefined';
			}),
			R.omit(['undefined']),
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			R.map(R.map(R.prop('id'))) as unknown as (
				value: VersionsGroupByDateObject,
			) => VersionIdsMapByDate,
		)([...versions, ...crossProjectVersions]);

	const wrap = renderMarker
		? (children: ReactNode) => <>{children}</>
		: (children: ReactNode) => (
				<div
					data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.release-bar.div"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={cx([styles.main, !fg('plan-timeline-non-transposed') && styles.transposed])}
				>
					{children}
				</div>
			);

	return wrap(
		<TimelineRangeSubscriber>
			{(timelineRange) => {
				const withinRange = getVersionIdsMapByDate(timelineRange);
				const all =
					zoomLevel === undefined
						? withinRange
						: getVersionIdsMapByDate({
								start: -Infinity,
								end: Infinity,
							});

				const nodes = Object.keys(all)
					.sort((a, b) => Number(a) - Number(b))
					.reduce<ReactNode[]>((acc, date, index) => {
						// We only want to render the releases within the rendering window
						if (!(date in withinRange)) {
							return acc;
						}

						const markerEl = finalRenderMarker(
							{
								mode,
								date,
								versionIdsMapByDate: all,
							},
							index,
						);

						acc.push(markerEl);

						return acc;
					}, []);
				return <>{nodes}</>;
			}}
		</TimelineRangeSubscriber>,
	);
}
