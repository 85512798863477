import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps from './query.tsx';
import ViewOld from './view-old.tsx';
import View from './view.tsx';

export default componentWithFG(
	'migrate_plan_filter_to_popup',
	connect(mapStateToProps, mapDispatchToProps)(View),
	connect(mapStateToProps, mapDispatchToProps)(ViewOld),
);
