import isNil from 'lodash/isNil';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	UNASSIGNED_GROUP,
	UNDEFINED_GROUP,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import type { EnrichedVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import type { GroupCombination } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { GROUPED, type Option, type OptionGroup } from '../types.tsx';
import { optionGroupSortComparator, optionSortComparator } from '../utils.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ReleaseOptionsProvider = ({
	groups,
	enrichedVersions,
	enrichedVersionsById,
	children,
}: Props) => {
	const { formatMessage } = useIntl();

	const createOptionFromGroup = (group: GroupOption): Option<GroupCombination> => ({
		id: group.group,
		name: group.groupName,
		value: { release: group.groupCombination?.release },
	});

	const createOptionFromRelease = (release: EnrichedVersion): Option<GroupCombination> => ({
		id: `release-${release.id}`,
		name: release.name,
		value: { release: release.id },
	});

	const getOptionsFromReleasesByProject = (): OptionGroup<GroupCombination>[] => {
		const projectToOptionsMap: Record<string, OptionGroup<GroupCombination>> = fg(
			'plan_timeline_issue_create_unassigned_release_fix',
		)
			? {
					[UNDEFINED_GROUP]: {
						id: UNDEFINED_GROUP,
						name: formatMessage(commonMessages.unassigned),
						sortValue: -1,
						options: [
							{
								id: UNDEFINED_GROUP,
								name: formatMessage(commonMessages.unassigned),
								value: { release: null },
							},
						],
					},
				}
			: {
					[UNASSIGNED_GROUP]: {
						id: UNASSIGNED_GROUP,
						name: formatMessage(commonMessages.unassigned),
						sortValue: -1,
						options: [
							{
								id: UNASSIGNED_GROUP,
								name: formatMessage(commonMessages.unassigned),
								value: { release: null },
							},
						],
					},
				};
		const processedReleaseIds: Set<string> = new Set();

		groups.forEach((group) => {
			if (isNil(group.groupCombination) || isNil(group.groupCombination.release)) return;

			const enrichedVersion = enrichedVersionsById[group.groupCombination.release];
			processedReleaseIds.add(group.groupCombination.release);

			const option = createOptionFromGroup(group);

			if (enrichedVersion === undefined) return;

			const project = enrichedVersion.projects[0];

			if (typeof project === 'number') return;

			const projectId = project.id;
			const projectName = project.name;
			const existingGroup = projectToOptionsMap[projectId] ?? {
				id: `project-${projectId}`,
				name: projectName,
				options: [],
			};

			existingGroup.options.push(option);
			projectToOptionsMap[projectId] = existingGroup;
		});

		enrichedVersions.forEach((release) => {
			if (processedReleaseIds.has(release.id)) return;

			const option = createOptionFromRelease(release);

			const project = release.projects[0];

			if (typeof project === 'number') return;

			const projectId = project.id;
			const projectName = project.name;
			const existingGroup = projectToOptionsMap[projectId] ?? {
				id: `project-${projectId}`,
				name: projectName,
				options: [],
			};

			existingGroup.options.push(option);
			projectToOptionsMap[projectId] = existingGroup;
		});

		const optionGroups = Object.values(projectToOptionsMap);

		optionGroups.forEach((group) => group.options.sort(optionSortComparator));
		optionGroups.sort(optionGroupSortComparator);

		return optionGroups;
	};

	return children({
		options: {
			type: GROUPED,
			groups: getOptionsFromReleasesByProject(),
		},
		menuTitle: formatMessage(messages.releaseMenuTitle),
		searchPlaceholder: formatMessage(messages.releaseSearchPlaceholder),
	});
};

export default ReleaseOptionsProvider;
