import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueSelectorPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-dependency.dependency-selector.issue-selector.issue-selector-placeholder',
		defaultMessage: 'Choose an issue...',
		description: 'Placeholder to show when no search text entered in issue selector',
	},
	issueSelectorLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-dependency.dependency-selector.issue-selector.issue-selector-label',
		defaultMessage: 'Select an issue',
		description:
			'Aria label for issue selector combobox that becomes available when user activates start dependency and end dependency buttons in the timeline screen',
	},
	issueSelectorPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-dependency.dependency-selector.issue-selector.issue-selector-placeholder-issue-term-refresh',
		defaultMessage: 'Choose an issue...',
		description: 'Placeholder to show when no search text entered in issue selector',
	},
	issueSelectorLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-dependency.dependency-selector.issue-selector.issue-selector-label-issue-term-refresh',
		defaultMessage: 'Select an issue',
		description:
			'Aria label for issue selector combobox that becomes available when user activates start dependency and end dependency buttons in the timeline screen',
	},
});
