import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	rankAllSelectedIssuesBelow: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-below',
		defaultMessage: 'Rank selected issues below',
		description: 'Label for drop menu to rank selected issues below this',
	},
	rankAllSelectedIssuesAbove: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-above',
		defaultMessage: 'Rank selected issues above',
		description: 'Label for drop menu to rank selected issues above this',
	},
	noRankItself: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.no-rank-itself',
		defaultMessage: 'Only unselected issues can be a reference for ranking.',
		description: 'Title for the no rank itself',
	},
	rankAllSelectedIssuesBelowIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-below-issue-term-refresh',
		defaultMessage: 'Rank selected issues below',
		description: 'Label for drop menu to rank selected issues below this',
	},
	rankAllSelectedIssuesAboveIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-all-selected-issues-above-issue-term-refresh',
		defaultMessage: 'Rank selected issues above',
		description: 'Label for drop menu to rank selected issues above this',
	},
	noRankItselfIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.no-rank-itself-issue-term-refresh',
		defaultMessage: 'Only unselected issues can be a reference for ranking.',
		description: 'Title for the no rank itself',
	},
});
