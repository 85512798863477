import get from 'lodash/get';
import includes from 'lodash/includes';
import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type ViewMode,
	VIEW_MODES,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import {
	isConfluenceMacro,
	isEmbed,
	getMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';

import { getSupportedCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import { isLoadingHistory } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import {
	getDateConfiguration,
	getPlanningUnit,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import { isAtlasConnectInstalled as isAtlasConnectInstalledSelector } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import {
	getTableItems,
	TABLE_ISSUE,
	type TableItem,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import {
	getFieldColumnsViewSettingsByViewMode,
	getViewMode,
	getWarningViewSettings,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import {
	EDIT,
	OPTIMIZED,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import type { DateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import {
	ADD_FIELDS_COLUMN_ID,
	isColumnId,
	getColumnIds,
	type FieldColumnsState,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/field-columns/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { CustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getCurrentValue, getOptimizedValue, hasValueChanged } from '../util.tsx';
import columnProperties, { customFieldColumnProperties } from './column-properties.tsx';
import CustomFieldCell from './columns/cells/custom-field/index.tsx';
import DateCell, { createDateCell } from './columns/cells/date/index.tsx';
import { DateTypes } from './columns/cells/date/types.tsx';
import type { IssueColumn, StateProps } from './types.tsx';
import { getDefaultWidth, getMinWidth } from './utils.tsx';

// @ts-expect-error - TS2322 - Type 'typeof (Anonymous class)' is not assignable to type 'ComponentClass<Omit<Props & RefAttributes<any>, string | number | symbol> & Partial<Props>> & { WrappedComponent: Component<...>; }'.
const StartDateCell = createDateCell(DateCell, DateTypes.Start);

// @ts-expect-error - TS2322 - Type 'typeof (Anonymous class)' is not assignable to type 'ComponentClass<Omit<Props & RefAttributes<any>, string | number | symbol> & Partial<Props>> & { WrappedComponent: Component<...>; }'.
const EndDateCell = createDateCell(DateCell, DateTypes.End);

export const customFieldsToColumns = (
	customFields: CustomField[],
	dateConfiguration: DateConfiguration,
	viewMode: ViewMode,
): IssueColumn[] => {
	const isCollapsible = viewMode !== VIEW_MODES.LIST;

	return customFields.map((field) => {
		let cell = CustomFieldCell;
		const { baselineStartField, baselineEndField } = dateConfiguration;
		const fieldId = `${field.id}`;
		const keyPath: string | undefined = get(field, ['type', 'key']);

		if (fieldId === baselineStartField.key) {
			// @ts-expect-error - TS2322
			cell = !fg('plan-timeline-non-transposed')
				? StartDateCell
				: // @ts-expect-error - TS2322 - Type 'typeof (Anonymous class)' is not assignable to type 'ComponentClass<Omit<Props & RefAttributes<any>, string | number | symbol> & Partial<Props>> & { WrappedComponent: Component<...>; }'.
					createDateCell(DateCell, DateTypes.Start);
		}
		if (fieldId === baselineEndField.key) {
			// @ts-expect-error - TS2322
			cell = !fg('plan-timeline-non-transposed')
				? EndDateCell
				: // @ts-expect-error - TS2322 - Type 'typeof (Anonymous class)' is not assignable to type 'ComponentClass<Omit<Props & RefAttributes<any>, string | number | symbol> & Partial<Props>> & { WrappedComponent: Component<...>; }'.
					createDateCell(DateCell, DateTypes.End);
		}

		const defaultWidth = getDefaultWidth(keyPath, viewMode);
		const minWidth = getMinWidth(keyPath, isCollapsible, viewMode);

		const column: Partial<IssueColumn> = {
			id: fieldId,
			isVisible: false,
			title: field.title,
			attribute: fieldId,
			cell,
			isCustomField: true,
			isLoading: false,
			isReadOnly: includes(CustomFields.ReadOnlyFields, get(field, ['type', 'key'])),
			isSortable: false,
			defaultWidth,
			isCollapsible,
			minWidth,
			projectId: field.projectId,
		};

		const customProperties: Partial<IssueColumn> = isDefined(keyPath)
			? customFieldColumnProperties(keyPath)
			: {};
		// Typescript complains here that id is optional because column/customProperties are partial, but we know that id is defined.
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return { ...column, ...customProperties } as IssueColumn;
	});
};

function getCustomColumn(id: string, customFields: Array<IssueColumn>) {
	return customFields.find((customColumn) => customColumn.id.toString() === id.toString());
}

export const findOptimizedValues =
	(attribute: string) =>
	(item: TableItem): boolean => {
		if (item.tag !== TABLE_ISSUE) {
			return false;
		}
		const { value: issue } = item;
		return hasValueChanged(getCurrentValue(issue, attribute), getOptimizedValue(issue, attribute));
	};

const filterGoalColumnIfNotAvailable = (columns: IssueColumn[], isAtlasConnectInstalled: boolean) =>
	columns.filter((column) => column.id !== 'goal' || isAtlasConnectInstalled);

export const getColumnsPure = (
	{ columns: columnStates }: FieldColumnsState,
	dateConfiguration: DateConfiguration,
	planningUnit: string,
	customFields: CustomField[],
	isLoadingHistoryIssues: boolean,
	items: TableItem[],
	showOptimizations: boolean,
	viewMode: ViewMode,
	isAtlasConnectInstalled: boolean,
	isConfluenceMacroMode: boolean,
	isEmbedMode: boolean,
): IssueColumn[] => {
	const customFieldColumns = customFieldsToColumns(customFields, dateConfiguration, viewMode);

	// Custom field columns could be added and then removed from a plan, so we filter the available columns
	const availableColumnStatesCheck = () =>
		columnStates.filter(
			(column) =>
				isColumnId(column.id, isEmbedMode, isConfluenceMacroMode) ||
				getCustomColumn(column.id, customFieldColumns),
		);

	const availableColumnStates = availableColumnStatesCheck();

	const columnPropertiesContext = {
		dateConfiguration,
		planningUnit,
	};
	const columnsInStore = availableColumnStates.map((columnState) => {
		const customColumn = getCustomColumn(columnState.id, customFieldColumns);
		const defaultColumn = columnProperties(
			columnState.id,
			columnPropertiesContext,
			viewMode,
			isConfluenceMacroMode,
			isEmbedMode,
		);
		const column = customColumn || defaultColumn;

		const defaultWidth = isDefined(customColumn)
			? customColumn.defaultWidth
			: getDefaultWidth(columnState.id, viewMode);

		const hasOptimizedValues =
			showOptimizations && column && !!items.find(findOptimizedValues(column.attribute));

		return {
			...column,
			...columnState,
			isLoading:
				isLoadingHistoryIssues &&
				(columnState.id === 'breakdown' || columnState.id === 'progressByEstimation'),
			defaultWidth,
			hasOptimizedValues,
		};
	});

	const columnIdsWithCustomFields = [
		...getColumnIds(isEmbedMode, isConfluenceMacroMode),
		...customFields.map((customField) => customField.id.toString()),
	];
	const storedColumnIds = availableColumnStates.map((column) => column.id.toString());
	const notStoredColumnIds = R.without(storedColumnIds, columnIdsWithCustomFields);

	const createHiddenColumn = (id: string) => {
		let properties;
		if (isColumnId(id, isEmbedMode, isConfluenceMacroMode)) {
			properties = {
				...columnProperties(
					id,
					columnPropertiesContext,
					viewMode,
					isConfluenceMacroMode,
					isEmbedMode,
				),
				defaultWidth: getDefaultWidth(id, viewMode),
			};
		} else {
			properties = getCustomColumn(id, customFieldColumns);
		}
		return {
			id,
			isVisible: false,
			isLoading: isLoadingHistoryIssues && id === 'breakdown',
			...properties,
		};
	};
	const columnsNotInStore = R.map(createHiddenColumn, notStoredColumnIds);

	if (viewMode === VIEW_MODES.LIST) {
		const addFieldsColumn = {
			id: ADD_FIELDS_COLUMN_ID,
			isVisible: true,
			defaultWidth: getDefaultWidth(ADD_FIELDS_COLUMN_ID, viewMode),
			...columnProperties(
				ADD_FIELDS_COLUMN_ID,
				columnPropertiesContext,
				viewMode,
				isConfluenceMacroMode,
				isEmbedMode,
			),
		};

		return filterGoalColumnIfNotAvailable(
			// @ts-expect-error - TS2322 - Type '{ id: any; isVisible: boolean; isLoading: boolean; }[]' is not assignable to type 'IssueColumn[]'.
			[...columnsInStore, ...columnsNotInStore, addFieldsColumn],
			isAtlasConnectInstalled,
		);
	}

	return filterGoalColumnIfNotAvailable(
		// @ts-expect-error - TS2322 - Type '{ id: any; isVisible: boolean; isLoading: boolean; }[]' is not assignable to type 'IssueColumn[]'.
		[...columnsInStore, ...columnsNotInStore],
		isAtlasConnectInstalled,
	);
};

export const getColumns = createSelector(
	[
		getFieldColumnsViewSettingsByViewMode,
		getDateConfiguration,
		getPlanningUnit,
		getSupportedCustomFields,
		isLoadingHistory,
		getTableItems,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(state: any) => getMode(state) === OPTIMIZED,
		getViewMode,
		isAtlasConnectInstalledSelector,
		isConfluenceMacro,
		isEmbed,
	],
	getColumnsPure,
);

const querySelector = createStructuredSelector<State, StateProps>({
	showWarning: (state) => getWarningViewSettings(state).showWarning,
	items: getTableItems,
	columns: getColumns,
	isReadOnly: (state) => getMode(state) !== EDIT,
	showOptimizations: (state) => getMode(state) === OPTIMIZED,
});

export default querySelector;
