import {
  TownsquareGoalAriResourceOwner,
  TownsquareGoalAriResourceType
} from "./chunk-FPGL2PN7.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/townsquare/goal/manifest.ts
var townsquareGoalAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: TownsquareGoalAriResourceOwner,
  resourceType: TownsquareGoalAriResourceType,
  resourceIdSlug: "{goalUuid}",
  resourceIdSegmentFormats: {
    goalUuid: /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/
    // eslint-disable-line no-useless-escape
  }
};

// src/townsquare/goal/index.ts
var TownsquareGoalAri = class _TownsquareGoalAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._goalUuid = opts.resourceIdSegmentValues.goalUuid;
  }
  get siteId() {
    return this._siteId;
  }
  get goalUuid() {
    return this._goalUuid;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: townsquareGoalAriStaticOpts.qualifier,
      platformQualifier: townsquareGoalAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: townsquareGoalAriStaticOpts.resourceOwner,
      resourceType: townsquareGoalAriStaticOpts.resourceType,
      resourceId: `${opts.goalUuid}`,
      resourceIdSegmentValues: {
        goalUuid: opts.goalUuid
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, townsquareGoalAriStaticOpts);
    return new _TownsquareGoalAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, townsquareGoalAriStaticOpts);
    return new _TownsquareGoalAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      goalUuid: this.goalUuid
    };
  }
};

export {
  TownsquareGoalAri
};
