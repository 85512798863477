import React, { useRef } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useDropTarget } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/bindings/index.tsx';
import { EpicLevelSpotlightTarget } from '@atlassian/jira-portfolio-3-onboarding/src/ui/spotlight-target/index.tsx';
import IssuesWithoutParent from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/issues-without-parent/view.tsx';
import { GROUPING } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { useCellOld } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';
import type { Props } from './types.tsx';

export const GroupHeader = ({
	hierarchyLevelsByLevel,
	item,
	onToggle,
	rankDigits,
	grouping,
}: Props) => {
	const level = hierarchyLevelsByLevel[item.value.level];
	const id = item.value.id ?? level?.title ?? '';

	const ref = useRef<HTMLDivElement>(null);
	let row;
	if (fg('plans_performance_improvements_2')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ row } = useRow());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[{ row }] = useCellOld();
	}

	useDropTarget(ref, row);

	return EpicLevelSpotlightTarget(
		<IssuesWithoutParent
			outerRef={ref}
			level={level}
			rootIssuesCount={item.value.rootIssuesCount}
			isExpanded={item.value.isExpanded}
			id={id}
			rankDigits={rankDigits}
			onToggle={onToggle}
		/>,
		grouping === GROUPING.NONE,
		level?.level,
	);
};
