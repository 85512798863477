.flyout {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	display: inline-block;
	width: 100%;
	max-width: 800px;
	max-height: 400px;
	padding: var(--ds-space-200, 16px) var(--ds-space-300, 24px);
	box-sizing: border-box;

	& tbody {
		border-bottom: none;
	}
}

/* Remove with the cleanup of plan-timeline-non-transposed */
.flyoutOld {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	display: inline-block;
	width: 100%;

	& tbody {
		border-bottom: none;
	}
}

.issueTitleCell {
	max-width: 400px;
}

.count {
	font-weight: 600;
	color: var(--ds-text-subtle, var(--adg3-color-N600));
}

.countLabel {
	font-weight: 600;
	color: var(--ds-text, var(--adg3-color-N700));
	margin-right: 5px;
}

.percentage {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
}

.totalValue {
	display: flex;
}

.separator {
	border-top: 1px solid var(--ds-border, var(--adg3-color-N40));
	border-bottom: none;
}

.issueTitle {
	display: flex;
	align-items: center;
}

.issueIcon {
	width: var(--jpo-icon-size-small);
	min-width: var(--jpo-icon-size-small);
	height: var(--jpo-icon-size-small);
	margin-right: 5px;
}

.issueSummary {
	margin-left: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.accuracyWarning {
	font-size: 12px;
}
