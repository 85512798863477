import React, { type PropsWithChildren } from 'react';
import isNil from 'lodash/isNil';
import { IconButton } from '@atlaskit/button/new';
import type { CustomTriggerProps } from '@atlaskit/dropdown-menu';
import AddIcon from '@atlaskit/icon/glyph/editor/add';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { CtpSpotlights } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctp-spotlights/index.tsx';
import { CttSpotlights } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-spotlights/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import {
	CustomFields,
	GROUPING,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	getCustomFieldIdFromCustomFieldGrouping,
	isRoadmapGroupedByCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import { getGroupForAnalyticEvent } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/grouping/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import ConditionalTooltip from './conditional-tooltip/index.tsx';
import GroupMenu from './group-menu/index.tsx';
import IssueTypeMenu from './issue-type-menu/index.tsx';
import messages from './messages.tsx';
import NestedMenu from './nested-menu/index.tsx';
import ProjectMenu from './project-menu/index.tsx';
import {
	type CreateIssueOptions,
	GROUP,
	ISSUE_CREATE_SUPPORTED_GROUPS,
	ISSUE_TYPE,
	PROJECT,
	type Props,
} from './types.tsx';
import { getProjectId } from './utils.tsx';

const Spotlights = ({ children }: PropsWithChildren) =>
	expValEquals('second_premium_template_experiment', 'cohort', 'variation') ? (
		<SpotlightTarget name={CttSpotlights.CreateIssue}>
			<SpotlightTarget name={CtpSpotlights.CreateIssue}>{children}</SpotlightTarget>
		</SpotlightTarget>
	) : (
		<SpotlightTarget name={CttSpotlights.CreateIssue}>{children}</SpotlightTarget>
	);

const CreateIssue = ({
	customFieldsById,
	groups,
	grouping = GROUPING.NONE,
	enrichedVersions,
	startInlineCreate,
	setGlobalCreateIssue,
	setIssueTypeIdForHierarchy,
	setDefaultProject,
	toggleExpandGroup,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isPlanGroupedBySingleValueCustomField = () => {
		if (!isRoadmapGroupedByCustomField(grouping)) return false;

		const fieldId = parseInt(getCustomFieldIdFromCustomFieldGrouping(grouping), 10);
		const customField = customFieldsById[fieldId];

		if (!customField) return false;

		return CustomFields.GroupableSingleValueFields.includes(customField.type.key);
	};

	const allowCreateIssue = (): boolean =>
		(!isNil(grouping) && ISSUE_CREATE_SUPPORTED_GROUPS.includes(grouping)) ||
		isPlanGroupedBySingleValueCustomField();

	const triggerInlineCreateIssueAnalytics = (
		group: string,
		projectId: number,
		hierarchyLevel: number,
	) => {
		const analyticsKey = PRODUCT_ANALYTICS_EVENT_NAMES.CREATED_ISSUE_FROM_HEADER;
		const [actionSubject, action] = analyticsKey.split(' ');
		const analyticGrouping = getGroupForAnalyticEvent(grouping, Object.values(customFieldsById));

		fireUIAnalytics(createAnalyticsEvent({ action, actionSubject }), analyticsKey, {
			group,
			grouping: analyticGrouping,
			hierarchyLevel,
			project: projectId,
		});
	};

	const onSelectOptions = (selectedOptions: Partial<CreateIssueOptions>) => {
		const projectId = getProjectId(selectedOptions, enrichedVersions);

		const issueTypeOption = selectedOptions.issueType;
		const issueTypeId = issueTypeOption?.value?.id;
		const hierarchyLevel = issueTypeOption?.value?.level;

		if (isNil(projectId) || isNil(issueTypeId) || isNil(hierarchyLevel)) {
			throw new Error('Project, issue type or level is missing');
		}

		setIssueTypeIdForHierarchy(hierarchyLevel, issueTypeId);
		setGlobalCreateIssue();
		setDefaultProject(projectId);

		if (grouping === GROUPING.NONE) {
			startInlineCreate({
				parentId: null,
				siblingId: null,
				hierarchyLevel,
				issueTypeId,
				projectId,
				source: 'HEADER',
			});

			if (fg('fire_analytics_from_plan_issue_create_header')) {
				triggerInlineCreateIssueAnalytics('', projectId, hierarchyLevel);
			}

			return;
		}

		const groupOption =
			grouping === GROUPING.PROJECT ? selectedOptions.project : selectedOptions.group;

		if (isNil(groupOption)) throw new Error('Group is missing');

		const group = groupOption.id;
		const groupCombination = groupOption.value;

		const startInlineCreateInEmptyGroup = !groups?.some(({ group: id }) => id === group);
		const parentGroup = groups?.find(({ group: id }) => id === group)?.parentGroup;

		if (parentGroup) toggleExpandGroup({ expand: true, groupName: parentGroup, grouping });
		toggleExpandGroup({ expand: true, groupName: group, grouping });

		triggerInlineCreateIssueAnalytics(group, projectId, hierarchyLevel);

		startInlineCreate({
			parentId: null,
			siblingId: null,
			hierarchyLevel,
			issueTypeId,
			projectId,
			group,
			grouping,
			groupCombination,
			startInlineCreateInEmptyGroup,
			source: 'HEADER',
		});
	};

	const renderTrigger = ({ triggerRef, ...triggerProps }: CustomTriggerProps) => {
		const isDisabled = !allowCreateIssue();

		return (
			<ConditionalTooltip
				shouldRender={isDisabled}
				content={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.addIssueDisabledTooltipIssueTermRefresh
						: messages.addIssueDisabledTooltip,
				)}
			>
				<Spotlights>
					{isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? (
						<IconButton
							appearance="subtle"
							ref={triggerRef}
							isDisabled={isDisabled}
							label={formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.createIssueIssueTermRefresh
									: messages.createIssue,
							)}
							{...triggerProps}
							icon={() => <AddIcon label="" />}
							isTooltipDisabled={isDisabled}
							testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.button"
						/>
					) : (
						<Button
							iconBefore={<AddIcon label="" />}
							appearance="link"
							ref={triggerRef}
							isDisabled={isDisabled}
							{...triggerProps}
							testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.button"
						>
							{formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.createIssueIssueTermRefresh
									: messages.createIssue,
							)}
						</Button>
					)}
				</Spotlights>
			</ConditionalTooltip>
		);
	};

	const getMenu = (
		selectedOptions: Partial<CreateIssueOptions>,
	): keyof CreateIssueOptions | undefined => {
		// Special case for grouping by project or none. In this case, we don't need to select the group because
		// either there are no groups, or we can reuse the project.
		if (grouping === GROUPING.NONE || grouping === GROUPING.PROJECT) {
			if (isNil(selectedOptions.project)) return PROJECT;
			if (isNil(selectedOptions.issueType)) return ISSUE_TYPE;
			return undefined;
		}

		// Special case for grouping by release. In this case, releases have a 1:1 relationship with projects. So we
		// can skip selecting the project if the release is already selected. If the unassigned release is selected, we
		// need to select the project.
		if (grouping === GROUPING.RELEASE) {
			if (isNil(selectedOptions.group)) return GROUP;
			if (isNil(getProjectId(selectedOptions, enrichedVersions))) return PROJECT;
			if (isNil(selectedOptions.issueType)) return ISSUE_TYPE;
			return undefined;
		}

		// For all other groupings, we need to select the group and project.
		if (isNil(selectedOptions.group)) return GROUP;
		if (isNil(selectedOptions.project)) return PROJECT;
		if (isNil(selectedOptions.issueType)) return ISSUE_TYPE;
		return undefined;
	};

	return (
		<NestedMenu
			onSelectOptions={onSelectOptions}
			trigger={renderTrigger}
			menus={{
				[GROUP]: GroupMenu,
				[PROJECT]: ProjectMenu,
				[ISSUE_TYPE]: IssueTypeMenu,
			}}
			getMenu={getMenu}
		/>
	);
};

export default CreateIssue;
