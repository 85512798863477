import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { AkFlag as Flag, FlagGroup } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-common/src/api/index.tsx';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { openInNewTab } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

function HiddenIssuesDialog({ closeDialog, hiddenIssues, isDialogOpen, projects }: Props) {
	const { formatMessage } = useIntl();
	const projectsById = indexBy((project) => project.id, projects);
	const issueKeys = hiddenIssues.map((issue) => {
		const project = projectsById[issue.projectId];
		return `${project.key}-${issue.issueKey}`;
	});

	let title;
	let description;

	if (hiddenIssues.length === 1) {
		const hiddenIssuesUrl = proxyContextSafeUrl(`/browse/${issueKeys[0]}`);

		title = (
			<BaseButtonContainer
				role="button"
				tabIndex={0}
				onClick={() => openInNewTab(hiddenIssuesUrl)}
				onKeyDown={() => openInNewTab(hiddenIssuesUrl)}
			>
				{formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.issueNotVisibleHeaderIssueTermRefresh
						: messages.issueNotVisibleHeader,
					{
						issueKey: issueKeys[0],
						span: (chunk: ReactNode) => <span>{chunk}</span>,
					},
				)}
			</BaseButtonContainer>
		);

		description = formatMessage(
			fg('jira-issue-terminology-refresh-m3')
				? messages.issueNotVisibleIssueTermRefresh
				: messages.issueNotVisible,
		);
	} else {
		const hiddenIssuesUrl = proxyContextSafeUrl(
			`/issues/?jql=key in (${issueKeys.join(', ')}) order by key`,
		);

		title = (
			<BaseButtonContainer
				role="button"
				tabIndex={0}
				onClick={() => openInNewTab(hiddenIssuesUrl)}
				onKeyDown={() => openInNewTab(hiddenIssuesUrl)}
			>
				{formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.issuesNotVisibleHeaderIssueTermRefresh
						: messages.issuesNotVisibleHeader,
					{
						numIssues: hiddenIssues.length,
						span: (chunk: ReactNode) => <span>{chunk}</span>,
					},
				)}
			</BaseButtonContainer>
		);

		description = formatMessage(
			fg('jira-issue-terminology-refresh-m3')
				? messages.issuesNotVisibleIssueTermRefresh
				: messages.issuesNotVisible,
		);
	}

	return isDialogOpen ? (
		<FlagGroup onDismissed={closeDialog}>
			<Flag
				icon={<InfoIcon label="" primaryColor={token('color.icon.discovery', colors.P300)} />}
				id="hidden-issues-info-flag"
				title={title}
				description={description}
			/>
		</FlagGroup>
	) : null;
}

export default HiddenIssuesDialog;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BaseButtonContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		color: `${token('color.link', 'var(--adg3-color-B400)')}`,
		cursor: 'pointer',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
});
