import React from 'react';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import { getIdForChangeCategory } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/update-jira/utils.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function TeamTitle(props: Props) {
	const { id, category, avatarUrl: teamAvatarUrl, name: teamName } = props;

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.teamTitle}
			id={getIdForChangeCategory(id, category)}
			data-testid={`portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.title.team.team-title-container-${id}`}
		>
			{!teamAvatarUrl ? (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles.defaultTeamAvatar}>
					<PeopleIcon label="" size="small" />
				</div>
			) : (
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={`${styles.teamIcon}`}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={
						teamAvatarUrl
							? {
									backgroundSize: 'contain',
									backgroundImage: `url(${teamAvatarUrl})`,
								}
							: {}
					}
				/>
			)}
			<EllipsedWithTooltip content={teamName}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.titleText}>{teamName}</div>
			</EllipsedWithTooltip>
		</div>
	);
}
