import React, { Component, type ReactNode } from 'react';
import cx from 'classnames';
import { fg } from '@atlassian/jira-feature-gating';
import type { EnrichedVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import type { EnrichedCrossProjectVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/cross-project-versions/types.tsx';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { ICON_TYPES } from '../types.tsx';
import {
	calculateLeftPositionPercentage,
	getMarkerTypeIcon,
	getVersionFlyoutType,
} from '../utils.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, MappedVersions } from './types.tsx';

const typeToClassNameSpa = {
	[ICON_TYPES.UNRELEASED]: styles.unreleasedSpa,
	[ICON_TYPES.RELEASED]: styles.releasedSpa,
	[ICON_TYPES.ONTRACK]: styles.onTrackSpa,
	[ICON_TYPES.OFFTRACK]: styles.offTrackSpa,
} as const;

// eslint-disable-next-line jira/react/no-class-components
export default class TimelineReleaseMarker extends Component<Props> {
	mapVersionsById = (ids: string[]): MappedVersions => {
		const { crossProjectVersionsById, versionsById } = this.props;
		const crossProjectVersions: Array<EnrichedCrossProjectVersion> = [];
		const versions: Array<EnrichedVersion> = [];
		ids.forEach((id) => {
			const version = versionsById[id];
			if (version) {
				versions.push(version);
			} else {
				crossProjectVersions.push(crossProjectVersionsById[id]);
			}
		});

		return { versions, crossProjectVersions };
	};

	render() {
		const {
			endDate,
			mode,
			solutionVersionsById,
			timelineRange,
			versionIdsMapByDate,
			highlightedVersionIds,
		} = this.props;

		const isHighlighted =
			versionIdsMapByDate[endDate] &&
			versionIdsMapByDate[endDate].find((versionId) =>
				highlightedVersionIds.some((vid) => vid === versionId),
			);

		if (!isHighlighted) {
			return null;
		}

		const leftPositionPercentage = calculateLeftPositionPercentage(Number(endDate), timelineRange);

		const mappedVersions: MappedVersions = this.mapVersionsById(versionIdsMapByDate[endDate]);
		const { versions, crossProjectVersions } = mappedVersions;

		const versionFlyoutType = getVersionFlyoutType([...versions, ...crossProjectVersions]);

		const type = getMarkerTypeIcon(mappedVersions, mode, versionFlyoutType, solutionVersionsById);

		// Consider remove this function when cleaning up plan-timeline-non-transposed
		const withZIndexOverride = (children: (zIndex: number | string | undefined) => ReactNode) => {
			if (fg('plan-timeline-non-transposed')) {
				return children(undefined);
			}

			return (
				<ZIndex layer={Z_INDEX_LAYER.ISSUE_BARS}>
					{(zIndex) => children(zIndex ?? 'initial')}
				</ZIndex>
			);
		};

		return withZIndexOverride((zIndex) => (
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={cx(
					styles.marker,
					!fg('plan-timeline-non-transposed') && styles.transposed,
					typeToClassNameSpa[type],
				)}
				style={{ left: `calc(${leftPositionPercentage}% - 1px)`, zIndex }}
			/>
		));
	}
}
