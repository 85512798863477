import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.project-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchProjectPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.project-filter.search-project-placeholder',
		defaultMessage: 'Show issues from projects',
		description: 'This is a placeholder for search',
	},
	searchProjectLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.project-filter.search-project-label',
		defaultMessage: 'Choose from projects',
		description: 'Aria label for projects search filed that appears on activating Filters button',
	},
	searchProjectPlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.project-filter.search-project-placeholder-issue-term-refresh',
		defaultMessage: 'Show issues from projects',
		description: 'This is a placeholder for search',
	},
});
