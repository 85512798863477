import React, { type ComponentType } from 'react';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { useAdvancedRoadmapsSummaryDataResource } from '@atlassian/jira-router-resources-advanced-roadmaps-summary/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps from './query/index.tsx';
import type {
	AdditionalProps,
	DispatchProps,
	OwnProps,
	PropsWithoutIntlWithoutDefaults,
	StateProps,
} from './types.tsx';
import View from './view.tsx';

const withGicModalOpen =
	<T extends {}>(WrappedComponent: ComponentType<T & AdditionalProps>) =>
	(props: T) => {
		const [{ isModalOpen }] = useTriggerIssueCreateModal();

		return <WrappedComponent {...props} isGicModalOpen={isModalOpen} />;
	};

const HooksInjector = (props: PropsWithoutIntlWithoutDefaults) => {
	const { clear } = useAdvancedRoadmapsSummaryDataResource();

	return <View {...props} clearSummaryPageCache={clear} />;
};

export default withGicModalOpen(
	connect<StateProps, DispatchProps, OwnProps, State>(
		mapStateToProps,
		mapDispatchToProps,
	)(HooksInjector),
);
