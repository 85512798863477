import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	heading: {
		id: 'portfolio-3-onboarding.ctp-onboarding.create-issue-spotlight.heading',
		defaultMessage: 'Create issues in the plan',
		description: 'Heading for the cross team planning template create issue spotlight card.',
	},
	bodyFirst: {
		id: 'portfolio-3-onboarding.ctp-onboarding.create-issue-spotlight.body-first',
		defaultMessage: 'Create issues in the plan and assign work to a team-specific board.',
		description:
			'First line of the body for the cross team planning template create issue spotlight card.',
	},
	bodyUnsavedChanges: {
		id: 'portfolio-3-onboarding.ctp-onboarding.create-issue-spotlight.body-unsaved-changes',
		defaultMessage:
			'Select <b>Unsaved changes</b> to save edits in your plan to issues in your project.',
		description:
			'Second line of the body for the cross team planning template create issue spotlight card.',
	},
	bodyThird: {
		id: 'portfolio-3-onboarding.ctp-onboarding.create-issue-spotlight.body-third',
		defaultMessage: 'You can also add an existing issue source to your plan.',
		description:
			'Third line of the body for the cross team planning template create issue spotlight card.',
	},
	next: {
		id: 'portfolio-3-onboarding.ctp-onboarding.create-issue-spotlight.next',
		defaultMessage: 'Next',
		description: 'Button copy for the cross team planning template create issue spotlight card.',
	},
	headingIssueTermRefresh: {
		id: 'portfolio-3-onboarding.ctp-onboarding.create-issue-spotlight.heading-issue-term-refresh',
		defaultMessage: 'Create issues in the plan',
		description: 'Heading for the cross team planning template create issue spotlight card.',
	},
	bodyFirstIssueTermRefresh: {
		id: 'portfolio-3-onboarding.ctp-onboarding.create-issue-spotlight.body-first-issue-term-refresh',
		defaultMessage: 'Create issues in the plan and assign work to a team-specific board.',
		description:
			'First line of the body for the cross team planning template create issue spotlight card.',
	},
	bodyUnsavedChangesIssueTermRefresh: {
		id: 'portfolio-3-onboarding.ctp-onboarding.create-issue-spotlight.body-unsaved-changes-issue-term-refresh',
		defaultMessage:
			'Select <b>Unsaved changes</b> to save edits in your plan to issues in your project.',
		description:
			'Second line of the body for the cross team planning template create issue spotlight card.',
	},
	bodyThirdIssueTermRefresh: {
		id: 'portfolio-3-onboarding.ctp-onboarding.create-issue-spotlight.body-third-issue-term-refresh',
		defaultMessage: 'You can also add an existing issue source to your plan.',
		description:
			'Third line of the body for the cross team planning template create issue spotlight card.',
	},
});
