import React, { useState, useRef, useEffect, useCallback } from 'react';
import createFocusTrap, { type FocusTrap } from 'focus-trap';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import type { Placement } from '@atlaskit/popper';
import { xcss, Box } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { CUSTOM_TIME_RANGE_TYPES } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { getRelativeDates } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { CustomDateRange } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/types.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { DateRangeModal } from '@atlassian/jira-portfolio-3-custom-date-range/src/ui/date-range-modal/index.tsx';
import { CustomDateRangeModalContent } from '@atlassian/jira-portfolio-3-custom-date-range/src/ui/modal-content/index.tsx';
import { getStringFromDates } from '@atlassian/jira-portfolio-3-custom-date-range/src/ui/modal-content/relative-time-range/utils.tsx';
import type { Props } from './types.tsx';

export default function CustomTimelineRange({
	customDateRange,
	isDisabled = false,
	onCustomDatesChange,
	fieldProps,
}: Props) {
	const intl = useIntl();
	const [isCustomRangeDialogOpen, setIsCustomRangeDialogOpen] = useState<boolean>(false);
	const [dialogPosition, setDialogPosition] = useState<Placement>('bottom-end');
	const ref = useRef<HTMLDivElement>(null);

	const deactivatedFocusLock = useRef<boolean>(false);

	const onCustomDatesApplied = (range: CustomDateRange) => {
		setIsCustomRangeDialogOpen(false);
		onCustomDatesChange(range);
	};

	const onClose = useCallback(() => {
		setIsCustomRangeDialogOpen(false);
	}, []);

	// A hack to make sure the dialog stays at the bottom-end of date range button
	// When the selected tab is changed, the dialog position is set to value other than 'bottom-end'
	const recalculateDialogPosition = () => {
		setDialogPosition('auto-end');
	};
	// Then the dialogPosition is set back to 'bottom-end' when position was changed in the recalculateDialogPosition
	useEffect(() => {
		setDialogPosition('bottom-end');
	}, [dialogPosition]);

	const customItemDialogContent = (
		<CustomDateRangeModalContent
			dateRange={customDateRange}
			onConfirm={onCustomDatesApplied}
			onCancel={() => setIsCustomRangeDialogOpen(false)}
			onSelectedTabChange={recalculateDialogPosition}
		/>
	);

	const onItemClicked = () => {
		// This is a workaround to allow focus to move to the modal after clicking the timeline range button.
		// The popup uses the same focus trap library, it prevents focus from moving to the modal is it is not
		// contained within the popup due to portal.
		// Activating and deactivating a temporary focus trap allows focus to reach the modal, while maintaining
		// the return focus behavior of the popup.
		if (!deactivatedFocusLock.current && ref.current && fg('smart_links_for_plans')) {
			const tempFocusTrap: FocusTrap = createFocusTrap(ref.current);
			tempFocusTrap.activate();
			tempFocusTrap.deactivate();

			deactivatedFocusLock.current = true;
		}

		setIsCustomRangeDialogOpen((prevIsCustomRangeDialogOpen) => !prevIsCustomRangeDialogOpen);
	};

	const getButtonText = () => {
		const { fromDate, toDate, typeOfCustomDateRange } = customDateRange;

		if (typeOfCustomDateRange === CUSTOM_TIME_RANGE_TYPES.RELATIVE) {
			const { start, end } = getRelativeDates(customDateRange, new Date());
			return getStringFromDates(start, end, intl);
		}
		return getStringFromDates(fromDate, toDate, intl);
	};

	const onStopPropagation = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Escape') {
			e.stopPropagation();
		}
	}, []);

	return (
		<div ref={ref}>
			{fg('smart_links_for_plans') ? (
				<>
					<Button
						onClick={() => onItemClicked()}
						{...fieldProps}
						aria-labelledby={`${fieldProps?.['aria-labelledby']} ${fieldProps?.id}-buttonText`}
						isDisabled={isDisabled}
						testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.custom-timeline-range.button"
						iconAfter={
							<Box xcss={buttonWithIcon}>
								<CalendarIcon label="" />
							</Box>
						}
					>
						<span id={`${fieldProps?.id}-buttonText`}>{getButtonText()}</span>
					</Button>
					<Box onKeyDown={onStopPropagation}>
						<DateRangeModal
							dateRange={customDateRange}
							isOpen={isCustomRangeDialogOpen}
							onConfirm={onCustomDatesApplied}
							onCancel={onClose}
						/>
					</Box>
				</>
			) : (
				<InlineDialog
					content={customItemDialogContent}
					isOpen={isCustomRangeDialogOpen}
					maxWidth={716}
					overflow="visible"
					placement={dialogPosition}
				>
					<Button
						onClick={() => onItemClicked()}
						{...fieldProps}
						aria-labelledby={`${fieldProps?.['aria-labelledby']} ${fieldProps?.id}-buttonText`}
						isDisabled={isDisabled}
						testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.custom-timeline-range.button"
						iconAfter={
							<Box xcss={buttonWithIcon}>
								<CalendarIcon label="" />
							</Box>
						}
					>
						<span id={`${fieldProps?.id}-buttonText`}>{getButtonText()}</span>
					</Button>
				</InlineDialog>
			)}
		</div>
	);
}

const buttonWithIcon = xcss({
	marginLeft: 'space.100',
});
