import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { fork, call, all, put, select, takeEvery } from 'redux-saga/effects';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { isReadOnly } from '../../query/app/index.tsx';
import {
	getCurrentUser,
	getPlanEditorPermissions,
	getPlanViewerPermissions,
	getTeamPermissions,
} from '../../query/permissions/index.tsx';
import { getPlan } from '../../query/plan/index.tsx';
import type { JiraUser } from '../../state/domain/assignees/types.tsx';
import {
	setPermissions,
	addPermission,
	removePermission,
	setCurrentUser,
	toggleFetchInProggress,
	setTeamPermissions,
} from '../../state/domain/permissions/actions.tsx';
import type {
	Permission,
	PermissionRole,
	PermissionActionPayload,
	User,
} from '../../state/domain/permissions/types.tsx';
import { GET, POST, parseError } from '../api.tsx';
import { getUsers } from '../assignees/index.tsx';
import { genericError, httpRequestError } from '../errors/index.tsx';
import { toErrorID } from '../util.tsx';
import { urls } from './api.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { setCurrentUser };

export const FETCH_PERMISSIONS = 'command.permissions.FETCH_PERMISSIONS' as const;

export const ESTABLISH_PERMISSION = 'command.permissions.ESTABLISH_PERMISSION' as const;

export const REVOKE_PERMISSION = 'command.permissions.REVOKE_PERMISSION' as const;

export const REVOKE_ALL_PERMISSIONS = 'command.permissions.REVOKE_ALL_PERMISSIONS' as const;
export const FETCH_TEAM_PERMISSIONS = 'command.permissions.FETCH_TEAM_PERMISSIONS' as const;

type EstablishPermissionAction = {
	type: typeof ESTABLISH_PERMISSION;
	payload: PermissionActionPayload;
};

type RevokePermissionAction = {
	type: typeof REVOKE_PERMISSION;
	payload: PermissionActionPayload;
};

type RevokeAllPermissionsAction = {
	type: typeof REVOKE_ALL_PERMISSIONS;
	payload: {
		permission: PermissionRole;
	};
};

export type FetchTeamPermissionsAction = {
	type: typeof FETCH_TEAM_PERMISSIONS;
	orgId: string;
	siteId?: string;
};

export const establishPermission = (
	payload: PermissionActionPayload,
): EstablishPermissionAction => ({
	type: ESTABLISH_PERMISSION,
	payload,
});

export const revokePermission = (payload: PermissionActionPayload) => ({
	type: REVOKE_PERMISSION,
	payload,
});

export const revokeAllPermissions = (payload: { permission: PermissionRole }) => ({
	type: REVOKE_ALL_PERMISSIONS,
	payload,
});

export const fetchPermissions = () => ({
	type: FETCH_PERMISSIONS,
});

export const fetchTeamPermissions = (
	orgId: string,
	siteId?: string,
): FetchTeamPermissionsAction => ({
	type: FETCH_TEAM_PERMISSIONS,
	orgId,
	siteId,
});

export const getAddPermissionErrorText = (payload: PermissionActionPayload) =>
	`Failed to add "${payload.holder.type}" permission for "${payload.holder.key}"`;

export const getRemovePermissionErrorText = (payload: PermissionActionPayload) =>
	`Failed to delete "${payload.holder.type}" permission for "${payload.holder.key}"`;

export function* doRemovePermission({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: RevokePermissionAction): Generator<Effect, any, any> {
	try {
		yield put(removePermission(payload));
		const { id: planId } = yield select(getPlan);
		const body = {
			permission: { permission: payload.permission, holder: payload.holder },
			planId,
		};
		if (!payload.holder) {
			if (fg('improve_redux_saga_error_reporting_plans_batch_3')) {
				const error = new Error('do-remove-permission-payload-holder-undefined');
				fireErrorAnalytics({
					error,
					meta: {
						packageName: PACKAGE_NAME,
						id: toErrorID(error),
						teamName: ERROR_REPORTING_TEAM,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			} else {
				fireErrorAnalytics({
					meta: {
						packageName: PACKAGE_NAME,
						id: ERROR_REPORTING_PACKAGE.APP,
						teamName: ERROR_REPORTING_TEAM,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		}
		const response = yield call(fetch, urls.revoke, { method: POST, body });
		if (!response.ok) {
			const error = parseError(response, yield call(response.text.bind(response)));
			yield put(addPermission(payload));
			yield put(httpRequestError(response, error, { method: POST }));
			return;
		}
		const { removed } = yield call(response.json.bind(response));
		if (!removed.length) {
			yield put(addPermission(payload));
			throw (() => ({ message: getRemovePermissionErrorText(payload) }))();
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	}
}

export function* doAddPermission({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: EstablishPermissionAction): Generator<Effect, any, any> {
	try {
		yield put(addPermission(payload));
		const { id: planId } = yield select(getPlan);
		const body = {
			permission: { permission: payload.permission, holder: payload.holder },
			planId,
		};
		const response = yield call(fetch, urls.establish, { method: POST, body });
		if (!response.ok) {
			const error = parseError(response, yield call(response.text.bind(response)));
			yield put(removePermission(payload));
			yield put(httpRequestError(response, error, { method: POST }));
			return;
		}
		const { added } = yield call(response.json.bind(response));
		if (!added.length) {
			yield put(removePermission(payload));
			throw (() => ({ message: getAddPermissionErrorText(payload) }))();
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	}
}

export function* doRemoveAllPermissions({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: RevokeAllPermissionsAction): Generator<Effect, any, any> {
	try {
		if (payload.permission === 'EDIT') {
			// `EDIT` permissions for the currentUser must be revoked last to trigger
			// proper behaviour on the backend as we don't allow users to exclude themselves
			// unless switching back to unrestricted mode.
			const currentUser: User = yield select(getCurrentUser);
			const [[currentUserPermissions], permissions] = R.partition<Permission>(
				({ holder: { key } }) => key === currentUser.key,
				yield select(getPlanEditorPermissions),
			);
			yield all(
				permissions.map((permission) =>
					call(doRemovePermission, {
						type: REVOKE_PERMISSION,
						payload: { ...permission, permission: payload.permission },
					}),
				),
			);
			if (currentUserPermissions) {
				yield call(doRemovePermission, {
					type: REVOKE_PERMISSION,
					payload: {
						...currentUserPermissions,
						permission: payload.permission,
					},
				});
			}
		} else {
			const permissions: Permission[] = yield select(getPlanViewerPermissions);
			yield all(
				permissions.map((permission) =>
					call(doRemovePermission, {
						type: REVOKE_PERMISSION,
						payload: { ...permission, permission: payload.permission },
					}),
				),
			);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doRetrievePermissions(): Generator<Effect, any, any> {
	try {
		// check if the view is under read only
		const isReadOnlyMode = yield select(isReadOnly);

		// The permission endpoint for the view currently is called during page load
		// This should not be called if the user is read-only
		// This has been confirmed with Ruslan, the reason why we load permissions on every page
		// which is merged as part of work on https://bulldog.internal.atlassian.com/browse/JPOS-2326 and
		// that call on every page load was a shortcut to make implementation simpler that was never fixed afterwards.
		// Also, from my investigation and testing, the readonly user doesn't even use permissions state.
		if (!isReadOnlyMode) {
			yield put(toggleFetchInProggress(true));
			const { id: planId } = yield select(getPlan);

			if (isDefined(planId)) {
				const permissionsResponse = yield call(fetch, urls.get(planId), {
					method: GET,
					profile: urls.get(':id'),
				});
				if (permissionsResponse && permissionsResponse.ok) {
					const { permissions }: { permissions: Permission[] } = yield call(
						permissionsResponse.json.bind(permissionsResponse),
					);
					const userKeys = permissions
						.filter((p) => p.holder.type === 'ACCOUNT_ID')
						.map((p) => p.holder.key);

					if (userKeys.length) {
						const users: JiraUser[] = yield call(getUsers, {
							keys: userKeys,
						});
						const enhancedPermissions = permissions.map((permission) => {
							const user = users.find(
								// eslint-disable-next-line @typescript-eslint/no-shadow
								(user) => user.accountId === permission.accountId,
							);
							if (user) {
								return {
									...permission,
									email: user.email,
									avatarUrl: user.avatarUrl,
								};
							}
							return permission;
						});
						yield put(setPermissions(enhancedPermissions));
					} else yield put(setPermissions(permissions));

					yield put(toggleFetchInProggress(false));
				} else if (permissionsResponse) {
					const error = parseError(
						permissionsResponse,
						yield call(permissionsResponse.text.bind(permissionsResponse)),
					);
					yield put(httpRequestError(permissionsResponse, error, { method: GET }));
				}
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	}
}

export function* doFetchTeamPermissions({
	orgId,
	siteId, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: FetchTeamPermissionsAction): Generator<Effect, any, any> {
	try {
		const teamPermissions = yield select(getTeamPermissions);
		if (
			!isDefined(teamPermissions) ||
			!isDefined(teamPermissions.canViewTeams) ||
			!isDefined(teamPermissions.canCreateTeams)
		) {
			const response = yield call(fetch, urls.getTeamsPermissionV4(orgId, siteId ?? 'None'), {
				method: GET,
			});

			if (response.ok) {
				const { permissions } = yield call(response.json.bind(response));
				yield put(
					setTeamPermissions({
						canViewTeams:
							Array.isArray(permissions) &&
							permissions.length > 0 &&
							permissions.includes('CAN_VIEW_TEAMS'),
						canCreateTeams:
							Array.isArray(permissions) &&
							permissions.length > 0 &&
							permissions.includes('CAN_CREATE_TEAMS'),
					}),
				);
			} else {
				const message = yield call(response.text.bind(response));
				throw new Error(`Failed to retrieve team permissions: ${message}`);
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		// Set defaut team permissions to false
		yield put(
			setTeamPermissions({
				canViewTeams: false,
				canCreateTeams: false,
			}),
		);
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchGetPermissions(): Generator<Effect, any, any> {
	yield takeEvery(FETCH_PERMISSIONS, doRetrievePermissions);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchRemovePermission(): Generator<Effect, any, any> {
	yield takeEvery(REVOKE_PERMISSION, doRemovePermission);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchRemoveAllPermissions(): Generator<Effect, any, any> {
	yield takeEvery(REVOKE_ALL_PERMISSIONS, doRemoveAllPermissions);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchAddPermission(): Generator<Effect, any, any> {
	yield takeEvery(ESTABLISH_PERMISSION, doAddPermission);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchFetchTeamPermissions(): Generator<Effect, any, any> {
	yield takeEvery(FETCH_TEAM_PERMISSIONS, doFetchTeamPermissions);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchGetPermissions);
	yield fork(watchAddPermission);
	yield fork(watchRemovePermission);
	yield fork(watchRemoveAllPermissions);
	yield fork(watchFetchTeamPermissions);
}
