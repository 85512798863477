import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useCellOld } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';
import { useColumnWidth } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import type { Props } from './types.tsx';

export const FieldNew = ({ issue, column, isReadOnly, isScrolling, showOptimizations }: Props) => {
	const { cell: Cell } = column;
	return (
		<Cell
			issue={issue}
			attribute={column.attribute}
			isReadOnly={(isReadOnly || column.isReadOnly) ?? false}
			showOptimizations={showOptimizations}
			isScrolling={isScrolling}
		/>
	);
};

export const FieldOld = ({ issue, column, isReadOnly, isScrolling, showOptimizations }: Props) => {
	const { cell: Cell } = column;
	const [{ column: colIndex }] = useCellOld();
	const [width] = useColumnWidth({
		column: colIndex,
		preview:
			false /* for performance, it's not critical to react on width changes while resizing. */,
	});

	return (
		<Box xcss={containerStyles} data-issue={issue.id}>
			<Cell
				issue={issue}
				attribute={column.attribute}
				isReadOnly={(isReadOnly || column.isReadOnly) ?? false}
				showOptimizations={showOptimizations}
				isScrolling={isScrolling}
				width={width}
			/>
		</Box>
	);
};

const containerStyles = xcss({
	height: 'space.500',
});
