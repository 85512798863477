import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	titleColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.title-column-header',
		defaultMessage: 'Title',
		description: 'The label for the title column header of the changes table',
	},
	categoryColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.category-column-header',
		defaultMessage: 'Category',
		description: 'The label for the category column header of the changes table',
	},
	whatChangedColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.what-changed-column-header',
		defaultMessage: 'What changed',
		description: 'The label for the header of the column showing the type of change made',
	},
	oldValueColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.old-value-column-header',
		defaultMessage: 'Current (Jira)',
		description:
			'The label for the header of the column showing the previous value of an attribute',
	},
	lastModifiedColumnHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.last-modified-column-header',
		defaultMessage: 'Last modified',
		description: 'The label for the last modified column showing the last modified date and user',
	},
	issueCategory: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.issue-category',
		defaultMessage: 'Issue',
		description: 'The label indicating that the changes relate to an issue',
	},
	teamCategory: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.team-category',
		defaultMessage: 'Team',
		description: 'The label indicating that the changes relate to an team',
	},
	releaseCategory: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.release-category',
		defaultMessage: 'Release',
		description: 'The label indicating that the changes relate to a release',
	},
	sprintCategory: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.sprint-category',
		defaultMessage: 'Sprint',
		description: 'The label indicating that the changes relate to a sprint',
	},
	iterationCategory: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.iteration-category',
		defaultMessage: 'Iteration',
		description: 'The label indicating that the changes relate to a kanban iteration',
	},
	exceptions: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.exceptions',
		defaultMessage: 'Exceptions',
		description: 'The label indicating that the changes relate to exceptions of a resource',
	},
	tableAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.table-aria-label',
		defaultMessage: 'Update Jira changes table',
		description:
			'Aria label text for the data table with changes made in JIRA in Review Changes dialog',
	},
	lastModifiedColumnAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.last-modified-column-aria-label',
		defaultMessage: 'Last modified',
		description: 'Aria label text for the last modified column in Review Changes dialog',
	},
	issueCategoryIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.issue-category-issue-term-refresh',
		defaultMessage: 'Issue',
		description: 'The label indicating that the changes relate to an issue',
	},
});
