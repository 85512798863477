import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl } from '@atlassian/jira-intl';
import { useDateFormatter } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { DATE_METHODS } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import { getEndDateMethod } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/utils.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import commonMessages from '../../messages.tsx';
import type { Props } from './types.tsx';

function ReleaseDate(props: Props) {
	const { intl, end, emptyMessage } = props;
	const { formatTimestamp } = useDateFormatter();

	if (end === undefined) {
		return <span>{emptyMessage}</span>;
	}

	const { method } = getEndDateMethod(end);

	if (method === DATE_METHODS.FIXED_DATE && isDefined(end)) {
		return <span>{formatTimestamp(end)}</span>;
	}

	return (
		<span>
			{intl.formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? commonMessages.afterAllIssuesAreCompletedIssueTermRefresh
					: commonMessages.afterAllIssuesAreCompleted,
			)}
		</span>
	);
}

export default injectIntl(ReleaseDate);
