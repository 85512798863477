import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	removeSavedIssue: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.remove-issue.remove-saved-issue',
		defaultMessage: 'Remove from plan',
		description: 'Label for drop menu to remove saved issue from plan',
	},
	removeScenarioIssue: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.remove-issue.remove-scenario-issue',
		defaultMessage: 'Delete draft issue',
		description: 'Label for drop menu to remove scenario issue from plan',
	},
	deleteIssueNonFinal: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.remove-issue.delete-issue-non-final',
		defaultMessage: 'Delete {issueType}',
		description: 'Label for drop menu to delete issue from plan',
	},
	removeScenarioIssueIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.remove-issue.remove-scenario-issue-issue-term-refresh',
		defaultMessage: 'Delete draft issue',
		description: 'Label for drop menu to remove scenario issue from plan',
	},
	deleteIssueNonFinalIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.remove-issue.delete-issue-non-final-issue-term-refresh',
		defaultMessage: 'Delete {issueType}',
		description: 'Label for drop menu to delete issue from plan',
	},
});
