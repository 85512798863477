import { subDays } from 'date-fns';
import { expVal } from '@atlassian/jira-feature-experiments';
import { INCLUDE_COMPLETED_ISSUE_FOR_DAYS } from '@atlassian/jira-portfolio-3-create-new-plan-wizard-drawer/src/common/constants.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { Plan } from '../../state/domain/plan/types.tsx';
import type { BacklogBody } from './types.tsx';

// urlsOld can be removed when tidying jsis_issue_search_for_initial_backlog_load
export const urlsOld = {
	backlog: '/rest/jpo/1.0/backlog',
} as const;

export const urls = (useJsis = false) => {
	if (useJsis) {
		expVal('jsis_adoption_backlog', 'isEnabled', false);
	}

	return { backlog: `/rest/jpo/1.0/backlog?useJsis=${String(useJsis)}` };
};

export const backlogBody = ({
	id: planId,
	currentScenarioId: scenarioId,
	includeCompletedIssuesFor,
}: Plan): BacklogBody => ({
	...{
		planId,
		scenarioId,
		filter: {
			includeCompleted: includeCompletedIssuesFor !== 0,
			includeCompletedSince: subDays(
				Date.now(),
				isDefined(includeCompletedIssuesFor)
					? includeCompletedIssuesFor
					: INCLUDE_COMPLETED_ISSUE_FOR_DAYS,
			).getTime(),
			includeIssueLinks: true,
		},
		supportingDataOptions: {
			assignees: true,
			reporters: true,
			externalTeams: true,
			sprints: true,
		},
	},
});
