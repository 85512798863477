import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-associated-issues/src/common/types.tsx';
import { useAssociatedIssues } from '@atlassian/jira-portfolio-3-associated-issues/src/controllers/index.tsx';
import { AssociatedIssuesView } from '@atlassian/jira-portfolio-3-associated-issues/src/ui/index.tsx';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuItem,
	DialogMenuContainer,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import {
	IDEA_FILTER_ID,
	type StringAttributeFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import FilterText from '../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const FilteredIdeas = ({
	value,
	changeFilter,
	searchQuery,
}: {
	value: StringAttributeFilterValue;
	changeFilter: (value: StringAttributeFilterValue) => void;
	searchQuery: string;
}) => {
	const [associatedIssues] = useAssociatedIssues();
	const isIdeaSelected = (id: string) => value.includes(id);
	const onIdeaClicked = (id: string): void => {
		if (isIdeaSelected(id)) {
			changeFilter(value.filter((ideaId) => ideaId !== id));
		} else {
			changeFilter([...value, id]);
		}
	};
	const summaryFilter = (ideaId: string) => {
		if (!associatedIssues) {
			return false;
		}
		const idea = associatedIssues[ideaId];
		return idea ? idea.summary.toLowerCase().includes(searchQuery.toLowerCase()) : false;
	};
	const filteredAssociatedIssueIds = associatedIssues
		? Object.keys(associatedIssues).filter(summaryFilter)
		: [];
	return (
		<>
			{filteredAssociatedIssueIds.map((associatedIssueId) => {
				if (!associatedIssues) {
					return null;
				}
				const issue = associatedIssues[associatedIssueId];
				return (
					<DialogMenuItem key={associatedIssueId}>
						<Checkbox
							id={`${associatedIssueId}`}
							isChecked={isIdeaSelected(associatedIssueId)}
							onChange={() => onIdeaClicked(associatedIssueId)}
							label={<AssociatedIssuesView associatedIssues={[issue]} />}
						/>
					</DialogMenuItem>
				);
			})}
			{filteredAssociatedIssueIds.length === 0 && <NoMatchFound />}
		</>
	);
};

const constructFilterText = (
	value: StringAttributeFilterValue,
	associatedIssues: Record<string, AssociatedIssue>,
) =>
	value
		.map((issueId) => associatedIssues[issueId])
		.filter(Boolean)
		.map((issue) => issue.summary)
		.sort()
		.join(', ');

const IdeaFilter = ({
	clearFilter,
	value,
	searchQuery,
	onOpenChange,
	isOpen,
	onQueryChange,
	changeFilter,
}: Props) => {
	const { formatMessage } = useIntl();
	const [associatedIssuesInStore] = useAssociatedIssues();
	const associatedIssues = associatedIssuesInStore || {};

	const isSelected = value.length > 0;

	const renderFilterText = () => {
		if (isSelected) {
			return <FilterText text={constructFilterText(value, associatedIssues)} />;
		}
		return <FormattedMessage {...messages.emptyPlaceholder} />;
	};

	const ariaText = () => {
		const filterText = isSelected
			? constructFilterText(value, associatedIssues)
			: formatMessage(messages.emptyPlaceholder);

		return `${formatMessage(filterMessages[IDEA_FILTER_ID])}, ${filterText} ${formatMessage(
			filterMessages.selected,
		)}`;
	};
	return (
		<InlineDialog
			noPaddings
			maxWidth={FILTER_MAX_WIDTH}
			minWidth={FILTER_WIDTH}
			onClose={onOpenChange}
			isOpen={isOpen}
			content={
				<DialogMenuContainer>
					<ClearFilterButton isVisible={isSelected} onClearClick={clearFilter} />
					<SearchField
						placeholder={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.searchIdeasPlaceholderIssueTermRefresh
								: messages.searchIdeasPlaceholder,
						)}
						searchQuery={searchQuery}
						onQueryChange={onQueryChange}
					/>
					<FilteredIdeas value={value} changeFilter={changeFilter} searchQuery={searchQuery} />
				</DialogMenuContainer>
			}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.idea-filter.idea-filter"
		>
			<TriggerButton
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				triggerButtonText={renderFilterText()}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.idea-filter.trigger-btn"
				ariaLabel={ariaText()}
			/>
		</InlineDialog>
	);
};

export default IdeaFilter;
