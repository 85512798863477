import type { ViewUserInfo } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { ViewSettingsState } from '../view-settings/types.tsx';

export const UNTITLED_VIEW_ID = 0;

type Persisted = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	preferences: Record<PropertyKey, any>;
};

export type View = {
	id: number;
	version: number;
	name: string;
	isDefault: boolean;
	createdBy?: ViewUserInfo | null | undefined;
	updatedBy?: ViewUserInfo | null | undefined;
	updatedAt: Date | null | undefined;
	preferences: ViewSettingsState;
	persisted: Persisted;
	isPredefined?: boolean;
	original: View | null | undefined;
	active: boolean;
	modified: boolean;
	isUnchangedPredefinedView?: boolean;
};

export type ModifiedView = Pick<View, 'id' | 'version' | 'preferences'>;

export type PredefinedView = Pick<View, 'name' | 'preferences' | 'isDefault'>;
