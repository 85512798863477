import React, { type ChangeEventHandler, useCallback } from 'react';
import { styled } from '@compiled/react';
import EditorInfoIcon from '@atlaskit/icon/glyph/editor/info';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const NotificationToggle = ({ active, onChange }: Props) => {
	const { formatMessage } = useIntl();

	const handleCheckboxChange: ChangeEventHandler<HTMLInputElement> = useCallback(
		(e) => onChange(e.target.checked),
		[onChange],
	);

	const label = formatMessage(messages.notifyWatchers);
	const tooltip = formatMessage(
		fg('jira-issue-terminology-refresh-m3')
			? messages.notifyWatchersDescIssueTermRefresh
			: messages.notifyWatchersDesc,
	);
	const tooltipAriaLabel = formatMessage(messages.notifyWatchersTooltipAriaLabel);

	return (
		<NotifyWatchersContainer>
			<Checkbox
				testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.notification-toggle.checkbox"
				isChecked={active}
				onChange={handleCheckboxChange}
				label={label}
			/>
			<Tooltip content={tooltip}>
				{(tooltipProps) => (
					<div role="button" tabIndex={0} {...tooltipProps}>
						<EditorInfoIcon
							label={tooltipAriaLabel}
							primaryColor={token('color.icon.information', colors.B400)}
						/>
					</div>
				)}
			</Tooltip>
		</NotifyWatchersContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NotifyWatchersContainer = styled.div({
	display: 'flex',
});
