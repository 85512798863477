import type {
	DragTarget,
	DropTarget,
	Indicator,
} from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/types.tsx';
import type { FlagActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/flags/actions.tsx';
import type { Sorting } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/types.tsx';
import type {
	DropType,
	Issues,
	OnMove,
	Item,
	TableIssueItem,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/types.tsx';

export type Props = {
	issues: Issues;
	onMove: OnMove;
	disableDnD?: boolean;
	startLevel: number;
	sorting: Sorting;
	onInvalidRanking: (payload: FlagActionPayload) => void;
};

type Relation = 'BEFORE' | 'AFTER' | 'LAST';

export type RelativePosition = { relation: Relation; anchor: string | undefined };

export type DropParameters = {
	dragTarget: DragTarget;
	dropTarget?: DropTarget;
};

export type ActionState = {
	id: string;
	relation: Relation;
	anchor: string | undefined;
	parentId: string | undefined;
	group?: string;
};

export const CHANGED_GROUP = 'CHANGED_GROUP';
export const ORPHANED_SUBTASK = 'ORPHANED_SUBTASK';
export const REPARENT_TMP_TO_EXTERNAL = 'REPARENT_TMP_TO_EXTERNAL';
export const REPARENT_CMP_TO_TMP = 'REPARENT_CMP_TO_TMP';

type ChangedGroupBlocker = {
	reason: typeof CHANGED_GROUP;
};

type OrphanedSubtaskBlocker = {
	reason: typeof ORPHANED_SUBTASK;
};

type ReparentTmpToExternalBlocker = {
	reason: typeof REPARENT_TMP_TO_EXTERNAL;
	context: {
		projectName: string;
		destinationProjectName: string;
		issueKey: string;
	};
};

type ReparentCmpToTmpBlocker = {
	reason: typeof REPARENT_CMP_TO_TMP;
	context: {
		projectName: string;
		destinationProjectName: string;
		issueKey: string;
	};
};

export type DropBlocker =
	| ChangedGroupBlocker
	| OrphanedSubtaskBlocker
	| ReparentTmpToExternalBlocker
	| ReparentCmpToTmpBlocker;

export type DropState = {
	type: DropType;
	parameters?: DropParameters;
	action?: ActionState;
	blocked?: DropBlocker;
	indicators?: Indicator[];
};

export type RelatedItems = {
	dragItem: TableIssueItem;
	dropItem: Item;
	beforeDropItem: Item;
	afterDropItem: Item;
	previousSiblingItem?: TableIssueItem;
};
