import React, { useCallback, useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { MenuGroup } from '@atlaskit/menu';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { getGroupKey } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/util.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import SuggestChildIssues from '../ai-suggest-child-issues/index.tsx';
import ChildIcon from './child-icon/index.tsx';
import IssueTypeItem from './issue-type-item/index.tsx';
import messages from './messages.tsx';
import type { Props, IssueTypeWithProjectId } from './types.tsx';

const CreateIssue = ({
	issue,
	projects,
	startInlineCreate,
	issueTypesByProjectId,
	hierarchyRangeFilter,
	groupCombination,
	isDisabled = false,
	setGlobalCreateIssue,
	setIssueTypeIdForHierarchy,
	toggleAiWorkBreakdownPopup,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const triggerInlineCreateIssueAnalytics = useCallback(
		(hierarchyLevel: number) => {
			const analyticsKey = PRODUCT_ANALYTICS_EVENT_NAMES.CREATED_ISSUE_INLINE;
			const [actionSubject, action] = analyticsKey.split(' ');

			fireUIAnalytics(createAnalyticsEvent({ action, actionSubject }), analyticsKey, {
				hierarchyLevel,
			});
		},
		[createAnalyticsEvent],
	);

	const getGroup = useCallback(
		() =>
			groupCombination && Object.keys(groupCombination).length
				? getGroupKey(groupCombination)
				: issue.group,
		[groupCombination, issue.group],
	);

	const isLevelWithinHierarchyRangeFilter = useCallback(
		(level: number): boolean =>
			level >= hierarchyRangeFilter.value.end && level <= hierarchyRangeFilter.value.start,
		[hierarchyRangeFilter],
	);

	const onItemClick = useCallback(
		(issueType: IssueTypeWithProjectId, relation: 'parent' | 'child') => {
			const issueTypeId = issueType.id;
			const hierarchyLevel = issueType.level;
			const parentId = relation === 'parent' ? issue.parent : issue.id;
			const siblingId = relation === 'parent' ? issue.id : null;
			const projectId = issueType.projectId;
			const group = getGroup();

			setIssueTypeIdForHierarchy(hierarchyLevel, issueTypeId);
			setGlobalCreateIssue();

			triggerInlineCreateIssueAnalytics(hierarchyLevel);

			return startInlineCreate({
				parentId,
				siblingId,
				projectId,
				hierarchyLevel,
				issueTypeId,
				group,
				groupCombination,
				source: 'INLINE',
			});
		},
		[
			issue,
			startInlineCreate,
			groupCombination,
			setIssueTypeIdForHierarchy,
			setGlobalCreateIssue,
			triggerInlineCreateIssueAnalytics,
			getGroup,
		],
	);

	const issueTypeComparator = useCallback(
		(a: IssueTypeWithProjectId, b: IssueTypeWithProjectId) => a.name.localeCompare(b.name),
		[],
	);

	const issueTypesByLevel = useMemo(() => {
		let issueTypes: IssueTypeWithProjectId[] = [];

		projects.forEach(({ id: projectId }) => {
			const projectIssueTypes = (issueTypesByProjectId[projectId] || []).map((issueType) => ({
				...issueType,
				projectId,
			}));
			if (projectIssueTypes?.length) {
				issueTypes = issueTypes.concat(projectIssueTypes);
			}
		});

		// Ensures that inline create is prepopulated with the meatball issue's project, if possible
		// This relies on the `uniqBy` function returning all 'first matching' elements in the array
		if (fg('populate_inline_create_with_parent_key_if_possible')) {
			issueTypes = issueTypes.sort((a: IssueTypeWithProjectId, b: IssueTypeWithProjectId) => {
				if (a.projectId === issue.project.id) return -1;
				if (b.projectId === issue.project.id) return 1;
				return 0;
			});
		}

		// For each Issue Type level, we only want to show unique Issue Type names
		issueTypes = uniqBy(issueTypes, ({ level, name }) => `${level}${name}`);

		return groupBy(issueTypes, (issueType) => issueType.level);
	}, [issueTypesByProjectId, projects, issue]);

	const { type } = issue;
	const childLevel = type.level - 1;
	const currentIssueType = {
		...type,
		projectId: issue.project.id,
	};

	const childLevelIssueTypes = useMemo(
		() => issueTypesByLevel[childLevel] && issueTypesByLevel[childLevel].sort(issueTypeComparator),
		[issueTypesByLevel, childLevel, issueTypeComparator],
	);

	const suggestChildIssuesComponent = () => {
		return (
			isLevelWithinHierarchyRangeFilter(childLevel) && (
				<SuggestChildIssues
					key="suggest-child-issues-dropdown-menu-item"
					issue={issue}
					toggleAiWorkBreakdownPopup={toggleAiWorkBreakdownPopup}
				/>
			)
		);
	};

	return (
		<MenuGroup maxHeight={350}>
			<DropdownItemGroup isScrollable>
				<IssueTypeItem
					onItemClick={onItemClick}
					issueType={currentIssueType}
					relation="parent"
					isDisabled={isDisabled}
				>
					{formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.createIssueIssueTermRefresh
							: messages.createIssue,
						{ issueType: currentIssueType.name },
					)}
				</IssueTypeItem>
				{!isNil(childLevelIssueTypes) &&
					isLevelWithinHierarchyRangeFilter(childLevel) &&
					childLevelIssueTypes.map((issueType) => (
						<IssueTypeItem
							key={issueType.id}
							onItemClick={onItemClick}
							issueType={issueType}
							relation="child"
							isDisabled={isDisabled}
							elemBefore={<ChildIcon label={formatMessage(messages.childIconLabel)} />}
						>
							{formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.createIssueIssueTermRefresh
									: messages.createIssue,
								{ issueType: issueType.name },
							)}
						</IssueTypeItem>
					))}
			</DropdownItemGroup>
			{expVal('support-ai-work-breakdown-in-plans', 'isEnabled', false) &&
				suggestChildIssuesComponent()}
		</MenuGroup>
	);
};

export default CreateIssue;
