import React, { Component } from 'react';
import classNames from 'classnames';
import * as R from 'ramda';
import StandardButton from '@atlaskit/button/standard-button';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import Popup from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import Spinner from '@atlaskit/spinner';
import AkTextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';

import { injectIntl } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import DropMenu, { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import InlineEdit from '@atlassian/jira-portfolio-3-common/src/inline-edit/index.tsx';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import {
	isDefined,
	values,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as issueStyles from '../issue/styles.module.css';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { InlineCreateProps as Props, State } from './types.tsx';

const isInlineCreateRow = (
	target: EventTarget | null,
	id: string,
	projectMenu: HTMLElement | null | undefined,
	issueMenu: HTMLElement | null | undefined,
): boolean => {
	let node = target;

	// Drop menu content opens in a portal, so we need to check the menu refs to
	// know if the target belongs to the scope of this component
	if (node instanceof Node && projectMenu && projectMenu.contains(node)) {
		return true;
	}

	if (node instanceof Node && issueMenu && issueMenu.contains(node)) {
		return true;
	}

	while (node instanceof Node) {
		if (node instanceof HTMLElement && node.dataset.issue === id) {
			return true;
		}
		node = node.parentNode;
	}
	return false;
};
// eslint-disable-next-line jira/react/no-class-components
export class InlineCreate extends Component<Props, State> {
	// eslint-disable-next-line react/sort-comp
	projectMenu: HTMLElement | null | undefined;

	issueMenu: HTMLElement | null | undefined;

	timeoutId: NodeJS.Timeout | undefined;

	initialProjectIsDefined = false;

	state = {
		key: Math.random(),
		isProjectMenuOpen: false,
	};

	issueSummaryInputRef: HTMLInputElement | null | undefined;

	// eslint-disable-next-line react/sort-comp
	static defaultProps = {
		getContainer: getBody,
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		setDefaultProjectId: () => {},
		isGlobalCreate: false,
	};

	clickOutsideListener = (e: MouseEvent) => {
		if (
			!isInlineCreateRow(e.target, this.props.id, this.projectMenu, this.issueMenu) &&
			!this.props.isOpenLocked
		) {
			this.props.cancelInlineCreate();
		}
	};

	escapeKeyListener = (e: KeyboardEvent) => {
		/* istanbul ignore else */
		if (e.key === 'Escape') {
			this.props.cancelInlineCreate();
		}
	};

	focusOnSummaryField = () => {
		this.setState({ isProjectMenuOpen: false }, () => {
			// this is necessary to set the focus back on the issue summary input field after a project has been selected
			if (this.issueSummaryInputRef) {
				this.issueSummaryInputRef.focus();
			}
		});
	};

	setSelectedProjectId = (id: number, currentLevelIssueTypes: number[]) => {
		const {
			setProjectId,
			projects,
			issueTypeId,
			setIssueTypeId,
			setDefaultProjectId,
			isGlobalCreate,
		} = this.props;
		const project = projects[id];
		if (!project) {
			throw new Error(`Project with id ${id} is not found in plan`);
		}
		setProjectId(id);
		if (isGlobalCreate === true) {
			setDefaultProjectId(id);
		}
		if (!project.issueTypeIds.includes(issueTypeId)) {
			const newIssueType = R.head(R.intersection(project.issueTypeIds, currentLevelIssueTypes));
			if (!isDefined(newIssueType)) {
				throw new Error(
					`Available project with id ${
						project.id
					} must contain an issue type of current level (one of ${currentLevelIssueTypes.join(
						', ',
					)})`,
				);
			}
			setIssueTypeId(newIssueType);
		}
	};

	saveIssue = (title: string) => {
		const titleValue = title && title.trim();
		traceUFOPress(KEYS.CREATE_ISSUE);
		if (isDefined(titleValue) && titleValue.length > 0) {
			this.props.createIssueWithTitle(title);
		}
		this.setState({ key: Math.random() }, () => {
			// JPO-19344 Trigger focus manually
			if (this.issueSummaryInputRef) {
				this.issueSummaryInputRef.focus();
			}
		});
	};

	doesIssueSummaryMatchFilters = (summary: string) =>
		summary.length === 0 || this.props.matchFilter(summary);

	validateIssueSummary = (summary: string) => {
		// if the issue summary is not valid, we return a warning message which disables the "confirm" button of <InlineEdit />
		const {
			intl: { formatMessage },
		} = this.props;

		if (summary.trim().length === 0) {
			return formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.blankInputErrorMessageIssueTermRefresh
					: messages.blankInputErrorMessage,
			);
		}

		if (summary.length > 255) {
			return formatMessage(messages.tooLongInputErrorMessage);
		}

		// if the issue summary is valid, we return undefined which enables the "confirm" button of <InlineEdit />
		return undefined;
	};

	componentDidMount() {
		this.props
			.getContainer()
			.addEventListener('click', this.clickOutsideListener, { capture: true });
		this.props.getContainer().addEventListener('keydown', this.escapeKeyListener);

		const { isSavingIssue } = this.props;

		/* JPO-20440 We trigger focus manually here. setTimeout is added to make sure the focus stays in issue summary.
		 * If no setTimeout, the focus somehow moved to the last active element.
		 */
		this.timeoutId = setTimeout(() => {
			// Checks if initial project is defined and not currently saving new issue
			if (this.initialProjectIsDefined && !isSavingIssue && this.issueSummaryInputRef) {
				this.issueSummaryInputRef.focus();
			}
		}, 0);
	}

	componentWillUnmount() {
		this.props
			.getContainer()
			.removeEventListener('click', this.clickOutsideListener, { capture: true });
		this.props.getContainer().removeEventListener('keydown', this.escapeKeyListener);

		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
	}

	/**
	 * Filter projects dropdown if group by Release or Component is enabled
	 */
	getFilteredProjects(projectsForCurrentIssueTypeLevel: Project[]) {
		const { projectId, fixVersions, components } = this.props;
		let filteredProjects = projectsForCurrentIssueTypeLevel;

		if (fixVersions && fixVersions.length) {
			// Filter by projects by fix versions if group by "release" is enabled
			const projectsForCurrentReleaseGroup: Project[] = filteredProjects.filter(
				(project) =>
					project.id === projectId ||
					(project.versions &&
						R.intersection(
							project.versions,
							fixVersions.map((fixVersion) => fixVersion.toString()),
						).length > 0),
			);

			filteredProjects =
				projectsForCurrentReleaseGroup.length > 0
					? projectsForCurrentReleaseGroup
					: filteredProjects;
		} else if (components && components.length) {
			// Filter by projects by components if group by "component" is enabled
			const projectsForCurrentComponentGroup: Project[] = filteredProjects.filter(
				(project) =>
					project.id === projectId ||
					(project.components &&
						project.components.find((component) => components.includes(component.id))),
			);
			filteredProjects =
				projectsForCurrentComponentGroup.length > 0
					? projectsForCurrentComponentGroup
					: filteredProjects;
		}

		return filteredProjects;
	}

	render() {
		const {
			id,
			intl: { formatMessage },
			issueTypeId,
			hierarchyLevel,
			projectId,
			issueTypes,
			orderedIssueTypes,
			projects,
			cancelInlineCreate,
			setIssueTypeId,
			rootIndex,
			isReadOnly = false,
			setIssueTypeIdForHierarchy,
			isSavingIssue,
			fixVersions,
			components,
		} = this.props;
		const { isProjectMenuOpen, key } = this.state;

		const issueType = issueTypes[issueTypeId];
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const currentLevelIssueTypes = orderedIssueTypes.map(({ id }) => id);
		const projectsForCurrentIssueTypeLevel = values(projects).filter(
			(project) => R.intersection(project.issueTypeIds, currentLevelIssueTypes).length > 0,
		);

		const filteredProjects = this.getFilteredProjects(projectsForCurrentIssueTypeLevel);

		// if the plan is grouped by component or release AND:
		// - if there are several relevant projects in the group, the user has to select a project in the projects dropdown, which then updates the current project in the state.
		// - if there is only one relevant project in the group, the projects dropdown only renders one project and is disabled, so the user cannot select a project.
		// Therefore we need to set the project ourselves in the state otherwise creating an issue will fail since the project is undefined.
		if (
			((components && components.length) || (fixVersions && fixVersions.length)) &&
			filteredProjects.length === 1
		) {
			this.setSelectedProjectId(filteredProjects[0].id, currentLevelIssueTypes);
		}

		const project = projects[projectId] || (filteredProjects.length === 1 && filteredProjects[0]);

		this.initialProjectIsDefined = !!project;

		const savingIssuePlaceholderMessage = fg('jira-issue-terminology-refresh-m3')
			? messages.savingIssuePlaceholderIssueTermRefresh
			: messages.savingIssuePlaceholder;
		const titlePlaceholderMessage = fg('jira-issue-terminology-refresh-m3')
			? messages.titlePlaceholderIssueTermRefresh
			: messages.titlePlaceholder;

		return (
			<div
				data-name="issue-inline-create"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={`${issueStyles.container} ${
					(rootIndex + 1) % 2 === 0 ? issueStyles.even : issueStyles.odd
				}`}
				data-issue={id}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.dropdown} data-name="issueTypes">
					<DropMenu
						shouldRenderToParent={!fg('plan-timeline-non-transposed') ? false : undefined}
						trigger={({ triggerRef, ...props }) => (
							<StandardButton
								ref={triggerRef}
								{...props}
								isDisabled={
									isReadOnly || orderedIssueTypes.length < 2 || !this.initialProjectIsDefined
								}
								iconAfter={<ChevronDownIcon label="" />}
								aria-label={
									issueType
										? issueType.name
										: formatMessage(
												fg('jira-issue-terminology-refresh-m3')
													? messages.selectIssueTypeIssueTermRefresh
													: messages.selectIssueType,
											)
								}
							>
								<div
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
									className={styles.icon}
									style={{
										// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
										backgroundSize: 'contain',
										backgroundImage: issueType && `url(${issueType.iconUrl})`,
										// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
										marginRight: token('space.300', '24px'),
									}}
									data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.issue-type-icon.${
										issueType && issueType.id
									}`}
								/>
							</StandardButton>
						)}
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.issue-types-select"
					>
						{this.initialProjectIsDefined ? (
							<DropMenuItemGroup
								ref={(ele) => {
									this.issueMenu = ele;
								}}
							>
								{orderedIssueTypes
									.filter((item) => project.issueTypeIds.includes(item.id))
									.map((item: IssueType) => (
										<DropMenuItem
											id={item.id}
											onClick={() => {
												setIssueTypeIdForHierarchy(hierarchyLevel, item.id);
												setIssueTypeId(item.id);
											}}
											key={item.id}
										>
											{item.name}
										</DropMenuItem>
									))}
							</DropMenuItemGroup>
						) : null}
					</DropMenu>
				</div>

				{values(projects).length > 1 && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.dropdown} data-name="projects">
						<DropMenu
							defaultOpen={!this.initialProjectIsDefined}
							shouldRenderToParent={!fg('plan-timeline-non-transposed') ? false : undefined}
							trigger={({ triggerRef, ...props }) => (
								<StandardButton
									ref={triggerRef}
									{...props}
									isDisabled={isReadOnly || filteredProjects.length === 1}
									iconAfter={<ChevronDownIcon label="" />}
								>
									<div
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className={classNames({
											[styles.projectIcon]: this.initialProjectIsDefined,
										})}
										style={{
											// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
											...(this.initialProjectIsDefined
												? {
														backgroundImage: `url(${project.avatarUrl})`,
													}
												: {}),
											// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
											marginRight: token('space.300', '24px'),
										}}
									>
										{this.initialProjectIsDefined
											? project.key
											: formatMessage(messages.selectProject)}
									</div>
								</StandardButton>
							)}
							testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.select-project"
							isOpen={isProjectMenuOpen}
						>
							<DropMenuItemGroup
								ref={(ele) => {
									this.projectMenu = ele;
								}}
							>
								{filteredProjects.map((item: Project) => (
									<DropMenuItem
										id={item.id}
										onClick={() => {
											this.setSelectedProjectId(item.id, currentLevelIssueTypes);
											this.focusOnSummaryField();
										}}
										key={item.id}
										isSelected={item.id === project.id}
									>
										{item.name}
									</DropMenuItem>
								))}
							</DropMenuItemGroup>
						</DropMenu>
					</div>
				)}
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.inlineEdit}
					data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.inline-edit-input"
				>
					<InlineEdit
						defaultValue=""
						editView={({ errorMessage, ...fieldProps }) =>
							!fg('plan-timeline-non-transposed') ? (
								<Popup
									placement="right"
									isOpen={
										errorMessage !== undefined ||
										(fieldProps &&
											!fieldProps.isInvalid &&
											!this.doesIssueSummaryMatchFilters(fieldProps.value))
									}
									autoFocus={false}
									content={() => (
										<Box
											xcss={popupContentStyles}
											testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.inline-dialog"
										>
											{errorMessage ||
												formatMessage(
													fg('jira-issue-terminology-refresh-m3')
														? messages.notMatchFilterMessageIssueTermRefresh
														: messages.notMatchFilterMessage,
												)}
										</Box>
									)}
									trigger={({ ref }) => (
										/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
										<div ref={ref} className={isSavingIssue ? styles.container : ''}>
											<AkTextField
												{...fieldProps}
												elemAfterInput={
													fieldProps &&
													(fieldProps.isInvalid ||
														!this.doesIssueSummaryMatchFilters(fieldProps.value)) && (
														<WarningIcon
															label=""
															primaryColor={token('color.icon.warning', colors.Y300)}
														/>
													)
												}
												isReadOnly={!this.initialProjectIsDefined || isSavingIssue}
												maxLength={256}
												placeholder={formatMessage(
													isSavingIssue ? savingIssuePlaceholderMessage : titlePlaceholderMessage,
												)}
												aria-label={formatMessage(
													fg('jira-issue-terminology-refresh-m3')
														? messages.ariaLabelIssueTermRefresh
														: messages.ariaLabel,
												)}
												ref={(inputEl?: HTMLInputElement | null) => {
													if (inputEl) {
														this.issueSummaryInputRef = inputEl;
													}
												}}
											/>
											{isSavingIssue && (
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
												<div className={styles.spinner}>
													<Spinner testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.saving-spinner" />
												</div>
											)}
										</div>
									)}
								/>
							) : (
								// if the issue summary is valid but it does not match the applied filters,
								// we display an info message to the user
								<InlineDialog
									isOpen={
										errorMessage ||
										(fieldProps &&
											!fieldProps.isInvalid &&
											!this.doesIssueSummaryMatchFilters(fieldProps.value))
									}
									placement="right"
									content={
										errorMessage ||
										formatMessage(
											fg('jira-issue-terminology-refresh-m3')
												? messages.notMatchFilterMessageIssueTermRefresh
												: messages.notMatchFilterMessage,
										)
									}
									noHorizontalScroll
									testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.inline-dialog"
								>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<div className={isSavingIssue ? styles.container : ''}>
										<AkTextField
											{...fieldProps}
											elemAfterInput={
												fieldProps &&
												(fieldProps.isInvalid ||
													!this.doesIssueSummaryMatchFilters(fieldProps.value)) && (
													<WarningIcon
														label=""
														primaryColor={token('color.icon.warning', colors.Y300)}
													/>
												)
											}
											isReadOnly={!this.initialProjectIsDefined || isSavingIssue}
											maxLength={256}
											placeholder={formatMessage(
												isSavingIssue ? savingIssuePlaceholderMessage : titlePlaceholderMessage,
											)}
											aria-label={formatMessage(
												fg('jira-issue-terminology-refresh-m3')
													? messages.ariaLabelIssueTermRefresh
													: messages.ariaLabel,
											)}
											ref={(inputEl?: HTMLInputElement | null) => {
												if (inputEl) {
													this.issueSummaryInputRef = inputEl;
												}
											}}
										/>
										{isSavingIssue && (
											// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
											<div className={styles.spinner}>
												<Spinner testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.inline-create.saving-spinner" />
											</div>
										)}
									</div>
								</InlineDialog>
							)
						}
						hideActionButtons={isSavingIssue}
						keepEditViewOpenOnBlur
						key={key}
						onCancel={cancelInlineCreate}
						onConfirm={this.saveIssue}
						readView={() => <></>}
						startWithEditViewOpen
						validate={this.validateIssueSummary}
					/>
				</div>
			</div>
		);
	}
}

export default injectIntl(InlineCreate);

const popupContentStyles = xcss({
	padding: 'space.200',
});
