import React from 'react';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { usePlan } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/plan/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import messages from './messages.tsx';

export default function DocumentTitleWrapper({ pageHeading }: { pageHeading?: string }) {
	const { data } = usePlan();
	const { formatMessage } = useIntl();

	const productName = formatMessage(messages.plans);

	const documentTitle =
		isDefined(data?.title) && isDefined(pageHeading)
			? `${pageHeading} - ${data?.title} - ${productName}`
			: `${productName}`;

	return <DocumentTitle title={documentTitle} />;
}
