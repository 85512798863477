import React, { Component } from 'react';
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu';
import type { DropdownMenuProps } from '@atlaskit/dropdown-menu/types';
import { fg } from '@atlassian/jira-feature-gating';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import type { State, OnOpenChangeType } from './types.tsx';

export const DropMenuItem = AnalyticsEventToProps('dropMenuItem', {
	onClick: 'clicked',
})(DropdownItem);

// eslint-disable-next-line jira/react/no-class-components
export default class DropMenu extends Component<DropdownMenuProps<HTMLButtonElement>, State> {
	static defaultProps = {
		defaultOpen: false,
		isLoading: false,
		isOpen: false,
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onOpenChange: () => {},
		placement: 'bottom-start',
		testId: '',
	};

	state = {
		isOpen: this.props.defaultOpen || false,
	};

	UNSAFE_componentWillReceiveProps(nextProps: DropdownMenuProps) {
		/* istanbul ignore else */
		if (nextProps.isOpen !== this.props.isOpen) {
			this.setState({ isOpen: !!nextProps.isOpen });
		}
	}

	handleOpenChange: OnOpenChangeType = (attrs) => {
		this.setState({ isOpen: attrs.isOpen });
		/* istanbul ignore else */
		if (this.props.onOpenChange) {
			this.props.onOpenChange(attrs);
		}
	};

	render() {
		const { isOpen } = this.state;
		const {
			children,
			isLoading,
			placement,
			testId,
			trigger,
			shouldRenderToParent = true,
		} = this.props;

		return (
			<DropdownMenu
				isOpen={isOpen}
				isLoading={isLoading}
				onOpenChange={this.handleOpenChange}
				placement={placement}
				testId={testId}
				trigger={trigger}
				shouldRenderToParent={!fg('plan-timeline-non-transposed') ? shouldRenderToParent : true}
			>
				{children}
			</DropdownMenu>
		);
	}
}
