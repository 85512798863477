import React, { type KeyboardEvent, type MouseEvent } from 'react';
import * as R from 'ramda';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { getGroupKey } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/util.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import { getInlineCreateIssueTypeId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import SuggestChildIssues from '../ai-suggest-child-issues/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

function CreateIssue(props: Props) {
	const {
		groupCombination,
		hierarchy,
		hierarchyLevelsForType,
		isDisabled = false,
		issue,
		projects,
		setGlobalCreateIssue,
		setIssueTypeIdForHierarchy,
		startInlineCreate,
		typeToLevel,
		toggleAiWorkBreakdownPopup,
	} = props;
	const { group, id, level, parent, project, type } = issue;
	const currentLevel = hierarchy.find((hierarchyLevel) => level === hierarchyLevel.level);
	if (!isDefined(currentLevel)) {
		return null;
	}

	const hierarchyLevelTitle = (hierarchyLevel: { readonly title: string }): string =>
		hierarchyLevel.title.toLowerCase();

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getInlineCreateProject = (level: number) => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const projectsForLevel = projects.filter((project) =>
			// eslint-disable-next-line @typescript-eslint/no-shadow
			project.issueTypeIds.some((id) => typeToLevel(id) === level),
		);
		// eslint-disable-next-line @typescript-eslint/no-shadow
		return projectsForLevel.some(({ id }) => id === project.id) ? project : projectsForLevel[0];
	};

	const hierarchyLevels = hierarchyLevelsForType(type.id);
	const childLevel = R.head(hierarchyLevels);

	let childInlineCreateProject: Project | undefined;
	if (isDefined(childLevel)) {
		childInlineCreateProject = getInlineCreateProject(childLevel.level);
	}
	const groupKey =
		groupCombination && Object.keys(groupCombination).length
			? getGroupKey(groupCombination)
			: group;

	const suggestChildIssuesComponent = () => {
		return (
			isDefined(childLevel) && (
				<SuggestChildIssues
					key="suggest-child-issues-dropdown-menu-item"
					issue={issue}
					toggleAiWorkBreakdownPopup={toggleAiWorkBreakdownPopup}
				/>
			)
		);
	};

	const fireInlineCreateIssueAnalytics = (
		analyticsEvent: UIAnalyticsEvent,
		hierarchyLevel: number,
	) => {
		const analyticsKey = PRODUCT_ANALYTICS_EVENT_NAMES.CREATED_ISSUE_INLINE;
		const [actionSubject, eventAction] = analyticsKey.split(' ');

		fireUIAnalytics(analyticsEvent.update({ action: eventAction, actionSubject }), analyticsKey, {
			hierarchyLevel,
		});
	};

	return (
		<DropMenuItemGroup>
			<DropMenuItem
				id={level}
				onClick={(_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
					const inlineCreateProject = getInlineCreateProject(currentLevel.level);
					if (!isDefined(inlineCreateProject)) {
						return;
					}
					const issueTypeId = type.id;
					if (isDefined(issueTypeId)) {
						setIssueTypeIdForHierarchy(currentLevel.level, issueTypeId);
						setGlobalCreateIssue();
						// triggering analytics
						fireInlineCreateIssueAnalytics(analyticsEvent, currentLevel.level);
						return startInlineCreate({
							parentId: parent,
							hierarchyLevel: currentLevel.level,
							issueTypeId,
							projectId: inlineCreateProject.id,
							siblingId: id,
							group: groupKey,
							groupCombination,
							source: 'INLINE',
						});
					}
				}}
				key={level}
				isDisabled={isDisabled}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.issueTypeLabel}>
					<FormattedMessage
						{...(fg('jira-issue-terminology-refresh-m3')
							? messages.createIssueIssueTermRefresh
							: messages.createIssue)}
						values={{ issueType: hierarchyLevelTitle(currentLevel) }}
					/>
				</span>
			</DropMenuItem>
			{isDefined(childInlineCreateProject) && isDefined(childLevel) && (
				<DropMenuItem
					id={childLevel.level}
					isDisabled={isDisabled}
					onClick={(_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
						const issueTypeId = getInlineCreateIssueTypeId(
							childLevel.level,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							childInlineCreateProject as Project,
							props,
						);
						setIssueTypeIdForHierarchy(childLevel.level, issueTypeId);
						setGlobalCreateIssue();
						// triggering analytics
						fireInlineCreateIssueAnalytics(analyticsEvent, childLevel.level);
						return startInlineCreate({
							parentId: id,
							hierarchyLevel: childLevel.level,
							issueTypeId,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							projectId: (childInlineCreateProject as Project).id,
							siblingId: null,
							group: groupKey,
							groupCombination,
							source: 'INLINE',
						});
					}}
					key={childLevel.level}
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className={styles.issueTypeLabel}>
						<FormattedMessage
							{...(fg('jira-issue-terminology-refresh-m3')
								? messages.createIssueIssueTermRefresh
								: messages.createIssue)}
							values={{ issueType: hierarchyLevelTitle(childLevel) }}
						/>
					</span>
				</DropMenuItem>
			)}
			{expVal('support-ai-work-breakdown-in-plans', 'isEnabled', false) &&
				suggestChildIssuesComponent()}
		</DropMenuItemGroup>
	);
}

export default CreateIssue;
