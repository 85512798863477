import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	spotlightEpicReparentBody: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-epic-reparent.spotlight-epic-reparent-body',
		defaultMessage:
			'To complete your planning spree, drag and drop an Epic under an Initiative. This lets you roll-up values from all the child issues to the Initiative level.',
		description: 'The body for cross team templates onboarding epic reparent card.',
	},
	spotlightNoEpicsBody: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-epic-reparent.spotlight-no-epics-body',
		defaultMessage: 'To keep going, add a project to your plan that includes at least one Epic.',
		description: 'The body for cross team templates onboarding no epics card.',
	},
	spotlightTmpEpicBody: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-epic-reparent.spotlight-tmp-epic-body',
		defaultMessage: "Right now, re-parenting issues isn't possible for team-managed projects.",
		description: 'The body for cross team templates onboarding tmp epics card.',
	},
	spotlightGotIt: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-epic-reparent.spotlight-got-it',
		defaultMessage: 'Got it',
		description: 'The got it button for cross team templates onboarding spotlight card.',
	},
	spotlightGoToIssueSources: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-epic-reparent.spotlight-go-to-issue-sources',
		defaultMessage: 'Go to issue sources',
		description:
			'The go to issue source button for cross team templates onboarding spotlight card.',
	},
	spotlightSkip: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-epic-reparent.spotlight-skip',
		defaultMessage: 'Skip',
		description: 'The skip button for cross team templates onboarding spotlight card.',
	},
	spotlightContinueAnyway: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-epic-reparent.spotlight-continue-anyway',
		defaultMessage: 'Continue anyway',
		description: 'The continue anyway button for cross team templates onboarding spotlight card.',
	},
	spotlightEpicReparentBodyIssueTermRefresh: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-epic-reparent.spotlight-epic-reparent-body-issue-term-refresh',
		defaultMessage:
			'To complete your planning spree, drag and drop an Epic under an Initiative. This lets you roll-up values from all the child issues to the Initiative level.',
		description: 'The body for cross team templates onboarding epic reparent card.',
	},
	spotlightTmpEpicBodyIssueTermRefresh: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-epic-reparent.spotlight-tmp-epic-body-issue-term-refresh',
		defaultMessage: "Right now, re-parenting issues isn't possible for team-managed projects.",
		description: 'The body for cross team templates onboarding tmp epics card.',
	},
	spotlightGoToIssueSourcesIssueTermRefresh: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-epic-reparent.spotlight-go-to-issue-sources-issue-term-refresh',
		defaultMessage: 'Go to issue sources',
		description:
			'The go to issue source button for cross team templates onboarding spotlight card.',
	},
});
