import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import { SUB_TASK_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import type { HierarchyRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy/index.tsx';
import type { ProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	MAX_BULK_CHANGE_ISSUES,
	SCENARIO_ISSUE_ID_PREFIX,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import RemoveIssuesDialog from './dialog/remove-issues/index.tsx';
import SetAssigneeDialog from './dialog/set-assignee/index.tsx';
import SetCPVersionsDialog from './dialog/set-cross-project-versions/index.tsx';
import SetDateDialog from './dialog/set-date/index.tsx';
import SetEstimateDialog from './dialog/set-estimate/index.tsx';
import SetLabelDialog from './dialog/set-label/index.tsx';
import SetParentDialog from './dialog/set-parent/index.tsx';
import SetRankDialog from './dialog/set-rank/index.tsx';
import SetSprintDialog from './dialog/set-sprint/index.tsx';
import SetTeamsDialog from './dialog/set-teams/index.tsx';
import SetVersionsDialog from './dialog/set-versions/index.tsx';
import messages from './messages.tsx';
import {
	type GetDropOption,
	type Props,
	REMOVE_FROM_PLAN,
	SET_RELEASE,
	SET_CP_RELEASE,
	MOVE_ISSUES,
	SET_ASSIGNEE,
	SET_TEAM,
	SET_RANK,
	SET_SPRINT,
	SET_DATES,
	BULK_CHANGE,
	SET_ESTIMATE,
	SET_LABEL,
} from './types.tsx';

type SelectedIssues = Issue[];

export const isSelectedIssuesSameLevel = (selectedIssues: SelectedIssues): boolean => {
	if (selectedIssues && selectedIssues.length) {
		return !selectedIssues.some((issue) => issue.level !== selectedIssues[0].level);
	}
	return false;
};

export const isSelectedIssuesTopLevelHierarchy = (
	selectedIssues: SelectedIssues,
	hierarchyRange: HierarchyRange,
): boolean => {
	if (selectedIssues && selectedIssues.length) {
		return selectedIssues.some((issue) => issue.level === hierarchyRange.max);
	}
	return false;
};

export const hasSubTaskLevel = (selectedIssues: SelectedIssues): boolean => {
	if (selectedIssues && selectedIssues.length) {
		return selectedIssues.some((issue) => issue.level === SUB_TASK_LEVEL);
	}
	return false;
};

export const isValidSelection = (selectedIssues: SelectedIssues): boolean => {
	if (selectedIssues && selectedIssues.length) {
		return selectedIssues.some(({ id }) => id.includes(SCENARIO_ISSUE_ID_PREFIX));
	}
	return false;
};

export const isIssuesSelectionOverLimit = (selectedIssues: SelectedIssues): boolean => {
	if (selectedIssues && selectedIssues.length) {
		return selectedIssues.length > MAX_BULK_CHANGE_ISSUES;
	}
	return false;
};

export const isSelectedIssuesSameProject = (selectedIssues: SelectedIssues): boolean => {
	if (selectedIssues && selectedIssues.length) {
		return !selectedIssues.some((issue) => issue.project !== selectedIssues[0].project);
	}
	return false;
};

export const isSelectedIssuesFromProjectWithReleasesDisabled = (
	selectedIssues: SelectedIssues,
	projectsById: ProjectsById,
): boolean => {
	if (selectedIssues && selectedIssues.length) {
		return selectedIssues.some((issue) => projectsById[issue.project]?.isReleasesEnabled !== true);
	}
	return false;
};

export type MapKey =
	| 'setAssignee'
	| 'dates'
	| 'setEstimate'
	| 'setLabel'
	| 'setParent'
	| 'setRank'
	| 'setReleases'
	| 'setSprint'
	| 'setTeam'
	| 'bulkChange'
	| 'removeFromPlanTitle';

export const DROPMENU_MAP: Record<MapKey, GetDropOption> = {
	setAssignee: () => ({
		type: SET_ASSIGNEE,
		label: commonMessages.assignee,
		// @ts-expect-error - TS2322 - The expected type comes from property 'dialog' which is declared here on type 'DropOption'
		dialog: SetAssigneeDialog,
		analyticsId: 'Assignee',
	}),
	dates: () => ({
		type: SET_DATES,
		label: commonMessages.dates,
		// @ts-expect-error - TS2322 - The expected type comes from property 'dialog' which is declared here on type 'DropOption'
		dialog: SetDateDialog,
		analyticsId: 'Dates',
	}),
	setEstimate: () => ({
		type: SET_ESTIMATE,
		label: commonMessages.estimate,
		// @ts-expect-error - TS2322 - The expected type comes from property 'dialog' which is declared here on type 'DropOption'
		dialog: SetEstimateDialog,
		analyticsId: 'Estimate',
	}),
	setLabel: () => ({
		type: SET_LABEL,
		label: messages.labels,
		// @ts-expect-error - TS2322 - The expected type comes from property 'dialog' which is declared here on type 'DropOption'
		dialog: SetLabelDialog,
		analyticsId: 'Labels',
	}),
	// @ts-expect-error - TS2322 - provides no match for the signature 'new (props: any, context?: any): Component<any, any, any>'.
	setParent: ({ selectedIssues, hierarchyRange }: Props) => {
		const isSameLevel = isSelectedIssuesSameLevel(selectedIssues);
		const isTopLevel = isSelectedIssuesTopLevelHierarchy(selectedIssues, hierarchyRange);
		const disabled = !isSameLevel && isTopLevel;

		const issuesTopLevelTooltip = fg('jira-issue-terminology-refresh-m3')
			? messages.issuesTopLevelTooltipIssueTermRefresh
			: messages.issuesTopLevelTooltip;
		const issuesSameLevelTooltip = fg('jira-issue-terminology-refresh-m3')
			? messages.issuesSameLevelTooltipIssueTermRefresh
			: messages.issuesSameLevelTooltip;

		const disabledTooltip = isSameLevel ? issuesTopLevelTooltip : issuesSameLevelTooltip;
		return {
			type: MOVE_ISSUES,
			label: messages.setParent,
			disabled,
			disabledTooltip,
			dialog: SetParentDialog,
			analyticsId: 'Parent',
		};
	},
	setRank: () => ({
		type: SET_RANK,
		label: messages.setRank,
		// @ts-expect-error - TS2322 - The expected type comes from property 'dialog' which is declared here on type 'DropOption'
		dialog: SetRankDialog,
		analyticsId: 'Rank',
	}),
	// @ts-expect-error - TS2322 - provides no match for the signature 'new (props: any, context?: any): Component<any, any, any>'.
	setReleases: ({ selectedIssues, cpVersionsForSelectedIssues, projectsById }: Props) => {
		const isSameProject = isSelectedIssuesSameProject(selectedIssues);

		const hasReleasesDisabled = isSelectedIssuesFromProjectWithReleasesDisabled(
			selectedIssues,
			projectsById,
		);
		const hasCPRs = !R.isEmpty(cpVersionsForSelectedIssues);
		const disabled = hasReleasesDisabled || (!hasCPRs && !isSameProject);
		const action = hasCPRs && !isSameProject ? SET_CP_RELEASE : SET_RELEASE;
		const dialog = hasCPRs && !isSameProject ? SetCPVersionsDialog : SetVersionsDialog;

		const releaseDisabledMenuItemTooltip = fg('jira-issue-terminology-refresh-m3')
			? messages.releaseDisabledMenuItemTooltipIssueTermRefresh
			: messages.releaseDisabledMenuItemTooltip;
		const releaseMenuItemTooltip = fg('jira-issue-terminology-refresh-m3')
			? messages.releaseMenuItemTooltipIssueTermRefresh
			: messages.releaseMenuItemTooltip;

		return {
			type: action,
			label: commonMessages.releases,
			disabled,
			disabledTooltip: hasReleasesDisabled
				? releaseDisabledMenuItemTooltip
				: releaseMenuItemTooltip,
			dialog,
			analyticsId: 'Release',
		};
	},
	// @ts-expect-error - TS2322 - provides no match for the signature 'new (props: any, context?: any): Component<any, any, any>'.
	setSprint: ({ selectedIssues }: Props) => {
		const disabled = hasSubTaskLevel(selectedIssues);
		return {
			type: SET_SPRINT,
			label: messages.setSprint,
			disabled,
			disabledTooltip: messages.sprintMenuItemTooltip,
			dialog: SetSprintDialog,
			analyticsId: 'Sprint',
		};
	},
	// @ts-expect-error - TS2322 - provides no match for the signature 'new (props: any, context?: any): Component<any, any, any>'.
	setTeam: ({ selectedIssues }: Props) => {
		const disabled = hasSubTaskLevel(selectedIssues);
		return {
			type: SET_TEAM,
			label: messages.setTeam,
			disabled,
			disabledTooltip: messages.teamMenuItemTooltip,
			dialog: SetTeamsDialog,
			analyticsId: 'Team',
		};
	},
	// Other actions
	bulkChange: ({ selectedIssues }: Props) => {
		const disabled = isIssuesSelectionOverLimit(selectedIssues) || isValidSelection(selectedIssues);

		const issueLimitExceededTooltip = fg('jira-issue-terminology-refresh-m3')
			? messages.issueLimitExceededTooltipIssueTermRefresh
			: messages.issueLimitExceededTooltip;
		const bulkChangeInJiraTooltip = fg('jira-issue-terminology-refresh-m3')
			? messages.bulkChangeInJiraTooltipIssueTermRefresh
			: messages.bulkChangeInJiraTooltip;

		const disabledTooltip = isIssuesSelectionOverLimit(selectedIssues)
			? issueLimitExceededTooltip
			: bulkChangeInJiraTooltip;

		return {
			type: BULK_CHANGE,
			label: messages.bulkChange,
			disabled,
			disabledTooltip,
			analyticsId: 'Bulk-change-in-Jira',
		};
	},
	removeFromPlanTitle: () => ({
		type: REMOVE_FROM_PLAN,
		label: messages.removeFromPlanTitle,
		// @ts-expect-error - TS2322 - The expected type comes from property 'dialog' which is declared here on type 'DropOption'
		dialog: RemoveIssuesDialog,
		analyticsId: 'Remove-from-plan',
	}),
} as const;
