import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issue: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.skeleton.issue',
		defaultMessage: 'Issue',
		description: 'Header for the issue region in Plans loading state',
	},
	fields: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.skeleton.fields',
		defaultMessage: 'Fields',
		description: 'Header for the fields region in Plans loading state',
	},
	issueIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.skeleton.issue-issue-term-refresh',
		defaultMessage: 'Issue',
		description: 'Header for the issue region in Plans loading state',
	},
});
