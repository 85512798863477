import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createIssue: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.create-issue.create-issue',
		defaultMessage: 'Create {issueType}',
		description: 'Label for drop menu to create new issue from an issue',
	},
	createIssueIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.create-issue.create-issue-issue-term-refresh',
		defaultMessage: 'Create {issueType}',
		description: 'Label for drop menu to create new issue from an issue',
	},
});
