import React from 'react';
import type { VersionDetails } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import { getIdForChangeCategory } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/title-bar/update-jira/utils.tsx';
import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-common/src/api/index.tsx';
import {
	ENTITY,
	SCENARIO_ISSUE_ID_PREFIX,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export const getReleaseLink = (version: VersionDetails) => {
	const { id, projects } = version;
	const project: Project = projects[0];

	return proxyContextSafeUrl(`/browse/${project.key}/fixforversion/${id}`);
};

export default function ReleaseTitle(props: Props) {
	const { details: version } = props;
	const {
		id,
		projects,
		values: { name: releaseName },
	} = version;
	const { avatarUrl: projectAvatar, name: projectName } = projects[0];

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.releaseTitle}
			id={getIdForChangeCategory(id, ENTITY.RELEASE)}
			data-testid={`portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.title.release.release-title-container-${id}`}
		>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={`${styles.projectIcon}`}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={{ backgroundSize: 'contain', backgroundImage: `url(${projectAvatar})` }}
			/>
			<EllipsedWithTooltip content={`${releaseName} (${projectName})`}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.titleText}>
					{id.startsWith(SCENARIO_ISSUE_ID_PREFIX) ? (
						`${releaseName} (${projectName})`
					) : (
						<a href={getReleaseLink(version)} target="_blank" rel="noopener noreferrer">
							{releaseName} ({projectName})
						</a>
					)}
				</div>
			</EllipsedWithTooltip>
		</div>
	);
}
