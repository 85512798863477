import React, { type ReactNode } from 'react';
import * as R from 'ramda';
import { Text } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, type IntlShape } from '@atlassian/jira-intl';
import SelectWireframe from '@atlassian/jira-portfolio-3-common/src/select-wireframe/index.tsx';
import { CheckboxOption } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	OptionType,
	OptionsGroup,
	OptionsType,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Version } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/versions/types.tsx';
import { getOptimizedValue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util.tsx';
import {
	withSlots,
	slots,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { RELEASE_STATUSES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	createVersionOption,
	formatReleaseOptionLabel,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/versions/utils.tsx';
import Cell from '../../column/cell/view.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export const isVersionRelevantToIssue: (arg1: ScopeIssue, arg2: Version) => boolean = (
	issue,
	version,
) => R.contains(issue.project, version.projects);

export const makeGetVersionName =
	({
		versionsById,
		formatMessage,
	}: {
		versionsById: Props['versionsById'];
		formatMessage: IntlShape['formatMessage'];
	}) =>
	(id: string): string => {
		const version = versionsById[id];

		if (R.isNil(version)) {
			return formatMessage(messages.unknownVersion, { id });
		}

		return version.name;
	};

export const makeOptimizedValueMapper =
	({
		versionsById,
		formatMessage,
	}: {
		versionsById: Props['versionsById'];
		formatMessage: IntlShape['formatMessage'];
	}) =>
	(_: unknown, fixVersions?: string[] | null) =>
		// fixVersions could be null or undefined depending on whether it's a scenario change or not
		R.compose(
			R.join(', '),
			R.map(makeGetVersionName({ versionsById, formatMessage })),
		)(fixVersions || []);

export const makeHandleOnChange =
	({ onChange, issue }: { onChange: Props['onChange']; issue: Props['issue'] }) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(selection: any) => {
		const selectionArray = Array.isArray(selection)
			? // eslint-disable-next-line @typescript-eslint/no-explicit-any
				selection.filter(({ value }: { value: any }) => typeof value === 'string')
			: [];
		onChange(
			issue,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			selectionArray.map(({ value }) => value as string),
		);
	};

export const ReleaseCell = ({
	issue,
	isScrolling,
	crossProjectVersionsById,
	versionsById,
	showOptimizations,
	versionsByProjectId,
	sortVersionsByLowestRank,
	sortVersionsByHighestRank,
	onChange,
	isReadOnly = false,
	releaseRollUpForIssue,
	CellSelectWithRollup,
	projectsById,
}: Props) => {
	const { formatMessage } = useIntl();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const versionsMapToOptions = (version: any) =>
		createVersionOption(version, crossProjectVersionsById);

	const optimizedValueMapper = makeOptimizedValueMapper({ versionsById, formatMessage });

	const getRelevantReleases = (): Version[] =>
		R.filter((version) => isVersionRelevantToIssue(issue, version), R.valuesIn(versionsById));

	const getAssignedReleases = (): Version[] => {
		let fixVersions = Array.isArray(issue.fixVersions) ? [...issue.fixVersions] : undefined;
		if (showOptimizations) {
			fixVersions = getOptimizedValue(issue, 'fixVersions');
		}

		if (!fixVersions) {
			return [];
		}

		const knownVersions = R.filter((versionId) => R.has(versionId, versionsById), fixVersions);

		return R.map((versionId) => versionsById[versionId], knownVersions);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getUnreleasedOptionsGroup = (projectIds: Array<any>) => {
		const unreleasedOptionGroups: OptionsGroup[] = R.map((projectId) => {
			const versions = R.filter(
				(version) => version.releaseStatusId !== RELEASE_STATUSES.RELEASED,
				sortVersionsByLowestRank(versionsByProjectId[projectId]),
			);
			return {
				label: formatMessage(messages.unreleased),
				options: R.map(versionsMapToOptions, versions),
			};
		}, projectIds);
		return unreleasedOptionGroups;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getReleasedOptionsGroup = (projectIds: Array<any>) => {
		const releasedOptionGroups: OptionsGroup[] = R.map((projectId) => {
			const versions = R.filter(
				(version) => version.releaseStatusId === RELEASE_STATUSES.RELEASED,
				sortVersionsByHighestRank(versionsByProjectId[projectId]),
			);
			return {
				label: formatMessage(messages.released),
				options: R.map(versionsMapToOptions, versions),
			};
		}, projectIds);
		return releasedOptionGroups;
	};

	const getOptions = (): OptionsGroup[] => {
		const versions = getRelevantReleases();
		const projectIds = Array.from(
			versions.reduce((acc, version) => {
				version.projects.forEach(acc.add, acc);
				return acc;
			}, new Set()),
		);
		return getUnreleasedOptionsGroup(projectIds).concat(getReleasedOptionsGroup(projectIds));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const filterOptions = (option: any, query: string): boolean => {
		const { label: versionName, data } = option;
		const { crossProjectVersionName = '' } = data;
		return (
			versionName.toLowerCase().includes(query.toLowerCase()) ||
			crossProjectVersionName.toLowerCase().includes(query.toLowerCase())
		);
	};

	const getSelections = (): OptionType[] => R.map(versionsMapToOptions, getAssignedReleases());

	const handleOnChange = makeHandleOnChange({ onChange, issue });

	const wireframe = () => {
		const selections = getSelections();
		return (
			<SelectWireframe
				content={!R.isEmpty(selections) ? selections.map((s) => s.label) || '' : ''}
				isReadOnly={showOptimizations}
			/>
		);
	};

	const select = () => {
		const options = getOptions();
		const selections = getSelections();
		const rollupLabels = R.sort(
			(a, b) => a.localeCompare(b),
			(releaseRollUpForIssue || []).map((releaseId) =>
				isDefined(versionsById[releaseId]) ? versionsById[releaseId].name : '',
			),
		);

		if (projectsById[issue.project]?.isReleasesEnabled === false) {
			return (
				<Tooltip
					content={formatMessage(messages.notAvailableTooltip, {
						projectName: projectsById[issue.project].name,
						strong: (chunks: ReactNode[]) => <Text as="strong">{chunks}</Text>,
					})}
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.disabledContainer}>
						{formatMessage(messages.disabledReleaseField)}
					</div>
				</Tooltip>
			);
		}

		return (
			<Cell
				attribute="fixVersions"
				isScrolling={isScrolling}
				issue={issue}
				showOptimizations={showOptimizations}
				valueMapper={optimizedValueMapper}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.release}>
					<CellSelectWithRollup
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.release.release-cell"
						aria-label={formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.releaseSelectorLabelIssueTermRefresh
								: messages.releaseSelectorLabel,
							{
								issue: issue.summary,
							},
						)}
						attribute="release"
						filterOption={filterOptions}
						formatOptionLabel={formatReleaseOptionLabel}
						isClearable
						isDisabled={isReadOnly}
						issueId={issue.id}
						hideSelectedOptions={false}
						closeMenuOnSelect={false}
						isMulti
						components={{
							Option: CheckboxOption,
						}}
						onChange={(option: OptionsType) => handleOnChange(option)}
						options={options}
						placeholder={formatMessage(messages.placeholder)}
						rolledUpValues={rollupLabels}
						value={selections}
					/>
				</div>
			</Cell>
		);
	};

	return isScrolling ? wireframe() : select();
};

export default withSlots({ CellSelectWithRollup: slots.CellSelectWithRollup })(ReleaseCell);
