import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps, { mapStateToPropsOld } from './query.tsx';
import type { StateProps, DispatchProps, OwnProps } from './types.tsx';
import View from './view.tsx';

const CreateIssueOld = connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToPropsOld,
	mapDispatchToProps,
)(View);

const CreateIssue = connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToProps,
	mapDispatchToProps,
)(View);

export default componentWithFG(
	'replace_gethierarchyfilter_gethierarchyrangefilter',
	CreateIssue,
	CreateIssueOld,
);

// delete the three lines above and uncomment this line when removing the "replace_gethierarchyfilter_gethierarchyrangefilter" feature gate
// export default connect<StateProps, DispatchProps, OwnProps, State>(
// 	mapStateToProps,
// 	mapDispatchToProps,
// )(View);
