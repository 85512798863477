import React, { type ReactNode } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { layers } from '@atlaskit/theme/constants';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { TeamAndResourceDetails } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/types.tsx';
import {
	ENTITY,
	SCENARIO_TYPE,
	SCHEDULE_MODE,
	type ScenarioType,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import WarningDetails from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/view.tsx';
import type { ColumnConfig } from '@atlassian/jira-portfolio-3-treegrid/src/common/types.tsx';
import { CellNew, CellOld } from '@atlassian/jira-portfolio-3-treegrid/src/ui/cell/index.tsx';
import { Row } from '@atlassian/jira-portfolio-3-treegrid/src/ui/row/index.tsx';
import { getIdForChangeCategory } from '../../utils.tsx';
import LastModified from '../last-modified/view.tsx';
import messages from '../messages.tsx';
import SelectChange from '../select-change/cell/index.tsx';
import CrossProjectReleaseTitleCell from '../title/cross-project-release/index.tsx';
import IssueTitle from '../title/issue/index.tsx';
import ReleaseTitleCell from '../title/release/index.tsx';
import SprintTitle from '../title/sprint/index.tsx';
import TeamTitleCell from '../title/team/index.tsx';
import type { CustomColumnConfig } from '../types.tsx';
import ValueChange from '../value-change/index.tsx';
import { VALUE_TYPE } from '../value-change/types.tsx';
import WhatChanged from '../what-changed/index.tsx';
import type { Props } from './types.tsx';

export default function BodyRow({
	change,
	rowIndex,
	isUpdating,
	columnConfig,
	SelectChangeCell = SelectChange,
	IssueTitleCell = IssueTitle,
	ValueChangeComponent = ValueChange,
	WhatChangedComponent = WhatChanged,
}: Props) {
	const { formatMessage } = useIntl();

	if (!change) return <></>;

	const commitWarningCellRenderer = () => {
		if (isEmpty(change.warnings)) {
			return null;
		}

		return (
			<WarningDetails
				warnings={change.warnings}
				zIndex={fg('review_changes_error_message_display') ? layers.flag() : undefined}
			/>
		);
	};

	const selectCellRenderer = () => {
		const { id, category, type } = change;
		if (type === SCENARIO_TYPE.NONE) return null;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let items: any[] = [{ id, category }];

		if (
			(category === ENTITY.TEAM || category === ENTITY.RESOURCE) &&
			change.details.changes.length > 0
		) {
			items = [];
			const uniqIds = new Set();
			const addItem = (
				item:
					| {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							category: any;
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							id: any;
					  }
					| {
							category: never;
							id: never;
					  },
			) => {
				const uniqId = `${item.category}:${item.id}`;
				if (!uniqIds.has(uniqId)) {
					uniqIds.add(uniqId);
					items.push(item);
				}
			};

			const detailChanges = change.details.changes;
			for (const detailChange of detailChanges) {
				if (detailChange.category === ENTITY.RESOURCE) {
					addItem({
						id: detailChange.resourceId,
						category: detailChange.category,
					});
				} else {
					addItem({
						id,
						category,
					});
				}
			}
		}

		return (
			<SelectChangeCell
				id={getIdForChangeCategory(id, category)}
				items={items}
				isUpdating={isUpdating}
			/>
		);
	};

	const titleCellRenderer = () => {
		if (change?.type && change?.type !== SCENARIO_TYPE.NONE) {
			switch (change.category) {
				case ENTITY.ISSUE:
					return <IssueTitleCell details={change.details} />;
				case ENTITY.RELEASE:
					return <ReleaseTitleCell details={change.details} />;
				case ENTITY.CROSS_PROJECT_RELEASE:
					return <CrossProjectReleaseTitleCell crossProjectVersion={change.details.values} />;
				case ENTITY.TEAM:
				case ENTITY.RESOURCE: {
					const teamDetail: TeamAndResourceDetails = change.details;
					return (
						<TeamTitleCell
							name={teamDetail.title.name}
							avatarUrl={teamDetail.title.avatarUrl}
							id={change.id}
							category={change.category}
						/>
					);
				}
				case ENTITY.SPRINT:
					return (
						<SprintTitle sprint={change.details.values} id={change.id} category={change.category} />
					);
				default:
					return <span />;
			}
		}
		return <span />;
	};

	const categoryCellRenderer = () => {
		const { type } = change;
		if (type !== SCENARIO_TYPE.NONE) {
			switch (change.category) {
				case ENTITY.ISSUE:
					return (
						<Text>
							{formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.issueCategoryIssueTermRefresh
									: messages.issueCategory,
							)}
						</Text>
					);
				case ENTITY.TEAM:
				case ENTITY.RESOURCE:
					return <Text>{formatMessage(messages.teamCategory)}</Text>;
				case ENTITY.RELEASE:
					return <Text>{formatMessage(messages.releaseCategory)}</Text>;
				case ENTITY.CROSS_PROJECT_RELEASE:
					return <Text>{formatMessage(commonMessages.crossProjectRelease)}</Text>;
				case ENTITY.SPRINT: {
					const schedulingMode = get(change, ['details', 'values', 'schedulingMode']);
					const label = formatMessage(
						schedulingMode === SCHEDULE_MODE.kanban
							? messages.iterationCategory
							: messages.sprintCategory,
					);
					return <Text>{label}</Text>;
				}
				default:
					return null;
			}
		}
		return null;
	};

	const whatChangedCellRenderer = () => {
		const scenarioType: ScenarioType = change.type;
		const { attributeName, changeCount, id, category } = change;
		return (
			<WhatChangedComponent
				attributeName={attributeName}
				changeType={scenarioType}
				changeCount={changeCount}
				id={id}
				category={category}
				changeDetails={change}
			/>
		);
	};

	const valueCellRenderer = (dataKey: string) => (
		<ValueChangeComponent change={change} valueType={dataKey} />
	);

	const lastModifiedCellRenderer = () => {
		const { date, title, personId, avatarUrl, dateFormat } = change.lastModified || {};
		if (!change.lastModified || !personId || !date) {
			return null;
		}

		return (
			<LastModified
				date={date}
				title={title}
				personId={personId}
				avatarUrl={avatarUrl}
				dateFormat={dateFormat || ''}
			/>
		);
	};

	const bodyCellRenderer = (column: ColumnConfig): ReactNode => {
		switch (column.id) {
			case 'warnings':
				return commitWarningCellRenderer();
			case 'isSelected':
				return selectCellRenderer();
			case 'details':
				return titleCellRenderer();
			case 'category':
				return categoryCellRenderer();
			case 'type':
				return whatChangedCellRenderer();
			case VALUE_TYPE.CURRENT:
				return valueCellRenderer(VALUE_TYPE.CURRENT);
			case VALUE_TYPE.NEW:
				return valueCellRenderer(VALUE_TYPE.NEW);
			case 'lastModified':
				return lastModifiedCellRenderer();
			default:
				return <></>;
		}
	};

	const Cell = fg('plans_performance_improvements_2') ? CellNew : CellOld;

	return (
		<Row index={rowIndex}>
			{columnConfig.map((column: CustomColumnConfig, index: number) => (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<Cell style={{ zIndex: 1 }} xcss={cellStyles} column={index} key={index}>
					<Box xcss={cellContainer}>{bodyCellRenderer(column)}</Box>
				</Cell>
			))}
		</Row>
	);
}

const cellStyles = xcss({
	backgroundColor: 'elevation.surface',
	verticalAlign: 'top',
});

const cellContainer = xcss({
	position: 'absolute',
	inset: 'space.0',
	alignContent: 'center',
	paddingRight: 'space.100',
	backgroundColor: 'elevation.surface',
	overflow: 'hidden',
	transition: 'background-color 0.1s ease',
	':focus-within': {
		overflow: 'initial',
	},
});
