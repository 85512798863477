import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	hiddenByFilterBody: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-search-warning-flag.description.hidden-by-filter-body',
		defaultMessage:
			'{filterCount} matching {filterCount, plural, one {issue is} other {issues are}} not displaying due to the filters being used in the plan.',
		description: 'Body message for warning when some issues are hidden behind filter',
	},
	hiddenByClearFilterBody: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-search-warning-flag.description.hidden-by-clear-filter-body',
		defaultMessage: ' Clear all filters, then try again.',
		description:
			'Body message for asking the user to clear filters when some issues are hidden behind filter',
	},
	hiddenByFilterLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-search-warning-flag.description.hidden-by-filter-label',
		defaultMessage: 'Hidden by: ',
		description: 'Label that comes before the list of filters hiding an issue',
	},
	hiddenByFilterBodyIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-search-warning-flag.description.hidden-by-filter-body-issue-term-refresh',
		defaultMessage:
			'{filterCount} matching {filterCount, plural, one {issue is} other {issues are}} not displaying due to the filters being used in the plan.',
		description: 'Body message for warning when some issues are hidden behind filter',
	},
});
