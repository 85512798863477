import {
	isExportMode,
	getMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getHierarchyFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/hierarchy-filter/index.tsx';
import { getHierarchyRangeFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/hierarchy-range-filter/index.tsx';
import { getHierarchyLevels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy/index.tsx';
import { EDIT } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';

// delete this selector when removing the "replace_gethierarchyfilter_gethierarchyrangefilter" feature gate
export const mapStateToPropsOld = createSelector(
	[getHierarchyLevels, getHierarchyFilter, isExportMode, getMode],
	(hierarchy, { value: { start, end } }, exportMode, mode) => ({
		hierarchy,
		start,
		end,
		isReadOnly: mode !== EDIT,
		isExportMode: exportMode,
	}),
);

export default createSelector(
	[getHierarchyLevels, getHierarchyRangeFilter, isExportMode, getMode],
	(hierarchy, { value: { start, end } }, exportMode, mode) => ({
		hierarchy,
		start,
		end,
		isReadOnly: mode !== EDIT,
		isExportMode: exportMode,
	}),
);
