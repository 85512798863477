import { fg } from '@atlassian/jira-feature-gating';
import type { IntlShape } from '@atlassian/jira-intl';
import {
	GOAL_NAME,
	GOAL_STATUS,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/goal/constants.tsx';
import { IDEA_NAME } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/idea/constants.tsx';
import type { CsvIntlMessages } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/types.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import messages from './messages.tsx';

export const getCsvIntlMessages = (
	formatMessage: IntlShape['formatMessage'],
	planTitle: string,
): CsvIntlMessages => {
	const columnHeadingRollUpLabel = formatMessage(messages.exportCsvColumnHeadingRollUp);
	return {
		exportName: formatMessage(messages.exportCsvFilename, { planTitle }),
		columnHeadings: {
			type: formatMessage(messages.exportCsvColumnHeadingHierarchy),
			summary: formatMessage(messages.exportCsvColumnHeadingTitle),
			project: formatMessage(messages.exportCsvColumnHeadingProject),
			releaseRollUp: `${formatMessage(
				messages.exportCsvColumnHeadingReleases,
			)} ${columnHeadingRollUpLabel}`,
			release: formatMessage(messages.exportCsvColumnHeadingReleases),
			teamRollUp: `${formatMessage(
				messages.exportCsvColumnHeadingTeam,
			)} ${columnHeadingRollUpLabel}`,
			team: formatMessage(messages.exportCsvColumnHeadingTeam),
			assignee: formatMessage(commonMessages.assignee),
			reporter: formatMessage(commonMessages.reporter),
			sprintRollUp: `${formatMessage(
				messages.exportCsvColumnHeadingSprint,
			)} ${columnHeadingRollUpLabel}`,
			sprint: formatMessage(messages.exportCsvColumnHeadingSprint),
			targetStartDateRollUp: `${formatMessage(
				messages.exportCsvColumnHeadingTargetStartDate,
			)} ${columnHeadingRollUpLabel}`,
			targetStartDate: formatMessage(messages.exportCsvColumnHeadingTargetStartDate),
			targetEndDateRollUp: `${formatMessage(
				messages.exportCsvColumnHeadingTargetEndDate,
			)} ${columnHeadingRollUpLabel}`,
			targetEndDate: formatMessage(messages.exportCsvColumnHeadingTargetEndDate),
			dueDateRollUp: `${formatMessage(commonMessages.dueDate)} ${columnHeadingRollUpLabel}`,
			dueDate: formatMessage(commonMessages.dueDate),
			estimateDaysRollUp: `${formatMessage(
				messages.exportCsvColumnHeadingEstimateDays,
			)} ${columnHeadingRollUpLabel}`,
			estimateDays: formatMessage(messages.exportCsvColumnHeadingEstimateDays),
			estimateHoursRollUp: `${formatMessage(
				messages.exportCsvColumnHeadingEstimateHours,
			)} ${columnHeadingRollUpLabel}`,
			estimateHours: formatMessage(messages.exportCsvColumnHeadingEstimateHours),
			estimateStoryPointsRollUp: `${formatMessage(
				messages.exportCsvColumnHeadingStoryPoints,
			)} ${columnHeadingRollUpLabel}`,
			estimateStoryPoints: formatMessage(messages.exportCsvColumnHeadingStoryPoints),
			[GOAL_NAME]: formatMessage(messages.exportCsvColumnHeadingGoalName),
			[GOAL_STATUS]: formatMessage(messages.exportCsvColumnHeadingGoalStatus),
			[IDEA_NAME]: formatMessage(messages.exportCsvColumnHeadingIdea),
			parent: formatMessage(messages.exportCsvColumnHeadingParent),
			priority: formatMessage(messages.exportCsvColumnHeadingPriority),
			labels: formatMessage(messages.exportCsvColumnHeadingLabels),
			components: formatMessage(messages.exportCsvColumnHeadingComponents),
			key: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.exportCsvColumnHeadingIssueKeyIssueTermRefresh
					: messages.exportCsvColumnHeadingIssueKey,
			),
			status: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.exportCsvColumnHeadingIssueStatusIssueTermRefresh
					: messages.exportCsvColumnHeadingIssueStatus,
			),
			progressPercentage: formatMessage(messages.exportCsvColumnHeadingProgressPercentage),
			progressCompletedDays: formatMessage(messages.exportCsvColumnHeadingProgressCompletedDays),
			progressCompletedHours: formatMessage(messages.exportCsvColumnHeadingProgressCompletedHours),
			progressCompletedStoryPoints: formatMessage(
				messages.exportCsvColumnHeadingProgressCompletedStoryPoints,
			),
			progressRemainingDays: formatMessage(messages.exportCsvColumnHeadingProgressRemainingDays),
			progressRemainingHours: formatMessage(messages.exportCsvColumnHeadingProgressRemainingHours),
			progressRemainingStoryPoints: formatMessage(
				messages.exportCsvColumnHeadingProgressRemainingStoryPoints,
			),
			progressIssueCount: formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.exportCsvColumnHeadingProgressIssueCountPercentageIssueTermRefresh
					: messages.exportCsvColumnHeadingProgressIssueCountPercentage,
			),
			toDoIssueCount: formatMessage(messages.exportCsvColumnHeadingToDoIssueCount),
			inProgressIssueCount: formatMessage(messages.exportCsvColumnHeadingInProgressIssueCount),
			doneIssueCount: formatMessage(messages.exportCsvColumnHeadingDoneIssueCount),
			totalIssueCount: formatMessage(messages.exportCsvColumnHeadingTotalIssueCount),
			rollUp: columnHeadingRollUpLabel,
		},
		labels: {
			external: formatMessage(messages.exportCsvExternalLabel),
			completed: formatMessage(messages.exportCsvCompletedLabel),
		},
	};
};
