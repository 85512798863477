import React from 'react';
import * as R from 'ramda';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, type IntlShape } from '@atlassian/jira-intl';
import SelectWireframe from '@atlassian/jira-portfolio-3-common/src/select-wireframe/index.tsx';
import { selectUtils } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	OptionType,
	ValueType,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import type { IssuePriority } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-priorities/types.tsx';
import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-common/src/api/index.tsx';
import {
	withSlots,
	slots,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import Cell from '../../column/cell/view.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const notInPlan: IssuePriority = {
	id: '',
	iconUrl: '',
	name: '',
	sequence: 0,
};

const formatOptionLabel =
	(intl: IntlShape) =>
	// eslint-disable-next-line @typescript-eslint/no-shadow
	({ icon, label, notInPlan }: OptionType) => {
		if (notInPlan) {
			return (
				<Tooltip content={intl.formatMessage(messages.tooltipText)}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={`${styles['not-in-plan']} ${styles.truncated}`}>{label}</div>
				</Tooltip>
			);
		}
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles['select-option']}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles['icon-wrapper']}>
					<span
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.icon}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={{ backgroundSize: 'contain', backgroundImage: `url(${icon})` }}
					/>
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.label}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className={styles.truncated}>{label}</span>
				</div>
			</div>
		);
	};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const initialSelectFilter = (selectUtils as Record<string, Function>).createFilter(null);

export const PriorityCell = ({
	isScrolling = false,
	issue,
	priorityIdsForProject,
	defaultIssuePriority,
	prioritiesByIdMap,
	onChange,
	isReadOnly = false,
	FieldPrioritySelect,
}: Props) => {
	const intl = useIntl();
	const { formatMessage } = intl;
	const getSelectedIssuePriority = (): IssuePriority | null | undefined => {
		const { priority, project } = issue;
		if (!priority) {
			if (!project) {
				return notInPlan;
			}

			if (!defaultIssuePriority) {
				return null;
			}

			if (!isDefined(prioritiesByIdMap) || R.isEmpty(prioritiesByIdMap)) {
				return null;
			}

			return prioritiesByIdMap[defaultIssuePriority];
		}

		if (!isDefined(priorityIdsForProject)) {
			return null;
		}

		if (!priorityIdsForProject.includes(priority)) {
			return null;
		}

		if (!isDefined(prioritiesByIdMap) || R.isEmpty(prioritiesByIdMap)) {
			return null;
		}

		return prioritiesByIdMap[priority];
	};

	const handleSelectChange = (selectedOption: ValueType): void => {
		if (!isDefined(selectedOption)) {
			return onChange(issue, null);
		}

		// @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'. | TS2551 - Property 'value' does not exist on type 'OptionType | OptionsType'. Did you mean 'values'?
		if (typeof selectedOption.value === 'string') {
			// @ts-expect-error - TS2533 - Object is possibly 'null' or 'undefined'. | TS2551 - Property 'value' does not exist on type 'OptionType | OptionsType'. Did you mean 'values'?
			return onChange(issue, selectedOption.value);
		}
	};

	// go/jfe-eslint

	const priorityToOption = (issuePriority: IssuePriority): OptionType => {
		const { name, iconUrl, id } = issuePriority;
		return {
			value: id,
			label: name,
			icon: proxyContextSafeUrl(iconUrl),
		};
	};

	const filterOption = (option: OptionType, query: string): boolean => {
		if (!initialSelectFilter(option, query)) {
			return false;
		}

		const selectedIssuePriority = getSelectedIssuePriority();

		if (selectedIssuePriority === notInPlan) {
			return false;
		}

		if (isDefined(selectedIssuePriority) && option.value === selectedIssuePriority.id) {
			return false;
		}

		return true;
	};

	const getSelectedOption = (): OptionType | null | undefined => {
		const selectedIssuePriority = getSelectedIssuePriority();

		if (!isDefined(selectedIssuePriority)) {
			return null;
		}

		if (selectedIssuePriority === notInPlan) {
			return { label: formatMessage(messages.notInPlan), notInPlan: true };
		}

		return priorityToOption(selectedIssuePriority);
	};

	const select = () => {
		const { summary } = issue;

		const priorities: IssuePriority[] = priorityIdsForProject.map((id) => prioritiesByIdMap[id]);
		const items: OptionType[] = R.map(priorityToOption, priorities);

		return (
			<Cell attribute="priority" issue={issue} isScrolling={isScrolling}>
				<FieldPrioritySelect
					aria-label={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.priorityCellLabelIssueTermRefresh
							: messages.priorityCellLabel,
						{
							issue: summary,
						},
					)}
					attribute="priority"
					filterOption={filterOption}
					formatOptionLabel={formatOptionLabel(intl)}
					isDisabled={isReadOnly}
					issueId={issue.id}
					onChange={handleSelectChange}
					options={items}
					placeholder={formatMessage(messages.placeholder)}
					value={getSelectedOption()}
				/>
			</Cell>
		);
	};

	const wireframe = () => {
		const selectedOption = getSelectedOption();
		const content: string = selectedOption ? selectedOption.label : '';

		return <SelectWireframe content={content} />;
	};

	const cell = <>{isScrolling ? wireframe() : select()}</>;

	return cell;
};

export default withSlots({ FieldPrioritySelect: slots.FieldPrioritySelect })(PriorityCell);
