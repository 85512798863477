import { useMemo, useRef, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { fg } from '@atlassian/jira-feature-gating';
import { useHorizontalScrolling } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import {
	useScrollX,
	useIsScrollingY,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import type { TooltipStyle } from './types.tsx';

/**
 * Computes different parameters to determine wether to position the tooltip
 * It will vary depending on if horizontal scrolling is enabled in the current view or not.
 * @param {number} leftPercentage the left offset of the element the tooltip is attached to
 * @param {number} rightPercentage the right offset of the element the tooltip is attached to
 * @param {number} width the width of the container the tooltip is attached to
 * @param {number} tooltipWidth the width of the tooltip itself
 * @param {number} minimumPercentageOffset the left percentage offset of the viewport
 * @param {number} maximumPercentageOffset the right percentage offset of the viewport
 * @param {boolean} isScrollableTimeline if the timeline where the tooltip is is scrollable horizontally
 * @returns TooltipStyle
 */
export const getTooltipStyle = (
	leftPercentage: number,
	rightPercentage: number,
	width: number,
	tooltipWidth: number,
	minimumPercentageOffset: number,
	maximumPercentageOffset: number,
	isScrollableTimeline: boolean,
): TooltipStyle => {
	const leftPositionPercentage = !isScrollableTimeline
		? Math.max(leftPercentage, 0)
		: Math.max(leftPercentage, minimumPercentageOffset);
	const rightPositionPercentage = !isScrollableTimeline
		? Math.max(rightPercentage, 0)
		: Math.max(rightPercentage, 100 - maximumPercentageOffset);

	const barWidthPercentage = 100 - (leftPositionPercentage + rightPositionPercentage);

	// The percentage of the tooltip that is inside a container
	const containerWidthPercentage = Math.max((tooltipWidth / width) * 100, barWidthPercentage);

	// Calculate the center position of the bar
	const middlePositionPercentage = leftPositionPercentage + barWidthPercentage / 2;

	// Calculate where to position the tooltip
	const containerLeftPositionPercentage = !isScrollableTimeline
		? Math.max(0, middlePositionPercentage - containerWidthPercentage / 2)
		: Math.max(minimumPercentageOffset, middlePositionPercentage - containerWidthPercentage / 2);

	let left;
	let right;

	if (!isScrollableTimeline && containerLeftPositionPercentage + containerWidthPercentage > 100) {
		right = 0;
	} else if (
		isScrollableTimeline &&
		containerLeftPositionPercentage + containerWidthPercentage > maximumPercentageOffset
	) {
		// If the bar is outside the viewport to the right we render it on the right side of the screen
		left = `calc(${maximumPercentageOffset - containerWidthPercentage}%)`;
	} else {
		left = `calc(${containerLeftPositionPercentage}%)`;
	}

	return {
		left,

		right,
		minWidth: `calc(${containerWidthPercentage}%)`,
	};
};

export const useTooltipVisibilityOld = () => {
	const [{ viewport }] = useHorizontalScrolling();
	const [visible, setVisible] = useState(true);
	const lastOffsetRef = useRef(viewport.offset);
	const show = useRef(
		debounce(() => {
			setVisible(true);
		}, 100),
	).current;
	useEffect(() => {
		if (viewport.offset !== lastOffsetRef.current) {
			setVisible(false);
			show();
			lastOffsetRef.current = viewport.offset;
		}
	}, [viewport.offset, show]);

	return visible;
};

export const useTooltipVisibilityTransposed = () => {
	const [isScrollingX, setIsScrollingX] = useState(false);
	const [scrollX] = useScrollX();
	const [isScrollingY] = useIsScrollingY();

	const lastScrollXRef = useRef(scrollX);

	const show = useMemo(
		() =>
			debounce(() => {
				setIsScrollingX(false);
			}, 100),
		[],
	);

	useEffect(() => {
		if (scrollX !== lastScrollXRef.current) {
			setIsScrollingX(true);
			show();
			lastScrollXRef.current = scrollX;
		}
	}, [scrollX, show]);

	return !isScrollingX && !isScrollingY;
};

/* eslint-disable react-hooks/rules-of-hooks */
export const useTooltipVisibility: typeof useTooltipVisibilityOld = (...args) =>
	!fg('plan-timeline-non-transposed')
		? useTooltipVisibilityTransposed(...args)
		: useTooltipVisibilityOld(...args);
/* eslint-enable react-hooks/rules-of-hooks */
