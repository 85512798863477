import { delay, type Effect } from 'redux-saga';
import * as R from 'ramda';
import { fork, takeEvery, put, select, call } from 'redux-saga/effects';
import { ISSUE_INFERRED_DATE_SELECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	indexBy,
	isDefined,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { getAncestors } from '../../query/issues/index.tsx';
import { getIssuesWithRolledUpDates, getIssueMapById } from '../../query/raw-issues/index.tsx';
import { getDescendents, getTimelineState } from '../../query/timeline-preview/index.tsx';
import { getShowRolledUpDates } from '../../query/view-settings/index.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import {
	setPreviewIssues,
	moveIssueAndDescendents,
	setIssuesStart as setIssuePreviewsStart,
	setIssuesEnd as setIssuePreviewsEnd,
	clearPreview,
} from '../../state/ui/main/tabs/roadmap/timeline-preview/actions.tsx';
import type {
	IssuePreview,
	TimelinePreview,
} from '../../state/ui/main/tabs/roadmap/timeline-preview/types.tsx';
import batch from '../batch/index.tsx';
import { updateIssueRaw } from '../issue/index.tsx';
import type { UpdateIssuePayload } from '../issue/types.tsx';
import {
	type HoverTimelineIssueAction,
	type MoveTimelineIssueAction,
	type ResizeTimelineIssueAction,
	HOVER_TIMELINE_ISSUE,
	MOVE_TIMELINE_ISSUE,
	RESIZE_TIMELINE_ISSUE,
	COMMIT_TIMELINE_PREVIEW,
	CANCEL_TIMELINE_PREVIEW,
} from './types.tsx';

export const hoverTimelineIssue = (
	issueId: string,
	defaultStart?: number | null,
	defaultEnd?: number | null,
) => ({
	type: HOVER_TIMELINE_ISSUE,
	payload: { issueId, defaultStart, defaultEnd },
});

export const moveTimelineIssue = (
	delta: number,
	targetStart?: number | null,
	targetEnd?: number | null,
) => ({
	type: MOVE_TIMELINE_ISSUE,
	payload: { delta, targetStart, targetEnd },
});

export const resizeTimelineIssue = (
	baselineStart?: number | null,
	baselineEnd?: number | null,
) => ({
	type: RESIZE_TIMELINE_ISSUE,
	payload: { baselineStart, baselineEnd },
});

export const commitTimelinePreview = () => ({
	type: COMMIT_TIMELINE_PREVIEW,
});

export const cancelTimelinePreview = () => ({
	type: CANCEL_TIMELINE_PREVIEW,
});

const { ROLL_UP, SPRINT } = ISSUE_INFERRED_DATE_SELECTION;

export const isRolledUp = (type: 'baselineStart' | 'baselineEnd') =>
	R.pathEq(['inferred', type], ROLL_UP);

export const isSprintInferred = (type: 'baselineStart' | 'baselineEnd') =>
	R.pathEq(['inferred', type], SPRINT);

/**
 * Bootstrap the timeline preview state, including loading all relevant issues.
 * Intended to be used during hover/mouse movement over the timeline view.
 * @param issueId the primary issue that the user is interacting with.
 * @param defaultStart the issue start to use if one is not already defined.
 * @param defaultEnd the issue end to use if one is not already defined.
 */
export function* doHoverTimelineIssue({
	payload: { issueId, defaultStart, defaultEnd }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: HoverTimelineIssueAction): Generator<Effect, void, any> {
	const issueMapById = yield select(getIssueMapById);
	const activeIssue = issueMapById[issueId];

	// Invalid state, do nothing
	if (!activeIssue) return;

	const timeline: ReturnType<typeof getTimelineState> = yield select(getTimelineState);

	// If there is a preview (used when moving we need to use the preview's dates)
	const baselineStart =
		timeline && isDefined(activeIssue.baselineStart)
			? R.path<TimelinePreview['previews'][string]['baselineStart']>(
					['previews', activeIssue.id, 'baselineStart'],
					timeline,
				)
			: defaultStart;
	const baselineEnd =
		timeline && isDefined(activeIssue.baselineEnd)
			? R.path<TimelinePreview['previews'][string]['baselineEnd']>(
					['previews', activeIssue.id, 'baselineEnd'],
					timeline,
				)
			: defaultEnd;

	// Strict descendants
	const strictDescendents: Issue[] = yield select(getDescendents, {
		issueId,
		isStrict: true,
	});
	const definedDescendentsWithoutSprintInferred = R.filter(
		(i) =>
			(isDefined(i.baselineStart) || isDefined(i.baselineEnd)) &&
			!isSprintInferred('baselineStart')(i), // If we infer from sprint, we do it on both start and end so we only need to check it on one side here.
		strictDescendents,
	);
	const indexedDescendentIssues: {
		[key: string]: Issue;
	} = indexBy(R.prop('id'), definedDescendentsWithoutSprintInferred);

	const strictDescendentsPreviews: {
		[key: string]: IssuePreview;
	} = R.map(
		(issue) => ({
			inferred: issue.inferred,
			baselineStart: issue.baselineStart,
			baselineEnd: issue.baselineEnd,
			shouldSync: true,
		}),
		indexedDescendentIssues,
	);

	const showRolledUpDates = yield select(getShowRolledUpDates);

	// when "Roll-up > Dates" view setting is disabled, just preview active issue
	if (!showRolledUpDates) {
		yield put(
			setPreviewIssues({
				activeIssueId: issueId,
				issueAndAncestors: {
					[issueId]: {
						baselineStart,
						baselineEnd,
						shouldSync: true,
						inferred: activeIssue.inferred,
					},
				},
				strictDescendents: strictDescendentsPreviews,
			}),
		);

		return;
	}

	// when "Roll-up > Dates" view setting is enabled

	const issueAncestors: Issue[] = getAncestors(activeIssue, issueMapById, Infinity);
	const activeAndAncestors: Issue[] = [activeIssue].concat(issueAncestors);

	const issuesSortedByLevelFromLowerToHigher: Issue[] = R.sortBy(
		R.prop('level'),
		activeAndAncestors,
	);

	const highestLevelParent = R.last(issuesSortedByLevelFromLowerToHigher);

	const siblingsDescendants = yield select(getDescendents, {
		issueId: highestLevelParent && highestLevelParent.id,
		isStrict: true,
	});

	// Delete inferred dates so we can recalculate state using getIssuesWithRolledUpDates
	const realDatesOnly = activeAndAncestors.concat(siblingsDescendants).map((issue) => {
		const result = { ...issue };
		const isStartInferred = R.path(['inferred', 'baselineStart'], issue) === -1;
		const isEndInferred = R.path(['inferred', 'baselineEnd'], issue) === -1;
		if (isStartInferred) result.baselineStart = undefined;
		if (isEndInferred) result.baselineEnd = undefined;
		if (issue.id !== issueId) return result;
		return { ...result, baselineStart, baselineEnd };
	});

	// Recalculate previews re-using same function as render getIssuesWithRolledUpDates
	const rolledUpIssues: Issue[] = getIssuesWithRolledUpDates(realDatesOnly)
		// Previews that don't have at least one date will error through out the app
		.filter((issue) => isDefined(issue.baselineStart) || isDefined(issue.baselineEnd));
	const rolledUpIssuesMap = indexBy(R.prop('id'), rolledUpIssues);

	const newPreviews: {
		[key: string]: IssuePreview;
	} = R.map(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		({ baselineStart, baselineEnd, id, inferred }) => ({
			inferred,
			baselineStart,
			baselineEnd,
			shouldSync: id === issueId,
		}),
		rolledUpIssuesMap,
	);

	yield put(
		setPreviewIssues({
			activeIssueId: issueId,
			issueAndAncestors: newPreviews,
			strictDescendents: strictDescendentsPreviews,
		}),
	);
}

/**
 * Move the active issue by some delta. This will slide the issue and all its non sprint inferred descendants
 * @param delta the number of units to slide the active issue by.
 */

export function* doMoveTimelineIssue({
	payload: { delta, targetStart, targetEnd }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: MoveTimelineIssueAction): Generator<Effect, void, any> {
	yield put(moveIssueAndDescendents({ delta, targetStart, targetEnd }));
}

/**
 * Resize the active issue to the specified baselineStart/End (if provided). This may
 * push out the bounds of rolled up parent issues to contain all their children.
 * This action will not affect child positions. Attempting to shrink an issue such that
 * a child will not be included will not affect the dates and will flag the issue as
 * being invalid.
 * @param baselineStart the new start or undefined to leave unchanged.
 * @param baselineEnd the new end or undefined to leave unchanged.
 */
export function* doResizeTimelineIssue({
	payload: { baselineStart, baselineEnd }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: ResizeTimelineIssueAction): Generator<Effect, void, any> {
	const timeline: ReturnType<typeof getTimelineState> = yield select(getTimelineState);
	if (!timeline) return;
	const { activeIssueId, previews, strictDescendentIds } = timeline;
	if (!activeIssueId || !previews) return;
	const strictDescendentPreviews = R.pick(strictDescendentIds, previews);

	if (isDefined(baselineStart)) {
		// Clamp to earliest as convenience, but mark as invalid user action.
		const listOfBaselineStarts = R.map<IssuePreview, number>(
			R.propOr(Infinity, 'baselineStart'),
			R.values(strictDescendentPreviews),
		);

		yield put(
			setIssuePreviewsStart({
				issueIds: [activeIssueId],
				baselineStart: Math.min(...listOfBaselineStarts, baselineStart),
			}),
		);
	}

	if (isDefined(baselineEnd)) {
		// Clamp to latest as convenience, but mark as invalid user action.
		const listOfBaselineEnds = R.map<IssuePreview, number>(
			R.propOr(-Infinity, 'baselineEnd'),
			R.values(strictDescendentPreviews),
		);

		yield put(
			setIssuePreviewsEnd({
				issueIds: [activeIssueId],
				baselineEnd: Math.max(...listOfBaselineEnds, baselineEnd),
			}),
		);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* syncIssueBaselines(payload: UpdateIssuePayload): Generator<Effect, void, any> {
	yield put(updateIssueRaw(payload));
}

/**
 * Promote timeline preview state to persisted issue scenario data and clear the preview.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doCommitTimelinePreview(): Generator<Effect, void, any> {
	const state: ReturnType<typeof getTimelineState> = yield select(getTimelineState);
	if (!state) return;
	const listOfPreviews = R.toPairs(state.previews);

	yield* batch(function* () {
		for (const [id, preview] of listOfPreviews) {
			const { baselineStart, baselineEnd, shouldSync, inferred } = preview;
			if (shouldSync) {
				const isInferredStart = inferred && !!inferred.baselineStart;
				const isInferredEnd = inferred && !!inferred.baselineEnd;
				yield* syncIssueBaselines({
					id,
					baselineStart: isInferredStart ? undefined : baselineStart,
					baselineEnd: isInferredEnd ? undefined : baselineEnd,
				});
			}
		}
		// ensure that emitted actions are processed by reducers
		yield call(delay, 0);
		yield put(clearPreview());
	});
}

/**
 * Drop all timeline state.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doCancelTimelinePreview(): Generator<Effect, void, any> {
	yield put(clearPreview());
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchHoverTimelineIssue(): Generator<Effect, void, any> {
	yield takeEvery(HOVER_TIMELINE_ISSUE, doHoverTimelineIssue);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchMoveTimelineIssue(): Generator<Effect, void, any> {
	yield takeEvery(MOVE_TIMELINE_ISSUE, doMoveTimelineIssue);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchResizeTimelineIssue(): Generator<Effect, void, any> {
	yield takeEvery(RESIZE_TIMELINE_ISSUE, doResizeTimelineIssue);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchCommitTimelinePreview(): Generator<Effect, void, any> {
	yield takeEvery(COMMIT_TIMELINE_PREVIEW, doCommitTimelinePreview);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchCancelTimelinePreview(): Generator<Effect, void, any> {
	yield takeEvery(CANCEL_TIMELINE_PREVIEW, doCancelTimelinePreview);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, void, any> {
	yield fork(watchMoveTimelineIssue);
	yield fork(watchHoverTimelineIssue);
	yield fork(watchResizeTimelineIssue);
	yield fork(watchCommitTimelinePreview);
	yield fork(watchCancelTimelinePreview);
}
