import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	iframeLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.share-tabs.iframe.iframe-label',
		defaultMessage: 'iframe url',
		description: 'Label of the text field on iframe tab in share dialog.',
	},
	iframeBody: {
		id: 'portfolio-3-portfolio.app-simple-plans.share-tabs.iframe.iframe-body',
		defaultMessage: 'To embed the Timeline view in an external website, select the code below:',
		description:
			'Body content of the iframe tab in share dialog shown when user clicks the "share" button.',
	},
	copyButton: {
		id: 'portfolio-3-portfolio.app-simple-plans.share-tabs.iframe.copy-button',
		defaultMessage: 'Copy',
		description: 'Button to copy the text in the input, shown in the share dialog.',
	},
	copied: {
		id: 'portfolio-3-portfolio.app-simple-plans.share-tabs.iframe.copied',
		defaultMessage: 'Copied',
		description: 'Message shown in the dialog after user clicks copy button on text field.',
	},
});
