import React, { type KeyboardEvent, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { TargetTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/issues/selectable-issue/actions.tsx';
import type { TargetType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/issues/selectable-issue/types.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export default function SelectIssueMenuGroup({ onRequestSelect }: Props) {
	const handleRequestSelect = (targetType: TargetType) => {
		onRequestSelect(true, { targetType });
	};

	const triggerAnalytics = (analyticsEvent: UIAnalyticsEvent, targetType: string) => {
		const [actionSubject, eventAction] =
			PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_SELECT_ISSUES.split(' ');
		fireUIAnalytics(
			analyticsEvent.update({ action: eventAction, actionSubject }),
			PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_SELECT_ISSUES,
			{
				selectOption: targetType,
			},
		);
	};

	return (
		<DropMenuItemGroup>
			<DropMenuItem
				onClick={(_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
					handleRequestSelect(TargetTypes.singleAndDescendants);
					if (analyticsEvent) {
						triggerAnalytics(analyticsEvent, TargetTypes.singleAndDescendants);
					}
				}}
				data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.select-issue.select-issue-and-descendants"
			>
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.selectSingleAndDescendantsIssueTermRefresh
						: messages.selectSingleAndDescendants)}
				/>
			</DropMenuItem>
			<DropMenuItem
				onClick={(_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
					handleRequestSelect(TargetTypes.descendants);
					if (analyticsEvent) {
						triggerAnalytics(analyticsEvent, TargetTypes.descendants);
					}
				}}
				data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.select-issue.select-only-descendant-issues"
			>
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.selectDescendantsIssueTermRefresh
						: messages.selectDescendants)}
				/>
			</DropMenuItem>
		</DropMenuItemGroup>
	);
}
