import { fg } from '@atlassian/jira-feature-gating';
import {
	isConfluenceMacro,
	isEmbed,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	type IdeaFilter,
	IDEA_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getFiltersViewSettings } from '../../view-settings/index.tsx';
import { applyFilter as applyAttributeFilter } from '../attribute-filter/index.tsx';

const EMPTY_IDEA_FILTER: IdeaFilter = {
	id: IDEA_FILTER_ID,
	value: [],
};

export const applyFilter = (issue: Issue, filter: IdeaFilter, optimizedMode: boolean): boolean =>
	applyAttributeFilter(issue, filter.value, 'associatedIssueIds', optimizedMode);

export const getAppliedFilter = (state: State): IdeaFilter =>
	getFiltersViewSettings(state)[IDEA_FILTER_ID] || EMPTY_IDEA_FILTER;

export const getIdeaFilter = createSelector(
	[getAppliedFilter, isEmbed, isConfluenceMacro],
	(pureFilter: IdeaFilter, isEmbedMode, isConfluenceMacroMode): IdeaFilter => {
		const isEmbedOrMacro = isEmbedMode || isConfluenceMacroMode;
		if (
			!fg('polaris-arj-eap-override') ||
			(isEmbedOrMacro &&
				!fg('polaris-use-rest-in-embed-macro-mode') &&
				fg('polaris-arj-eap-override'))
		) {
			return EMPTY_IDEA_FILTER;
		}

		const { value = [] } = pureFilter;
		return {
			id: IDEA_FILTER_ID,
			value,
		};
	},
);
