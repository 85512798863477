import React, {
	useRef,
	useState,
	memo,
	useCallback,
	type SyntheticEvent,
	type ChangeEvent,
	type MouseEvent,
} from 'react';
import { createPortal } from 'react-dom';
import * as R from 'ramda';
import { withAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { Box, Text } from '@atlaskit/primitives';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import Tooltip from '@atlaskit/tooltip';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import ScrollLock from '@atlassian/jira-portfolio-3-common/src/scroll-lock/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { misconfiguredChildrenWarning } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/warnings/index.tsx';
import { splitString } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import { getGroupKey } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/util.tsx';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import { TargetTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/issues/selectable-issue/actions.tsx';
import BarFlyout from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/schedule/row/flyout/index.tsx';
import {
	getCurrentValue,
	hasValueChanged,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util.tsx';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import ChangeIndicator from '@atlassian/jira-portfolio-3-portfolio/src/common/view/change-indicator/index.tsx';
import { SCENARIO_ISSUE_ID_PREFIX } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/interaction-constants.tsx';
import type { Warning } from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types.tsx';
import WarningDetails from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/view.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import AiWorkBreakdownPopup from './ai-work-breakdown-popup/index.tsx';
import { REMOVE_ISSUE } from './constants.tsx';
import { DraggableButton } from './draggable-button/index.tsx';
import CreateIssue from './drop-menu/create-issue/index.tsx';
import CreateIssueWithType from './drop-menu/create-issue-type/index.tsx';
import RankIssue from './drop-menu/rank-issue/index.tsx';
import RemoveIssue from './drop-menu/remove-issue/index.tsx';
import SelectIssue from './drop-menu/select-issue/view.tsx';
import DropMenu from './drop-menu/view.tsx';
import { HighlightSummary, HighlightIssueLink } from './highlight/view.tsx';
import RemoveIssueDialog from './remove-issue-dialog/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles
import * as styles from './styles.module.css';
import type { Props, ModalAction } from './types.tsx';

const DIGIT_WIDTH = 8;
const WARNING_WIDTH = 16;

export const getIssueClasses = ({
	isDragging,
	isOptimized,
	rootIndex,
	isSelected,
	isDropParent,
	isListMode = false,
}: {
	isDragging?: boolean;
	isOptimized: boolean;
	rootIndex?: number;
	isSelected?: boolean;
	isDropParent?: boolean;
	isListMode: boolean;
}) => {
	const isEven = rootIndex === undefined ? false : rootIndex % 2 === 0;
	const isTransposed =
		!fg('plan-timeline-non-transposed') && (isListMode || !fg('plan-timeline-non-transposed'));

	let classes = '';
	if (isDragging && !isTransposed) {
		classes = styles.isDragging;
	} else if (isOptimized) {
		classes = isEven ? styles.evenOptimized : styles.oddOptimized;
	} else if (!isTransposed) {
		// These ones are set in roadmap/table/utils/background-provider/index.tsx
		classes = isEven ? styles.even : styles.odd;
	}

	if (isSelected) {
		classes = styles.isSelected;
	}

	if (isDropParent) {
		classes += ` ${styles['drop-parent']}`;
	}

	return classes;
};

export const Issue = ({
	issue,
	issue: {
		id: issueId = '',
		summary,
		depth,
		isExpandable,
		isExpanded,
		issueKey,
		// @ts-expect-error - TS2322 - Type '{}' is not assignable to type 'number & Readonly<{ id: number; key: string; name: string; avatarUrl: string; versions: string[]; components?: Component[] | undefined; issueTypeIds: number[]; isDefault?: boolean | undefined; ... 4 more ...; projectTypeKey: "business" | ... 1 more ... | "service_desk"; }>'.
		project = {},
		rootIndex,
		type: issueType,
		// @ts-expect-error - TS2322 - '' is compatible with Grouping
		group = '',
		originals,
	},
	isDropParent,
	isReadOnly,
	isDragging,
	isOptimizedMode,
	isScrolling,
	groupCombination = {},
	onToggle,
	isSelected = false,
	hasChildSelected,
	rankDigits,
	hideRank,
	warnings,
	showWarning,
	hasIndentation,
	issueSearchResults,
	activeSearchResultIndex,
	searchQuery,
	showTooltip = true,
	setIssueBeingEdited,
	isExportMode,
	hierarchyLevelsForType,
	projects,
	typeToLevel,
	startInlineCreate,
	toggleSelected,
	hasSelectedIssues,
	isGroupingByMultiSelectCustomField,
	viewMode,
	outerRef,
}: Props) => {
	const [showMeatballButton, setShowMeatballButton] = useState(false);
	const [dropMenuIsOpen, setDropMenuIsOpen] = useState(false);
	const [isMouseOverIssue, setIsMouseOverIssue] = useState(false);
	const [isAiSuggestIssuesPopupOpen, setIsAiSuggestIssuesPopupOpen] = useState(false);
	const [optimizeFlyoutTopPosition, setOptimizeFlyoutTopPosition] = useState(0);
	const [modalAction, setModalAction] = useState<ModalAction | null>(null);

	const innerRef = useRef<HTMLDivElement>(null);
	const ref = useMergeRefs(innerRef, outerRef);

	const handleToggle = (event: MouseEvent) => {
		if (!issueId) {
			throw new Error('Issue must have id!');
		}

		const idSuffix = Object.keys(groupCombination).length
			? `:${getGroupKey(groupCombination)}`
			: '';
		if (isExpanded) {
			traceUFOPress(KEYS.COLLAPSE_ISSUE, event.timeStamp);
		} else {
			traceUFOPress(KEYS.EXPAND_ISSUE, event.timeStamp);
		}
		onToggle({ [`${issueId}${idSuffix}`]: !isExpanded });
	};

	const summaryWithMatchHighlighting = (issueLinkChunks: string[]) => {
		const { searchMatches } = issueSearchResults;
		return (
			<HighlightSummary
				group={group}
				numberOfIssueLinkChunks={issueLinkChunks.length}
				searchMatches={searchMatches}
				summary={summary}
				searchQuery={searchQuery}
				id={issueId}
				activeSearchResultIndex={activeSearchResultIndex}
			/>
		);
	};

	const isSummaryDirty = () => originals && R.has('summary', originals);

	const isDescriptionDirty = () => originals && R.has('description', originals);

	const shouldShowSummaryChangeIndicator = () =>
		!isOptimizedMode && (isSummaryDirty() || isDescriptionDirty());

	const isRankDirty = () => !issueKey || (originals && originals.lexoRank);

	const shouldShowRankChangeIndicator = () => !isOptimizedMode && isRankDirty();

	const triggerOpenIssueDescriptionDialogAnalytics = (analyticsEvent: UIAnalyticsEvent) => {
		const [actionSubject, action] =
			PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_ISSUE_SUMMARY_BUTTON.split(' ');
		fireUIAnalytics(
			analyticsEvent.update({ action, actionSubject }),
			PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_ISSUE_SUMMARY_BUTTON,
			'arjScopeIssueSummaryBtn',
			{
				isReadOnly,
				isScenarioIssue: issue.id.startsWith(SCENARIO_ISSUE_ID_PREFIX),
				issueTypeId: issue.type.id,
			},
		);
	};

	const getIssueSummary = (issueLinkChunks: string[]) => {
		if (isExportMode) {
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles.summaryReadView}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<span className={styles.ellipsis}>{issue.summary ?? ' '}</span>
				</div>
			);
		}

		if (fg('plan-timeline-non-transposed')) {
			const wrappedNode = (
				<Button
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.summaryBtn}
					onClick={(_e: SyntheticEvent<HTMLButtonElement>, analyticsEvent: UIAnalyticsEvent) => {
						/**
						 * This function does three things:
						 * 1. it displays a spinner on the issue description field in the issue modal dialog
						 * 2. it fetches the issue description from the backend based on the issue id
						 * 3. it hides the spinner upon receiving the response from the backend
						 */
						setIssueBeingEdited(issue.id);
						triggerOpenIssueDescriptionDialogAnalytics(analyticsEvent);
					}}
					appearance="subtle"
				>
					{summaryWithMatchHighlighting(issueLinkChunks)}
				</Button>
			);

			if (showTooltip) {
				return <Tooltip content={issue.summary || ''}>{wrappedNode}</Tooltip>;
			}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			return <div className={styles.summaryReadView}>{wrappedNode}</div>;
		}

		const wrappedNode = isDragging ? (
			<Box padding="space.075">
				<Text weight="medium">{summaryWithMatchHighlighting(issueLinkChunks)}</Text>
			</Box>
		) : (
			<DraggableButton
				onClick={(_e: SyntheticEvent<HTMLButtonElement>, analyticsEvent: UIAnalyticsEvent) => {
					/**
					 * This function does three things:
					 * 1. it displays a spinner on the issue description field in the issue modal dialog
					 * 2. it fetches the issue description from the backend based on the issue id
					 * 3. it hides the spinner upon receiving the response from the backend
					 */
					setIssueBeingEdited(issue.id);

					triggerOpenIssueDescriptionDialogAnalytics(analyticsEvent);
				}}
				appearance="subtle"
			>
				{summaryWithMatchHighlighting(issueLinkChunks)}
			</DraggableButton>
		);

		if (showTooltip) {
			return <Tooltip content={issue.summary || ''}>{wrappedNode}</Tooltip>;
		}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		return <div className={styles.summaryReadView}>{wrappedNode}</div>;
	};

	const shouldShowDropMenu = () =>
		!isReadOnly && !isScrolling && !isDragging && (showMeatballButton || dropMenuIsOpen);

	// When clicking a drop menu item, the menu disappears and no mouseleave event is triggered
	const hoverOut =
		<F extends Function>(fn: F) =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(...args: any[]) => {
			setShowMeatballButton(false);
			fn(...args);
		};

	const handleDropMenuToggle = ({ isOpen }: { isOpen: boolean }) => setDropMenuIsOpen(isOpen);

	const handleMeatBallButtonBlurEvent = () => {
		setShowMeatballButton(false);
	};

	const toggleAiWorkBreakdownPopup = ({ isOpen }: { isOpen: boolean }) =>
		setIsAiSuggestIssuesPopupOpen(isOpen);

	const dropMenu = () => {
		const issueDropMenuOptions = [
			fg('plan_timeline_issue_type_create_inline') ? (
				<CreateIssueWithType
					key="create-issue-type-dropdown-menu-item"
					issue={issue}
					projects={projects}
					groupCombination={groupCombination}
					startInlineCreate={hoverOut(startInlineCreate)}
					isDisabled={isGroupingByMultiSelectCustomField}
					toggleAiWorkBreakdownPopup={toggleAiWorkBreakdownPopup}
				/>
			) : (
				<CreateIssue
					key="create-issue-dropdown-menu-item"
					issue={issue}
					hierarchyLevelsForType={hierarchyLevelsForType}
					projects={projects}
					typeToLevel={typeToLevel}
					startInlineCreate={hoverOut(startInlineCreate)}
					triggerClassName={styles.addIcon}
					groupCombination={groupCombination}
					isDisabled={isGroupingByMultiSelectCustomField}
					toggleAiWorkBreakdownPopup={toggleAiWorkBreakdownPopup}
				/>
			),
			<SelectIssue
				key="select-issue-dropdown-menu-item"
				onRequestSelect={hoverOut(toggleSelected)}
			/>,
			<RemoveIssue
				key="remove-issue-dropdown-menu-item"
				issue={issue}
				isDisabled={isGroupingByMultiSelectCustomField}
				setModalAction={setModalAction}
			/>,
		];
		if (hasSelectedIssues) {
			issueDropMenuOptions.push(
				<RankIssue
					key="rank-issue-dropdown-menu-item"
					issueId={issue.id}
					isSelected={isSelected}
				/>,
			);
		}
		return (
			<DropMenu
				onOpenChange={handleDropMenuToggle}
				ariaLabel={issue?.summary}
				handleBlurEvent={handleMeatBallButtonBlurEvent}
				hierarchyLevel={issue.level}
			>
				{issueDropMenuOptions}
			</DropMenu>
		);
	};

	const renderDialog = useCallback(() => {
		if (modalAction === REMOVE_ISSUE) {
			return (
				<RemoveIssueDialog
					issueId={issueId}
					issueKey={issueKey}
					projectKey={project.key}
					onClose={() => setModalAction(null)}
				/>
			);
		}
		/*
			Commenting out this option until inline delete is rolled out for Plans
			See: https://hello.atlassian.net/wiki/spaces/Spork/pages/4017365823/MAKE+PLVR-208+Allow+user+to+delete+unsaved+and+remove+saved+issue+directly+from+timeline
			if (modalAction === DELETE_ISSUE) {
				return <DeleteIssueDialog issueId={issueId} onClose={() => setModalAction(null)} />;
			}
		 */
		return null;
	}, [modalAction, issueId, issueKey, project.key]);

	const isOptimized = (() => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		let isOptimized = false;

		if (isOptimizedMode && isDefined(issue.optimized)) {
			for (const [optimizedKey, optimizedValue] of Object.entries(issue.optimized)) {
				if (hasValueChanged(getCurrentValue(issue, optimizedKey), optimizedValue)) {
					isOptimized = true;
					break;
				}
			}
		}

		return isOptimized;
	})();

	const onHoverChanged = (hovered: boolean): void => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let rect: Record<string, any> = {};

		if (isOptimized) {
			if (innerRef.current) {
				rect = innerRef.current.getBoundingClientRect();
			}
		}
		setShowMeatballButton(hovered);
		setIsMouseOverIssue(hovered);
		setOptimizeFlyoutTopPosition(rect.top);
	};

	const triggerCheckboxChangeAnalytics = (analyticsEvent: UIAnalyticsEvent) => {
		const [actionSubject, eventAction] =
			PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_SELECT_ISSUES.split(' ');
		fireUIAnalytics(
			analyticsEvent.update({ action: eventAction, actionSubject }),
			PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_SELECT_ISSUES,
			{
				selectOption: TargetTypes.single,
			},
		);
	};

	const handleCheckboxChange = (
		e: ChangeEvent<HTMLInputElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const on = !isSelected;
		const extending = e.nativeEvent instanceof MouseEvent && e.nativeEvent.shiftKey;

		toggleSelected(on, {
			extending,
			targetType: TargetTypes.single,
		});

		if (on) {
			triggerCheckboxChangeAnalytics(analyticsEvent);
		}
	};

	const { searchMatches, hasNestedMatches } = issueSearchResults;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	const issueWarnings: Warning[] = (warnings.byIssue[issueId] as any) || [];
	const hasWarnings = !!issueWarnings.length && showWarning;
	const rankWidth = DIGIT_WIDTH * rankDigits;

	const issueLink = issueKey && project.key ? `${project.key}-${issueKey}` : project.key || '';
	const issueLinkChunks = searchQuery.trim() ? splitString(searchQuery, issueLink) : [];
	const backgroundClassName = getIssueClasses({
		isDragging,
		isOptimized,
		rootIndex,
		isSelected,
		isDropParent,
		isListMode: viewMode === VIEW_MODES.LIST,
	});
	const withNestedMatches = hasNestedMatches[group] && hasNestedMatches[group][issueId];

	const rankContent = () => {
		if (hasWarnings) {
			return <WarningDetails warnings={issueWarnings} />;
		}
		if (R.contains(issueId, warnings.transitiveIssueIds) && showWarning) {
			const transitiveWarnings = [misconfiguredChildrenWarning()];
			return <WarningDetails warnings={transitiveWarnings} />;
		}
		if (!hideRank && depth === 0) {
			return rootIndex + 1;
		}
		return null;
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getIssueTypeIcon = (issueId: string, issueType: IssueType) => {
		const classNameNew = fg('plans_performance_improvements_3')
			? styles['issue-icon-new']
			: styles['issue-icon'];

		const issueTypeIcon = (
			<img
				// We need to use img instead of a div with backgroundImage to img due to export PNG rendering issue in Safari (JPO-18968)
				alt={issueType && issueType.name}
				data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.issue-icon-${issueId}`}
				src={issueType && issueType.iconUrl}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={!fg('plan-timeline-non-transposed') ? classNameNew : styles['issue-icon-old']}
			/>
		);
		if (isExportMode) {
			return issueTypeIcon;
		}

		if (!fg('plan-timeline-non-transposed') && fg('plans_performance_improvements_3')) {
			return <Tooltip content={issueType ? issueType.name : ''}>{issueTypeIcon}</Tooltip>;
		}

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.issueIconTooltipWrapper}>
				<Tooltip content={issueType ? issueType.name : ''}>
					{!fg('plan-timeline-non-transposed') ? <span>{issueTypeIcon}</span> : issueTypeIcon}
				</Tooltip>
			</div>
		);
	};

	const cursorStyle = { cursor: isOptimized || isReadOnly ? 'default' : 'pointer' };
	const shouldShowFlyout = isOptimized && isMouseOverIssue;
	const shouldLockScroll = shouldShowFlyout || dropMenuIsOpen;

	const onFocusChanged = (event: React.FocusEvent<HTMLElement>) => {
		if (event.target === event.currentTarget) {
			setShowMeatballButton(true);
		}
	};

	const onKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Tab' && event.shiftKey && event.target === event.currentTarget) {
			setShowMeatballButton(false);
		}
	};

	return (
		<HoverObserver onHoverChanged={onHoverChanged}>
			{
				// eslint-disable-next-line jsx-a11y/no-static-element-interactions
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={cursorStyle}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={`${styles.container} ${backgroundClassName}`}
					data-issue={issueId}
					data-name={`scope-issue-${issueId}`}
					data-group={group}
					ref={ref}
					onFocus={onFocusChanged}
					onKeyDown={onKeyDown}
					data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.row"
					// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
					tabIndex={0}
				>
					<ScrollLock enabled={shouldLockScroll} />
					{shouldShowFlyout &&
						createPortal(
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							<div className={styles.flyoutPositioner} style={{ top: optimizeFlyoutTopPosition }}>
								{/* @ts-expect-error - TS2741 - Property 'dateFormat' is missing in type '{ issue: Issue; }' but required in type 'Readonly<Omit<Props, "versions" | "sprints" | "customFields" | "teams" | "dateConfiguration">>'. */}
								<InlineDialog content={<BarFlyout issue={issue} />} isOpen>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<div className={styles.container} />
								</InlineDialog>
							</div>,
							getBody(),
						)}
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles['ranked-gutter']}>
						{shouldShowRankChangeIndicator() && <ChangeIndicator appearance="bar" />}
					</div>
					{!isReadOnly && (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles['select-checkbox']}>
							<Checkbox
								isChecked={isSelected || hasChildSelected}
								isIndeterminate={hasChildSelected && !isSelected}
								onChange={handleCheckboxChange}
								testId={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-${issueId}`}
								aria-labelledby={`issue-link-${issueId}`}
							/>
						</div>
					)}
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.rank}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={{ width: rankWidth, minWidth: WARNING_WIDTH }}
						data-name="rank"
					>
						{rankContent()}
					</div>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					{hasIndentation && <div className={styles['group-indentation']} />}
					{R.times(
						(n) => (
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							<div key={n} className={styles.icon} style={cursorStyle} data-name="spacer" />
						),
						depth || 0,
					)}
					{isExpandable ? (
						<button
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={`${styles.icon} ${styles.toggle} ${
								withNestedMatches ? styles['parent-match'] : ''
							}`}
							onClick={handleToggle}
							aria-expanded={isExpanded}
							aria-controls={`issue-link-${issueId}`}
							data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.button-${issueId}`}
							aria-labelledby={`issue-link-${issueId}`}
						>
							{isExpanded ? (
								<ChevronDownIcon label="" size="medium" />
							) : (
								<ChevronRightIcon label="" size="medium" />
							)}
						</button>
					) : (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						<div className={styles.icon} style={cursorStyle} />
					)}
					{getIssueTypeIcon(issueId, issueType)}

					{!!project.key && (
						<HighlightIssueLink
							issueKey={issueKey}
							projectKey={project.key}
							issueLinkChunks={issueLinkChunks}
							searchQuery={searchQuery}
							activeMatch={searchMatches[activeSearchResultIndex]}
							issueId={issueId}
							group={group}
						/>
					)}
					{UNSAFE_noExposureExp('support-ai-work-breakdown-in-plans') ? (
						<AiWorkBreakdownPopup
							groupCombination={groupCombination}
							isOpen={isAiSuggestIssuesPopupOpen}
							issue={issue}
							// we remove "aria-expanded" attribute from triggerProps to prevent an increase of accessibility violations
							renderIssueSummary={({ 'aria-expanded': _, ...rest }) => (
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<div className={styles.summary} {...rest}>
									{getIssueSummary(issueLinkChunks)}
									{shouldShowSummaryChangeIndicator() && <ChangeIndicator />}
								</div>
							)}
							toggleAiWorkBreakdownPopup={toggleAiWorkBreakdownPopup}
						/>
					) : (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.summary}>
							{getIssueSummary(issueLinkChunks)}
							{shouldShowSummaryChangeIndicator() && <ChangeIndicator />}
						</div>
					)}

					{shouldShowDropMenu() && dropMenu()}
					{renderDialog()}
				</div>
			}
		</HoverObserver>
	);
};

Issue.displayName = 'Issue';

Issue.whyDidYouRender = true;

export default withAnalyticsEvents()(memo(Issue));
