import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	updateJiraDialogHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.update-jira-dialog-header',
		defaultMessage: 'Unsaved changes',
		description: 'This is the title of the dialog used to commit changes to Jira',
	},
	updateJiraDialogBodyTop: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.update-jira-dialog-body-top',
		defaultMessage:
			'Save issues and releases created or changed in your plan so they update in your project. <link>Read more about saving changes.</link>',
		description:
			'This is part 1 of the description of the dialog used to commit changes to Jira in plans',
	},
	updateJiraDialogBodyBottom: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.update-jira-dialog-body-bottom',
		defaultMessage:
			'To assign issues to a team, save a plan-only team here first before converting it to an Atlassian team.',
		description:
			'This is part 2 of the description of the dialog used to commit changes to Jira in plans',
	},
	updateJiraDialogReadMoreText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.update-jira-dialog-read-more-text',
		defaultMessage: 'Read more about saving changes.',
		description:
			'The text action button visible in the dialog when there are changes in the plan used to redirect to documentation.',
	},
	updateJiraDialogContentNoChanges: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.update-jira-dialog-content-no-changes',
		defaultMessage: 'There are no unsaved changes',
		description:
			'This is the description of the dialog used to commit changes to Jira in plans when there are no changes in the plan.',
	},
	updateJiraDialogRevertButtonText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.update-jira-dialog-revert-button-text',
		defaultMessage: 'Discard selected changes',
		description:
			'This is label for the button to revert changes to be the same as currently on Jira',
	},
	updateJiraDialogUpdateButtonText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.update-jira-dialog-update-button-text',
		defaultMessage: 'Save changes',
		description: 'This is the label on the button used to save changes to Jira in plans',
	},
	updateJiraDialogUpdateButtonTextOld: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.update-jira-dialog-update-button-text-old',
		defaultMessage: 'Save selected changes in Jira',
		description: 'This is the label on the button used to commit changes to Jira',
	},
	updateJiraDialogStopButtonText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.update-jira-dialog-stop-button-text',
		defaultMessage: 'Quit',
		description:
			'The label on a button that can be used to prevent further changes from being committed to Jira.',
	},
	updateJiraDialogStoppingButtonText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.update-jira-dialog-stopping-button-text',
		defaultMessage: 'Quitting',
		description:
			'The label on a button indicating that the user has requested that further changes should not be committed, but that the last change is already in the process of being committed.',
	},
	outOfSyncMessageConcurrentEditing: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.out-of-sync-message-concurrent-editing',
		defaultMessage: 'Your plan is out of sync due to concurrent editing.',
		description: 'Message shown when plan is out of sync due to concurrent editing',
	},
	refreshCta: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.refresh-cta',
		defaultMessage: 'Refresh to get the latest version. Your changes will not be lost.',
		description: 'Message shown when plan is out of sync due to concurrent editing',
	},
	refreshButton: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.refresh-button',
		defaultMessage: 'Refresh',
		description: 'Button text to refresh the page',
	},
	disabledTooltipForUpdateJira: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.disabled-tooltip-for-update-jira',
		defaultMessage: "You don't have permission to save changes in Jira.",
		description: 'Tooltip shown when update/connect jira is disabled',
	},
	disabledTooltipForUpdateJiraWhenGicIsOpen: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.disabled-tooltip-for-update-jira-when-gic-is-open',
		defaultMessage:
			"You can't save selected changes in Jira because you already have an issue in creation.",
		description:
			'Tooltip shown when update/connect jira is disabled because there is an existing issue create modal opened',
	},
	closeDialog: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.close-dialog',
		defaultMessage: 'Close dialog',
		description: 'Close the update jira dialog on the Jira Plans page.',
	},
	updateJiraDialogBodyTopIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.update-jira-dialog-body-top-issue-term-refresh',
		defaultMessage:
			'Save issues and releases created or changed in your plan so they update in your project. <link>Read more about saving changes.</link>',
		description:
			'This is part 1 of the description of the dialog used to commit changes to Jira in plans',
	},
	updateJiraDialogBodyBottomIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.update-jira-dialog-body-bottom-issue-term-refresh',
		defaultMessage:
			'To assign issues to a team, save a plan-only team here first before converting it to an Atlassian team.',
		description:
			'This is part 2 of the description of the dialog used to commit changes to Jira in plans',
	},
	disabledTooltipForUpdateJiraWhenGicIsOpenIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.disabled-tooltip-for-update-jira-when-gic-is-open-issue-term-refresh',
		defaultMessage:
			"You can't save selected changes in Jira because you already have an issue in creation.",
		description:
			'Tooltip shown when update/connect jira is disabled because there is an existing issue create modal opened',
	},
});
export default messages;
