import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.assignee-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchAssigneePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.assignee-filter.search-assignee-placeholder',
		defaultMessage: 'Show issues from assignees',
		description: 'This is a placeholder for search',
	},
	searchAssigneeLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.assignee-filter.search-assignee-label',
		defaultMessage: 'Choose from assignees',
		description: 'Aria label for assignees search filed that appears on activating Filters button',
	},
	searchAssigneePlaceholderIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.assignee-filter.search-assignee-placeholder-issue-term-refresh',
		defaultMessage: 'Show issues from assignees',
		description: 'This is a placeholder for search',
	},
});
