import React, { useCallback, useMemo } from 'react';
import Popup from '@atlaskit/popup';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import {
	WarningTypes,
	type WarningMessages,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/warnings/engine.tsx';
import { ISSUE_WARNING_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { START_DATE_AFTER_END_DATE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/warnings/types.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import FilterText from '../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const WarningMenuItem = ({
	warningType,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	messages,
	value,
	onWarningFilterChange,
}: {
	warningType: string;
	messages: WarningMessages;
	value: string[];
	onWarningFilterChange: (value: string[]) => void;
}) => {
	const { formatMessage } = useIntl();

	const onChange = useCallback(
		() =>
			onWarningFilterChange(
				value.includes(warningType)
					? value.filter((x) => x !== warningType)
					: [...value, warningType],
			),
		[onWarningFilterChange, warningType, value],
	);
	return (
		<DialogMenuItem key={warningType}>
			<Checkbox
				id={warningType}
				key={warningType}
				isChecked={value.includes(warningType)}
				onChange={onChange}
				label={formatMessage(messages.header)}
			/>
		</DialogMenuItem>
	);
};

export const IssueWarningFilter = ({
	value,
	onWarningFilterChange,
	onClearWarningFilter,
	onOpenChange,
	isOpen,
}: Props) => {
	const { formatMessage } = useIntl();
	const filterText = useMemo(
		() =>
			value.length > 0
				? value
						.map((id) => WarningTypes[id])
						// eslint-disable-next-line @typescript-eslint/no-shadow
						.filter((messages) => messages !== undefined)
						// eslint-disable-next-line @typescript-eslint/no-shadow
						.map((messages) => formatMessage(messages.header))
						.join(', ')
				: '',
		[value, formatMessage],
	);
	return fg('migrate_plan_filter_to_popup') ? (
		<Popup
			isOpen={isOpen}
			onClose={() =>
				onOpenChange({
					isOpen: false,
				})
			}
			shouldUseCaptureOnOutsideClick
			autoFocus
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-warning-filter"
			placement="bottom-start"
			content={() => (
				<DialogMenuContainer>
					<ClearFilterButton isVisible={!!value.length} onClearClick={onClearWarningFilter} />
					{
						// eslint-disable-next-line @typescript-eslint/no-shadow
						Object.entries(WarningTypes).map(([warningType, messages]) => {
							if (
								!fg('due_date_warning_on_plan_timeline') &&
								warningType === START_DATE_AFTER_END_DATE
							) {
								return null;
							}
							return (
								<WarningMenuItem
									key={warningType}
									warningType={warningType}
									messages={messages}
									value={value}
									onWarningFilterChange={onWarningFilterChange}
								/>
							);
						})
					}
				</DialogMenuContainer>
			)}
			trigger={(triggerProps) => (
				<TriggerButton
					{...triggerProps}
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-warning-filter.trigger-btn"
					triggerButtonText={
						value.length > 0 ? (
							<FilterText text={filterText} />
						) : (
							formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.emptyPlaceholderIssueTermRefresh
									: messages.emptyPlaceholder,
							)
						)
					}
					ariaLabel={`${formatMessage(filterMessages[ISSUE_WARNING_FILTER_ID])}, ${
						value.length > 0
							? filterText
							: formatMessage(
									fg('jira-issue-terminology-refresh-m3')
										? messages.emptyPlaceholderIssueTermRefresh
										: messages.emptyPlaceholder,
								)
					} ${formatMessage(filterMessages.selected)}`}
				/>
			)}
		/>
	) : (
		<InlineDialog
			noPaddings
			maxWidth={FILTER_MAX_WIDTH}
			minWidth={FILTER_WIDTH}
			onClose={onOpenChange}
			isOpen={isOpen}
			content={
				<DialogMenuContainer>
					<ClearFilterButton isVisible={!!value.length} onClearClick={onClearWarningFilter} />
					{
						// eslint-disable-next-line @typescript-eslint/no-shadow
						Object.entries(WarningTypes).map(([warningType, messages]) => {
							if (
								!fg('due_date_warning_on_plan_timeline') &&
								warningType === START_DATE_AFTER_END_DATE
							) {
								return null;
							}
							return (
								<WarningMenuItem
									key={warningType}
									warningType={warningType}
									messages={messages}
									value={value}
									onWarningFilterChange={onWarningFilterChange}
								/>
							);
						})
					}
				</DialogMenuContainer>
			}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-warning-filter"
		>
			<TriggerButton
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-warning-filter.trigger-btn"
				triggerButtonText={
					value.length > 0 ? (
						<FilterText text={filterText} />
					) : (
						formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.emptyPlaceholderIssueTermRefresh
								: messages.emptyPlaceholder,
						)
					)
				}
				ariaLabel={`${formatMessage(filterMessages[ISSUE_WARNING_FILTER_ID])}, ${
					value.length > 0
						? filterText
						: formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.emptyPlaceholderIssueTermRefresh
									: messages.emptyPlaceholder,
							)
				} ${formatMessage(filterMessages.selected)}`}
			/>
		</InlineDialog>
	);
};
