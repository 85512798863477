.main {
	height: 40px;
	position: relative;
	top: 50px;
	width: 100%;
	z-index: 10;
	/* remove the next class and merge with the parent one when cleaning up plan-timeline-non-transposed */
	&.transposed {
		top: 0;
		z-index: unset;
	}
}
