import React, {
	useCallback,
	useRef,
	useEffect,
	forwardRef,
	type SyntheticEvent,
	type Ref,
} from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { Box, Stack } from '@atlaskit/primitives';
import { Radio } from '@atlaskit/radio';
import { AsyncSelect, type SelectInstance } from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
	DialogMenuGroup,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import { selectIssueOption } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import type { ProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	type DependecyTypeSpecificIssue,
	type DependenciesFilterValue,
	DEPENDENCIES_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';

import { fireUIAnalytics, AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import FilterText from '../common/filter-text/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';

import type { Props } from './types.tsx';

type AtlaskitSelectRefType = {
	select: SelectInstance;
};

export const RadioButtonWithAnalytics = AnalyticsEventToProps('radioButton', {
	onChange: 'changed',
})(Radio);

const AsyncSelectWithAnalytics = AnalyticsEventToProps('asyncSelect', {
	onChange: 'changed',
})(AsyncSelect);

function isDepdendencyFilterValueHasTransitive(
	val: DependenciesFilterValue,
): val is DependecyTypeSpecificIssue {
	return val.type === 'specificIssue';
}

const getIssueKey = (issue: Issue, projectsById: ProjectsById) => {
	const { issueKey, project: projectId } = issue;
	const project = projectsById[projectId || NaN];
	const key = issueKey ? `${project.key}-${issueKey}` : project.key;
	return key;
};

const DependencyFilterTriggerButton = forwardRef(
	(
		props: Pick<Props, 'issueMapById' | 'value' | 'projectsById' | 'isOpen' | 'onOpenChange'> &
			TriggerProps,
		ref: Ref<HTMLButtonElement>,
	) => {
		const { issueMapById, value, projectsById, isOpen, onOpenChange, ...triggerProps } = props;
		const { formatMessage } = useIntl();

		let triggerButtonText = '';

		if (value.type === 'specificIssue') {
			const { issueId } = value;
			if (issueId && issueMapById[issueId]) {
				triggerButtonText = formatMessage(messages.specificIssueTrigger, {
					issueKey: getIssueKey(issueMapById[issueId], projectsById),
				});
			} else {
				triggerButtonText = formatMessage(messages.offTrigger);
			}
		} else {
			triggerButtonText = formatMessage(messages[`${value.type}Trigger`]);
		}

		const ariaText = () =>
			// Dependencies, All issues selected
			`${formatMessage(
				filterMessages[DEPENDENCIES_FILTER_ID],
			)}, ${triggerButtonText} ${formatMessage(filterMessages.selected)}`;

		return (
			<TriggerButton
				{...triggerProps}
				ref={ref}
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				ariaLabel={ariaText()}
				triggerButtonText={<FilterText text={triggerButtonText} />}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.trigger-btn"
			/>
		);
	},
);

function DependenciesFilter(
	props: Pick<
		Props,
		'changeDependenciesFilter' | 'value' | 'issueMapById' | 'issueTypes' | 'projectsById' | 'issues'
	> & { setInitialFocusRef?: (elem: HTMLElement | null) => void },
) {
	const {
		value,
		changeDependenciesFilter,
		issueMapById,
		issueTypes,
		projectsById,
		issues,
		setInitialFocusRef,
	} = props;
	const { formatMessage } = useIntl();
	const selectRef = useRef<AtlaskitSelectRefType | null>(null);

	useEffect(() => {
		if (setInitialFocusRef && selectRef && selectRef?.current?.select?.inputRef) {
			setInitialFocusRef(selectRef.current.select.inputRef);
		}
	}, [setInitialFocusRef]);

	const onDependencyFilterChange = useCallback(
		(payload: DependenciesFilterValue, analyticsEvent: UIAnalyticsEvent) => {
			changeDependenciesFilter(payload);

			// triggering analytics when a user adds changes the "Dependencies" filter
			const [actionSubject, action] =
				PRODUCT_ANALYTICS_EVENT_NAMES.UPDATED_DEPENDENCIES_FILTER.split(' ');
			fireUIAnalytics(analyticsEvent.update({ action, actionSubject }), {
				type: payload.type,
			});
		},
		[changeDependenciesFilter],
	);

	const getDependencyIssueValue = useCallback(
		(issue: Issue) => `${getIssueKey(issue, projectsById)} ${issue.summary}`,
		[projectsById],
	);

	const renderDependencyIssue = useCallback(
		(issue: Issue) => {
			const issueType = issueTypes.find(({ id }) => id === issue.type);

			return selectIssueOption({
				icon: issueType && issueType.iconUrl,
				label: getDependencyIssueValue(issue),
			});
		},
		[issueTypes, getDependencyIssueValue],
	);

	const loadOptions = useCallback(
		(query: string) => {
			const q = query.toLowerCase();
			const issuesFiltered = issues
				.filter((issue) => getDependencyIssueValue(issue).toLowerCase().includes(q))
				.slice(0, 50);
			return Promise.resolve(issuesFiltered);
		},
		[getDependencyIssueValue, issues],
	);

	const onIssueChange = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(issue: Issue, _: SyntheticEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
			onDependencyFilterChange(
				{
					type: 'specificIssue',
					issueId: issue && issue.id,
					includeTransitive:
						(isDepdendencyFilterValueHasTransitive(value) && value.includeTransitive) || false,
				},
				analyticsEvent,
			);
		},
		[onDependencyFilterChange, value],
	);

	return (
		<DialogMenuContainer>
			<DialogMenuGroup heading={formatMessage(messages.showIssues)}>
				<DialogMenuItem>
					<RadioButtonWithAnalytics
						isChecked={value.type === 'off'}
						label={formatMessage(messages.allIssues)}
						name="showIssues"
						onChange={(
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							_: SyntheticEvent<any>,
							analyticsEvent: UIAnalyticsEvent,
						) => onDependencyFilterChange({ type: 'off' }, analyticsEvent)}
						value="off"
					/>
				</DialogMenuItem>
				<DialogMenuItem>
					<RadioButtonWithAnalytics
						isChecked={value.type === 'on'}
						label={formatMessage(messages.hasDependencies)}
						name="showIssues"
						onChange={(
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							_: SyntheticEvent<any>,
							analyticsEvent: UIAnalyticsEvent,
						) => onDependencyFilterChange({ type: 'on' }, analyticsEvent)}
						value="on"
					/>
				</DialogMenuItem>
				<DialogMenuItem>
					<RadioButtonWithAnalytics
						isChecked={value.type === 'offtrack'}
						label={formatMessage(messages.offtrackDependencies)}
						name="showIssues"
						onChange={(
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							_: SyntheticEvent<any>,
							analyticsEvent: UIAnalyticsEvent,
						) => onDependencyFilterChange({ type: 'offtrack' }, analyticsEvent)}
						value="offtrack"
					/>
				</DialogMenuItem>
				<DialogMenuItem>
					<RadioButtonWithAnalytics
						isChecked={value.type === 'specificIssue'}
						label={formatMessage(messages.specificIssue)}
						name="showIssues"
						onChange={(
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							_: SyntheticEvent<any>,
							analyticsEvent: UIAnalyticsEvent,
						) => onDependencyFilterChange({ type: 'specificIssue' }, analyticsEvent)}
						value="specificIssue"
					/>
				</DialogMenuItem>
			</DialogMenuGroup>
			{value.type === 'specificIssue' ? (
				<Stack>
					<Box paddingInlineStart="space.600" paddingInlineEnd="space.250" paddingBlock="space.100">
						<AsyncSelectWithAnalytics
							defaultOptions
							defaultValue={value.issueId && issueMapById[value.issueId]}
							formatOptionLabel={renderDependencyIssue}
							getOptionValue={getDependencyIssueValue}
							getOptionLabel={getDependencyIssueValue}
							isClearable
							isTransparentBackground={false}
							loadOptions={loadOptions}
							menuPlacement="auto"
							onChange={onIssueChange}
							placeholder={formatMessage(messages.issueSelectorPlaceholder)}
							ref={selectRef}
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							menuPortalTarget={document.body}
							styles={{
								// @ts-expect-error - TS7006 - Parameter 'base' implicitly has an 'any' type.
								menuPortal: (base) => ({
									...base,
									zIndex: 401,
								}),
							}}
						/>
					</Box>
					<Box
						paddingInlineStart="space.600"
						paddingInlineEnd="space.250"
						paddingBlockStart="space.100"
						paddingBlockEnd="space.200"
					>
						<Checkbox
							isChecked={value.includeTransitive}
							label={formatMessage(messages.includeTransitive)}
							onChange={(
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								_: SyntheticEvent<any>,
								analyticsEvent: UIAnalyticsEvent,
							) =>
								onDependencyFilterChange(
									{
										type: 'specificIssue',
										issueId: value.issueId,
										includeTransitive: !value.includeTransitive,
									},
									analyticsEvent,
								)
							}
						/>
					</Box>
				</Stack>
			) : null}
		</DialogMenuContainer>
	);
}

function DependenciesFilterDialog(props: Props) {
	const {
		isOpen,
		onOpenChange,
		value,
		projectsById,
		issueMapById,
		changeDependenciesFilter,
		issues,
		issueTypes,
	} = props;

	return (
		<Popup
			isOpen={isOpen}
			placement="bottom-start"
			rootBoundary="viewport"
			shouldFitContainer
			onClose={() => {
				if (value.type === 'specificIssue' && !value.issueId) {
					changeDependenciesFilter({ type: 'off' });
				}
				onOpenChange({ isOpen: false });
			}}
			content={(contentProps) => (
				<DependenciesFilter
					changeDependenciesFilter={changeDependenciesFilter}
					value={value}
					issueMapById={issueMapById}
					issueTypes={issueTypes}
					projectsById={projectsById}
					issues={issues}
					setInitialFocusRef={contentProps.setInitialFocusRef}
				/>
			)}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter"
			trigger={({ ref, ...triggerProps }) => (
				<DependencyFilterTriggerButton
					{...triggerProps}
					ref={ref}
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					value={value}
					projectsById={projectsById}
					issueMapById={issueMapById}
				/>
			)}
		/>
	);
}

export default DependenciesFilterDialog;
