import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	teamMenuTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.team.team-menu-title',
		defaultMessage: 'Choose team',
		description: 'This is a title for team menu',
	},
	teamTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.team.team-tooltip',
		defaultMessage: "You can't create issues for external teams.",
		description: 'Tooltip for disabled option for external team',
	},
	teamSearchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.team.team-search-placeholder',
		defaultMessage: 'Find teams...',
		description: 'This is a placeholder for search',
	},
	teamTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.team.team-tooltip-issue-term-refresh',
		defaultMessage: "You can't create issues for external teams.",
		description: 'Tooltip for disabled option for external team',
	},
});
