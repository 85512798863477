.container {
	min-height: 200px;
}

.containerOld {
	min-height: 200px;
	padding-bottom: 10px;
}

.errorMessage {
	color: var(--ds-text-danger, var(--adg3-color-R400));
	font-size: 15px;
	margin-top: 5px;
}

.previewContainer {
	background-color: var(--ds-background-neutral-bold, var(--adg3-color-N500));
	margin-top: 16px;
	position: relative;
}

.previewContainerOld {
	background-color: var(--ds-background-neutral-bold, var(--adg3-color-N500));
	margin-top: 16px;
}

.previewPaneOld {
	padding: 50px 20px;
	min-height: 400px;
}

.previewPane {
	padding: 50px 20px;
	min-height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.previewBadge {
	background-color: var(--ds-background-neutral-bold-hovered, var(--adg3-color-N700));
	color: var(--ds-text-inverse, var(--adg3-color-N0));
	display: flex;
	position: absolute;
	opacity: 0.8;
	border-radius: 3px;
	margin: 10px;
	padding: 5px;
	font-size: 13px;

	& svg {
		stroke: var(--ds-icon-inverse, var(--adg3-color-N0));
	}
}

.spinnerContainer {
	margin-right: 5px;
}

.previewImageOld {
	object-fit: contain;
	width: 100%;
	height: 400px;
}

.previewImage {
	object-fit: contain;
	width: 100%;
	flex: 1;
}

.collapsedIframeOld {
	visibility: collapse;
}

.collapsedIframe {
	visibility: collapse;
	position: absolute;
	top: 0;
	left: 0;
}

.footer {
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
}

.warningMessage {
	display: flex;
	align-items: center;
	gap: 4px;
}
