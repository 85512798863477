import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueEstimateTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.estimate.issue-estimate-tooltip',
		defaultMessage: 'Estimate of this issue: ',
		description: 'Tooltip text for the estimate value of the issue',
	},
	estimateCellLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.estimate.estimate-cell-label',
		defaultMessage: 'Enter an estimate for issue {issue}',
		description: 'Label for the estimate input field',
	},
	issueEstimateTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.estimate.issue-estimate-tooltip-issue-term-refresh',
		defaultMessage: 'Estimate of this issue: ',
		description: 'Tooltip text for the estimate value of the issue',
	},
	estimateCellLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.estimate.estimate-cell-label-issue-term-refresh',
		defaultMessage: 'Enter an estimate for issue {issue}',
		description: 'Label for the estimate input field',
	},
});
