import React, { type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { AkFlag as Flag } from '@atlassian/jira-flags';
import { FormattedMessage } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import {
	redirectToIssueSourceSetup,
	getPathParams,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import {
	fireUIAnalytics,
	MODAL,
	fireScreenAnalytics,
	ContextualAnalyticsData,
	MountEvent,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export const getProjectLimitWarningFlag = () => {
	const handleUpdateIssueSourcesButtonClick = (
		_: SyntheticEvent,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const { id: planId } = getPathParams();
		fireUIAnalytics(
			analyticsEvent.update({
				actionSubject: 'projectLimitWarningModal',
				action: 'updateLinkClicked',
			}),
		);
		redirectToIssueSourceSetup(planId);
	};

	return (
		<Flag
			key="project-limit-warning"
			id="project-limit-warning"
			title={
				<ContextualAnalyticsData sourceType={MODAL} sourceName="projectLimitWarning">
					<MountEvent onMount={fireScreenAnalytics} />
					<FormattedMessage {...messages.title} />
				</ContextualAnalyticsData>
			}
			description={
				<FormattedMessage
					{...(fg('jira-issue-terminology-refresh-m3')
						? messages.descriptionIssueTermRefresh
						: messages.description)}
				/>
			}
			icon={
				<ErrorIcon
					label="ProjectLimitException"
					primaryColor={token('color.icon.danger', colors.R400)}
				/>
			}
			actions={[
				{
					content: (
						<FormattedMessage
							{...(fg('jira-issue-terminology-refresh-m3')
								? messages.updateEditIssueSourcesIssueTermRefresh
								: messages.updateEditIssueSources)}
						/>
					),
					onClick: handleUpdateIssueSourcesButtonClick,
				},
			]}
		/>
	);
};
