import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export const DisabledCustomFieldRender = ({
	customField: { title },
	projectName,
	issueTypeNames,
}: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Tooltip
			content={
				<>
					{formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.disabledScopedFieldTooltipIssueTermRefresh
							: messages.disabledScopedFieldTooltip,
						{
							customFieldName: title,
							projectName,
							issueTypeNames,
						},
					)}
				</>
			}
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.container}>{formatMessage(messages.disabledScopedFieldNa)}</div>
		</Tooltip>
	);
};

export default DisabledCustomFieldRender;
