import React, { useState, useEffect, memo } from 'react';
import { GET } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/api.tsx';
import {
	genericError,
	type GenericErrorActionPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/errors/index.tsx';
import { urls } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/teams/api.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import TeamProfile from '@atlassian/jira-portfolio-3-portfolio/src/common/view/team-profile/index.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/team-profile/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { Props, DispatchProps, OwnProps } from './types.tsx';

// eslint-disable-next-line @typescript-eslint/no-shadow
export const LoadTeamProfileFromId = ({ teamId, members, genericError }: Props) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [team, setTeam] = useState<Team | null>(null);
	const [hasError, setHasError] = useState<boolean>(false);
	const siteId = useCloudId();

	useEffect(() => {
		const handleError = (error: GenericErrorActionPayload) => {
			genericError(error);
			setHasError(true);
			setLoading(false);
		};
		if (!isDefined(teamId)) {
			return handleError({ message: 'Team ID is undefined', stackTrace: new Error().stack });
		}
		const loadTWPTeam = async () => {
			try {
				const response = await fetch(urls.getTWPTeamV4(teamId, siteId ?? 'None'), {
					method: GET,
				});

				if (response.ok) {
					// eslint-disable-next-line @typescript-eslint/no-shadow
					const team = await response.json();
					const formattedMembers = members.map(({ label, icon, accountId }) => ({
						fullName: label,
						avatarUrl: icon,
						accountId,
					}));
					const teamAndMembers: Team = { ...team, members: formattedMembers };
					setTeam(teamAndMembers);
					setLoading(false);
				} else {
					throw Error('Failed to retrieve team info');
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				handleError({ message: e.message, stackTrace: e.stack });
			}
		};
		loadTWPTeam();
	}, [teamId, siteId, members, genericError]);
	return <TeamProfile team={team} isLoading={loading} hasError={hasError} />;
};

function areEqual(prevProps: Props, nextProps: Props) {
	// do not re-render unless team id is changed to avoid flickering when editing the team form
	return (
		prevProps.teamId === nextProps.teamId &&
		prevProps?.members?.length === nextProps?.members?.length
	);
}

const memoizedLoadTeamProfileFromId = memo<Props>(LoadTeamProfileFromId, areEqual);

export default connect<{}, DispatchProps, OwnProps, State>(null, { genericError })(
	memoizedLoadTeamProfileFromId,
);
