import React, { Component, type ReactNode } from 'react';
import { ExperienceFailureTracker as ViewExperienceFailureTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import { ErrorPage as ErrorBoundaryPage } from '@atlassian/jira-error-boundary/src/ui/error-page/ErrorPage.tsx';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import {
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

type Props = {
	children: ReactNode;
};

class PortfolioErrorBoundary extends Component<
	Props,
	{
		hasError: boolean;
		error: Error | null;
	}
> {
	// eslint-disable-next-line react/sort-comp
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false, error: null };
	}

	static getDerivedStateFromError(error: Error) {
		return { hasError: true, error };
	}

	componentDidCatch(error: Error) {
		fireErrorAnalytics({
			error,
			meta: {
				id: ERROR_REPORTING_PACKAGE.APP,
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}

	render() {
		if (this.state.hasError) {
			return (
				<>
					<ViewExperienceFailureTracker location="portfolio-3.simple-plans.view-error" />
					<ErrorBoundaryPage error={this.state.error} />
				</>
			);
		}

		return this.props.children;
	}
}

export default PortfolioErrorBoundary;
