import React, { Component } from 'react';
import * as R from 'ramda';
import CheckIcon from '@atlaskit/icon/glyph/check';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { Box, Inline, Flex, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { DependenciesDialogContext } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/columns/cells/dependencies/context.tsx';
import { ISSUE_LINK_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import IssueSelector from './issue-selector/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import TypeSelector from './type-selector/index.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class DependencySelector extends Component<Props> {
	renderConfirmButton = () => {
		const {
			addDependencyIssue,
			addDependencyType,
			direction,
			internalIssueLinks,
			issueLinkTypes,
			intl: { formatMessage },
			onAddDependencyConfirm,
		} = this.props;
		const type = addDependencyType || R.head(issueLinkTypes);
		const typeId = type && type.id;
		const otherIssueKey =
			direction === ISSUE_LINK_DIRECTION.OUTWARD ? 'targetItemKey' : 'sourceItemKey';
		// do not allow to confirm when issue is not selected or the same type-issue pair already exists
		const isDisabled =
			!typeId ||
			!addDependencyIssue ||
			!!internalIssueLinks.find(
				// eslint-disable-next-line @typescript-eslint/no-shadow
				({ type, [otherIssueKey]: otherIssueId }) =>
					type === typeId && otherIssueId === addDependencyIssue.id,
			);

		return (
			<Button
				ariaLabel={formatMessage(messages.dependencyConfirmButtonAriaLabel)}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.addDependencyButton}
				iconBefore={<CheckIcon label="" size="small" />}
				isDisabled={isDisabled}
				onClick={onAddDependencyConfirm}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-dependency.dependency-selector.dependency-confirm-btn"
			/>
		);
	};

	renderCancelButton = () => (
		<Button
			ariaLabel={this.props.intl.formatMessage(messages.dependencyCancelButtonAriaLabel)}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.addDependencyButton}
			iconBefore={<CrossIcon label="" size="small" />}
			onClick={this.props.onAddDependencyCancel}
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-dependency.dependency-selector.button"
		/>
	);

	render() {
		const {
			addDependencyType,
			direction,
			issue,
			issueLinkTypes,
			issueTypes,
			issues,
			onChangeDependencyIssue,
			onChangeDependencyType,
			projectsById,
			isInitiallyAddingNewDependency,
		} = this.props;
		const typeSelectorProps = {
			addDependencyType,
			direction,
			issueLinkTypes,
			onChangeDependencyType,
		};
		const issueSelectorProps = {
			issue,
			issueTypes,
			issues,
			onChangeDependencyIssue,
			projectsById,
		};
		return (
			<DependenciesDialogContext.Consumer>
				{({ toggleDialogLocked }) => (
					<Inline xcss={inlineStyles}>
						<Box
							xcss={[
								addDependencyTypeStyles,
								!isInitiallyAddingNewDependency && addDependencyTypeLeftStyles,
							]}
						>
							<TypeSelector
								{...typeSelectorProps}
								toggleDialogLocked={toggleDialogLocked('TypeSelector')}
								ariaLabel={this.props.intl.formatMessage(
									fg('jira-issue-terminology-refresh-m3')
										? messages.dependencyTypeSelectorLabelIssueTermRefresh
										: messages.dependencyTypeSelectorLabel,
								)}
							/>
						</Box>

						<Box xcss={addDependencyIssueStyles}>
							<IssueSelector
								{...issueSelectorProps}
								toggleDialogLocked={toggleDialogLocked('IssueSelector')}
							/>
						</Box>
						{isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2') ? (
							<Flex gap="space.050" xcss={buttonContainerStyles}>
								{this.renderConfirmButton()}
								{this.renderCancelButton()}
							</Flex>
						) : (
							<>
								{this.renderConfirmButton()}
								{this.renderCancelButton()}
							</>
						)}
					</Inline>
				)}
			</DependenciesDialogContext.Consumer>
		);
	}
}

const inlineStyles = xcss({
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
});

const addDependencyIssueStyles = xcss({
	minWidth: '300px',
	flexWrap: 'nowrap',
	flex: '1',
});

const addDependencyTypeStyles = xcss({
	paddingRight: 'space.100',
	minWidth: '164px',
});

const addDependencyTypeLeftStyles = xcss({
	paddingLeft: 'space.100',
});

const buttonContainerStyles = xcss({
	marginLeft: 'space.100',
});

export default injectIntl(DependencySelector);
