import React, { Component } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl } from '@atlassian/jira-intl';
import { selectIssueOption } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	getBody,
	removeNode,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import { AsyncSelect } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/select/view.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

type Option = Issue & {
	label?: string;
};

// eslint-disable-next-line jira/react/no-class-components
class IssueSelector extends Component<Props> {
	// eslint-disable-next-line react/sort-comp
	menuContainer: HTMLDivElement;

	constructor(props: Props) {
		super(props);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		this.menuContainer = document.createElement('div');
		this.menuContainer.style.pointerEvents = 'none';
		// menu container is stretched to the viewport size and clips menu
		// if it extends outside viewport to prevent breaking page layout and jumps
		this.menuContainer.classList.add(styles.menuContainer);
		getBody().appendChild(this.menuContainer);
	}

	componentWillUnmount() {
		removeNode(this.menuContainer);
	}

	renderDependencyIssue = (issue: Issue) => {
		const { issueTypes } = this.props;
		const issueType = issueTypes.find(({ id }) => id === issue.type);
		return selectIssueOption({
			icon: issueType && issueType.iconUrl,
			label: this.getDependencyIssueValue(issue),
		});
	};

	getDependencyIssueValue = ({ issueKey, project: projectId, summary }: Issue) => {
		const { projectsById } = this.props;
		const project = projectsById[projectId || NaN];
		const key = issueKey ? `${project.key}-${issueKey}` : project.key;
		return `${key} ${summary}`;
	};

	loadOptions = (query: string) => {
		const {
			issue: { id },
		} = this.props;
		const q = query.toLowerCase();
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const options: Option[] = this.props.issues.reduce<Option[]>((options, issue) => {
			if (
				options.length < 50 &&
				this.getDependencyIssueValue(issue).toLowerCase().includes(q) &&
				issue.id !== id
			) {
				options.push({
					...issue,
					label: this.getDependencyIssueValue(issue),
				});
			}
			return options;
		}, []);

		return Promise.resolve(options);
	};

	onMenuOpen = () => {
		this.props.toggleDialogLocked(true);
	};

	onMenuClose = () => {
		this.props.toggleDialogLocked(false);
	};

	render() {
		const { onChangeDependencyIssue, intl } = this.props;
		return (
			<Box
				xcss={addDependencyIssueSelectorStyles}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-dependency.dependency-selector.issue-selector.dependency-flyout-issue-selector"
			>
				<AsyncSelect
					autoFocus
					defaultOptions
					// @ts-expect-error - TS2322 - Type '(issue: Issue) => JSX.Element' is not assignable to type '(arg1: OptionType, arg2: FormatOptionLabelMeta) => ReactElement<any, string | JSXElementConstructor<any>>'.
					formatOptionLabel={this.renderDependencyIssue}
					getOptionValue={this.getDependencyIssueValue}
					isClearable
					isTransparentBackground={false}
					loadOptions={this.loadOptions}
					menuPlacement="auto"
					menuPortalTarget={this.menuContainer}
					// @ts-expect-error - TS2322 - Type '(arg1?: Readonly<{ assignee: string; assignments: Assignment[]; annotations?: Annotation[] | undefined; baselineStart?: number | null | undefined; baselineEnd?: number | null | undefined; ... 30 more ...; inferred?: InferredFields | undefined; }> | null | undefined) => void' is not assignable to type '(arg1: ValueType, arg2: ActionMeta) => void'.
					onChange={onChangeDependencyIssue}
					placeholder={intl.formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.issueSelectorPlaceholderIssueTermRefresh
							: messages.issueSelectorPlaceholder,
					)}
					styles={{
						menuPortal: (menuPortalStyles) => ({
							...menuPortalStyles,
							zIndex: 1000,
							pointerEvents: 'initial',
						}),
					}}
					onMenuOpen={this.onMenuOpen}
					onMenuClose={this.onMenuClose}
					aria-label={intl.formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.issueSelectorLabelIssueTermRefresh
							: messages.issueSelectorLabel,
					)}
				/>
			</Box>
		);
	}
}

const addDependencyIssueSelectorStyles = xcss({
	width: '100%',
});

export default injectIntl(IssueSelector);
