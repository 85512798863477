import type { Effect } from 'redux-saga';
import { fork, takeEvery, put, call, select } from 'redux-saga/effects';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ENTITY,
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getOutgoingLinks, type IssueLinksByIssueId } from '../../query/issue-links/index.tsx';
import { getPlan } from '../../query/plan/index.tsx';
import { getDependencySettingsInfo } from '../../query/system/index.tsx';
import { getSequence } from '../../query/update-jira/index.tsx';
import * as issueLinksActions from '../../state/domain/issue-links/actions.tsx';
import type { PlanInfo } from '../../state/domain/plan/types.tsx';
import * as sequenceActions from '../../state/domain/sequence/actions.tsx';
import type { Sequence } from '../../state/domain/sequence/types.tsx';
import type { DependencySettingsInfo } from '../../state/domain/system/types.tsx';
import * as warningActions from '../../state/domain/update-jira/warnings/actions.tsx';
import { parseError } from '../api.tsx';
import type { BulkCommitResponseEntity } from '../commit-bulk/types.tsx';
import { revertBody } from '../commit/api.tsx';
import { inspectForCommitWarnings, defaultWarning } from '../commit/warnings.tsx';
import { genericError as deprecatedGenericError } from '../errors/index.tsx';
import * as http from '../http/index.tsx';
import { toErrorID } from '../util.tsx';
import { urls } from './api.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { RemoveActionPayload } from '../../state/domain/issue-links/actions';

export type AddActionPayload = Flow.Diff<
	issueLinksActions.AddActionPayload,
	{
		itemKey: string;
	}
>;

export const ADD_ISSUE_LINK = 'command.issue-links.ADD_ISSUE_LINK' as const;

export const DELETE_ISSUE_LINK = 'command.issue-links.DELETE_ISSUE_LINK' as const;

export type AddAction = {
	type: typeof ADD_ISSUE_LINK;
	payload: AddActionPayload;
};

export type DeleteAction = {
	type: typeof DELETE_ISSUE_LINK;
	payload: issueLinksActions.RemoveActionPayload;
};

export type ActionWithExternalPromise<T> = T & {
	payload: {
		promise?: {
			resolve: Function;
			reject: Function;
		};
	};
};

export const addIssueLink = (payload: AddActionPayload): AddAction => ({
	type: ADD_ISSUE_LINK,
	payload,
});

export const deleteIssueLink = (payload: issueLinksActions.RemoveActionPayload): DeleteAction => ({
	type: DELETE_ISSUE_LINK,
	payload,
});

export function* withExternalPromiseResolve(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	block: () => Generator<Effect, any, any>,
	promise: { resolve: Function; reject: Function } | undefined,
	checkResult = false,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	if (!promise || !promise?.resolve || !promise?.reject) {
		return yield call(block);
	}

	try {
		const result = yield call(block);

		if (checkResult && result === undefined) {
			promise.reject(new Error('No result returned from the block'));
		} else {
			promise.resolve(result);
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		promise.reject(err);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doAddIssueLink({ payload }: AddAction): Generator<Effect, any, any> {
	// random alpha-numeric key for optimistic issue link
	const pendingKey = Math.random().toString(36).slice(2);

	const { sourceItemKey, targetItemKey, type } = payload;
	const { currentScenarioId: scenarioId, id: planId } = yield select(getPlan);
	const { dependencyIssueLinkTypes }: DependencySettingsInfo =
		yield select(getDependencySettingsInfo);
	const outgoingLinks: IssueLinksByIssueId = yield select(getOutgoingLinks);
	const dependencyIssueLinkTypeById = indexBy(
		(dependencyIssueLinkType) => dependencyIssueLinkType.issueLinkTypeId,
		dependencyIssueLinkTypes,
	);
	const dependencyIssueLinkType = dependencyIssueLinkTypeById[type];

	if (dependencyIssueLinkType == null) {
		log.safeErrorWithoutCustomerData(
			'plans.accessing-property-of-undefined',
			'doAddIssueLink dependencyIssueLinkTypeById[type] is undefined',
		);
	}

	const isOutward = dependencyIssueLinkType?.isOutward;
	const url = urls.add;

	// Check if source and target are the same
	if (sourceItemKey === targetItemKey) {
		return;
	}

	// We check if this dependency already exists
	const outgoingLinksForItem = outgoingLinks[sourceItemKey] || [];

	const existingLinkArray = outgoingLinksForItem.filter(
		(link) => link.targetItemKey === targetItemKey && link.type === type,
	);
	if (existingLinkArray.length > 0) {
		return;
	}

	// If a dependency link type is swapped through the dependency settings page, the entry points of the application swap them target and source
	// so portfolio would handle is properly. Look into domain => system reducer
	// This is the exit point so we swap is back
	const body = {
		description: {
			type: { value: type },
		},
		sourceItemKey: isOutward ? sourceItemKey : targetItemKey,
		targetItemKey: isOutward ? targetItemKey : sourceItemKey,
		scenarioId,
		planId,
	};

	yield put(issueLinksActions.add({ ...payload, itemKey: pendingKey }));

	const response = yield call(fetch, url, {
		method: 'POST',
		body,
	});

	if (response.ok) {
		const result = yield call(response.json.bind(response));
		const {
			itemKey,
			change: { sequence },
		} = result;

		yield put(issueLinksActions.add({ ...payload, itemKey, pendingKey }));

		yield put(sequenceActions.update(sequence));
		if (result) {
			return result;
		}
	} else if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
		yield put(
			deprecatedGenericError({
				...parseError(response, yield call(response.text.bind(response))),
				requestInfo: {
					url,
					type: 'POST',
					status: response.status,
					body,
				},
			}),
		);
		// remove the pending issue link from state
		yield put(issueLinksActions.remove({ ...payload, itemKey: pendingKey }));
	} else {
		const error = new Error(yield call(response.text.bind(response)));
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'add-issue-link-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		// remove the pending issue link from state
		yield put(issueLinksActions.remove({ ...payload, itemKey: pendingKey }));
	}
}

export function* doAddIssueLinkWithExternalPromise(
	action: ActionWithExternalPromise<AddAction>, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const {
		payload: { promise, ...payload },
		type,
	} = action;
	yield call(
		withExternalPromiseResolve,
		function* () {
			return yield call(doAddIssueLink, { type, payload });
		},
		promise,
		true,
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doDeleteIssueLink({ payload }: DeleteAction): Generator<Effect, any, any> {
	yield put(issueLinksActions.remove(payload));
	const { itemKey } = payload;
	const { currentScenarioId: scenarioId, id: planId } = yield select(getPlan);
	const url = urls.delete;
	const body = {
		itemKeys: [itemKey],
		scenarioId,
		planId,
	};
	const response = yield call(fetch, url, {
		method: 'POST',
		body,
	});

	if (response.ok) {
		const result = yield call(response.json.bind(response));
		const {
			change: { sequence },
		} = result;

		yield put(sequenceActions.update(sequence));
	} else if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
		yield put(
			deprecatedGenericError({
				...parseError(response, yield call(response.text.bind(response))),
				requestInfo: {
					url,
					type: 'POST',
					status: response.status,
					body,
				},
			}),
		);
	} else {
		const error = new Error(yield call(response.text.bind(response)));
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'delete-issue-link-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

export function* doDeleteIssueLinkWithExternalPromise(
	action: ActionWithExternalPromise<DeleteAction>, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const {
		payload: { promise, ...payload },
		type,
	} = action;
	yield call(
		withExternalPromiseResolve,
		function* () {
			return yield call(doDeleteIssueLink, { type, payload });
		},
		promise,
	);
}

export function* handleIssueLinkCommitResponse(
	entityResponse: BulkCommitResponseEntity,
	issueId: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	if (entityResponse.success) {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const issueId = entityResponse.itemKey;
		const internalEntity = entityResponse.entity?.entity;
		const error = entityResponse.error;
		const warnings = yield call(inspectForCommitWarnings, internalEntity, error);
		if (warnings.length) {
			// Issue links are represented as issue value in the Review changes dialog.
			// It has two important consequences:
			// 1. Committing link is triggered by the issue which was selected first.
			// 2. Warning produced by issue link commit should be shown as issue warnings.
			// To make things simpler id of the issue which triggered link commit
			// is passed here and warnings are attached to this issue.
			yield put(
				warningActions.add({
					category: ENTITY.ISSUE,
					itemId: issueId,
					warnings,
				}),
			);
		}
	} else {
		yield put(
			warningActions.add({
				category: ENTITY.ISSUE,
				itemId: issueId,
				warnings: [defaultWarning],
			}),
		);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* revertChange(id: string): Generator<Effect, http.JsonResponse<any>, any> {
	const { id: planId, currentScenarioId }: PlanInfo = yield select(getPlan);
	const sequence: Sequence = yield select(getSequence);
	const body = revertBody({ id: planId, currentScenarioId }, sequence, id);

	const response = yield* http.json({
		url: urls.revertChanges,
		method: 'POST',
		body,
	});

	if (response.ok) {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			change: { sequence },
		} = response.data;
		yield put(sequenceActions.update(sequence));
	}

	return response;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchAddIssueLink(): Generator<Effect, any, any> {
	yield takeEvery(ADD_ISSUE_LINK, doAddIssueLinkWithExternalPromise);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchDeleteIssueLink(): Generator<Effect, any, any> {
	yield takeEvery(DELETE_ISSUE_LINK, doDeleteIssueLinkWithExternalPromise);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchAddIssueLink);
	yield fork(watchDeleteIssueLink);
}
