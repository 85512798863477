import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	updateJiraSpotlightTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-button-wrapper.review-changes-button.review-changes-spotlight-manager.review-changes-spotlight.update-jira-spotlight-title',
		defaultMessage: 'Your plan has unsaved changes',
		description:
			'Title content of the spotlight that appears after a user makes a change on the any plan for the first time. This spotlight is anchored to the review changes button and opens a modal to save changes',
	},
	updateJiraSpotlightBody: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-button-wrapper.review-changes-button.review-changes-spotlight-manager.review-changes-spotlight.update-jira-spotlight-body',
		defaultMessage: 'Save issues created or changed in your plan so they update in your projects.',
		description:
			'Body content of the spotlight that appears after a user makes a change on the any plan for the first time. This spotlight is anchored to the review changes button and opens a modal to save changes',
	},
	closeSpotlight: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-button-wrapper.review-changes-button.review-changes-spotlight-manager.review-changes-spotlight.close-spotlight',
		defaultMessage: 'Close unsaved changes spotlight',
		description:
			'Label for the close icon of the unsaved changes spotlight onboarding message that appears after a user has made their first change in plans.',
	},
	updateJiraSpotlightBodyIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-button-wrapper.review-changes-button.review-changes-spotlight-manager.review-changes-spotlight.update-jira-spotlight-body-issue-term-refresh',
		defaultMessage: 'Save issues created or changed in your plan so they update in your projects.',
		description:
			'Body content of the spotlight that appears after a user makes a change on the any plan for the first time. This spotlight is anchored to the review changes button and opens a modal to save changes',
	},
});
export default messages;
