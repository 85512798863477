import React from 'react';
import Button from '@atlaskit/button/standard-button';
import FeedbackIcon from '@atlaskit/icon/glyph/feedback';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import feedbackButtonMessages from './messages.tsx';

const FallbackComponent = () => (
	<Button appearance="subtle" iconBefore={<FeedbackIcon label="" />} isDisabled>
		<FormattedMessage {...feedbackButtonMessages.giveFeedback} />
	</Button>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const FeedbackButtonLazy = lazy(() =>
	import(/* webpackChunkName: "portfolio-3-feedback-collector-lazy" */ './index').then(
		(module) => module.FeedbackButton,
	),
);

export const FeedbackButtonLazyComponent = () => (
	<JSErrorBoundary
		id="advanced-roadmaps-plan.feedback-collector"
		packageName="jira-portfolio-3-feedback-collector"
		teamName="axolotl"
		fallback={FallbackComponent}
	>
		<Placeholder name="feedback-button" fallback={<FallbackComponent />}>
			<FeedbackButtonLazy />
		</Placeholder>
	</JSErrorBoundary>
);
