import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showSprintArrowLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.arrows.arrow.show-sprint-arrow-label',
		defaultMessage: 'Show sprint for {issueSummary}',
		description:
			'Aria label text for arrow button in timeline for showing sprint in the current view',
	},
});
