import React, { forwardRef } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Tooltip from '@atlaskit/tooltip';
import { getAncestorsOfIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import { DEFAULT_SCRATCH_ISSUE_TYPE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/util.tsx';
import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-common/src/api/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, NoUrlCrumbProps } from './types.tsx';

const NoUrlCrumb = forwardRef<HTMLDivElement, NoUrlCrumbProps>(({ className, children }, ref) => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
	<div ref={ref} className={className} style={{ cursor: 'default' }}>
		{children}
	</div>
));

function IssueBreadcrumbs({ issue, issuesById, projectsById, issueTypes }: Props) {
	const issueType = issueTypes.find((type) => type.id === issue.type) || DEFAULT_SCRATCH_ISSUE_TYPE;

	type BreadcrumbProps = {
		href: string;
		target: '_blank';
		text: string;
		component?: typeof NoUrlCrumb;
	};
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getBreadcrumbProps = (issue: Issue): BreadcrumbProps => {
		const projectKey = projectsById[issue.project].key;
		const issueKey = isDefined(issue.issueKey) ? `${projectKey}-${issue.issueKey}` : projectKey;
		const hasUrl = issueKey !== projectKey;
		const url = proxyContextSafeUrl(`/browse/${issueKey}`);

		const props: BreadcrumbProps = {
			href: hasUrl ? url : '',
			target: '_blank',
			text: issueKey,
		};

		if (!hasUrl) {
			props.component = NoUrlCrumb;
		}

		return props;
	};

	const ancestorsOfIssue = getAncestorsOfIssue(issue, issuesById);
	return (
		<Breadcrumbs maxItems={5}>
			{ancestorsOfIssue.length > 0 &&
				// eslint-disable-next-line @typescript-eslint/no-shadow
				ancestorsOfIssue.reverse().map((issue) => (
					<div
						key={`crumb-${projectsById[issue.project].key}-${issue?.issueKey || issue.id}`}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.breadCrumbItem}
					>
						<Tooltip content={issue?.summary || null} position="top">
							<BreadcrumbsItem {...getBreadcrumbProps(issue)} />
						</Tooltip>
					</div>
				))}
			<BreadcrumbsItem
				{...getBreadcrumbProps(issue)}
				iconBefore={
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles['issue-icon']}
						style={{
							backgroundImage: `url(${issueType.iconUrl})`,
						}}
					/>
				}
			/>
		</Breadcrumbs>
	);
}

export default IssueBreadcrumbs;
