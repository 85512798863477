import React, { Component } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl } from '@atlassian/jira-intl';
import { groupSortComparator } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import { GROUPING } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import DialogContent from '../dialog-content/view.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class ProjectOptions extends Component<Props> {
	isProjectDisabled = (projecId: number) =>
		this.props.projectsForLevel.every(({ id }) => id !== projecId);

	createGroupOptionFromProjects = (project: Project): GroupOption => {
		const { groups, intl } = this.props;
		const startInlineCreateInEmptyGroup = !groups.some(
			({ groupCombination }) => groupCombination && groupCombination.project === project.id,
		);
		return {
			group: `project-${project.id}`,
			groupCombination: { project: project.id },
			groupName: project.name,
			groupUrl: project.avatarUrl || '',
			grouping: GROUPING.PROJECT,
			startInlineCreateInEmptyGroup,
			isDisabled: this.isProjectDisabled(project.id),
			groupTooltip: project.isSimplified
				? intl.formatMessage(messages.projectTooltipTmp)
				: intl.formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.projectTooltipIssueTermRefresh
							: messages.projectTooltip,
					),
		};
	};

	getGroupOptionsFromProjects = (): GroupOption[] => {
		const { projects } = this.props;
		return projects.map(this.createGroupOptionFromProjects).sort(groupSortComparator);
	};

	render() {
		const { intl, onGroupClick, hierarchyItem } = this.props;

		return (
			<DialogContent
				groupOptions={this.getGroupOptionsFromProjects()}
				hierarchyItem={hierarchyItem}
				searchPlaceholder={intl.formatMessage(messages.projectSearchPlaceholder)}
				onGroupClick={onGroupClick}
			/>
		);
	}
}

export default injectIntl(ProjectOptions);
