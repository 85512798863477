import React, {
	createContext,
	useCallback,
	useContext,
	useState,
	type ComponentType,
	type ReactElement,
} from 'react';
import * as R from 'ramda';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import type { CSSObject } from '@atlassian/jira-portfolio-3-common/src/common/types/css-object.tsx';
import { selectComponents } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	Props as SelectProps,
	CommonProps,
	ValueType,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { withSlots, slots } from '../../component-slots/index.tsx';
import RollupContainer from '../roll-up/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as rollupStyles from '../roll-up/styles.module.css';
import messages from './messages.tsx';
import type { AsyncSelectWithRollupProps, CellSelectWithRollupProps } from './types.tsx';

type ActualValueLabel =
	| 'releaseActualValueLabel'
	| 'sprintActualValueLabel'
	| 'teamActualValueLabel';

const rollupContext = createContext<{
	rolledUpValues: string[];
	showRolledUpEstimate: boolean;
	// Set to required on FG cleanup smart_links_for_plans
	isSmartLink?: boolean;
}>({
	rolledUpValues: [],
	showRolledUpEstimate: false,
	isSmartLink: false,
});

const ValueContainer = ({
	children,
	...innerProps
}: {
	children: ReactElement;
	selectProps: SelectProps;
}) => {
	const value = innerProps.selectProps.value;
	const { rolledUpValues, showRolledUpEstimate, isSmartLink } = useContext(rollupContext);

	return (
		// @ts-expect-error - TS2740 - Type '{ children: Element; isCreatable?: boolean | undefined; "aria-label"?: string | undefined; autoFocus?: boolean | undefined; blurInputOnSelect?: boolean | undefined; closeMenuOnSelect?: boolean | undefined; ... 36 more ...; onCreateOption?: ((inputValue: string) => void) | undefined; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 6 more.
		<selectComponents.ValueContainer {...innerProps}>
			<RollupContainer
				menuIsOpen={innerProps.selectProps.menuIsOpen}
				isDisabled={innerProps.selectProps.isDisabled}
				showRollup={showRolledUpEstimate && rolledUpValues && rolledUpValues.length > 0}
				isMultiSelect={innerProps.selectProps.isMulti}
				rolledUpValues={rolledUpValues}
				withValue={Array.isArray(value) ? !R.isEmpty(value) : !!value}
				{...(fg('smart_links_for_plans') ? { isSmartLink } : {})}
			>
				{children}
			</RollupContainer>
		</selectComponents.ValueContainer>
	);
};

const getValueLabelKey = (attribute: 'sprint' | 'team' | 'release'): ActualValueLabel => {
	if (attribute === 'sprint') {
		return 'sprintActualValueLabel';
	}
	if (attribute === 'team') {
		return 'teamActualValueLabel';
	}
	return 'releaseActualValueLabel';
};

export const getToolTipContent = ({
	value,
	showRolledUpEstimate,
	rolledUpValues,
	menuIsOpen,
	attribute,
	formatMessage,
}: {
	value: ValueType;
	showRolledUpEstimate: boolean;
	rolledUpValues: string[];
	menuIsOpen: boolean;
	attribute: 'sprint' | 'team' | 'release';
	formatMessage: (descriptor: MessageDescriptor) => string;
}) => {
	let actualValue;
	if (Array.isArray(value)) {
		actualValue = value.map((option) => option.label).join(', ');
	} else {
		actualValue = value ? String(value.label || '') : '';
	}
	// only want the special tooltip if we have rollups and menu is closed
	if (showRolledUpEstimate && rolledUpValues && rolledUpValues.length > 0 && !menuIsOpen) {
		const rolledUpValuesStr = rolledUpValues ? rolledUpValues.join(', ') : '';

		const label = getValueLabelKey(attribute);
		const actualValueTooltipLabel = formatMessage(messages[label]);

		return (
			<>
				{`${actualValue}`.length > 0 && (
					<>
						{' '}
						{actualValueTooltipLabel}: {actualValue}{' '}
					</>
				)}
				<>
					{formatMessage(messages.rolledUpValueLabel)}: {rolledUpValuesStr}
				</>
			</>
		);
	}
	if (`${actualValue}`.length > 0) {
		return <>{actualValue}</>;
	}
	return null;
};

const sharedRollupStyles = (
	menuIsOpen: boolean,
	isMulti: boolean | undefined,
	isSmartLink?: boolean,
) => ({
	multiValueLabel: (styles: CSSObject) => ({
		...styles,
		minWidth: 'auto',
		maxWidth: '240px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		height: '18px',
		padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
		font: token('font.body'),
		lineHeight: '14px',
		paddingLeft: token('space.050', '4px'),
		paddingRight: token('space.050', '4px'),
	}),
	valueContainer: (styles: CSSObject) => ({
		...styles,
		flexWrap: 'nowrap',
	}),
	singleValue: (styles: CSSObject) => ({
		...styles,
		...(menuIsOpen
			? {}
			: {
					top: 0,
					transform: 'none',
					position: 'relative',
				}),
		...(isSmartLink && fg('smart_links_for_plans') ? { color: token('color.text') } : {}),
	}),
	input: () => ({
		flex: isMulti && menuIsOpen ? '1 1 auto' : '1 1 0',
	}),
	multiValueRemove: (styles: CSSObject, state: CommonProps) => ({
		...styles,
		display: state.selectProps.menuIsOpen ? 'flex' : 'none',
	}),
	clearIndicator: (styles: CSSObject, state: CommonProps) => ({
		...styles,
		display: state.selectProps.menuIsOpen ? 'flex' : 'none',
	}),
	container: (
		styles: CSSObject,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		{ selectProps: { menuIsOpen, isMulti } }: CommonProps,
	) => ({
		...styles,
		...(menuIsOpen && isMulti ? { zIndex: 1 } : {}),
	}),
	control: (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		styles: any,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		{ hasValue, selectProps: { menuIsOpen, isMulti } }: CommonProps,
	) =>
		menuIsOpen && isMulti
			? { ...styles, zIndex: 99999 }
			: {
					...styles,
					background: 'none',
					borderColor: 'transparent',
					// eslint-disable-next-line no-useless-computed-key
					[':hover']: {
						...styles[':hover'],
						background: 'none',
						...(hasValue ? { borderColor: 'transparent' } : {}),
					},
				},
});

export const SelectWithRollup = withSlots({ FieldSelect: slots.FieldSelect })((
	props: CellSelectWithRollupProps & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		FieldSelect: ComponentType<any>;
	},
) => {
	// first destructure out all of the props we don't want to pass down
	const {
		rolledUpValues,
		showRolledUpEstimate,
		isSmartLink,
		FieldSelect,
		onMenuOpen,
		onMenuClose,
		...selectProps
	} = props;
	// get remaining props of interest
	const { attribute, value } = selectProps;

	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const intl = useIntl();
	const getToolTipContentFunc = () =>
		getToolTipContent({
			value,
			showRolledUpEstimate,
			rolledUpValues,
			menuIsOpen,
			attribute,
			formatMessage: intl.formatMessage,
		});

	const handleMenuOpen = useCallback(() => {
		setMenuIsOpen(true);
		if (onMenuOpen) {
			onMenuOpen();
		}
	}, [onMenuOpen]);

	const handleMenuClose = useCallback(() => {
		setMenuIsOpen(false);
		if (onMenuClose) {
			onMenuClose();
		}
	}, [onMenuClose]);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={`${rollupStyles.selectRollupContainer} `}>
			<Tooltip content={getToolTipContentFunc()}>
				<rollupContext.Provider
					value={{
						rolledUpValues,
						showRolledUpEstimate,
						isSmartLink: fg('smart_links_for_plans') ? isSmartLink : false,
					}}
				>
					<FieldSelect
						onMenuOpen={handleMenuOpen}
						onMenuClose={handleMenuClose}
						{...selectProps}
						styles={sharedRollupStyles(menuIsOpen, selectProps.isMulti, isSmartLink)}
						components={{
							...selectProps.components,
							ValueContainer,
						}}
						menuIsOpen={menuIsOpen}
						{...(fg('smart_links_for_plans') ? { isSmartLink } : {})}
					/>
				</rollupContext.Provider>
			</Tooltip>
		</div>
	);
});

export const AsyncSelectWithRollup = withSlots({ FieldAsyncSelect: slots.FieldAsyncSelect })((
	props: AsyncSelectWithRollupProps & {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		FieldAsyncSelect: ComponentType<any>;
	},
) => {
	// first destructure out all of the props we don't want to pass down
	const {
		rolledUpValues,
		showRolledUpEstimate,
		isSmartLink,
		onMenuOpen,
		onMenuClose,
		isConfluenceMacro,
		FieldAsyncSelect,
		...asyncSelectProps
	} = props;
	// get remaining props of interest
	const { attribute, value } = asyncSelectProps;

	const [menuIsOpen, setMenuIsOpen] = useState(false);

	const intl = useIntl();

	const getToolTipContentFunc = () =>
		getToolTipContent({
			value,
			showRolledUpEstimate,
			rolledUpValues,
			menuIsOpen,
			attribute,
			formatMessage: intl.formatMessage,
		});

	const handleMenuOpen = useCallback(() => {
		setMenuIsOpen(true);
		if (onMenuOpen) {
			onMenuOpen();
		}
	}, [onMenuOpen]);

	const handleMenuClose = useCallback(() => {
		setMenuIsOpen(false);
		if (onMenuClose) {
			onMenuClose();
		}
	}, [onMenuClose]);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={`${rollupStyles.selectRollupContainer} `}>
			<Tooltip content={getToolTipContentFunc()}>
				<rollupContext.Provider
					value={{
						rolledUpValues,
						showRolledUpEstimate,
						isSmartLink: fg('smart_links_for_plans') ? isSmartLink : false,
					}}
				>
					<FieldAsyncSelect
						onMenuOpen={handleMenuOpen}
						onMenuClose={handleMenuClose}
						{...asyncSelectProps}
						styles={sharedRollupStyles(menuIsOpen, asyncSelectProps.isMulti, isSmartLink)}
						components={{
							...props.components,
							ValueContainer,
						}}
						menuIsOpen={menuIsOpen}
						{...(fg('smart_links_for_plans')
							? { isSmartLink, isDisabled: isSmartLink || asyncSelectProps.isDisabled }
							: {})}
					/>
				</rollupContext.Provider>
			</Tooltip>
		</div>
	);
});

export default SelectWithRollup;
