import React from 'react';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import Toggle from '@atlaskit/toggle';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import {
	type WarningMessages,
	WarningTypes,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/warnings/engine.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	WARNING_TYPE_NUMBER_MAP,
	START_DATE_AFTER_END_DATE,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/warnings/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { IPH_IDS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import DocLink from '@atlassian/jira-portfolio-3-portfolio/src/common/view/doc-link/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import IssueCell from '../issue-link/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const Content = ({
	warnings,
	toggleWarningByType,
	disabledWarnings,
	issuesById,
	setSearchQuery,
	issueTypesById,
	projectsById,
	typeId,
	onTypeIdChange,
	showWarning,
	toggleShowWarning,
	focusRef,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const getOnIssueItemClick = (issue: Issue) => () => {
		const [actionSubject, action] =
			PRODUCT_ANALYTICS_EVENT_NAMES.LOCATED_WARNING_ISSUE_ITEM.split(' ');
		const analyticsEvent = createAnalyticsEvent({ action, actionSubject });
		const warningType = typeof typeId === 'string' ? WARNING_TYPE_NUMBER_MAP[typeId] || null : null;
		fireUIAnalytics(analyticsEvent, {
			warningType,
		});
		setSearchQuery(issue.summary);
	};

	const getOnToggleWarningByType = (key: string, nextState: boolean) => () => {
		const [actionSubject, action] = PRODUCT_ANALYTICS_EVENT_NAMES.TOGGLED_WARNING_TYPE.split(' ');
		const analyticsEvent = createAnalyticsEvent({ action, actionSubject });

		const warningType = WARNING_TYPE_NUMBER_MAP[key] || null;
		fireUIAnalytics(analyticsEvent, {
			selectedOption: nextState ? 1 : 0,
			warningType,
		});
		toggleWarningByType(key);
	};

	const getWarningDialogContent = () => (
		<Box>
			<Box xcss={warningsDialogHeaderStyles}>
				<FormattedMessage {...messages.warningsDialogHeader} />
			</Box>
			<Stack space="space.100">
				{Object.entries(WarningTypes).map(([key, value]: [string, WarningMessages]) => {
					if (!fg('due_date_warning_on_plan_timeline') && key === START_DATE_AFTER_END_DATE) {
						return null;
					}
					const { header } = value;
					const isEnabled = !(disabledWarnings || []).includes(key);
					const issueCount = (warnings[key] && warnings[key].length) || 0;

					return (
						<Inline key={key} spread="space-between">
							<Box xcss={warningToggleAndTextStyles}>
								<Toggle
									isChecked={isEnabled}
									onChange={getOnToggleWarningByType(key, !isEnabled)}
									testId={`portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.toggle-warning-type--${key}`}
									label={formatMessage(header)}
								/>
								<FormattedMessage {...header} />
							</Box>
							<Box>
								{isEnabled && (
									<Box>
										{issueCount === 0 ? (
											// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
											<Button appearance="link" isDisabled className={styles.disabledWarningButton}>
												<FormattedMessage {...messages.zeroCountWarningText} />
											</Button>
										) : (
											<Button
												onClick={() => onTypeIdChange(key)}
												appearance="link"
												isDisabled={issueCount === 0}
												// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
												className={styles.affectedIssuesCount}
												iconAfter={<ArrowRightIcon label="" />}
											>
												<FormattedMessage
													{...(fg('jira-issue-terminology-refresh-m3')
														? messages.warningCountTextIssueTermRefresh
														: messages.warningCountText)}
													values={{
														count: issueCount,
													}}
												/>
											</Button>
										)}
									</Box>
								)}
								{!isEnabled && (
									// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
									<Button appearance="link" isDisabled className={styles.disabledWarningButton}>
										<FormattedMessage {...messages.disabledIssueStatusText} />
									</Button>
								)}
							</Box>
						</Inline>
					);
				})}
			</Stack>
		</Box>
	);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getTypeDefinedWarningDialogContent = (typeId: string) => (
		<Box testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warning-type-modal">
			<Box xcss={issueListHeaderStyles}>
				<FormattedMessage {...WarningTypes[typeId].header} />
				<Box xcss={warningDescriptionStyles}>
					<FormattedMessage {...WarningTypes[typeId].description} />
				</Box>
			</Box>
			<Box xcss={issueListContainerStyles}>
				{(warnings[typeId] || []).map((issueId) => {
					const issue = issuesById[issueId];

					return (
						<IssueCell
							key={issueId}
							onClick={getOnIssueItemClick(issue)}
							issue={issue}
							projectKey={projectsById[issue.project.toString()].key}
							issueType={issueTypesById[issue.type.toString()]}
						/>
					);
				})}
			</Box>
		</Box>
	);

	let content = getWarningDialogContent();

	if (isDefined(typeId) && typeId !== '') {
		content = getTypeDefinedWarningDialogContent(typeId);
	}

	return (
		<Box
			testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warning-centre-modal"
			xcss={modalContentHeightStyles}
		>
			{(!isDefined(typeId) || typeId === '') && (
				<Box xcss={topDescriptionStyles}>
					<Box xcss={warningCenterHeaderStyles} as="h2">
						<FormattedMessage {...messages.warningCenterHeader} />
					</Box>
					<Box xcss={warningCenterIntroStyles}>
						<FormattedMessage
							{...(fg('jira-issue-terminology-refresh-m3')
								? messages.warningsDialogDescriptionAndDocumentationLinkIssueTermRefresh
								: messages.warningsDialogDescriptionAndDocumentationLink)}
							values={{
								link: (
									<HelpPanelLink
										articleId={IPH_IDS.WARNING_CENTER}
										fallbackComponent={
											<DocLink href={DocLink.URLs.warningsDialog} tagRef={focusRef}>
												{formatMessage(messages.iphLink)}
											</DocLink>
										}
										appearance="link"
										spacing="none"
									>
										{formatMessage(messages.iphLink)}
									</HelpPanelLink>
								),
							}}
						/>
					</Box>
					<Box xcss={toggleWrapperStyles}>
						<Toggle
							isChecked={showWarning}
							onChange={toggleShowWarning}
							testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.toggle-warnings-visibility"
							label={formatMessage(messages.showWarningsOnYourTimeline)}
						/>
						<FormattedMessage {...messages.showWarningsOnYourTimeline} />
					</Box>
				</Box>
			)}
			{isDefined(typeId) && typeId !== '' && (
				<Box xcss={backButtonStyles}>
					<Button
						appearance="link"
						iconBefore={<ArrowLeftIcon label="" />}
						onClick={() => onTypeIdChange(null)}
					>
						<FormattedMessage {...messages.goBackToOverview} />
					</Button>
				</Box>
			)}
			<Box xcss={contentBoxStyles}>{content}</Box>
		</Box>
	);
};

export default Content;

// Styles
const warningToggleAndTextStyles = xcss({
	display: 'inline-flex',
	alignItems: 'center',
});

const warningsDialogHeaderStyles = xcss({
	fontWeight: '600',
	paddingLeft: 'space.075',
	paddingBottom: 'space.150',
});

const issueListHeaderStyles = xcss({
	marginBottom: 'space.250',
});

const warningDescriptionStyles = xcss({
	color: 'color.text.subtle',
	font: token('font.body.UNSAFE_small'),
});

const issueListContainerStyles = xcss({
	maxHeight: '400px',
	overflowY: 'auto',
});

const modalContentHeightStyles = xcss({
	maxHeight: 'calc(100vh - 188px)',
});

const topDescriptionStyles = xcss({
	padding: 'space.200',
	backgroundColor: 'color.background.neutral',
});

const warningCenterHeaderStyles = xcss({
	marginBottom: 'space.150',
	font: token('font.heading.small'),
	color: 'color.text',
	marginLeft: 'space.075',
});

const warningCenterIntroStyles = xcss({
	marginBottom: 'space.100',
	marginLeft: 'space.075',
});

const toggleWrapperStyles = xcss({
	alignItems: 'center',
	display: 'flex',
});

const backButtonStyles = xcss({
	marginTop: 'space.150',
});

const contentBoxStyles = xcss({
	padding: 'space.200',
	width: '595px',
});
