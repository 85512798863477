import type { Effect } from 'redux-saga';
import { fork, takeEvery, put, call } from 'redux-saga/effects';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type * as Api from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import {
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import * as issueTypesActions from '../../state/domain/issue-types/actions.tsx';
import { parseError } from '../api.tsx';
import { genericError as deprecatedGenericError } from '../errors/index.tsx';
import { toErrorID } from '../util.tsx';
import { urls } from './api.tsx';

const GET_ISSUE_TYPES_FOR_IDS = 'command.issue-type.GET_ISSUE_TYPES_FOR_IDS' as const;

type GetIssueTypesForIdsPayload = number[];
type GetIssueTypesForIdsAction = {
	type: typeof GET_ISSUE_TYPES_FOR_IDS;
	payload: GetIssueTypesForIdsPayload;
};

export const getIssueTypesForIds = (
	payload: GetIssueTypesForIdsPayload,
): GetIssueTypesForIdsAction => ({
	type: GET_ISSUE_TYPES_FOR_IDS,
	payload,
});

export function* doGetIssueTypesForIds({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: GetIssueTypesForIdsAction): Generator<Effect, any, any> {
	const url = urls.issueTypesForIds;
	const body = { ids: payload };

	const response = yield call(fetch, url, {
		method: 'POST',
		body,
	});

	if (response.ok) {
		const issueTypes: Api.IssueType[] = yield call(response.json.bind(response));

		yield put(issueTypesActions.add(issueTypes));
	} else if (!fg('improve_redux_saga_error_reporting_plans_batch_2')) {
		yield put(
			deprecatedGenericError({
				...parseError(response, yield call(response.text.bind(response))),
				requestInfo: {
					url,
					type: 'POST',
					status: response.status,
					body,
				},
			}),
		);
	} else {
		const error = new Error(yield call(response.text.bind(response)));
		fireErrorAnalytics({
			error,
			meta: {
				id: toErrorID(error, 'get-issue-types-failed'),
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchGetIssueTypesForIds(): Generator<Effect, any, any> {
	yield takeEvery(GET_ISSUE_TYPES_FOR_IDS, doGetIssueTypesForIds);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchGetIssueTypesForIds);
}
