import type { ReactElement } from 'react';
import type {
	EnrichedVersion,
	SolutionVersionsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import type { Mode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import type { EnrichedCrossProjectVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/cross-project-versions/types.tsx';
import type {
	ReleaseBar,
	Mode as MarkerMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/timeline/release-bar/types.tsx';
import type { TimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';

const UNRELEASED = 'UNRELEASED' as const;
const RELEASED = 'RELEASED' as const;
const ONTRACK = 'ONTRACK' as const;
const OFFTRACK = 'OFFTRACK' as const;

export const ICON_TYPES = {
	UNRELEASED,
	RELEASED,
	ONTRACK,
	OFFTRACK,
} as const;

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { OVERVIEW_RELEASES } from '../../../../../../state/ui/main/tabs/roadmap/timeline/release-bar/types';

type Versions = EnrichedVersion | EnrichedCrossProjectVersion;

export type VersionsGroupByDateObject = Record<string, Versions[]>;

export type VersionIdsMapByDate = Record<string, string[]>;

export type RenderMarkerProps = {
	mode: Mode;
	date: string;
	versionIdsMapByDate: VersionIdsMapByDate;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { TimelineRange };

export type OwnProps = {
	renderMarker?: (arg1: RenderMarkerProps, arg2: number | undefined) => ReactElement;
	width: number;
};

export type DispatchProps = {
	closeFlyout: () => void;
	openFlyout: (arg1: string[], arg2: MarkerMode) => void;
};

export type StateProps = {
	crossProjectVersions: EnrichedCrossProjectVersion[];
	mode: Mode;
	releaseBarState: ReleaseBar;
	solutionVersionsById: SolutionVersionsById;
	versions: EnrichedVersion[];
};

export type Props = OwnProps & DispatchProps & StateProps;
