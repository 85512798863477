import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.date.placeholder',
		defaultMessage: 'Choose a date',
		description: 'The placeholder for the date picker',
	},
	startDateLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.date.start-date-label',
		defaultMessage: 'Choose target start date for issue {issue}',
		description: 'The label for the target date picker',
	},
	endDateLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.date.end-date-label',
		defaultMessage: 'Choose target end date for issue {issue}',
		description: 'The label for the target date picker',
	},
	dateLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.date.date-label',
		defaultMessage: 'Choose date field for issue {issue}',
		description: 'The label for the date picker',
	},
	projectedDatesUsingSprintTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.date.projected-dates-using-sprint-tooltip',
		defaultMessage: "This issue has no dates, so it's using the dates of its assigned sprint.",
		description: 'The label for the projected dates based on assigned sprint',
	},
	projectedDatesUsingReleasesTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.date.projected-dates-using-releases-tooltip',
		defaultMessage: "This issue has no dates, so it's using the dates of its assigned releases.",
		description: 'The label for the projected dates based on assigned releases',
	},
	noDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.date.no-date',
		defaultMessage: 'No date',
		description: 'A hint to indicate that roll-up ended in no date for this column',
	},
	startDateLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.date.start-date-label-issue-term-refresh',
		defaultMessage: 'Choose target start date for issue {issue}',
		description: 'The label for the target date picker',
	},
	endDateLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.date.end-date-label-issue-term-refresh',
		defaultMessage: 'Choose target end date for issue {issue}',
		description: 'The label for the target date picker',
	},
	dateLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.date.date-label-issue-term-refresh',
		defaultMessage: 'Choose date field for issue {issue}',
		description: 'The label for the date picker',
	},
	projectedDatesUsingSprintTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.date.projected-dates-using-sprint-tooltip-issue-term-refresh',
		defaultMessage: "This issue has no dates, so it's using the dates of its assigned sprint.",
		description: 'The label for the projected dates based on assigned sprint',
	},
	projectedDatesUsingReleasesTooltipIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.date.projected-dates-using-releases-tooltip-issue-term-refresh',
		defaultMessage: "This issue has no dates, so it's using the dates of its assigned releases.",
		description: 'The label for the projected dates based on assigned releases',
	},
});
