import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	percentage: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.percentage',
		defaultMessage: '({percentage}%)',
		description: 'Text for percentage value of progress',
	},
	todo: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.todo',
		defaultMessage: 'To do',
		description: 'Label for the number of descendant issues remaining to do',
	},
	inprogress: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.inprogress',
		defaultMessage: 'In progress',
		description: 'Label for the number of descendant issues that are currently in progress',
	},
	done: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.done',
		defaultMessage: 'Done',
		description: 'Label for the number of descendant issues that are currently done',
	},
	completed: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.completed',
		defaultMessage: 'Completed',
		description: 'Label for the number of units that are currently completed',
	},
	remaining: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.remaining',
		defaultMessage: 'Remaining',
		description: 'Label for the number of units that are currently remaining',
	},
	total: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.total',
		defaultMessage: 'Total',
		description: 'Label for the total number',
	},
	estimatedIssuesLabel: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.estimated-issues-label',
		defaultMessage: 'Estimated issues',
		description: 'estimated issues label',
	},
	unestimatedIssuesLabel: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.unestimated-issues-label',
		defaultMessage: 'Unestimated issues',
		description: 'unestimated issues label',
	},
	issueCountLabel: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.issue-count-label',
		defaultMessage:
			'{numberOfIssues, plural, one {{numberOfIssues} issue} other {{numberOfIssues} issues}}',
		description: 'Issue count label',
	},
	storyPointsCountLabel: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.story-points-count-label',
		defaultMessage: '{storyPoints, plural, one {{storyPoints} point} other {{storyPoints} points}}',
		description: 'Story points label',
	},
	hoursCountLabel: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.hours-count-label',
		defaultMessage: '{hours, plural, one {{hours} hour} other {{hours} hours}}',
		description: 'Hours label',
	},
	daysCountLabel: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.days-count-label',
		defaultMessage: '{days, plural, one {{days} day} other {{days} days}}',
		description: 'Days label',
	},
	totalIssuesLabel: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.total-issues-label',
		defaultMessage: 'Total issues',
		description: 'Label for total issues',
	},
	notAccurateProgress: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.not-accurate-progress',
		defaultMessage: '*Progress may not be accurate due to unestimated issues',
		description: 'Warning about non accurate progress due to un-estimated items',
	},
	individualIssueBreakdownLabel: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.individual-issue-breakdown-label',
		defaultMessage: '{percent}% | {remaining} out of {total} remaining',
		description: 'Label for breakdown of progress of individual issue',
	},
	individualIssueUnestimatedLabel: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.individual-issue-unestimated-label',
		defaultMessage: 'Unestimated',
		description: 'Label for unestimated individual issue',
	},
	estimatedIssuesLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.estimated-issues-label-issue-term-refresh',
		defaultMessage: 'Estimated issues',
		description: 'estimated issues label',
	},
	unestimatedIssuesLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.unestimated-issues-label-issue-term-refresh',
		defaultMessage: 'Unestimated issues',
		description: 'unestimated issues label',
	},
	issueCountLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.issue-count-label-issue-term-refresh',
		defaultMessage:
			'{numberOfIssues, plural, one {{numberOfIssues} issue} other {{numberOfIssues} issues}}',
		description: 'Issue count label',
	},
	totalIssuesLabelIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.total-issues-label-issue-term-refresh',
		defaultMessage: 'Total issues',
		description: 'Label for total issues',
	},
	notAccurateProgressIssueTermRefresh: {
		id: 'portfolio-3-portfolio.common.status-breakdown.components.not-accurate-progress-issue-term-refresh',
		defaultMessage: '*Progress may not be accurate due to unestimated issues',
		description: 'Warning about non accurate progress due to un-estimated items',
	},
});
