import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	childIconLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.create-issue-type.child-icon-label',
		defaultMessage: 'Child',
		description: 'Label for child icon',
	},
	createIssue: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.create-issue-type.create-issue',
		defaultMessage: 'Create {issueType}',
		description: 'Label for drop menu to create new issue from an issue',
	},
	createIssueIssueTermRefresh: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.create-issue-type.create-issue-issue-term-refresh',
		defaultMessage: 'Create {issueType}',
		description: 'Label for drop menu to create new issue from an issue',
	},
});
