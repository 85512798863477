import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { put, call, select, fork, takeEvery } from 'redux-saga/effects';
import { fireErrorAnalytics } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
	ENTITY,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getPlan } from '../../query/plan/index.tsx';
import {
	getCapacityForTeamAndIteration,
	getOriginalCapacityForTeamAndIteration,
	getOriginalPlannedCapacities,
} from '../../query/sprints/index.tsx';
import type { PlannedCapacityChange } from '../../query/sprints/types.tsx';
import { getSequence } from '../../query/update-jira/index.tsx';
import {
	update as updateOriginalFromDomain,
	remove as removeOriginalFromDomain,
	reset as resetOriginalPlannedCapacities,
} from '../../state/domain/original-planned-capacities/actions.tsx';
import type { PlanInfo } from '../../state/domain/plan/types.tsx';
import {
	update as updateFromDomain,
	remove as removeFromDomain,
} from '../../state/domain/planned-capacities/actions.tsx';
import type {
	PlannedCapacity,
	PlannedCapacities,
} from '../../state/domain/planned-capacities/types.tsx';
import { update as updateSequence } from '../../state/domain/sequence/actions.tsx';
import type { Sequence } from '../../state/domain/sequence/types.tsx';
import { add as addCommitWarning } from '../../state/domain/update-jira/warnings/actions.tsx';
import { POST } from '../api.tsx';
import batch from '../batch/index.tsx';
import type { BulkCommitResponseEntity } from '../commit-bulk/types.tsx';
import { revertBody } from '../commit/api.tsx';
import { genericError as deprecatedGenericError } from '../errors/index.tsx';
import { jsonOrError, type JsonResponse } from '../http/index.tsx';
import { toErrorID } from '../util.tsx';
import { type UpdatePlannedCapacityPayload, urls } from './api.tsx';
import { warning as defaultWarning } from './messages.tsx';

export const UPDATE = 'command.planned-capacities.UPDATE' as const;
export const RESET = 'command.planned-capacities.RESET' as const;
export const REMOVE = 'command.planned-capacities.REMOVE' as const;

export type ResetAction = {
	type: typeof RESET;
	payload: PlannedCapacities;
};

export type UpdateAction = {
	type: typeof UPDATE;
	payload: PlannedCapacity;
};

export type RevertAction = {
	type: typeof UPDATE;
	payload: {
		teamId: string;
		sprintId: string;
	};
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: PlannedCapacity;
};

type ActionWithExternalPromise<T> = T & {
	payload: {
		promise?: {
			resolve: Function;
			reject: Function;
		};
	};
};

export const reset = (payload: PlannedCapacities) => ({
	type: RESET,
	payload,
});

export const update = (payload: PlannedCapacity) => ({
	type: UPDATE,
	payload,
});

export const remove = (payload: PlannedCapacity) => ({
	type: REMOVE,
	payload,
});

export function* withExternalPromiseResolve(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	block: () => Generator<Effect, any, any>,
	promise: { resolve: Function; reject: Function } | undefined, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	if (!promise || !promise?.resolve || !promise?.reject) {
		return yield call(block);
	}

	try {
		const result = yield call(block);
		promise.resolve(result); // eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		promise.reject(err);
	}
}

export function* handlePlannedCapacityBulkCommitResponse(
	entityResponse: BulkCommitResponseEntity,
	plannedCapacityChange: PlannedCapacityChange, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	// We need to query the actual value here so we know if we added/updated a capacity
	// or if we removed it
	const capacitySelectorProps = R.pick(['teamId', 'iterationId'], plannedCapacityChange.values);
	const currentPlannedCapacity = yield select(
		getCapacityForTeamAndIteration,
		capacitySelectorProps,
	);
	const wasRemoved = !currentPlannedCapacity;

	const id = entityResponse.itemKey;
	const newId = entityResponse.generatedId;
	// Check if the capacity has a new ID that must replace the old one
	// (happens when a capacity change in the scenario is committed for the first time)
	const hasNewId = isDefined(newId) && id !== newId;
	if (!wasRemoved && hasNewId) {
		// Update state with the new item key
		yield put(
			updateFromDomain({
				...plannedCapacityChange.values,
				itemKey: newId,
			}),
		);
	}

	const { teamId, iterationId } = plannedCapacityChange.values;

	const originals = yield select(getOriginalPlannedCapacities);
	yield put(resetOriginalPlannedCapacities(R.dissocPath([teamId, iterationId], originals)));
}

// When it gets reverted from review dialog
export function* revertPlannedCapacityChange(
	plannedCapacityChange: PlannedCapacityChange, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, JsonResponse<any> | undefined, any> {
	const { id: planId, currentScenarioId }: PlanInfo = yield select(getPlan);
	const sequence: Sequence = yield select(getSequence);

	if (!isDefined(plannedCapacityChange.values.itemKey)) {
		return;
	}

	if (!isDefined(planId)) {
		return;
	}

	const body = revertBody(
		{ id: planId, currentScenarioId },
		sequence,

		plannedCapacityChange.values.itemKey,
	);

	// We need to query out the original here, instead of getting it from plannedCapacityChange,
	// because we need to know if the original capacity value is NULL (i.e. default). Whereas
	// `plannedCapacityChange.originals.capacity` will be resolved to an actual number, so
	// there's no way to know if we're reverting back to that number or to NULL.
	const capacitySelectorProps = R.pick(['teamId', 'iterationId'], plannedCapacityChange.values);
	const original = yield select(getOriginalCapacityForTeamAndIteration, capacitySelectorProps);

	const { iterationId } = plannedCapacityChange.values;
	const response = yield call(jsonOrError, {
		url: urls.revertPlannedCapacity,
		method: POST,
		body,
	});

	if (response.ok) {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			change: { sequence },
		} = response.data;
		yield put(updateSequence(sequence));

		if (original.capacity === null) {
			const removePayload = R.pick(['teamId', 'iterationId'], plannedCapacityChange.values);
			yield put(removeFromDomain(removePayload));
		} else {
			yield put(updateFromDomain(original));
		}
	} else {
		yield put(
			addCommitWarning({
				category: ENTITY.PLANNED_CAPACITY,
				itemId: iterationId,
				warnings: [defaultWarning],
			}),
		);
	}

	return response;
}

export const getPlannedCapacityDescriptionPayload = (action: UpdateAction) => {
	const payload: UpdatePlannedCapacityPayload = {
		teamKey: { value: action.payload.teamId },
		iterationId: { value: parseInt(action.payload.iterationId, 10) },
		schedulingMode: { value: action.payload.schedulingMode },
		planningUnit: { value: action.payload.planningUnit },
		capacity: { value: action.payload.capacity },
	};

	return payload;
};

// When it gets updated to scenario (e.g from flyout)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doUpdatePlannedCapacity(action: UpdateAction): Generator<Effect, void, any> {
	const { id: planId, currentScenarioId: scenarioId }: PlanInfo = yield select(getPlan);

	if (!isDefined(planId)) {
		return;
	}

	const capacitySelectorProps = R.pick(['teamId', 'iterationId'], action.payload);
	const original = yield select(getOriginalCapacityForTeamAndIteration, capacitySelectorProps);
	const currentPlannedCapacity: PlannedCapacity | undefined = yield select(
		getCapacityForTeamAndIteration,
		capacitySelectorProps,
	);

	const isAddingNewCapacity = !original && !currentPlannedCapacity;

	let originalUpdatePayload: PlannedCapacity;

	if (!original) {
		if (currentPlannedCapacity) {
			originalUpdatePayload = currentPlannedCapacity;
		} else {
			originalUpdatePayload = { ...action.payload, capacity: null };
		}
		yield put(updateOriginalFromDomain(originalUpdatePayload));
	} else {
		originalUpdatePayload = original;
	}

	const endpoint = isAddingNewCapacity ? urls.addPlannedCapacity : urls.updatePlannedCapacity;
	const { itemKey } = isAddingNewCapacity
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			({} as PlannedCapacity)
		: currentPlannedCapacity || original || {};

	// Pre response update (better User experience) so they dont see the request lag
	yield put(
		updateFromDomain({
			...action.payload,
			itemKey,
		}),
	);

	const response = yield call(jsonOrError, {
		url: endpoint,
		method: POST,
		body: {
			planId,
			scenarioId,
			itemKey,
			description: getPlannedCapacityDescriptionPayload(action),
		},
	});

	if (response.ok) {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			itemKey,
			change: { sequence },
		} = response.data;

		yield* batch(function* () {
			// Update the sequence
			yield put(updateSequence(sequence));

			// If we've created a new planned capacity, set the itemKey in redux
			if (isAddingNewCapacity) {
				yield put(updateOriginalFromDomain({ ...originalUpdatePayload, itemKey }));
				yield put(updateFromDomain({ ...action.payload, itemKey }));
			}
		});

		if (response.data) {
			return response.data;
		}
	} else {
		// eslint-disable-next-line no-lonely-if
		if (fg('improve_redux_saga_error_reporting_plans_batch_3')) {
			const error = new Error(yield call(response.text.bind(response)));
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'do-update-planned-capacity-fetch-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		} else {
			const { error } = response.data;
			yield put(deprecatedGenericError({ message: error }));
		}
	}
}

export function* doUpdatePlannedCapacityWithExternalPromise(
	action: ActionWithExternalPromise<UpdateAction>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	const {
		payload: { promise, ...payload },
		type,
	} = action;
	yield call(
		withExternalPromiseResolve,
		function* () {
			return yield call(doUpdatePlannedCapacity, { type, payload });
		},
		promise,
	);
}

// When we press enter on empty input, we want to set it back to default
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doRemovePlannedCapacity(action: RemoveAction): Generator<Effect, void, any> {
	const { id: planId, currentScenarioId: scenarioId }: PlanInfo = yield select(getPlan);

	const capacitySelectorProps = R.pick(['teamId', 'iterationId'], action.payload);
	const currentPlannedCapacity = yield select(
		getCapacityForTeamAndIteration,
		capacitySelectorProps,
	);
	const original = yield select(getOriginalCapacityForTeamAndIteration, capacitySelectorProps);

	if (!isDefined(planId)) {
		return;
	}

	if (!currentPlannedCapacity) {
		return;
	}

	if (!original) {
		const clone = yield select(getCapacityForTeamAndIteration, capacitySelectorProps);
		if (clone) {
			yield put(updateOriginalFromDomain(clone));
		}
	}

	yield put(removeFromDomain(capacitySelectorProps));

	if (original && original.capacity === null) {
		// original planned capacity is actually the default value
		yield put(removeOriginalFromDomain(capacitySelectorProps));
	}

	const response = yield call(jsonOrError, {
		url: urls.deletePlannedCapacity,
		method: POST,
		body: {
			planId,
			scenarioId,
			itemKeys: [currentPlannedCapacity.itemKey],
		},
	});

	if (response.ok) {
		const {
			change: { sequence },
		} = response.data;

		// Update the sequence
		yield put(updateSequence(sequence));
		if (response.data) {
			return response.data;
		}
	} else {
		// eslint-disable-next-line no-lonely-if
		if (fg('improve_redux_saga_error_reporting_plans_batch_3')) {
			const error = new Error(yield call(response.text.bind(response)));
			fireErrorAnalytics({
				error,
				meta: {
					id: toErrorID(error, 'do-remove-planned-capacity-fetch-failed'),
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		} else {
			const { error } = response.data;
			yield put(deprecatedGenericError({ message: error }));
		}
	}
}

export function* doRemovePlannedCapacityWithExternalPromise(
	action: ActionWithExternalPromise<RemoveAction>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	const {
		payload: { promise, ...payload },
		type,
	} = action;
	yield call(
		withExternalPromiseResolve,
		function* () {
			return yield call(doRemovePlannedCapacity, { type, payload });
		},
		promise,
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdatePlannedCapacity(): Generator<Effect, void, any> {
	yield takeEvery(UPDATE, doUpdatePlannedCapacityWithExternalPromise);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchRemovePlannedCapacity(): Generator<Effect, void, any> {
	yield takeEvery(REMOVE, doRemovePlannedCapacityWithExternalPromise);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, void, any> {
	yield fork(watchUpdatePlannedCapacity);
	yield fork(watchRemovePlannedCapacity);
}
