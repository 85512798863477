import {
	getPlanModeForAnalytics,
	isReportMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getAssociatedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/associated-issues/index.tsx';
import { getDefinedGoals } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-goals/index.tsx';
import { getPlanTitle } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { getEnhancedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/query.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { MapStateToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { StateProps } from './types.tsx';

const noIssuesInPlan = createSelector(
	[getEnhancedIssues],
	(enhancedIssues) => enhancedIssues.length === 0,
);

const mapStateToProps: MapStateToPropsSimple<State, StateProps> = (state): StateProps => ({
	isReportMode: isReportMode(state),
	noIssuesInPlan: noIssuesInPlan(state),
	definedGoalsByARI: getDefinedGoals(state),
	associatedIssues: getAssociatedIssues(state),
	planTitle: getPlanTitle(state),
	planModeForAnalytics: getPlanModeForAnalytics(state),
});

export default mapStateToProps;
